import React, { FC } from 'react'
import { TextField } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/Edit'
import CustomerForm from './CustomerForm'

type Props = {}

const CustomerEdit: FC<Props> = props => (
  <Edit {...props} component="div">
    <SectionedForm variant="outlined" redirect="list">
      <FormSection title="ID">
        <TextField source="id" addLabel={false} />
      </FormSection>
      <CustomerForm />
    </SectionedForm>
  </Edit>
)

export default CustomerEdit
