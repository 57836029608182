import React, { FC } from 'react'
import { NumberInput, TextInput, NullableBooleanInput } from 'react-admin'
import Filter from '../../components/Filter'

type Props = {
  //
}

const EventStatusFilters: FC<Props> = (props) => (
  <Filter {...props}>
    <NumberInput source="id" />
    <TextInput source="name" alwaysOn />
  </Filter>
)
export default EventStatusFilters
