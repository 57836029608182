import React, { FC, useCallback } from 'react'
import RAList from '../../components/List'
import { TextField, NumberField, Datagrid, BooleanField, FunctionField } from 'react-admin'
import EditButtonWPermission from '../../components/EditButtonWPermission'
import EventStatusFilters from './EventStatusFilters'
import { ColorField } from 'react-admin-color-input'

const sort = { field: 'order', order: 'ASC' }
// const filterDefaultValues = { active: true }

type Props = {
  //
}

const EventStatusList: FC<Props> = (props) => {
  return (
    <RAList {...{ sort }} {...props} filters={<EventStatusFilters />}>
      <Datagrid>
        <NumberField source="id" />
        <TextField source="name" />
        <ColorField source="color" />
        <NumberField source="order" />
        <EditButtonWPermission {...props} />
      </Datagrid>
    </RAList>
  )
}

export default EventStatusList
