import React, { FC } from 'react'
import { Box, Typography } from '@material-ui/core'
import { SelectInput, BooleanInput, NumberInput } from 'react-admin'
import { useTranslate } from 'ra-core'
import { useFormState } from 'react-final-form'
import InputAdornment from '@material-ui/core/InputAdornment'
import PriceInput from './PriceInput'

type Props = {}

const calculateDiscount = (value: number, discount: number): number => {
  return value - value * (discount / 100)
}

const ProductDiscountInput: FC<Props> = (props) => {
  const translate = useTranslate()
  const { values } = useFormState()

  return (
    <Box pl="1rem" flexDirection="column" pt="1rem">
      <Typography variant="h5">{translate('customFields.discountSection.title')}</Typography>
      <Box display="flex" flexDirection="column" pb="1.5rem">
        <Box flex={1}>
          <BooleanInput
            fullWidth
            source="discountActive"
            label={translate('resources.Product.fields.discountActive')}
          />
        </Box>
        <Box flex={1}>
          <SelectInput
            fullWidth
            disabled={!values.discountActive}
            source="discountType"
            variant="outlined"
            label={translate('resources.Product.fields.discountType.field')}
            choices={[
              { id: 'ABSOLUTE', name: translate('resources.Product.fields.discountType.absolute') },
              { id: 'PERCENTAGE', name: translate('resources.Product.fields.discountType.percentage') },
            ]}
          />
        </Box>
        <Box display="flex" flex={1}>
          <Box>
            {values.discountType === 'PERCENTAGE' ? (
              <NumberInput
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                disabled={!values.discountActive}
                source="discountValue"
                label={translate('resources.Product.fields.discountValue')}
              />
            ) : (
              <PriceInput
                fullWidth
                source="discountValue"
                label={translate('resources.Product.fields.discountValue')}
              />
            )}
          </Box>

          <Box display="flex" flexDirection="column" pl="1rem" pt="0.5rem" alignItems="center">
            {values.discountActive && (
              <>
                <Typography variant="subtitle2">{translate('resources.Product.fields.discountPrice')}</Typography>
                <Typography variant="h6">
                  {values.discountType === 'ABSOLUTE'
                    ? (values.retailPrice - values.discountValue).toFixed(2)
                    : calculateDiscount(values.retailPrice, values.discountValue).toFixed(2)}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ProductDiscountInput
