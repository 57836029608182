import React, { Fragment, FC, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep'
import classnames from 'classnames'
import { Confirm } from 'react-admin'
import { useDataProvider, useRedirect } from 'ra-core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

const DeleteAllRecurringEventsButton: FC<any> = (props) => {
  const {
    basePath,
    classes: classesOverride,
    className,
    confirmTitle = 'ra.message.delete_title',
    icon,
    label = 'ra.action.delete',
    record,
    plannedEvent,
    ...rest
  } = props

  const classes = useStyles(props)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const dataProvider = useDataProvider()
  const redirect = useRedirect()

  const [loading, setLoading] = useState<boolean>(false)
  const [isDeleted, setIsDeleted] = useState<boolean>(false)

  const [transactions, setTransactions] = useState<any[]>([])
  const [drivingPackages, setDrivingPackages] = useState<any[]>([])
  // const [oldAccountingCard, setOldAccountingCard] = useSstate<any>({})
  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false)

  const handleDialogClose = (): void => setIsDialogOpen(false)
  const handleDialogOpen = (): void => setIsDialogOpen(true)

  const handleDelete = async (): Promise<void> => {
    setLoading(true)
    // console.log('xxx esisto1')
    if (transactions?.length) {
      for (let i = 0; i < transactions.length; i++) {
        const element = transactions[i]
        const a = await dataProvider.delete('AccountingCardTransaction', {
          id: element.id,
          previousData: { id: element.id },
        })
        // console.log('xxx removing a whole transaction, id: ', element.id)
      }
    }
    if (drivingPackages?.length) {
      for (let i = 0; i < drivingPackages.length; i++) {
        const element = drivingPackages[i]
        const a = await dataProvider.delete('TransactionPlannedEvent', {
          id: element.associationId,
          previousData: { id: element.associationId },
        })
        // console.log('xxx deleting only TPE from a package, ', element.associationId, element.id)
      }
    }

    const xxx = await dataProvider.delete('RecurringEvent', {
      id: record.recurringEventId,
      previousData: { id: record.recurringEventId },
    })

    setIsDeleted(true)
    setLoading(false)
    redirect(props.redirect)
  }

  const checkAccountingCard = async (): Promise<void> => {
    setIsDialogLoading(true)
    // console.log('checking accounting cards...')
    // STEP 1 - TROVARE TUTTI GLI EVENTI CON QUESTO recurringEventId
    const plannedEvents = await dataProvider.getList('PlannedEvent', {
      filter: { recurringEventId: record.recurringEventId },
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'id', order: 'DESC' },
    })

    if (plannedEvents?.data?.length) {
      const plannedEventIds = plannedEvents.data.map(({ id }) => id)
      // console.log('xxx peIDS ', plannedEventIds)
      const tpes = await dataProvider.getList('TransactionPlannedEvent', {
        filter: { plannedEventIds },
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'id', order: 'DESC' },
      })
      // console.log('xxx TPES ', tpes)
      if (tpes?.data?.length) {
        const ACTransactions = []

        for (let i = 0; i < tpes.data.length; i++) {
          const id = tpes.data[i].accountingCardTransactionId
          const accountingCardTransaction = await dataProvider.getOne('AccountingCardTransaction', { id })

          ACTransactions.push({ ...accountingCardTransaction.data, associationId: tpes.data[i].id })
        }
        // const accountingCardId = (ACTransactions[0] as any).accountingCardId
        // const card = await dataProvider.getOne('AccountingCard', { id: accountingCardId })
        // console.log('xxx cardData: ', card.data)
        // setOldAccountingCard(card.data)
        setDrivingPackages(ACTransactions.filter((elem: any) => elem.isDrivingPackage))
        setTransactions(ACTransactions.filter((elem: any) => !elem.isDrivingPackage))
      }
      setIsDialogLoading(false)
    }
  }

  useEffect(() => {
    // cannot avoid this check, because other related events could have different fields
    checkAccountingCard()
  }, [])

  return (
    <Fragment>
      <Button
        onClick={handleDialogOpen}
        label={label}
        className={classnames('ra-delete-button', classes.deleteButton, className)}
        key="button"
        variant="contained"
        disabled={isDialogLoading}
        {...rest}
      >
        {isDialogLoading ? <CircularProgress size={18} thickness={2} /> : <DeleteSweepIcon className={classes.icon} />}
        {'Elimina tutti gli eventi ricorrenti'}
      </Button>
      <Confirm
        isOpen={isDeleted ? false : isDialogOpen}
        loading={loading}
        title={confirmTitle}
        content={`Sei sicuro di voler cancellare questo evento e tutti gli eventi ricorrenti associati? (id: ${
          plannedEvent.recurringEventId
        }) ${
          transactions.length || drivingPackages.length
            ? // eslint-disable-next-line quotes
              `\n\n(Verranno anche cancellate delle transazioni in uscita associate a questi eventi)`
            : ''
        }`}
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </Fragment>
  )
}

const useStyles = makeStyles(
  (theme) => ({
    icon: { marginRight: theme.spacing(1) },
    deleteButton: {
      flexGrow: 2,
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
  { name: 'RaDeleteWithConfirmButton' }
)

export default DeleteAllRecurringEventsButton
