import React, { FC, useCallback, useEffect } from 'react'
import {
  NumberInput,
  ReferenceField,
  TextField,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
  TextInput,
  useQuery,
  useMutation,
  DateInput,
  useTranslate,
} from 'react-admin'
import { useForm } from 'react-final-form'
import { makeStyles } from '@material-ui/core'

import FormSection from '../../components/forms/FormSection'
import Edit from './../../components/Edit'
import SectionedForm from './../../components/forms/SectionedForm'
import CreateQuoteItem from '../Quote/CreateQuoteItem'
import OrderEditSummary from './OrderEditSummary'
import BaseButton from '@material-ui/core/Button'
import ContentAdd from '@material-ui/icons/Add'
// import ProductImageName from './ProductImageName'
import CustomerContactsField from '../../components/CustomFields/CustomerContactsField'

type Props = {}

const useStyles = makeStyles({
  form: {
    display: 'flex',
    alignItems: 'baseline',
  },
  root: {
    maxWidth: 128,
    marginLeft: 16,
  },
  line: {
    display: 'flex',
    alignItems: 'stretch',
    alignContent: 'center',
    borderBottom: 0,
  },
})

const EditQuoteItem: FC<any> = ({ formData, scopedFormData, getSource, ...rest }) => {
  const translate = useTranslate()
  const dataForm = useForm()
  const { root } = useStyles()
  // const { data, loading, error } = useQuery({
  //   type: 'getOne',
  //   resource: 'OrderItem',
  //   payload: { id: scopedFormData.id },
  // })

  const [getProd, { loading, data: dataProd, error: error }] = useMutation()

  useEffect(() => {
    if (!loading && scopedFormData && scopedFormData.productId && !dataProd) {
      getProd({
        type: 'getOne',
        resource: 'Product',
        payload: { id: scopedFormData.productId },
      })
    }
  }, [loading])

  useEffect(() => {
    if (dataProd) {
      // dataForm.change(`${rest.id}.productId`, dataProd.originalId)
    }
  }, [dataProd])

  return loading || error || !dataProd ? null : (
    <>
      {dataProd && dataProd.name && (
        <TextInput
          {...rest}
          disabled
          value={dataProd.name.toString()}
          label={translate('resources.Order.fields.product')}
        />
      )}
      <NumberInput
        {...rest}
        source={getSource('quantity')}
        label={translate('resources.Order.fields.quantity')}
        initialValue={scopedFormData.quantity}
        classes={{ root }}
        disabled={scopedFormData.paid}
      />
      <NumberInput
        {...rest}
        source={getSource('productPriceOverride')}
        label={translate('resources.Order.fields.price')}
        initialValue={scopedFormData.productPriceOverride}
        classes={{ root }}
        disabled={scopedFormData.paid}
      />
      <NumberInput
        {...rest}
        source={getSource('taxRateOverride')}
        label={translate('resources.Order.fields.tax')}
        initialValue={scopedFormData.taxRateOverride}
        classes={{ root }}
        disabled={scopedFormData.paid}
      />
      {/* {scopedFormData && scopedFormData.productId && (
        <div style={{ display: 'inline-block' }}>
          <ProductImageName orderProductId={scopedFormData.productId} isInIterator hideName />
        </div>
      )} */}
    </>
  )
}

const OrderEdit: FC<Props> = (props) => {
  const translate = useTranslate()
  const { form, line } = useStyles()

  const renderNotes = useCallback(
    ({ formData, ...rest }: any) =>
      formData && formData.notes ? (
        <FormSection title={translate('resources.Order.sections.info')} {...rest}>
          {formData.approved ? (
            <TextField source="notes" fullWidth multiline label={translate('resources.Customer.fields.notes')} />
          ) : (
            <TextInput source="notes" fullWidth multiline label={translate('resources.Customer.fields.info')} />
          )}
        </FormSection>
      ) : null,
    []
  )

  const renderItemRow = useCallback((formDataProps: any) => {
    if (formDataProps && formDataProps.scopedFormData && formDataProps.scopedFormData.id)
      return <EditQuoteItem {...formDataProps} />
    else return <CreateQuoteItem {...formDataProps} />
  }, [])

  const transformData = useCallback((values) => {
    console.log('sono i values', values)
    return {
      ...values,
      orderItems: values.orderItems.map((item: any, index: number) => {
        return {
          id: item.id,
          productId: item.productId,
          quantity: item.quantity,
          productPriceOverride: item.productPriceOverride,
          taxRateOverride: item.taxRateOverride,
          itemPosition: index + 1,
        }
      }),
    }
  }, [])

  return (
    <Edit {...props} transform={transformData} component="div">
      <SectionedForm variant="outlined" redirect="show">
        <FormSection title={translate('resources.Order.sections.customer')} {...props}>
          <ReferenceField source="customerId" reference="Customer" label={translate('resources.Order.fields.customer')}>
            <TextField source="fullName" />
          </ReferenceField>
          <ReferenceField
            source="customerId"
            reference="Customer"
            label={translate('resources.Customer.fields.fiscalCode')}
            link={false}
          >
            <TextField source="fiscalCode" emptyText="N.D." />
          </ReferenceField>
          <ReferenceField
            source="customerId"
            reference="Customer"
            label={translate('resources.Customer.fields.email')}
            link={false}
          >
            <CustomerContactsField contactType="email" />
          </ReferenceField>
          <ReferenceField
            source="customerId"
            reference="Customer"
            label={translate('resources.Customer.fields.phone')}
            link={false}
          >
            <CustomerContactsField contactType="sms" />
          </ReferenceField>
          <DateInput source="orderDate" defaultValue={new Date().toString()} />
          <DateInput source="expiringDate" />
        </FormSection>
        <FormDataConsumer>
          {({ formData, ...rest }: any): any => (
            <FormSection title={translate('resources.Order.sections.list')} fullWidth {...rest}>
              <ArrayInput source="orderItems" label={false} {...rest}>
                <SimpleFormIterator
                  classes={{ form, line }}
                  {...rest}
                  // disableRemove
                  addButton={
                    <BaseButton startIcon={<ContentAdd />} color="primary" variant="contained">
                      {'AGGIUNGI'}
                    </BaseButton>
                  }
                >
                  <FormDataConsumer>{renderItemRow}</FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </FormSection>
          )}
        </FormDataConsumer>
        <FormDataConsumer>{renderNotes}</FormDataConsumer>
        <FormSection title={translate('resources.Order.sections.summary')}>
          <OrderEditSummary />
        </FormSection>
      </SectionedForm>
    </Edit>
  )
}

export default OrderEdit
