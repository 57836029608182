import React, { FC, useEffect } from 'react'
import { useMutation } from 'react-admin'
import { IconButton, CircularProgress } from '@material-ui/core'
import { CheckCircle, Error } from '@material-ui/icons'

const IsWorkTimeUpdater: FC<Record<string, any>> = ({ id, isWorkTime, saveIsWorkTime, dayNumber, resetSave }) => {
  const [update, { loading, loaded, error }] = useMutation({
    type: 'update',
    resource: 'WorkingHour',
    payload: { id, data: { isWorkTime } },
  })

  useEffect(() => {
    if (saveIsWorkTime) update()
  }, [])

  useEffect(() => {
    if (loaded) {
      resetSave(dayNumber, id, 'saveIsWorkTime')
    }
  }, [loaded])

  return loading ? (
    <CircularProgress />
  ) : (
    <IconButton disabled>
      {error && <Error />}
      {loaded && !error && <CheckCircle />}
    </IconButton>
  )
}

export default IsWorkTimeUpdater
