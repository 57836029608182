import React, { Fragment, FC, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import ActionDelete from '@material-ui/icons/Delete'
import classnames from 'classnames'
import { useTranslate, useDataProvider, useRedirect } from 'ra-core'
import gql from 'graphql-tag'
// import { Confirm, Button } from 'react-admin'
import Button from '@material-ui/core/Button'
import { useApolloClient, useQuery } from '@apollo/client'
import { QUERY_GET_EVENT_STATUSES_CONFIGURATION } from '../queries'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress'
import { PriceField } from './CustomFields'
import SimpleTransactionRow, { AccountingCardTransaction } from '../resources/AccountingCard/SimpleTransactionRow'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'

const QUERY_GET_TELEGRAM_TEMPLATE_UPDATE_EVENT_2 = gql`
  query GetTelegramTemplateUpdateEvent {
    getConfiguration(key: "CHANGED_EVENT_TELEGRAM_TEMPLATE_ID") {
      id
      key
      value
    }
  }
`

const MUTATION_SEND_TELEGRAM_INVITE_UPDATE_2 = gql`
  mutation SendTelegramInviteUpdate($data: TelegramInputData!) {
    sendTelegramMessage(data: $data)
  }
`

const DeleteEventButton: FC<any> = (props) => {
  const {
    basePath,
    classes: classesOverride,
    className,
    confirmTitle = 'ra.message.delete_title',
    confirmContent = 'ra.message.delete_content',
    icon = defaultIcon,
    label = 'ra.action.delete',
    onClick,
    record,
    resource,
    redirect = 'list',
    plannedEvent,
    ...rest
  } = props
  const translate = useTranslate()
  const classes = useStyles(props)
  // utile quanto un culo senza buco
  // const { open, handleDialogOpen, handleDialogClose, handleDelete } = useDeleteWithConfirmController({
  //   resource,
  //   record,
  //   redirect,
  //   basePath,
  //   onClick,
  // })
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const dataProvider = useDataProvider()
  const redimerda = useRedirect()
  const apolloClient = useApolloClient()

  const [loading, setLoading] = useState<boolean>(false)
  const [isDeleted, setIsDeleted] = useState<boolean>(false)

  const [transactions, setTransactions] = useState<any[]>([])
  const [drivingPackages, setDrivingPackages] = useState<any[]>([])
  const [oldAccountingCard, setOldAccountingCard] = useState<any>({})
  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false)

  const { data: eventStatusConfiguration } = useQuery(QUERY_GET_EVENT_STATUSES_CONFIGURATION)

  const handleDialogClose = (): void => setIsDialogOpen(false)
  const handleDialogOpen = (): void => setIsDialogOpen(true)

  const preDelete = async () => {
    setLoading(true)

    // const { data: plannedEvent } = await apolloClient.query({
    //   query: QUERY_GET_PLANNED_EVENT,
    //   variables: { id: record.id },
    // })
    if (transactions?.length) {
      for (let i = 0; i < transactions.length; i++) {
        const element = transactions[i]
        const a = await dataProvider.delete('AccountingCardTransaction', {
          id: element.id,
          previousData: { id: element.id },
        })
        // console.log('xxx removing a whole transaction, id: ', element.id)
      }
    } else {
      if (drivingPackages.length) {
        for (let i = 0; i < drivingPackages.length; i++) {
          const element = drivingPackages[i]
          const a = await dataProvider.delete('TransactionPlannedEvent', {
            id: element.associationId,
            previousData: { id: element.associationId },
          })
          // console.log('xxx deleting only TPE from a package, ', element.associationId, element.id)
        }
      }
    }

    const xxx = await dataProvider.delete(resource, { id: record.id, previousData: { id: record.id } })

    if (record.customerEvents && record.customerEvents.length > 0) {
      // const { data: eventStatusConfiguration } = await apolloClient.query({
      //   query: QUERY_GET_EVENT_STATUSES_CONFIGURATION,
      // })

      if (
        plannedEvent &&
        plannedEvent.plannedEvent &&
        plannedEvent.plannedEvent.customerEvents &&
        plannedEvent.plannedEvent.customerEvents.length > 0 &&
        (eventStatusConfiguration.getConfiguration.value.confirmed.includes(
          plannedEvent.plannedEvent.customerEvents[0].status.id
        ) ||
          plannedEvent.plannedEvent.customerEvents[0].status.id ===
            eventStatusConfiguration.getConfiguration.value.waitingConfirmation)
        // (plannedEvent.plannedEvent.customerEvents[0].status.id ===
        //   eventStatusConfiguration.getConfiguration.value.confirmed ||
        //   plannedEvent.plannedEvent.customerEvents[0].status.id ===
        //     eventStatusConfiguration.getConfiguration.value.waitingConfirmation)
      ) {
        const { data: contacts } = await dataProvider.getList('CustomerContact', {
          filter: {
            customerId: record.customerIds[0],
            contactType: 'telegram',
          },
          pagination: { perPage: 10, page: 1 },
          sort: { field: 'id', order: 'ASC' },
        })
        // console.log('contacts?? ', contacts, record.customerIds)
        if (contacts) {
          try {
            const { data: telegramTemplate } = await apolloClient.query({
              query: QUERY_GET_TELEGRAM_TEMPLATE_UPDATE_EVENT_2,
            })
            if (telegramTemplate) {
              const x = await apolloClient.mutate({
                mutation: MUTATION_SEND_TELEGRAM_INVITE_UPDATE_2,
                variables: {
                  data: {
                    telegramId: parseInt(contacts[0].contact),
                    templateId: telegramTemplate.getConfiguration.value,
                  },
                },
              })
              // console.log('CIAOOOOOOO SENDTGUPDATE', x)
            }
          } catch (err) {
            console.error('error')
          }
        }
      }
    }
    setIsDeleted(true)
    setLoading(false)
    redimerda(props.redirect)
  }

  const checkAccountingCard = async () => {
    console.log('checking planned event')
    setIsDialogLoading(true)
    const x = await dataProvider.getList('TransactionPlannedEvent', {
      filter: { plannedEventId: record.id },
      pagination: { page: 1, perPage: 2 },
      sort: { field: 'id', order: 'DESC' },
    })
    // console.log('XXX tpes involved?? ', x)
    if (x && x.total) {
      const ACTransactions = []

      for (let i = 0; i < x.data.length; i++) {
        const id = x.data[i].accountingCardTransactionId
        const accountingCardTransaction = await dataProvider.getOne('AccountingCardTransaction', { id })

        ACTransactions.push({ ...accountingCardTransaction.data, associationId: x.data[i].id })
      }
      const accountingCardId = (ACTransactions[0] as any).accountingCardId
      const card = await dataProvider.getOne('AccountingCard', { id: accountingCardId })
      setOldAccountingCard(card.data)
      setDrivingPackages(ACTransactions.filter((elem: any) => elem.isDrivingPackage))
      setTransactions(ACTransactions.filter((elem: any) => !elem.isDrivingPackage))
    }
    setIsDialogLoading(false)
  }

  useEffect(() => {
    if (
      plannedEvent?.plannedEvent?.customerEvents?.length &&
      eventStatusConfiguration?.getConfiguration?.value &&
      (eventStatusConfiguration.getConfiguration.value.confirmed.includes(
        plannedEvent.plannedEvent.customerEvents[0].status.id
      ) ||
        eventStatusConfiguration.getConfiguration.value.sales.includes(
          plannedEvent.plannedEvent.customerEvents[0].status.id
        ))
    ) {
      checkAccountingCard()
    }
  }, [eventStatusConfiguration, plannedEvent])

  return (
    <Fragment>
      <Button
        onClick={handleDialogOpen}
        label={label}
        className={classnames('ra-delete-button', classes.deleteButton, className)}
        key="button"
        variant="contained"
        {...rest}
      >
        {icon}
        {'ELIMINA'}
      </Button>
      <Dialog open={isDeleted ? false : isDialogOpen} onClose={handleDialogClose} fullWidth maxWidth="md">
        <DialogTitle id="alert-dialog-title">{'Conferma eliminazione'}</DialogTitle>
        <DialogContent>
          {isDialogLoading ? (
            <LinearProgress />
          ) : transactions.length ? (
            <DialogContentText id="alert-dialog-description">
              {
                'Sei sicuro di voler eliminare questo evento? \nAttenzione! Questo comporta la modifica di una scheda contabile. Le seguenti transazioni verranno eliminate:'
              }
              <Table size="small">
                <TableBody>
                  {transactions.map((elem: AccountingCardTransaction) => (
                    <SimpleTransactionRow record={elem} hideMenu accountingCardRecord={oldAccountingCard} />
                  ))}
                </TableBody>
              </Table>
              {'I totali prima della modifica sono: '}
              <p>
                {'Totale: '}
                <PriceField record={oldAccountingCard} source="totalAmount" />
              </p>
              <p>
                {'Pagato: '}
                <PriceField record={oldAccountingCard} source="paidAmount" color="green" />
              </p>
              <p>
                {'Da pagare: '}
                <PriceField record={oldAccountingCard} source="remainingAmount" color="red" />
              </p>
            </DialogContentText>
          ) : drivingPackages.length ? (
            <DialogContentText id="alert-dialog-description">
              {
                'Sei sicuro di voler eliminare questo evento? \nAttenzione! Questo comporta la modifica di una scheda contabile. Le seguenti transazioni verranno modificate:'
              }
              <Table size="small">
                <TableBody>
                  {drivingPackages.map((elem: AccountingCardTransaction) => (
                    <SimpleTransactionRow record={elem} hideMenu accountingCardRecord={oldAccountingCard} />
                  ))}
                </TableBody>
              </Table>
              {'I totali prima della modifica sono: '}
              <p>
                {'Totale: '}
                <PriceField record={oldAccountingCard} source="totalAmount" />
              </p>
              <p>
                {'Pagato: '}
                <PriceField record={oldAccountingCard} source="paidAmount" color="green" />
              </p>
              <p>
                {'Da pagare: '}
                <PriceField record={oldAccountingCard} source="remainingAmount" color="red" />
              </p>
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              {'Sei sicuro di voler eliminare questo evento?'}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Annulla
          </Button>
          <Button onClick={preDelete} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Confirm
        isOpen={isDeleted ? false : open}
        loading={loading}
        title={confirmTitle}
        content={confirmContent}
        translateOptions={{
          name: translate(`resources.${resource}.forcedCaseName`, {
            smart_count: 1,
            _: inflection.humanize(
              translate(`resources.${resource}.name`, {
                smart_count: 1,
                _: inflection.singularize(resource),
              }),
              true
            ),
          }),
          id: record.id,
        }}
        onConfirm={preDelete}
        onClose={handleDialogClose}
      /> */}
    </Fragment>
  )
}

const defaultIcon = <ActionDelete />

const useStyles = makeStyles(
  (theme) => ({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
  { name: 'RaDeleteWithConfirmButton' }
)

// interface Props {
//   basePath?: string
//   classes?: object
//   className?: string
//   confirmTitle?: string
//   confirmContent?: string
//   icon?: ReactElement
//   label?: string
//   onClick?: ReactEventHandler<any>
//   record?: Record
//   redirect?: RedirectionSideEffect
//   resource?: string
//   // May be injected by Toolbar - sanitized in Button
//   handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>
//   handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void
//   invalid?: boolean
//   pristine?: boolean
//   saving?: boolean
//   submitOnEnter?: boolean
//   undoable?: boolean
// }

// // type DeleteWithConfirmButtonProps = Props & ButtonProps

// DeleteEventButton.propTypes = {
//   basePath: PropTypes.string,
//   classes: PropTypes.object,
//   className: PropTypes.string,
//   confirmTitle: PropTypes.string,
//   confirmContent: PropTypes.string,
//   label: PropTypes.string,
//   record: PropTypes.any,
//   redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
//   resource: PropTypes.string,
//   icon: PropTypes.element,
// }

export default DeleteEventButton
