import React, { FC, useEffect, useState } from 'react'
import { useQueryWithStore } from 'react-admin'
import { AiOutlineUser } from 'react-icons/ai'
import { useTranslate } from 'ra-core'
import moment from 'moment'
import {
  Card,
  CardContent,
  Box,
  Typography,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  LinearProgress,
  CardActions,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useMemo } from 'react'
import { stringify } from 'query-string'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    list: {
      minHeight: 400,
    },
    title: {},
    icon: {
      marginRight: theme.spacing(1),
    },
  }
})

type Props = { teamMemberWorkPlanning: any; permissionsMap: any }

// need this because weekDays inside hoursMap are unordered
const weekDays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']

const WorkingHoursCard: FC<Props> = ({ teamMemberWorkPlanning, permissionsMap }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [hoursMap, setHoursMap] = useState<Record<string, any>>({})

  const planningStartDate = useMemo(
    () => (teamMemberWorkPlanning?.startDate ? moment(teamMemberWorkPlanning.startDate).format('L') : undefined),
    [teamMemberWorkPlanning?.startDate]
  )

  const planningEndDate = useMemo(
    () => (teamMemberWorkPlanning?.endDate ? moment(teamMemberWorkPlanning.endDate).format('L') : undefined),
    [teamMemberWorkPlanning?.endDate]
  )

  const { data } = useQueryWithStore({
    type: 'getList',
    resource: 'WorkingHour',
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'dayNumber', order: 'ASC' },
      filter: { teamMemberWorkPlanningId: teamMemberWorkPlanning.id, isWorkTime: true },
    },
  })

  useEffect(() => {
    if (data) {
      const myMap: Record<string, any> = {
        MONDAY: [],
        TUESDAY: [],
        WEDNESDAY: [],
        THURSDAY: [],
        FRIDAY: [],
        SATURDAY: [],
        SUNDAY: [],
      }
      data.forEach((workingHour: any) => {
        myMap[workingHour.dayNumber].push({
          startTime: workingHour.startTime.slice(0, 5),
          finishTime: workingHour.finishTime.slice(0, 5),
        })
      })
      setHoursMap(myMap)
      setInitialized(true)
    }
  }, [data])
  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <AiOutlineUser size="1.2rem" className={classes.icon} />
          <Typography variant="h4" className={classes.title}>
            Orari di lavoro{planningStartDate && planningEndDate ? ` ${planningStartDate} - ${planningEndDate}` : ''}
          </Typography>
        </Box>
        <Divider />
        <TableContainer>
          <Table size="small">
            <TableBody>
              {weekDays.map((weekDay: string) => (
                <TableRow key={`working-hour-${weekDay}`}>
                  <TableCell>{translate(`dayOfWeek.${weekDay}`)}</TableCell>
                  <TableCell>
                    <Typography>
                      {initialized ? (
                        hoursMap[weekDay].map(
                          (range: any, index: number) =>
                            `${index > 0 ? ', ' : ''}${range.startTime} - ${range.finishTime}`
                        )
                      ) : (
                        <LinearProgress />
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Divider />
      {permissionsMap.teamMemberWorkPlannings && (
        <CardActions>
          <Button
            component={Link}
            to={{
              pathname: '/TeamMemberWorkPlanning',
              search: stringify({
                page: 1,
                perPage: 40,
                filter: JSON.stringify({ teamMemberId: teamMemberWorkPlanning.teamMemberId }),
              }),
            }}
            color="primary"
            fullWidth
            variant="text"
          >
            Gestisci Pianificazione
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

export default WorkingHoursCard
