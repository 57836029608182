import React, { FC } from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const SimpleTransactionHeader: FC<{}> = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Data</TableCell>
        <TableCell>Descrizione</TableCell>
        <TableCell align="right">Sconto</TableCell>
        <TableCell align="right">Prezzo Lordo</TableCell>
        <TableCell align="right">Imponibile</TableCell>
        <TableCell align="right">IVA</TableCell>
        <TableCell align="right">TOTALE</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  )
}

export default SimpleTransactionHeader
