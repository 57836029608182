import React, { FC, useCallback, useEffect } from 'react'
import {
  NumberInput,
  ReferenceField,
  TextField,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
  TextInput,
  useQuery,
  useMutation,
  useRedirect,
  SaveButton,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import { useForm } from 'react-final-form'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { useTranslate } from 'ra-core'

import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/Edit'
import SectionedForm from '../../components/forms/SectionedForm'
import CreateQuoteItem from './CreateQuoteItem'
import QuoteSummary from './QuoteSummary'
import AppBarFormToolbar from '../../components/forms/AppBarFormToolbar'
import SaveAndApproveButton from './SaveAndApproveButton'
import PrintQuoteButton from '../../components/PrintQuoteButton'
import ImporQuoteTemplate from './ImportQuoteTemplate'
import BaseButton from '@material-ui/core/Button'
import ContentAdd from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import ProductImageName from '../Order/ProductImageName'
import CustomerContactsField from '../../components/CustomFields/CustomerContactsField'
import ApproveAndCreateAccountingCardButton from './ApproveAndCreateAccountingCardButton'

type Props = {
  //
}

const filterToQueryPrintTemplate = (description: string): Record<string, string> => ({ description })
const sortPrintTemplate = { field: 'description', order: 'ASC' }

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  line: {
    display: 'flex',
    alignItems: 'stretch',
    alignContent: 'center',
    borderBottom: 0,
  },
  root: {
    maxWidth: 128,
    marginLeft: 16,
    // marginTop: 30,
  },
  input: {
    display: 'flex',
  },
})

const EditQuoteItem: FC<any> = ({ formData, scopedFormData, getSource, ...rest }) => {
  const translate = useTranslate()
  const dataForm = useForm()
  const { root } = useStyles()
  // const { data, loading, error } = useQuery({
  //   type: 'getOne',
  //   resource: 'QuoteItem',
  //   payload: { id: scopedFormData.id },
  // })
  // const { data: contactsData } = useQuery({
  //   type: 'getList',
  //   resource: 'CustomerContact',
  //   payload: { customerId: }
  // })
  // console.log('scopedFormData: ', scopedFormData)
  const [getProd, { loading: loading, data: dataProd, error }] = useMutation()

  useEffect(() => {
    if (!loading && scopedFormData && scopedFormData.productId && !dataProd)
      getProd({
        type: 'getOne',
        resource: 'Product',
        payload: { id: scopedFormData.productId },
      })
  }, [loading])

  useEffect(() => {
    if (dataProd) {
      // dataForm.change(`${rest.id}.productId`, dataProd.originalId)
    }
  }, [dataProd])

  return loading || error || !dataProd || !scopedFormData ? null : (
    <>
      {dataProd && dataProd.name && (
        <TextInput
          {...rest}
          disabled
          value={dataProd.name.toString()}
          label={translate('resources.Quote.fields.product')}
          helperText={false}
        />
      )}
      <NumberInput
        {...rest}
        source={getSource('quantity')}
        label={translate('resources.Quote.fields.quantity')}
        initialValue={scopedFormData.quantity}
        classes={{ root }}
        disabled={formData.approved}
        helperText={false}
      />
      <NumberInput
        {...rest}
        source={getSource('productPriceOverride')}
        label={translate('resources.Quote.fields.price')}
        initialValue={scopedFormData.productPriceOverride}
        classes={{ root }}
        disabled={formData.approved}
        helperText={false}
      />
      <NumberInput
        {...rest}
        source={getSource('taxRateOverride')}
        label={translate('resources.Quote.fields.tax')}
        initialValue={scopedFormData.taxRateOverride}
        classes={{ root }}
        disabled={formData.approved}
        helperText={false}
      />
      {scopedFormData && scopedFormData.productId && (
        <div style={{ display: 'inline-block' }}>
          <ProductImageName orderProductId={scopedFormData.productId} isInIterator hideName />
        </div>
      )}
    </>
  )
}

const ToolBarContent: FC<any> = (props) => {
  const redirect = useRedirect()
  const onClick = (): void => redirect(`/Order/${props.record.orderId}/show`)
  return (
    <>
      <Box mr={1}>
        <PrintQuoteButton record={props.record} />
      </Box>
      {props.record.approved ? (
        <Button variant="text" onClick={onClick}>
          PREVENTIVO GIA' APPROVATO E NON MODIFICABILE (VAI ALL'ORDINE)
        </Button>
      ) : (
        <>
          {/* <SaveAndApproveButton {...props} variant="text" /> */}
          <ApproveAndCreateAccountingCardButton {...props} variant="text" />
          <SaveButton {...props} variant="text" />
        </>
      )}
    </>
  )
}

const QuoteEdit: FC<Props> = (props) => {
  const { form, line } = useStyles()

  const renderNotes = useCallback(
    ({ formData, ...rest }: any) =>
      formData && formData.notes ? (
        <FormSection title="Informazioni aggiuntive" {...rest}>
          {formData.approved ? (
            <TextField source="notes" fullWidth multiline />
          ) : (
            <TextInput source="notes" fullWidth multiline />
          )}
        </FormSection>
      ) : null,
    []
  )

  const transformData = useCallback((values) => {
    return {
      ...values,
      quoteItems: values.quoteItems.map((item: any, index: number) => {
        return {
          id: item.id,
          productId: item.productId,
          quantity: item.quantity,
          productPriceOverride: item.productPriceOverride,
          taxRateOverride: item.taxRateOverride,
          itemPosition: index + 1,
        }
      }),
    }
  }, [])

  const renderItemRow = useCallback((formDataProps: any) => {
    if (formDataProps && formDataProps.scopedFormData && formDataProps.scopedFormData.id)
      return <EditQuoteItem {...formDataProps} />
    else return <CreateQuoteItem {...formDataProps} />
  }, [])

  return (
    <Edit {...props} component="div" transform={transformData}>
      <SectionedForm
        variant="outlined"
        redirect={false}
        toolbar={
          <AppBarFormToolbar>
            <ToolBarContent />
          </AppBarFormToolbar>
        }
      >
        <FormSection title="Cliente" {...props}>
          <ReferenceField label="Cognome e nome" source="customerId" reference="Customer">
            <TextField source="fullName" />
          </ReferenceField>
          <ReferenceField label="Codice fiscale" source="customerId" reference="Customer" link={false}>
            <TextField source="fiscalCode" emptyText="N.D." />
          </ReferenceField>

          <ReferenceField label="Email" source="customerId" reference="Customer" link={false}>
            <CustomerContactsField contactType="email" />
          </ReferenceField>
          <ReferenceField label="Telefono" source="customerId" reference="Customer" link={false}>
            <CustomerContactsField contactType="sms" />
          </ReferenceField>
        </FormSection>

        <FormDataConsumer>
          {({ formData, ...rest }: any): any =>
            !formData.approved ? (
              <FormSection
                title="Importa da modello"
                subtitle="Genera velocemente un preventivo importando un modello"
                {...props}
                variant="outlined"
              >
                <ImporQuoteTemplate />
              </FormSection>
            ) : null
          }
        </FormDataConsumer>

        <FormSection title="Template di stampa" {...props}>
          <ReferenceInput
            label="Template di stampa"
            reference="Document"
            source="printTemplateId"
            filterToQuery={filterToQueryPrintTemplate}
            filter={{ tag: 'quote_print_template' }}
            sort={sortPrintTemplate}
          >
            <AutocompleteInput optionText="description" />
          </ReferenceInput>
        </FormSection>

        <FormDataConsumer>
          {({ formData, ...rest }: any): any => (
            <FormSection title="Lista prodotti" fullWidth {...rest}>
              <ArrayInput label={false} source="quoteItems" {...rest}>
                <SimpleFormIterator
                  {...rest}
                  classes={{ form, line }}
                  disableRemove={formData.approved}
                  disableAdd={formData.approved}
                  addButton={
                    <BaseButton startIcon={<ContentAdd />} color="primary" variant="contained">
                      {'AGGIUNGI'}
                    </BaseButton>
                  }
                  removeButton={
                    <BaseButton startIcon={<DeleteOutlineIcon />} color="primary" variant="contained">
                      {'RIMUOVI'}
                    </BaseButton>
                  }
                >
                  <FormDataConsumer>{renderItemRow}</FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </FormSection>
          )}
        </FormDataConsumer>
        <FormDataConsumer>{renderNotes}</FormDataConsumer>
        <FormSection title="Resoconto">
          <QuoteSummary />
        </FormSection>
      </SectionedForm>
    </Edit>
  )
}

export default QuoteEdit
