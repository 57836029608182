import React, { FC } from 'react'
import { TextField } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/Edit'
import { TextInput } from 'react-admin'

type Props = {
  //
}

const DrivingLicenseCategoryFiltersEdit: FC<Props> = (props) => (
  <Edit {...props} component="div">
    <SectionedForm>
      <FormSection title="ID">
        <TextField source="id" addLabel={false} />
      </FormSection>
      <FormSection title="Nome">
        <TextInput source="name" />
      </FormSection>
    </SectionedForm>
  </Edit>
)

export default DrivingLicenseCategoryFiltersEdit
