import React, { FC, useCallback } from 'react'
import { useQueryWithStore, DateField, ReferenceField, TextField } from 'react-admin'
import { LinearProgress } from '@material-ui/core'
import { CalendarToday as CalendarTodayIcon } from '@material-ui/icons'
import SummaryCard from './../../components/SummaryCard'

const ExamField = ({ id }: any): any => {
  const { loading, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'ExamPlanning',
    payload: { id },
  })

  return loading || error || !data ? (
    <LinearProgress />
  ) : (
    <div>
      <ReferenceField basePath="/Order" source="examId" record={data} reference="Exam" label={undefined} link={false}>
        <TextField source="name" />
      </ReferenceField>
      <span>{': '}</span>
      <DateField record={data} source="startDate" showTime />
    </div>
  )
}

const ExamSummaryCard = ({ customerId }: any): any => {
  const { data } = useQueryWithStore({
    type: 'getList',
    resource: 'ExamPlanningCustomer',
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
      filter: { customerId },
    },
  })

  const renderContent = useCallback(() => {
    if (!data) return <LinearProgress />
    return data.map((elem: any, idx: any) => <ExamField key={`examField-${idx}`} id={elem.examPlanningId} />)
  }, [data])

  return <SummaryCard icon={CalendarTodayIcon} title="Esami" render={renderContent} />
}

export default ExamSummaryCard
