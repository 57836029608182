import React, { FC, useEffect, useState } from 'react'
import { Admin, Resource } from 'react-admin'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import buildGraphQLProvider from 'ra-hyperting-graphql'
import { ApolloProvider } from '@apollo/client'
import { client } from './apolloClient'
import authProvider from './authProvider'
import customRoutes from './customRoutes'
import LoginPage from './routes/LoginPage'
import createHistory from 'history/createBrowserHistory'
import englishMessages from './i18n/en'
import italianMessages from './i18n/it'
import Loading from './components/Loading'
import Layout from './components/layout/Layout'
import Organization from './resources/Organization'
import Vehicle from './resources/Vehicle'
import VehicleFuel from './resources/Vehicle/VehicleFuel'
import Office from './resources/Office'
import VehicleManufacturer from './resources/Vehicle/VehicleManufacturer'
import VehicleType from './resources/Vehicle/VehicleType'
import VehicleModel from './resources/Vehicle/VehicleModel'
import TeamMember from './resources/TeamMember'
import Customer from './resources/Customer'
import Address from './resources/Address'
import Country from './resources/Country'
import Document from './resources/Document'
import State from './resources/State'
import Currency from './resources/Currency'
import Tax from './resources/Tax'
import Product from './resources/Product'
import ProductCategory from './resources/ProductCategory'
import ProductAttribute from './components/ProductAttribute'
import ProductFeature from './components/ProductFeature'
import ProductFeatureValue from './components/ProductFeatureValue'
import ProductAttributeValue from './components/ProductAttributeValue'
import PlannedEvent from './resources/PlannedEvent'
import Order from './resources/Order'
import Quote from './resources/Quote'
import EventTemplate from './resources/EventTemplate'
import addUploadFeature from './addUploadFeature'
import GlobalStyles from './components/GlobalStyles'
import theme from './theme'
import EventStatus from './resources/EventStatus'
import Course from './resources/Course'
import CourseSchedule from './resources/CourseSchedule'
import CoursePlanning from './resources/CoursePlanning'
import Exam from './resources/Exam'
import ExamPlanning from './resources/ExamPlanning'
import DrivingLicenseCategory from './resources/DrivingLicenseCategory'
import DrivingLicense from './resources/DrivingLicense'
import './App.css'
import SMSTemplate from './resources/SMSTemplate'
import EmailTemplate from './resources/EmailTemplate'
import TelegramTemplate from './resources/TelegramTemplate'
import Role from './resources/Role'
import QuoteTemplate from './resources/QuoteTemplate'
import User from './resources/User'
import TeamMemberWorkPlanning from './resources/TeamMemberWorkPlanning'
import WorkReport from './resources/WorkReport'
import AccountingCard from './resources/AccountingCard'

const history = createHistory()

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === 'it') {
    return italianMessages
  }

  // Always fallback on english
  return englishMessages
}, 'it')

const App: FC = () => {
  const [dataProvider, setDataProvider] = useState(null)

  useEffect(() => {
    const buildProvider = async (): Promise<any> => {
      const dP = await buildGraphQLProvider({
        client,
      })
      setDataProvider(() => dP)
    }

    buildProvider()
  }, [])

  if (!dataProvider) {
    return <Loading />
  }

  const uploadCapableDataProvider = addUploadFeature(dataProvider)

  return (
    <>
      <ApolloProvider client={client}>
        <Admin
          history={history}
          layout={Layout}
          dataProvider={uploadCapableDataProvider}
          i18nProvider={i18nProvider}
          authProvider={authProvider}
          theme={theme}
          loginPage={LoginPage}
          customRoutes={customRoutes}
        >
          <Resource {...Customer} />
          <Resource {...Organization} />
          <Resource {...Vehicle} />
          <Resource {...Office} />
          <Resource name="OpeningHour" />
          <Resource {...VehicleFuel} />
          <Resource {...VehicleManufacturer} />
          <Resource {...VehicleType} />
          <Resource {...VehicleModel} />
          <Resource {...TeamMember} />
          <Resource {...Address} />
          <Resource {...Country} />
          <Resource {...State} />
          {/* <Resource name="Zone" /> */}
          <Resource {...Currency} />
          <Resource {...Tax} />
          <Resource {...Product} />
          <Resource {...ProductCategory} />
          <Resource {...ProductAttribute} />
          <Resource {...ProductFeature} />
          <Resource {...ProductFeatureValue} />
          <Resource {...ProductAttributeValue} />
          <Resource {...PlannedEvent} />
          <Resource name="Brand" />
          <Resource name="EventTeamMember" />
          <Resource name="Event" />
          <Resource name="WorkingHour" />
          <Resource {...Order} />
          <Resource {...Quote} />
          <Resource {...EventTemplate} />
          <Resource {...EventStatus} />
          <Resource {...Document} />
          <Resource {...QuoteTemplate} />
          <Resource {...Course} />
          <Resource {...CourseSchedule} />
          <Resource {...CoursePlanning} />
          <Resource name="CustomerCoursePlanning" />
          <Resource name="CourseSchedule" />
          <Resource {...Exam} />
          <Resource {...ExamPlanning} />
          <Resource {...DrivingLicenseCategory} />
          <Resource {...DrivingLicense} />
          <Resource name="ExamPlanningTeamMember" />
          <Resource name="ExamPlanningCustomer" />
          <Resource name="QuoteItem" />
          <Resource name="OrderItem" />
          <Resource name="QuoteProduct" />
          <Resource name="OrderMessage" />
          <Resource name="Picture" />
          <Resource name="CustomerContact" />
          <Resource name="CourseLesson" />
          <Resource name="ProductDrivingSchoolAttribute" />
          <Resource {...SMSTemplate} />
          <Resource {...EmailTemplate} />
          <Resource {...TelegramTemplate} />
          <Resource {...TeamMemberWorkPlanning} />
          {/* <Resource name="Configuration" /> */}
          {/* <Resource
                name="Role"
                list={hasPermission(permissions) ? Role.list : undefined}
                create={hasPermission(permissions) ? Role.create : undefined}
                edit={hasPermission(permissions) ? Role.edit : undefined}
                show={hasPermission(permissions) ? Role.show : undefined}
              /> */}
          <Resource {...Role} />
          <Resource {...User} />
          <Resource name="ExamRegister" />
          <Resource name="ExamPlanningVehicle" />
          <Resource {...WorkReport} />
          <Resource {...AccountingCard} />
          <Resource name="AccountingCardNote" />
          <Resource name="AccountingCardTransaction" />
          <Resource name="TransactionPlannedEvent" />
          <Resource name="RecurringEvent" />
        </Admin>
      </ApolloProvider>
    </>
  )
}

export default App
