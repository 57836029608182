import React, { FC, useCallback, useEffect, useState } from 'react'
// import RAList from '../../components/List'
// import { NumberField, Datagrid, EditButton, BooleanField, FunctionField, TextInput } from 'react-admin'
import { useMutation, useQueryWithStore, useNotify } from 'react-admin'
import { Button, Container, LinearProgress, TextField, Typography, makeStyles } from '@material-ui/core'
// import EmailTemplateFilters from './TelegramTemplateFilters'

import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/client'

const QUERY_GET_TG_CONF = gql`
  query GetTGConf {
    getConfiguration(key: "telegramBotStartInstructionsLabel") {
      id
      key
      organizationId
      value
    }
  }
`
const MUTATION_SET_TG_WELCOME = gql`
  mutation SetTGWelcome($data: ConfigurationInput!) {
    setConfiguration(data: $data) {
      organizationId
      id
      key
      value
    }
  }
`

const useStyles = makeStyles({
  textInput: {
    marginTop: 16,
    marginBottom: 16,
  },
  saveButton: {
    display: 'flex',
    alignItems: 'center',
  },
})

const sort = { field: 'id', order: 'DESC' }
// const filterDefaultValues = { active: true }

type Props = {
  //
}

const TelegramTemplateFilters: FC<Props> = (props) => {
  const notify = useNotify()
  const classes = useStyles()
  const apolloClient = useApolloClient()
  const [welcomeLoading, setWelcomeLoading] = useState<boolean>(true)
  const [inviteLoading, setInviteLoading] = useState<boolean>(true)
  const [savingWelcome, setSavingWelcome] = useState<boolean>(false)
  // const [savingInvite, setSavingInvite] = useState<boolean>(false)
  const [welcomeMessage, setWelcomeMessage] = useState<Record<string, any>>({})
  const [inviteTemplate, setInviteTemplate] = useState<Record<string, any>>({})

  const [
    updateInviteMutation,
    { loading: updateInviteLoading, error: updateInviteError, data: updateInviteData, loaded },
  ] = useMutation()

  const { data } = useQueryWithStore({
    type: 'getOne',
    resource: 'TelegramTemplate',
    payload: { id: '1' },
  })

  const onWelcomeChange = (event: any) => setWelcomeMessage({ ...welcomeMessage, value: event.target.value })

  const saveWelcome = useCallback(async () => {
    try {
      setSavingWelcome(true)
      const result = await apolloClient.mutate({
        mutation: MUTATION_SET_TG_WELCOME,
        variables: {
          data: welcomeMessage,
        },
      })
      console.log('RESULT: ', result)
      notify('Template salvato con successo')
    } catch (err) {
      console.log('mutation error', err)
      notify('Errore nel salvataggio del template iniziale')
    } finally {
      console.log('FINALLY MUTATION')
      setSavingWelcome(false)
    }
  }, [welcomeMessage])

  const onInviteChange = (event: any) =>
    setInviteTemplate({ ...inviteTemplate, [event.target.name]: event.target.value })

  const saveInvite = useCallback(() => {
    updateInviteMutation({
      type: 'update',
      resource: 'TelegramTemplate',
      payload: {
        id: '1',
        data: inviteTemplate,
      },
    })
  }, [inviteTemplate])

  useEffect(() => {
    const getConf = async (): Promise<any> => {
      let toSave = {}
      try {
        const { data: result } = await apolloClient.query({
          query: QUERY_GET_TG_CONF,
        })
        console.log('TG RESULT: ', result)
        if (result && result.getConfiguration)
          toSave = { ...result.getConfiguration, __typename: undefined, id: undefined }
        else throw new Error('Welcome message template not found')
      } catch (err) {
        console.log('ERRRORRRR: ', err)
      } finally {
        // console.log('tosave: ', toSave)
        setWelcomeMessage(toSave)
        setWelcomeLoading(false)
      }
    }
    getConf()
  }, [])

  useEffect(() => {
    if (data) {
      setInviteTemplate({
        content: data.content,
        confirmLabel: data.confirmLabel,
        rejectLabel: data.rejectLabel,
        afterConfirmLabel: data.afterConfirmLabel,
        afterRejectLabel: data.afterRejectLabel,
      })
      setInviteLoading(false)
    }
  }, [data])

  useEffect(() => {
    if (loaded && updateInviteData && !updateInviteLoading) {
      notify('Template di invito salvato con successo')
    }
  }, [loaded, updateInviteData])

  // if (data) console.log('DATAAAA', data)
  // if (updateInviteError) console.log('update invite error: ', updateInviteError)

  return welcomeLoading || inviteLoading ? (
    <LinearProgress />
  ) : (
    <Container>
      <SectionedForm>
        <FormSection title="Template messaggio iniziale">
          {/* <Typography variant="body1">Messaggio iniziale</Typography> */}
          <TextField
            value={welcomeMessage.value}
            onChange={onWelcomeChange}
            variant="outlined"
            multiline
            fullWidth
            className={classes.textInput}
          />
          <Button variant="contained" color="primary" onClick={saveWelcome} disabled={savingWelcome}>
            SALVA
          </Button>
        </FormSection>
        <FormSection title="Template messaggio di invito - Elenco variabili disponibili">
          <Typography variant="body1">{'{{title}} - il titolo dell\'evento'}</Typography>
          <Typography variant="body1">{'{{firstName}} - il nome del cliente'}</Typography>
          <Typography variant="body1">{'{{startDate}} - data e ora di inizio dell\'evento'}</Typography>
          <Typography variant="body1">{'{{endDate}} - data e ora di fine dell\'evento'}</Typography>
        </FormSection>
        <FormSection title="Template messaggio di invito ad evento">
          {/* <TextInput source="name" label="Nome template" /> */}
          <TextField
            fullWidth
            multiline
            name="content"
            value={inviteTemplate.content}
            className={classes.textInput}
            label="Messaggio completo di invito con variabili"
            onChange={onInviteChange}
          />
          <TextField
            name="confirmLabel"
            value={inviteTemplate.confirmLabel}
            className={classes.textInput}
            label="Testo pulsante CONFERMA"
            onChange={onInviteChange}
          />
          <TextField
            name="rejectLabel"
            label="Testo pulsante RIFIUTA"
            className={classes.textInput}
            value={inviteTemplate.rejectLabel}
            onChange={onInviteChange}
          />
          <TextField
            fullWidth
            multiline
            className={classes.textInput}
            name="afterConfirmLabel"
            label="Testo che appare dopo la conferma"
            value={inviteTemplate.afterConfirmLabel}
            onChange={onInviteChange}
          />
          <TextField
            fullWidth
            multiline
            className={classes.textInput}
            name="afterRejectLabel"
            label="Testo che appare dopo il rifiuto"
            value={inviteTemplate.afterRejectLabel}
            onChange={onInviteChange}
          />
          <Button variant="contained" color="primary" onClick={saveInvite} disabled={updateInviteLoading}>
            SALVA
          </Button>
        </FormSection>
      </SectionedForm>
    </Container>
  )

  {
    /* </Container>

  // return (
  //   <RAList {...{ sort }} {...props} filters={<EmailTemplateFilters />}>
  //     <Datagrid>
  //       <NumberField source="id" />
  //       <TextField source="name" />
  //       <EditButton />
  //     </Datagrid>
  //   </RAList>
  // ) */
  }
}

export default TelegramTemplateFilters
