import React, { FC } from 'react'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core'
import { calculateTax, numToEUR } from '../../utils/prices'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  row: {
    minWidth: '300px',
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:last-child': {
      marginBottom: 0,
    },
    '&:first-child': {
      marginTop: theme.spacing(1),
    },
  },
}))

const QuoteSummary: FC<any> = (): any => {
  const { values: formData } = useFormState()

  const classes = useStyles()

  let totalNet = 0
  let totalTaxes = 0

  if (formData && formData.quoteItems && formData.quoteItems.length > 0) {
    formData.quoteItems.forEach((elem: any) => {
      if (elem && elem.quantity && elem.productPriceOverride) {
        const singleNet = elem.quantity * elem.productPriceOverride
        const singleTax = calculateTax(singleNet, elem.taxRateOverride)
        totalNet += singleNet
        totalTaxes += singleTax
      }
    })
    return (
      <div className={classes.container}>
        <div className={classes.row}>
          <h4>TOTALE NETTO</h4>
          <span>{numToEUR(totalNet)}</span>
        </div>
        <div className={classes.row}>
          <h4>TOTALE TASSE</h4>
          <span>{!isNaN(totalTaxes) && numToEUR(totalTaxes)}</span>
        </div>
        <div className={classes.row}>
          <h2>TOTALE</h2>
          <h3>{!isNaN(totalTaxes) && numToEUR(totalTaxes + totalNet)}</h3>
        </div>
      </div>
    )
  } else return null
}

export default QuoteSummary
