import React, { FC } from 'react'
import { Button } from 'react-admin'
import { Link } from 'react-router-dom'

const CreateSubcategoryButton: FC = (props: any) => (
  <Button
    component={Link}
    to={`/ProductCategory/create?parentId=${props.record.id}`}
    label="Add a subcategory"
    title="Add a subcategory"
  />
)

export default CreateSubcategoryButton
