import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useVersion } from 'ra-core'
import { TextInput, SelectInput, AutocompleteInput, ReferenceInput } from 'react-admin'
import { FormRenderProps, Form, useForm } from 'react-final-form'
import { useHistory, useLocation } from 'react-router-dom'
import { parse as parseQS } from 'query-string'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const filterToQuery = (name: string): any => ({ name })
const sort = { field: 'name', order: 'ASC' }
const choices = [
  { id: 'INCOME', name: 'Ingresso (+)' },
  { id: 'OUTCOME', name: 'Uscita (-)' },
]
const empty = (): void => {}

const FiltersBody: FC<FormRenderProps> = ({ values, handleSubmit }: FormRenderProps) => {
  const classes = useStyles()
  const history = useHistory()
  const { pathname } = useLocation()
  const form = useForm()

  const onRemoveFilters = useCallback(() => {
    form.change('productId', null)
    form.change('description', '')
    form.change('type', null)
  }, [form])

  useEffect(() => {
    const newSearch: any = {}

    if (values && values.description) newSearch.description = values.description
    if (values && values.type) newSearch.type = values.type
    if (values && values.productId) newSearch.productId = values.productId

    const params = new URLSearchParams(newSearch)
    history.replace({ pathname, search: params.toString() })
  }, [values.description, values.type, values.productId])

  return (
    <form onSubmit={handleSubmit} className={classes.container}>
      <ReferenceInput
        allowEmpty
        basePath="/AccountingCard"
        source="productId"
        reference="Product"
        variant="outlined"
        helperText={false}
        label="Prodotto"
        filterToQuery={filterToQuery}
        sort={sort}
      >
        <AutocompleteInput optionText="name" variant="outlined" />
      </ReferenceInput>
      <TextInput
        source="description"
        variant="outlined"
        helperText={false}
        label="Descrizione"
        className={classes.child}
      />
      <SelectInput
        source="type"
        variant="outlined"
        helperText={false}
        label="Tipologia"
        choices={choices}
        allowEmpty
        className={classes.child}
      />
      <Button
        color="primary"
        variant="outlined"
        onClick={onRemoveFilters}
        className={classes.child}
        disabled={!values.type && !values.description && !values.productId}
      >
        Rimuovi filtri
      </Button>
    </form>
  )
}

export const AccountingCardTransactionFilters: FC<{}> = () => {
  const { search } = useLocation()
  const version = useVersion()

  const initialValues: Record<string, any> = useMemo(
    () => ({
      description: parseQS(search).description,
      type: parseQS(search).type,
      productId: parseQS(search).productId,
    }),
    [version]
  )

  const renderProp = useCallback((props) => <FiltersBody {...props} />, [])

  return <Form key={version} onSubmit={empty} initialValues={initialValues} render={renderProp} />
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  child: {
    marginLeft: theme.spacing(2),
  },
}))

export default AccountingCardTransactionFilters
