import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/Create'
import { TextInput } from 'react-admin'
import FormSection from '../../components/forms/FormSection'

type Props = {
  //
}

const ExamCreate: FC<Props> = (props) => (
  <Create {...props} component="div">
    <SectionedForm redirect="list">
      <FormSection title="Nome">
        <TextInput source="name" />
      </FormSection>
    </SectionedForm>
  </Create>
)

export default ExamCreate
