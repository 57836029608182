import React, { FC, useCallback, useMemo } from 'react'
import { FieldProps, InjectedFieldProps } from '../utils/ra-types'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {
  ReferenceFieldController,
  useGetList,
  useTranslate,
  useRedirect,
  useDeleteWithConfirmController,
} from 'ra-core'
import get from 'lodash/get'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Loading } from 'react-admin'
import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton'
import { Confirm } from 'ra-ui-materialui'
import inflection from 'inflection'

type Props = FieldProps & InjectedFieldProps & { permissionsMap: Record<string, boolean> }

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0.5, 3),
  },
}))

const DeleteAddress: FC<any> = ({ record }) => {
  const translate = useTranslate()
  const {
    open,
    loading,
    handleDialogOpen: handleDeleteDialogOpen,
    handleDialogClose: handleDeleteModalClose,
    handleDelete,
  } = useDeleteWithConfirmController({
    resource: 'Address',
    record,
    redirect: false,
    basePath: undefined,
    onClick: undefined,
  })

  const onDeleteClick = useCallback(
    (event: React.MouseEvent<any, any>) => {
      event.preventDefault()
      event.stopPropagation()
      handleDeleteDialogOpen(event)
    },
    [record]
  )

  return (
    <>
      <IconButton onClick={onDeleteClick}>
        <DeleteForeverIcon />
      </IconButton>
      <Confirm
        isOpen={open}
        loading={loading}
        title="ra.message.delete_title"
        content="ra.message.delete_content"
        translateOptions={{
          name: translate('resources.Address.forcedCaseName', {
            smart_count: 1,
            _: inflection.humanize(
              translate('resources.Address.name', {
                smart_count: 1,
                _: inflection.singularize('Address'),
              }),
              true
            ),
          }),
          id: record.id,
        }}
        onConfirm={handleDelete}
        onClose={handleDeleteModalClose}
      />
    </>
  )
}

const AddressDetailsField: FC<Props> = ({ record, permissionsMap }) => {
  const translate = useTranslate()
  const redirect = useRedirect()
  const classes = useStyles()

  const { loading, data, total } = useGetList(
    'Address',
    {
      page: 1,
      perPage: 25,
    },
    { field: 'id', order: 'DESC' },
    { customerId: record!.id }
  )

  const onEditClick = useCallback(
    () => redirect(`/Address/${Object.values(data!)[0].id}/edit?customerId=${record!.id}`),
    [data, record]
  )

  const address = useMemo(() => (total && total > 0 ? Object.values(data!)[0] : { id: undefined }), [total, data])

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2" color="textSecondary">
          {translate('resources.Customer.fields.address')}
        </Typography>
        {!loading && total && total > 0 && data ? (
          <span>
            <IconButton onClick={onEditClick}>
              <EditIcon />
            </IconButton>
            <DeleteAddress record={address} />
          </span>
        ) : null}
      </Box>
      {loading ? (
        <Loading />
      ) : total && total > 0 && data && address && address.id ? (
        <Box mt={1}>
          <Grid container spacing={1} direction="column" className={classes.root}>
            <Typography variant="body2">{`${get(address, 'firstName')} ${get(address, 'lastName')}`}</Typography>
            <Typography variant="body2">{`${get(address, 'postCode')}, ${get(address, 'street')}`}</Typography>
            <Typography variant="body2">{get(address, 'city')}</Typography>

            <ReferenceFieldController
              resource="Address"
              basePath="/Address"
              record={address}
              link={false}
              reference="State"
              source="stateId"
            >
              {({ referenceRecord, ...props }: any): any =>
                referenceRecord ? <Typography variant="body2">{get(referenceRecord, 'name')}</Typography> : null
              }
            </ReferenceFieldController>

            <ReferenceFieldController
              resource="Address"
              basePath="/Address"
              record={address}
              link={false}
              reference="Country"
              source="countryId"
            >
              {({ referenceRecord, ...props }: any): any =>
                referenceRecord ? <Typography variant="body2">{get(referenceRecord, 'name')}</Typography> : null
              }
            </ReferenceFieldController>
          </Grid>
        </Box>
      ) : permissionsMap.createAddress ? (
        <Typography variant="body2">
          Nessun indirizzo presente, <Link to={`/Address/create?customerId=${record!.id}`}>clicca qui</Link> per crearlo
        </Typography>
      ) : (
        <Typography variant="body2">Nessun indirizzo presente</Typography>
      )}
    </Box>
  )
}

export default AddressDetailsField
