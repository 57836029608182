import React, { FC, useEffect, useState, useMemo } from 'react'
import classNames from 'classnames'
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import { useFormState } from 'react-final-form'

import {
  Box,
  Grid,
  Button,
  Hidden,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  makeStyles
} from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useInput, useDataProvider, Loading } from 'react-admin'

import ProductCombinationsInputTable from './ProductCombinationsInputTable'
import cartesian from '../utils/cartesian'

const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1 },
  attributeHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  attributeControl: {},
  attributeControlColorOrTexture: {
    '& $checkboxLabel': {
      color: theme.palette.text.secondary
    },
    '& $checkboxSelected $checkboxLabel': {
      color: theme.palette.text.primary
    }
  },
  checkboxColorIcon: {
    height: '1rem',
    width: '1rem'
  },
  checkboxSelected: {},
  checkboxLabel: {}
}))

const getAttributeControlClassName = (attribute: any): string =>
  `attributeControl${upperFirst(camelCase(attribute.type))}`

const getAutocompleteOptionLabel = (option: any, attributesLookup: any[]): string => {
  // if it's a attribute value we compose the label using the related attribute
  if (option.value) {
    const attribute = attributesLookup[option.productAttributeId]
    return `${attribute.name}: ${option.value}`
  }

  return `${option.name}: All`
}

const ProductCombinationsInput: FC<any> = props => {
  const {
    input: { name, onChange, value },
    meta: { touched },
    isRequired,
    ...others
  } = useInput(props)
  const formState = useFormState()

  const classes: any = useStyles()

  const dataProvider = useDataProvider()
  const [combinations, setCombinations] = useState<any[]>([])
  const [attributes, setAttributes] = useState<any[]>([])
  const [attributeValues, setAttributeValues] = useState<any[]>([])
  const [selected, setSelected] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  const memoizedAttributesLookup = useMemo<any>(() => keyBy(attributes, 'id'), [attributes])

  const memoizedAutocompleteOptions = useMemo(
    () =>
      sortBy(attributes.concat(attributeValues), (option: any): string =>
        getAutocompleteOptionLabel(option, memoizedAttributesLookup)
      ),
    [attributes, attributeValues]
  )

  useEffect(() => {
    // load current product combinations
    if (value && value.length) {
      dataProvider
        .getMany('ProductCombination', { ids: value.map((v: any): string => v.id) })
        .then((response: any): void => {
          setCombinations(response.data)
        })
    }

    Promise.all([
      // load all product attributes to build the sidebar
      dataProvider.getList('ProductAttribute', {
        // @todo: find a better way to load all attributes
        pagination: { disabled: true },
        sort: { field: 'order', order: 'ASC' },
        filter: {}
      }),
      // load all product attributes values to build the sidebar
      dataProvider.getList('ProductAttributeValue', {
        // @todo: find a better way to load all attributes values
        pagination: { disabled: true },
        sort: { field: 'order', order: 'ASC' },
        filter: {}
      })
    ]).then((responses: any[]): void => {
      const [attributesResponse, attributeValuesResponse] = responses

      setAttributes(attributesResponse.data)
      setAttributeValues(attributeValuesResponse.data)

      setLoading(false)
    })
  }, [])

  const autocompleteFilterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: option => getAutocompleteOptionLabel(option, memoizedAttributesLookup)
  })

  const handleCheckValue = (e: any, attributeValue: any, parent: any): void => {
    if (!e.target.checked) {
      if (selected.includes(attributeValue)) {
        setSelected(selected.filter(v => v !== attributeValue))
      } else if (selected.includes(parent)) {
        const sibillingAttributeValues = attributeValues.filter(
          v => v.productAttributeId === parent.id && v.id !== attributeValue.id
        )
        setSelected(selected.filter(v => v !== parent).concat(sibillingAttributeValues))
      }
    }

    if (e.target.checked && !selected.includes(attributeValue)) {
      setSelected([...selected, attributeValue])
    }
  }

  const handleSelectAttribute = (attribute: any): void => {
    if (!selected.includes(attribute)) {
      setSelected([...selected, attribute])
    }
  }

  const handleSelectValue = (e: any, value: any): void => {
    setSelected(value)
  }

  const handleGenerate = (): void => {
    // split selected attributes (All) from selected attributes values
    const [selectedAttributes, selectedAttributesValues] = selected.reduce(
      (carry, option) => {
        const carryIdx = option.productAttributeId ? 1 : 0
        carry[carryIdx].push(option)
        return carry
      },
      [[], []]
    )

    // group selected attributes values by attributes
    const lookupAttributeValue = groupBy(selectedAttributesValues, 'productAttributeId')

    // replace selected attributes values with all values in case the attribute (All) was selected
    if (selectedAttributes.length) {
      selectedAttributes.forEach((attribute: any) => {
        const options = attributeValues.filter(option => option.productAttributeId === attribute.id)
        lookupAttributeValue[attribute.id] = options
      })
    }

    // @todo: use the following code whenever the updateProduct mutation will handle product combinations
    // const newCombinations = cartesian(...Object.values(lookupAttributeValue)).map(combination => ({
    //   productId: formState.values.id,
    //   attributeValues: combination.map((option: any): string => option.id),
    //   quantity: 0,
    //   label: ''
    // }))
    // onChange(value.concat(newCombinations.map(combination => ({ id: combination.id }))))
    // setCombinations(combinations.concat(newCombinations))
    // setSelected([])

    // @todo: Delete the following code whenever the updateProduct mutation will handle product combinations
    Promise.all(
      cartesian(...Object.values(lookupAttributeValue)).map(combination => {
        const combinationData = {
          productId: formState.values.id,
          attributeValues: combination.map((option: any): any => ({ id: option.id })),
          quantity: 0,
          label: ''
        }
        return dataProvider.create('ProductCombination', {
          data: combinationData
        })
      })
    ).then(responses => {
      // merge all responses into one
      const newValues = responses.reduce((carry: any[], response: any): any[] => {
        carry.push(response.data)
        return carry
      }, [])

      // add the new combinations to the state
      setCombinations(combinations.concat(newValues))

      // clear selected values
      setSelected([])
    })
  }

  const handleChangeCombination = (id: string, data: any): void => {
    dataProvider.update('ProductCombination', { id, data }).then((response: any): void => {
      setCombinations(combinations.map(combination => (combination.id === id ? response.data : combination)))
    })
  }

  const handleDeleteCombination = (id: string): void => {
    dataProvider.delete('ProductCombination', { id }).then((response: any): void => {
      setCombinations(combinations.filter(combination => combination.id !== id))
    })
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} md={9} lg={10}>
        <Typography>{props.label}</Typography>
        <Box display="flex" alignItems="center" my={2}>
          <Box flex={1} mr={2}>
            <Autocomplete
              multiple
              filterSelectedOptions
              id="attributes-filled"
              loading={loading}
              value={selected}
              options={memoizedAutocompleteOptions}
              onChange={handleSelectValue}
              getOptionLabel={(option: any): string => getAutocompleteOptionLabel(option, memoizedAttributesLookup)}
              filterOptions={autocompleteFilterOptions}
              renderInput={(params: any): any => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Attributes"
                  placeholder="Combine several attributes..."
                />
              )}
            />
          </Box>

          <Box flex="0 0 auto">
            <Button color="primary" disabled={selected.length === 0} onClick={handleGenerate}>
              Generate
            </Button>
          </Box>
        </Box>

        {loading ? (
          <Loading />
        ) : (
          <ProductCombinationsInputTable
            combinations={combinations}
            attributeValues={attributeValues}
            onChangeCombination={handleChangeCombination}
            onDeleteCombination={handleDeleteCombination}
          />
        )}
      </Grid>
      <Hidden mdDown>
        <Grid item md={3} lg={2}>
          {loading ? (
            <Loading />
          ) : (
            <Box>
              {attributes.map((attribute, i) => (
                <Accordion key={attribute.id} defaultExpanded={i <= 2}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`attribute-panel-${attribute.id}`}
                    id={`attribute-panel-${attribute.id}-header`}
                  >
                    <Typography className={classes.attributeHeading}>{attribute.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      <Button
                        size="small"
                        color="primary"
                        disabled={selected.includes(attribute)}
                        onClick={(): void => {
                          handleSelectAttribute(attribute)
                        }}
                      >
                        Select all
                      </Button>
                      <FormControl
                        fullWidth
                        component="fieldset"
                        classes={{
                          root: classNames(
                            classes.attributeControl,
                            classes[getAttributeControlClassName(attribute)] || ''
                          )
                        }}
                      >
                        <FormGroup>
                          {attributeValues
                            .filter(attributeValue => attributeValue.productAttributeId === attribute.id)
                            .map(attributeValue => {
                              const checkboxProps: any = {}
                              const checked = selected.includes(attributeValue) || selected.includes(attribute)

                              if (attribute.type === 'COLOR_OR_TEXTURE') {
                                const colorIcon = (
                                  <div
                                    className={classes.checkboxColorIcon}
                                    style={{ backgroundColor: attributeValue.color }}
                                  />
                                )
                                checkboxProps.icon = colorIcon
                                checkboxProps.checkedIcon = colorIcon
                              }

                              return (
                                <FormControlLabel
                                  key={attributeValue.id}
                                  value={attributeValue}
                                  classes={{
                                    root: checked ? classes.checkboxSelected : '',
                                    label: classes.checkboxLabel
                                  }}
                                  checked={checked}
                                  onChange={(e: any): void => {
                                    handleCheckValue(e, attributeValue, attribute)
                                  }}
                                  control={<Checkbox {...checkboxProps} />}
                                  label={attributeValue.value}
                                />
                              )
                            })}
                        </FormGroup>
                      </FormControl>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          )}
        </Grid>
      </Hidden>
    </Grid>
  )
}

export default React.memo(ProductCombinationsInput)
