import React, { FC, useCallback } from 'react'
import RAList from './../../components/List'
import { TextField, NumberField, Datagrid, BooleanField, FunctionField } from 'react-admin'
import EditButtonWPermission from '../../components/EditButtonWPermission'
import TaxFilters from './TaxFilters'

const sort = { field: 'name', order: 'ASC' }
const filterDefaultValues = { active: true }

type Props = {}

const TaxList: FC<Props> = (props) => {
  const render = useCallback(({ rate }) => `${rate}%`, [])
  return (
    <RAList {...{ sort, filterDefaultValues }} {...props} filters={<TaxFilters />}>
      <Datagrid>
        <NumberField source="id" />
        <TextField source="name" />
        <FunctionField label="resources.Tax.fields.rate" source="rate" {...{ render }} />
        <BooleanField source="active" />
        <EditButtonWPermission {...props} />
      </Datagrid>
    </RAList>
  )
}

export default TaxList
