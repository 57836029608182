import React, { FC } from 'react'
import { TextInput, ReferenceInput, AutocompleteInput, DateInput } from 'react-admin'
import FormSection from './../../components/forms/FormSection'
import moment from 'moment'
// import { DateInput } from 'react-admin-date-inputs2'
import { useTranslate } from 'ra-core'

const sort = { field: 'name', order: 'ASC' }
const perPage = 300
const filterToQuery = () => (name: string): Record<string, string> => ({ name })
type Props = {}

// const parseDate = (v: any): any => {
//   try {
//     console.log(v, moment(v).toISOString())
//     return moment(v).format()
//   } catch (e) {
//     return v
//   }
// }

// TODO: add addresses
const CustomerForm: FC<Props> = (props) => {
  const translate = useTranslate()

  return (
    <>
      <FormSection title={translate('CustomerForm.mainInfo')} {...props}>
        <TextInput source="firstName" fullWidth />
        <TextInput source="lastName" fullWidth />
        <TextInput source="fiscalCode" fullWidth />
        {/* <DateInput
          fullWidth
          resource="Customer"
          source="birthday"
          label={translate('resources.Customer.fields.birthday')}
          options={{ format: 'dd/MM/yyyy', ampm: false, clearable: true }}
          parse={(v: any): any => v}
        /> */}
        <DateInput source="birthday" fullWidth />
        <ReferenceInput reference="Country" source="countryId" fullWidth {...{ filterToQuery, sort, perPage }}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </FormSection>
      {/* <FormSection title="Contacts" {...props}>
      <TextInput source="email" fullWidth />
      <TextInput source="phone" fullWidth />
      <TextInput source="fax" fullWidth />
      <TextInput source="pec" fullWidth />
      <TextInput source="telegramId" fullWidth />
    </FormSection> */}
      <FormSection title={translate('CustomerForm.otherInfo')} {...props}>
        <TextInput source="note" fullWidth multiline />
        <ReferenceInput reference="Office" source="subscriptionOfficeId" fullWidth>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput reference="TeamMember" source="mainReferenceTeamMemberId" fullWidth>
          <AutocompleteInput optionText="fullName" />
        </ReferenceInput>
      </FormSection>
    </>
  )
}

export default CustomerForm
