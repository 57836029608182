import React, { FC, useCallback, useState } from 'react'
import { ReferenceManyField, TextField, ReferenceField, SimpleList, Pagination, Button as RAButton } from 'react-admin'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Dialog from '@material-ui/core/Dialog'
import IconCancel from '@material-ui/icons/Cancel'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { makeStyles } from '@material-ui/core/styles'
import CustomerAvatarField from './CustomerAvatarField'
import { AiOutlineUser } from 'react-icons/ai'
import AssignmentIcon from '@material-ui/icons/Assignment'
import MoreVert from '@material-ui/icons/MoreVert'
// import DeleteIcon from '@material-ui/icons/Delete'
import QuickCreateCourseLessonButton from './QuickCreateCourseLessonButton'
import moment from 'moment'
import { useDataProvider, useNotify } from 'ra-core'
import CourseAttendanceRegisterForm from './CourseAttendanceRegisterForm'

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    list: {
      minHeight: 400,
    },
    title: {},
    icon: {
      marginRight: theme.spacing(1),
    },
  }
})

type Props = {
  record?: any
  permissionsMap: Record<string, boolean>
  [x: string]: any
}

const MoreMenu: FC<Props> = ({ record, permissionsMap }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  // const translate = useTranslate()
  const [loading, setLoading] = useState<boolean>(false)
  const [dirty, setDirty] = useState<boolean>(false)
  const [tempRegistryMap, setTempRegistryMap] = useState<any>({})
  const dataProvider = useDataProvider()
  const notify = useNotify()
  // console.log(record, 'sono record')

  const onMoreClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const onMoreClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onRegistryClick = useCallback(() => {
    onMoreClose()
    setIsModalOpen(true)
  }, [])

  // const handleModalOpen = useCallback(() => {
  //   setIsModalOpen(true)
  // }, [])

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleSubmit = useCallback(async () => {
    //
    try {
      setLoading(true)
      const promises = Object.keys(tempRegistryMap).map((item) =>
        dataProvider.create('CourseAttendanceRegister', {
          data: {
            present: tempRegistryMap[item],
            customerId: item,
            courseLessonId: record.id,
          },
        })
      )

      await Promise.all(promises)
      notify('ra.success')
    } catch (e) {
      notify(e.message, 'error')
    } finally {
      setLoading(false)
    }
  }, [tempRegistryMap])

  const onDirtyChange = useCallback((value: boolean) => {
    setDirty(value)
  }, [])
  const onValuesChange = useCallback((value: any) => {
    setTempRegistryMap(value)
  }, [])

  return (
    <>
      <IconButton edge="end" aria-label="delete" onClick={onMoreClick}>
        <MoreVert />
      </IconButton>
      <Menu id={`more-${record.id}`} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onMoreClose}>
        {permissionsMap.createCourseAttendanceRegister && (
          <MenuItem onClick={onRegistryClick}>
            <ListItemIcon>
              <AssignmentIcon fontSize="small" />
            </ListItemIcon>
            Registro Presenze
          </MenuItem>
        )}
        {/* <MenuItem onClick={onMoreClose}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Elimina
        </MenuItem> */}
      </Menu>

      <Dialog open={isModalOpen} fullScreen onClose={handleModalClose}>
        <DialogTitle>Registro Presenze</DialogTitle>
        <DialogContent>
          <CourseAttendanceRegisterForm onDirtyChange={onDirtyChange} onChange={onValuesChange} record={record} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} disabled={!dirty} color="primary">
            Genera Registro Presenze
          </Button>
          <RAButton label="ra.action.cancel" onClick={handleModalClose} disabled={loading}>
            <IconCancel />
          </RAButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const CoursePlanningLessonsCard: FC<Props> = ({ record, permissionsMap, ...rest }) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <AiOutlineUser size="1.2rem" className={classes.icon} />
          <Typography variant="h4" className={classes.title}>
            Registro Lezioni
          </Typography>
        </Box>
        <Divider />
        <ReferenceManyField
          {...rest}
          pagination={<Pagination />}
          record={record}
          reference="CourseLesson"
          target="coursePlanningId"
          link={false}
        >
          <SimpleList
            linkType={false}
            className={classes.list}
            primaryText={(record: any): any => (
              // <pre>{JSON.stringify(record, null, 2)}</pre>
              <ReferenceField {...rest} link={false} record={record} reference="TeamMember" source="teamMemberId">
                <TextField source="fullName" />
              </ReferenceField>
            )}
            secondaryText={(record: any): any => {
              return moment(record.date).format('L LT')
            }}
            leftAvatar={(record: any): any => (
              <ReferenceField reference="TeamMember" source="teamMemberId" record={record} {...rest} link={false}>
                <CustomerAvatarField avatarSize={50} record={record} source="profilePictureId" />
              </ReferenceField>
            )}
            rightIcon={(record: any): any => <MoreMenu record={record} permissionsMap={permissionsMap} />}
          />
        </ReferenceManyField>
      </CardContent>
      <Divider />
      {permissionsMap.createCourseLesson && (
        <CardActions>
          <QuickCreateCourseLessonButton color="primary" fullWidth variant="text" coursePlanningId={record.id} />
        </CardActions>
      )}
    </Card>
  )
}

export default CoursePlanningLessonsCard
