import React, { FC, useCallback, useState } from 'react'
import { ButtonProps } from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconCancel from '@material-ui/icons/Cancel'
import ContentAdd from '@material-ui/icons/Add'
import { useTranslate, useCreate, useNotify, useRefresh } from 'ra-core'
import {
  FormWithRedirect,
  SaveButton,
  Button as RAButton,
  Button,
  TextInput,
  SelectInput,
  BooleanInput,
} from 'react-admin'
import { useForm } from 'react-final-form'

const useStyles = makeStyles((theme) => ({
  suggestionsContainer: {
    zIndex: 1300,
  },
}))

type Props = {
  label?: string
  onChange?: (data: any) => void
  customerId?: string | number
} & ButtonProps

const QuickCreateCustomerContactButton: FC<Props> = ({
  label = 'QuickCreateCustomerContactButton.label',
  onChange,
  customerId,
  ...rest
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [create, { loading }] = useCreate('CustomerContact')
  const notify = useNotify()
  const refresh = useRefresh()
  const filterToQuery = useCallback(() => (fullName: string): Record<string, string> => ({ fullName }), [])

  const handleModalOpen = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleSubmit = useCallback((values: any) => {
    create(
      {
        payload: {
          data: {
            ...values,
            customerId,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          setIsModalOpen(false)
          refresh()
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error')
        },
      }
    )
  }, [])

  return (
    <>
      <Button label={translate(label)} {...(rest as any)} onClick={handleModalOpen}>
        <ContentAdd />
      </Button>

      <Dialog open={isModalOpen} onClose={handleModalClose} fullWidth maxWidth="md">
        <DialogTitle>{translate(label)}</DialogTitle>
        <FormWithRedirect
          resource="CustomerContact"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }: any): React.ReactNode => (
            <>
              <DialogContent>
                {/* <TextInput variant="outlined" resource="CustomerContact" fullWidth source="description" /> */}
                <SelectInput
                  fullWidth
                  resourceCustomerContact
                  variant="outlined"
                  source="contactType"
                  resource="CustomerContact"
                  choices={[
                    { id: 'sms', name: 'Cellulare' },
                    { id: 'email', name: 'E-mail' },
                    { id: 'telegram', name: 'Telegram ID' },
                  ]}
                />

                <TextInput variant="outlined" resource="CustomerContact" fullWidth source="contact" />
                <BooleanInput initialValue={true} resource="CustomerContact" fullWidth source="notificationEnabled" />
              </DialogContent>
              <DialogActions>
                <RAButton label="ra.action.cancel" onClick={handleModalClose} disabled={loading}>
                  <IconCancel />
                </RAButton>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}

export default QuickCreateCustomerContactButton
