/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, Children, isValidElement } from 'react'
import { FormWithRedirect } from 'react-admin'
import AppBarFormToolbar from './AppBarFormToolbar'
import { useFormState } from 'react-final-form'
import Fade from '@material-ui/core/Fade'
import { makeStyles, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import DeleteButtonWPermission from '../DeleteButtonWPermission'
import { usePermissions } from 'ra-core'

type Props = {
  children?: React.ReactNode
  initialValues?: any
  resource?: string
  basePath?: string
  record?: any
  redirect?: string | boolean | (() => string)
  save?: any
  saving?: any | boolean
  submitOnEnter?: boolean
  undoable?: boolean
  validate?: () => void
  version?: string
  variant?: 'outlined' | 'standard' | 'filled'
  handleSubmitWithRedirect?: any
  handleSubmit?: any
  invalid?: boolean
  pristine?: boolean
  toolbar?: React.ReactElement
  defaultTitle?: string
  title?: string
}

const SectionedForm: FC<Props> = ({ variant = 'outlined', toolbar = <AppBarFormToolbar />, ...props }) => {
  return (
    <FormWithRedirect
      {...props}
      variant={variant}
      toolbar={toolbar}
      render={(formProps: any): React.ReactNode => <SectionedFormView {...formProps} />}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  deleteButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2, 0),
  },
}))

export const SectionedFormView: FC<Props> = ({
  children,
  resource,
  record,
  basePath,
  variant,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  pristine,
  saving,
  submitOnEnter,
  undoable,
  toolbar,
  redirect: defaultRedirect,
  defaultTitle,
  title,
  ...rest
}) => {
  const classes = useStyles(rest)
  const { loaded, permissions } = usePermissions()

  return (
    <Container maxWidth="lg">
      <form {...sanitizeRestProps(rest)}>
        <Typography variant="h5">{!title ? defaultTitle : title}</Typography>
        {Children.map(children, (section) => {
          if (section && isValidElement(section)) {
            return React.cloneElement(section, {
              resource,
              record,
              basePath,
              variant: section.props.variant || variant,
            })
          }
        })}

        {toolbar &&
          React.cloneElement(toolbar, {
            basePath,
            className: 'toolbar',
            handleSubmitWithRedirect,
            handleSubmit,
            invalid,
            pristine,
            record,
            redirect: defaultRedirect,
            resource,
            saving,
            submitOnEnter,
            undoable,
          })}
        {record && typeof record.id !== 'undefined' && loaded && (
          <div className={classes.deleteButtonContainer}>
            <DeleteButtonWPermission
              basePath={basePath}
              record={record}
              resource={resource}
              undoable={undoable}
              permissions={permissions}
            />
          </div>
        )}
      </form>
    </Container>
  )
}

const sanitizeRestProps = ({
  anyTouched,
  array,
  asyncBlurFields,
  asyncValidate,
  asyncValidating,
  autofill,
  blur,
  change,
  clearAsyncError,
  clearFields,
  clearSubmit,
  clearSubmitErrors,
  destroy,
  dirty,
  dirtyFields,
  dirtyFieldsSinceLastSubmit,
  dirtySinceLastSubmit,
  dispatch,
  form,
  handleSubmit,
  hasSubmitErrors,
  hasValidationErrors,
  initialize,
  initialized,
  initialValues,
  pristine,
  pure,
  redirect,
  reset,
  resetSection,
  save,
  staticContext,
  submit,
  submitAsSideEffect,
  submitError,
  submitErrors,
  submitFailed,
  submitSucceeded,
  submitting,
  touch,
  translate,
  triggerSubmit,
  undoable,
  untouch,
  valid,
  validate,
  validating,
  _reduxForm,
  setRedirect,
  ...props
}: any): Props => props

export default SectionedForm
