import React, { FC, useCallback } from 'react'
import { NumberInput, TextInput, ReferenceInput, NullableBooleanInput, AutocompleteInput } from 'react-admin'
import Filter from '../../components/Filter'

type Props = {
  //
}

const CourseFilters: FC<Props> = (props) => {
  return (
    <Filter {...props}>
      <NumberInput source="id" />
      <TextInput source="name" alwaysOn />
    </Filter>
  )
}

export default CourseFilters
