import React, { FC, useCallback, useState } from 'react'
import { ReferenceManyField, TextField, ReferenceField, SimpleList, Pagination } from 'react-admin'
import inflection from 'inflection'
import { useDataProvider, useDeleteWithConfirmController, useNotify, useTranslate, useQuery, useRefresh } from 'ra-core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { makeStyles } from '@material-ui/core/styles'
import CustomerAvatarField from './CustomerAvatarField'
import { AiOutlineUser } from 'react-icons/ai'
import MoreVert from '@material-ui/icons/MoreVert'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import ErrorIcon from '@material-ui/icons/Error'
import QuickCreateCustomerExamPlanningButton from './QuickCreateCustomerExamPlanningButton'
import { Confirm } from 'ra-ui-materialui'
import { LinearProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    list: {
      minHeight: 400,
    },
    title: {},
    icon: {
      marginRight: theme.spacing(1),
    },
  }
})

type Props = {
  record?: any
  [x: string]: any
}

const QualifiedResult: FC<any> = ({ customerId, examPlanningId }) => {
  const { loading, error, data } = useQuery({
    type: 'getList',
    resource: 'ExamRegister',
    payload: {
      pagination: { perPage: 1, page: 1 },
      sort: { field: 'id', order: 'DESC' },
      filter: { customerId, examPlanningId },
    },
  })

  if (loading) return <LinearProgress />

  if (error) {
    console.error('error getting examRegister:', error)
    return <ErrorIcon />
  }

  if (data && data.length === 1)
    return <Typography variant="h6">{data[0].absent ? 'ASSENTE' : data[0].passed ? 'Idoneo' : 'Non idoneo'}</Typography>

  return null
}

const MoreMenu: FC<Props> = ({ record, resource }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const translate = useTranslate()
  const refresh = useRefresh()
  const [mutationLoading, setMutationLoading] = useState<boolean>(false)

  const {
    open,
    loading,
    handleDialogOpen: handleDeleteDialogOpen,
    handleDialogClose: handleDeleteModalClose,
    handleDelete,
  } = useDeleteWithConfirmController({
    resource,
    record,
    redirect: false,
    basePath: `/ExamPlanning/${record.examPlanningId}/show`,
    onClick: undefined,
  })

  const onMoreClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const onMoreClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onSetResult = useCallback(
    (passed: boolean | 'absent') => async (): Promise<void> => {
      try {
        setMutationLoading(true)
        const alreadyExist = await dataProvider.getList('ExamRegister', {
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'id', order: 'DESC' },
          filter: { customerId: record.customerId, examPlanningId: record.examPlanningId },
        })
        // console.log('alreadyExists: ', alreadyExist)

        let result = null
        const data = {
          examPlanningId: record.examPlanningId,
          customerId: record.customerId,
          date: new Date().toDateString(), // TODO: CHANGE ME
          // teamMemberId,
          absent: passed === 'absent',
          passed: passed === 'absent' ? false : passed,
        }

        if (alreadyExist && alreadyExist.total > 0) {
          result = await dataProvider.update('ExamRegister', {
            id: alreadyExist.data[0].id,
            previousData: { ...alreadyExist.data[0] },
            data,
          })
        } else {
          result = await dataProvider.create('ExamRegister', { data })
        }

        // console.log('result?? ', result)
        notify('Esito aggiornato con successo', 'info')
        refresh()
      } catch (e) {
        notify(e.message, 'error')
      } finally {
        setMutationLoading(false)
        onMoreClose()
      }
    },
    []
  )

  const onRemoveCustomer = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()
      onMoreClose()
      handleDeleteDialogOpen(event)
    },
    [record]
  )

  return (
    <>
      <IconButton edge="end" aria-label="delete" onClick={onMoreClick}>
        <MoreVert />
      </IconButton>
      <Menu id={`more-${record.id}`} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onMoreClose}>
        <MenuItem onClick={onSetResult(false)} disabled={mutationLoading}>
          <ListItemIcon>
            <CancelIcon fontSize="small" />
          </ListItemIcon>
          Segna come "Non Idoneo"
        </MenuItem>
        <MenuItem onClick={onSetResult(true)} disabled={mutationLoading}>
          <ListItemIcon>
            <CheckCircleIcon fontSize="small" />
          </ListItemIcon>
          Segna come "Idoneo"
        </MenuItem>
        <MenuItem onClick={onSetResult('absent')} disabled={mutationLoading}>
          <ListItemIcon>
            <ErrorIcon fontSize="small" />
          </ListItemIcon>
          Segna come "Assente"
        </MenuItem>
        <MenuItem onClick={onRemoveCustomer} disabled={mutationLoading}>
          <ListItemIcon>
            <CheckCircleIcon fontSize="small" />
          </ListItemIcon>
          Rimuovi Utente
        </MenuItem>
      </Menu>
      <Confirm
        isOpen={open}
        loading={loading}
        title="ra.message.delete_title"
        content="ra.message.delete_content"
        translateOptions={{
          name: translate(`resources.${resource}.forcedCaseName`, {
            smart_count: 1,
            _: inflection.humanize(
              translate(`resources.${resource}.name`, {
                smart_count: 1,
                _: inflection.singularize(`${resource}`),
              }),
              true
            ),
          }),
          id: record.id,
        }}
        onConfirm={handleDelete}
        onClose={handleDeleteModalClose}
      />
    </>
  )
}

const ExamPlanningCustomersCard: FC<Props> = ({ record, permissionsMap, ...rest }) => {
  const refresh = useRefresh()
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <AiOutlineUser size="1.2rem" className={classes.icon} />
          <Typography variant="h4" className={classes.title}>
            Partecipanti e Risultati
          </Typography>
        </Box>
        <Divider />
        <ReferenceManyField
          {...rest}
          pagination={<Pagination />}
          record={record}
          reference="ExamPlanningCustomer"
          target="examPlanningId"
          link={false}
        >
          <SimpleList
            linkType={false}
            className={classes.list}
            primaryText={(record: any): React.ReactElement => (
              <ReferenceField {...rest} link={false} record={record} reference="Customer" source="customerId">
                <TextField source="fullName" />
              </ReferenceField>
            )}
            secondaryText={(customerRecord: any): React.ReactElement => (
              <QualifiedResult customerId={customerRecord.customerId} examPlanningId={record.id} />
            )}
            leftAvatar={(record: any): any => (
              <ReferenceField {...rest} link={false} record={record} reference="Customer" source="customerId">
                <CustomerAvatarField source="profilePictureId" />
              </ReferenceField>
            )}
            rightIcon={(customerRecord: any): React.ReactElement => (
              <MoreMenu
                record={customerRecord}
                resource="ExamPlanningCustomer"
                // basePath={rest.basePath}
                teamMemberId={record.teamMembersIds[0]}
              />
            )}
          />
        </ReferenceManyField>
      </CardContent>
      <Divider />
      {permissionsMap.createExamPlanningCustomer && (
        <CardActions>
          <QuickCreateCustomerExamPlanningButton
            color="primary"
            fullWidth
            variant="text"
            examPlanningId={record.id}
            onChange={refresh}
          />
        </CardActions>
      )}
    </Card>
  )
}

export default ExamPlanningCustomersCard
