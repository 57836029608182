import React, { FC } from 'react'
import RAList from './../../components/List'
import { TextField, NumberField, Datagrid, ReferenceField, DateField, useTranslate } from 'react-admin'
import EditButtonWPermission from '../../components/EditButtonWPermission'
import { ColoredNullableBooleanField, PriceField } from './../../components/CustomFields'
import OrderFilters from './OrderFilters'

type Props = {
  //
}

const sort = { field: 'createdAt', order: 'DESC' }

const OrderList: FC<Props> = (props) => {
  const translate = useTranslate()
  return (
    <RAList {...props} filters={<OrderFilters />} {...{ sort }}>
      <Datagrid rowClick="show">
        <NumberField source="id" />
        <DateField source="orderDate" />
        <DateField source="expiringDate" />
        <TextField source="reference" />
        <ReferenceField
          label={translate('resources.Order.fields.customer')}
          source="customerId"
          reference="Customer"
          link={false}
        >
          <TextField source="fullName" />
        </ReferenceField>
        <ColoredNullableBooleanField source="closed" />
        <PriceField tableField source="amountToPay" color="red" sortable={false} />
        <PriceField tableField source="amountPayed" color="green" sortable={false} />
        <PriceField tableField source="totalAmount" sortable={false} />
        <EditButtonWPermission {...props} />
      </Datagrid>
    </RAList>
  )
}

export default OrderList
