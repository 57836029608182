import React, { FC, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  ReferenceInput,
  SelectInput,
  SaveButton,
  Toolbar,
} from 'react-admin'
import { useNotify, useRefresh, useDataProvider } from 'ra-core'
import { useFormState } from 'react-final-form'
import Button from '@material-ui/core/Button'
import Create from '../../components/Create'
import PlannedEventDatesInput from '../../components/PlannedEventDatesInput'
import { useHistory, useLocation } from 'react-router-dom'
import SelectDsEventType from './SelectDsEventType'
import RecurringEventFormSection from './RecurringEventFormSection'

type Props = any

const vehicleFilterToQuery = (licensePlate: string): any => ({
  licensePlate,
})

const officeFilterToQuery = (name: string): any => ({
  name,
})

const CustomToolbar: FC<any> = ({ onSuccess, isRecurringEventInvalid, ...props }) => {
  const { values } = useFormState()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const [loading, setLoading] = useState(false)

  const onRecurringSubmit = async (): Promise<void> => {
    try {
      setLoading(true)
      const {
        recurringEvent,
        startDate: eventStart,
        endDate: eventEnd,
        title,
        endMode,
        isRecurringEvent,
        ...additionalData
      } = values
      const weekDays = recurringEvent.weekDays ? recurringEvent.weekDays.join('-') : undefined

      const result = await dataProvider.create('RecurringEvent', {
        data: {
          ...recurringEvent,
          weekDays,
          eventStart,
          eventEnd,
          title,
          additionalData,
        },
      })
      onSuccess(result.data)
    } catch (error) {
      console.error('Error creating recurringEvent: ', error)
      notify(`Errore nella creazione degli eventi ricorrent: ${error?.message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Toolbar {...props}>
      {values.isRecurringEvent ? (
        <Button
          onClick={onRecurringSubmit}
          color="primary"
          disabled={loading || isRecurringEventInvalid}
          variant="contained"
        >
          SALVA EVENTI RICORRENTI
        </Button>
      ) : (
        <SaveButton />
      )}
    </Toolbar>
  )
}

const PlannedEventCreate: FC<Props> = (props) => {
  const classes = useToolbarStyles(props)
  const notify = useNotify()
  const history = useHistory()
  const { search } = useLocation()
  const refresh = useRefresh()

  const [isRecurringEventInvalid, setIsRecurringEventInvalid] = useState<boolean>(false)

  const onSuccess = useCallback(({ data }) => {
    notify('Event created successfully')
    history.push({ pathname: '/PlannedEvent', search })
    refresh()
  }, [])

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm
        variant="outlined"
        toolbar={<CustomToolbar onSuccess={onSuccess} isRecurringEventInvalid={isRecurringEventInvalid} />}
      >
        <PlannedEventDatesInput {...props} />
        <RecurringEventFormSection {...props} setIsRecurringEventInvalid={setIsRecurringEventInvalid} />
        <ReferenceInput source="officeId" reference="Office" filterToQuery={officeFilterToQuery} fullWidth>
          <AutocompleteInput
            options={{
              suggestionsContainerProps: {
                className: classes.suggestionsContainer,
              },
            }}
            optionText="name"
            optionValue="id"
          />
        </ReferenceInput>
        <ReferenceArrayInput source="teamMemberIds" reference="TeamMember" fullWidth>
          <AutocompleteArrayInput
            options={{
              suggestionsContainerProps: {
                className: classes.suggestionsContainer,
              },
            }}
            optionText="fullName"
            optionValue="id"
          />
        </ReferenceArrayInput>
        <SelectInput
          fullWidth
          label=""
          source="isWorkEvent"
          initialValue={true}
          choices={[
            { id: true, name: 'Lavoro' },
            { id: false, name: 'Ferie / Pausa' },
          ]}
        />
        <SelectDsEventType />
        <ReferenceArrayInput source="customerIds" reference="Customer" fullWidth>
          <AutocompleteArrayInput
            options={{
              suggestionsContainerProps: {
                className: classes.suggestionsContainer,
              },
            }}
            optionText="fullName"
            optionValue="id"
          />
        </ReferenceArrayInput>
        <ReferenceInput source="vehicleId" reference="Vehicle" filterToQuery={vehicleFilterToQuery} fullWidth>
          <AutocompleteInput
            options={{
              suggestionsContainerProps: {
                className: classes.suggestionsContainer,
              },
            }}
            optionText="licensePlate"
            optionValue="id"
          />
        </ReferenceInput>
        <TextInput source="description" fullWidth />
      </SimpleForm>
    </Create>
  )
}

const useToolbarStyles = makeStyles((theme) => ({
  suggestionsContainer: {
    zIndex: 1300,
  },
}))

export default PlannedEventCreate
