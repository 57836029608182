import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

type Props = {
  label?: string
}

const CardField: FC<Props> = ({ children, label }) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="subtitle2" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="body1">{children}</Typography>
    </Box>
  )
}

export default CardField
