import React, { FC } from 'react'
import { TextInput, DateInput, ReferenceInput, AutocompleteInput } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/Create'
import FormSection from '../../components/forms/FormSection'

type Props = {
  //
}

const TeamMemberWorkPlanningCreate: FC<Props> = (props) => (
  <Create {...props} component="div">
    <SectionedForm redirect="list">
      <FormSection title="Nome" {...props}>
        <ReferenceInput reference="TeamMember" source="teamMemberId">
          <AutocompleteInput
            // options={{
            //   suggestionsContainerProps: {
            //     className: classes.suggestionsContainer,
            //   },
            // }}
            variant="outlined"
            fullWidth
            optionText="fullName"
          />
        </ReferenceInput>
        <DateInput source="startDate" />
        <DateInput source="endDate" />
      </FormSection>
    </SectionedForm>
  </Create>
)

export default TeamMemberWorkPlanningCreate
