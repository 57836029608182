import React, { FC, useCallback } from 'react'
import { Create } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import OrderForm from './OrderForm'
import HiddenOrganizationField from './../../components/forms/HiddenOrganizationField'

type Props = {}

const OrderCreate: FC<Props> = (props) => {
  const transform = useCallback((data: any) => {
    console.log('vuoi dirmi che sei così stronzo?', data.orderDate)
    return {
      ...data,
      currencyIsoCode: 'EUR',
      orderDate: typeof data.orderDate === 'string' ? data.orderDate : data.orderDate.toISOString(),
      orderItems: (data.orderItems || []).map((item: any, index: number) => ({ ...item, itemPosition: index + 1 })),
    }
  }, [])

  return (
    <Create {...props} transform={transform} component="div">
      <SectionedForm variant="outlined" redirect="list">
        <HiddenOrganizationField />
        <OrderForm />
      </SectionedForm>
    </Create>
  )
}

export default OrderCreate
