import React, { FC } from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import { DateField, NumberField, ReferenceField, TextField } from 'react-admin'
import { PriceField } from '../../components/CustomFields'
import GenericMoreMenu from '../../components/GenericMoreMenu'
import TransactionForm from './TransactionForm'
import { renderColor } from '../../utils/accountingCard'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import NotesIcon from '@material-ui/icons/Notes'
// import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import { makeStyles } from '@material-ui/core/styles'
import { FaBalanceScaleRight } from 'react-icons/fa'

export type AccountingCardTransactionType = 'INCOME' | 'OUTCOME'

export type AccountingCardTransaction = {
  id: string
  accountingCardId: string
  productId: string
  date: string
  description: string
  discountAmount: number
  netPrice: number
  salePrice: number
  taxRate: number
  total: number
  type: AccountingCardTransactionType
  method: string
  notes?: string
  plannedEventIds?: string[]
  examPlanningId?: string
  isDrivingPackage?: boolean
  remainingDuration?: number
  relatedTransactionId?: string
  outcomeTransactionId?: string
  userId?: string
  isFreeCredit?: boolean
}

type Props = {
  record: AccountingCardTransaction
  transIdToEdit?: string | null
  setTransIdToEdit?: React.Dispatch<React.SetStateAction<string | null>>
  closeCreateForm?: () => void
  onAddPayment?: (record: Record<string, any>) => void
  isAccountClosed?: boolean
  accountingCardRecord: Record<string, any>
  hideMenu?: boolean
}

const SimpleTransactionRow: FC<Props> = ({
  record,
  closeCreateForm,
  transIdToEdit,
  setTransIdToEdit,
  onAddPayment,
  isAccountClosed,
  accountingCardRecord,
  hideMenu,
}: Props) => {
  const classes = useStyles()

  const customEditFunction = (id: string): void => {
    if (closeCreateForm && setTransIdToEdit) {
      closeCreateForm()
      setTransIdToEdit(id)
    }
  }

  const onUndoEdit = (): void => {
    if (setTransIdToEdit) {
      setTransIdToEdit(null)
    }
  }

  const onAddRow = (record: Record<string, any>): void => {
    if (onAddPayment) {
      const {
        type,
        id,
        createdAt,
        updatedAt,
        salePrice,
        netPrice,
        discountAmount,
        plannedEventId,
        plannedEventIds,
        ...partialRecord
      } = record
      const newRecord = {
        ...partialRecord,
        isPayment: true,
        salePrice: salePrice - discountAmount,
        // netPrice: ((salePrice - discountAmount) * 100) / (100 + record.taxRate),
        netPrice: salePrice - discountAmount,
        taxRate: 0,
        outcomeTransactionId: id,
        isDrivingPackage: false,
        date: new Date().toString(),
      }
      onAddPayment(newRecord)
    }
  }

  return transIdToEdit !== record.id ? (
    <TableRow>
      <TableCell>
        <DateField record={record} source="date" />
      </TableCell>
      <TableCell>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          arrow
          title={
            <>
              {record.type === 'INCOME' && (
                <Typography variant="body2">Metodo di pagamento: {record.method}</Typography>
              )}
              {record.notes && <Typography>NOTE: {record.notes}</Typography>}
              <Typography variant="body2">
                {'UTENTE: '}
                {record.userId ? (
                  <ReferenceField
                    basePath="/User"
                    resource="User"
                    record={record}
                    reference="User"
                    source="userId"
                    link={false}
                  >
                    <TextField source="fullName" />
                  </ReferenceField>
                ) : (
                  'SISTEMA'
                )}
              </Typography>
            </>
          }
          placement="bottom"
          disableHoverListener={hideMenu}
        >
          <Typography variant="h5" className={classes.descriptionCell}>
            {record.description}
            {record.notes && <NotesIcon className={classes.noteIcon} />}
            {record.relatedTransactionId && <FaBalanceScaleRight size="1.6rem" className={classes.noteIcon} />}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="right">
        {renderColor(record) === 'red' && <PriceField record={record} source="discountAmount" />}
      </TableCell>
      <TableCell align="right">
        {renderColor(record) === 'green' && '+'}
        {renderColor(record) === 'red' && '-'}
        <PriceField record={record} source="salePrice" color={renderColor(record)} />
      </TableCell>
      <TableCell align="right">
        {record.type === 'OUTCOME' && <PriceField record={record} source="netPrice" color={renderColor(record)} />}
      </TableCell>
      <TableCell align="right">
        {record.type === 'OUTCOME' && <NumberField record={record} source="taxRate" color={renderColor(record)} />}
        {record.type === 'OUTCOME' && '%'}
      </TableCell>
      <TableCell align="right">
        {renderColor(record) === 'green' && <b>+</b>}
        {renderColor(record) === 'red' && <b>-</b>}
        <PriceField record={record} source="total" color={renderColor(record)} variant="h5" />
      </TableCell>
      {!hideMenu && (
        <TableCell align="center">
          {!isAccountClosed && (
            <GenericMoreMenu
              record={record}
              resource="AccountingCardTransaction"
              customEditFunction={customEditFunction}
              additionalActionIcon={record.type === 'OUTCOME' ? <PlaylistAddIcon fontSize="small" /> : undefined}
              additionalActionLabel={record.type === 'OUTCOME' ? 'Aggiungi pagamento' : undefined}
              additionalActionCallback={record.type === 'OUTCOME' ? onAddRow : undefined}
            />
          )}
        </TableCell>
      )}
    </TableRow>
  ) : (
    <TableRow>
      <TableCell colSpan={8}>
        <TransactionForm
          onUndo={onUndoEdit}
          accountingCardId={record.accountingCardId}
          id={record.id}
          record={accountingCardRecord}
        />
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(1),
    backgroundColor: 'white',
    border: '1px solid gray',
    color: 'black',
  },
  noteIcon: {
    marginLeft: theme.spacing(1),
  },
  descriptionCell: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default SimpleTransactionRow
