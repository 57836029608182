import React, { FC, useCallback } from 'react'
import { ReferenceManyField, TextField, ReferenceField, SimpleList, Pagination } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { makeStyles } from '@material-ui/core/styles'
import CustomerAvatarField from './CustomerAvatarField'
import { AiOutlineUser } from 'react-icons/ai'
import QuickCreateCustomerCoursePlanningButton from './QuickCreateCustomerCoursePlanningButton'
import MoreVert from '@material-ui/icons/MoreVert'
// import DeleteIcon from '@material-ui/icons/Delete'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useRedirect } from 'ra-core'

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    list: {
      minHeight: 400,
    },
    title: {},
    icon: {
      marginRight: theme.spacing(1),
    },
  }
})

type Props = {
  record?: any
  permissionsMap: Record<string, boolean>
  [x: string]: any
}

// TODO: deleteCustomerCoursePlanning
const MoreMenu: FC<Props> = ({ record, permissionsMap }) => {
  const redirect = useRedirect()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const onMoreClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const onMoreClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onGoToProfile = useCallback(() => {
    onMoreClose()
    redirect(`/Customer/${record.customerId}/show`)
  }, [record])

  return (
    <>
      <IconButton edge="end" aria-label="delete" onClick={onMoreClick}>
        <MoreVert />
      </IconButton>
      <Menu id={`more-${record.id}`} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onMoreClose}>
        {permissionsMap.customer && (
          <MenuItem onClick={onGoToProfile}>
            <ListItemIcon>
              <GetAppIcon fontSize="small" />
            </ListItemIcon>
            Visualizza Profilo
          </MenuItem>
        )}
        {/* <MenuItem onClick={onMoreClose}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Elimina
        </MenuItem> */}
      </Menu>
    </>
  )
}

const CoursePlanningCustomersCard: FC<Props> = ({ record, permissionsMap, ...rest }) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <AiOutlineUser size="1.2rem" className={classes.icon} />
          <Typography variant="h4" className={classes.title}>
            Partecipanti
          </Typography>
        </Box>
        <Divider />
        <ReferenceManyField
          {...rest}
          pagination={<Pagination />}
          record={record}
          reference="CustomerCoursePlanning"
          target="coursePlanningId"
          link={false}
        >
          <SimpleList
            linkType={false}
            className={classes.list}
            primaryText={(record: any): any => (
              <ReferenceField {...rest} link={false} record={record} reference="Customer" source="customerId">
                <TextField source="fullName" />
              </ReferenceField>
            )}
            leftAvatar={(record: any): any => (
              <ReferenceField {...rest} link={false} record={record} reference="Customer" source="customerId">
                <CustomerAvatarField source="profilePictureId" />
              </ReferenceField>
            )}
            rightIcon={(record: any): any => <MoreMenu record={record} permissionsMap={permissionsMap} />}
          />
        </ReferenceManyField>
      </CardContent>
      {permissionsMap.createCustomerCoursePlanning && (
        <>
          <Divider />
          <CardActions>
            <QuickCreateCustomerCoursePlanningButton
              color="primary"
              fullWidth
              variant="text"
              coursePlanningId={record.id}
            />
          </CardActions>
        </>
      )}
    </Card>
  )
}

export default CoursePlanningCustomersCard
