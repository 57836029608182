import React, { FC, useEffect, useMemo } from 'react'
import { FormRenderProps, useForm } from 'react-final-form'
import { SelectInput, ReferenceInput, AutocompleteInput, DateInput, NumberInput, TextInput } from 'react-admin'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import SelectDriveTrainingsInput from './SelectDriveTrainingsInput'
// import SelectExamInput from './SelectExamInput'
import SelectOtherEventsInput from './SelectOtherEventsInput'
// import RedGreenFormSwitchInput from '../../components/forms/RedGreenFormSwitchInput'

const paymentMethodChoices = [
  { id: 'CASH', name: 'Contanti' },
  { id: 'TRANSFER', name: 'TRASFER' },
  { id: 'POS', name: 'POS' },
]

type Props = FormRenderProps & {
  values: Record<string, any>
  mutationLoading: boolean
  isEdit: boolean
  onUndo: () => void
  handleSubmit: () => void
  currentActiveField?: string
  setCurrentActiveField: React.Dispatch<React.SetStateAction<string | undefined>>
  record: Record<string, any>
  allDrivingAttributes: Record<string, any>
}

const filterToQuery = (name: string): any => ({ name })

const TransactionFormBody: FC<Props> = ({
  handleSubmit,
  values,
  onUndo,
  mutationLoading,
  isEdit,
  invalid,
  active,
  currentActiveField,
  setCurrentActiveField,
  record,
  allDrivingAttributes,
  ...rest
}) => {
  const classes = useStyles()
  const form = useForm()

  const drivingAttributes = useMemo(() => {
    if (!allDrivingAttributes || !values.productId) return null
    else
      return Object.values(allDrivingAttributes)
        .filter((elem) => elem.isDrivingTraining)
        .filter((elem: any) => elem.productId === values.productId)[0]
  }, [values?.productId, allDrivingAttributes])

  const examsAttributes = useMemo(() => {
    if (!allDrivingAttributes || !values.productId) return null
    else
      return Object.values(allDrivingAttributes)
        .filter((elem) => elem.isTheoryExam || elem.isDrivingExam || elem.isMedicalExam)
        .filter((elem: any) => elem.productId === values.productId)[0]
  }, [values?.productId, allDrivingAttributes])

  useEffect(() => {
    if (active !== currentActiveField) {
      setCurrentActiveField(active)
    }
  }, [active])

  useEffect(() => {
    form.change('plannedEventId', null)
    form.change('plannedEventIds', null)
  }, [values.productId])

  useEffect(() => {
    if (rest.initialValues.plannedEventIds && rest.initialValues.plannedEventIds.length > 0) {
      if (examsAttributes || drivingAttributes?.driveTrainingMinutesDuration <= 90) {
        form.change('plannedEventId', rest.initialValues.plannedEventIds[0])
      }
      if (drivingAttributes?.driveTrainingMinutesDuration > 90) {
        form.change('plannedEventIds', rest.initialValues.plannedEventIds)
      }
    }
  }, [])

  const isIncomePackage: boolean = values.isPayment && Array.isArray(values.plannedEventIds)

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.row}>
        <Typography variant="h6">{`${isEdit ? 'Modifica' : 'Nuova'} riga`}</Typography>
      </div>
      <div className={classes.row}>
        {/* <RedGreenFormSwitchInput
          source="isPayment"
          helperText={values.isPayment ? 'Ingresso (+)' : 'Uscita (-)'}
          disabled
        /> */}
        <Typography variant="h4" className={values.isPayment ? classes.green : classes.red}>
          {values.isPayment ? 'Ingresso (+)' : 'Uscita (-)'}
        </Typography>
        {values.isPayment && (
          <SelectInput
            source="method"
            choices={paymentMethodChoices}
            variant="outlined"
            label="Metodo di pagamento"
            helperText={false}
          />
        )}
        <DateInput
          source="date"
          label="Data"
          variant="outlined"
          defaultValue={new Date().toString()}
          required
          helperText={false}
        />
      </div>
      <div className={classes.row}>
        {!values.isFreeCredit && (
          <ReferenceInput
            basePath="/AccountingCard"
            source="productId"
            reference="Product"
            variant="outlined"
            disabled={isEdit || values.isPayment}
            helperText={false}
            label="Prodotto"
            filterToQuery={filterToQuery}
          >
            <AutocompleteInput optionText="name" variant="outlined" />
          </ReferenceInput>
        )}
        <TextInput
          className={values.isFreeCredit ? undefined : classes.descriptionField}
          source="description"
          fullWidth
          variant="outlined"
          helperText={false}
          label="Descrizione"
        />
      </div>
      {!values.isPayment && drivingAttributes && !isIncomePackage && (
        <div className={classes.row}>
          <SelectDriveTrainingsInput
            isPayment={values.isPayment}
            record={record}
            trainingDuration={drivingAttributes.driveTrainingMinutesDuration}
            selectedId={values.plannedEventId}
            selectedIds={values.plannedEventIds}
            isEdit={isEdit}
            formChange={form.change}
            transactionId={isEdit ? values.id : undefined}
          />
        </div>
      )}
      {!values.isPayment &&
        examsAttributes &&
        (examsAttributes.isTheoryExam || examsAttributes.isDrivingExam || examsAttributes.isMedicalExam) && (
          <SelectOtherEventsInput
            isPayment={values.isPayment}
            record={record}
            examAttributes={
              examsAttributes.isTheoryExam
                ? 'THEORYEXAM'
                : examsAttributes.isDrivingExam
                ? 'DRIVINGEXAM'
                : examsAttributes.isMedicalExam
                ? 'MEDICAL'
                : undefined
            }
            selectedId={values.plannedEventId}
            isEdit={isEdit}
            formChange={form.change}
            transactionId={isEdit ? values.id : undefined}
            productId={values.productId}
          />
        )}
      <div className={classes.row}>
        <NumberInput
          className={classes.numberField}
          source="salePrice"
          variant="outlined"
          helperText={false}
          label="Prezzo lordo"
          disabled={(!values.productId && !values.isFreeCredit) || values.relatedTransactionId}
        />
        {!values.isPayment && (
          <NumberInput
            className={classes.numberField}
            source="netPrice"
            variant="outlined"
            helperText={false}
            label="Imponibile"
            disabled={values.isPayment || !values.productId}
          />
        )}
        {!values.isPayment && (
          <TextInput
            className={classes.numberField}
            disabled
            source="taxRate"
            label="IVA (%)"
            variant="outlined"
            helperText={false}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        )}
      </div>
      {!values.isPayment && (
        <div className={classes.row}>
          <NumberInput
            className={classes.numberField}
            source="discountAmount"
            variant="outlined"
            helperText={false}
            label="Sconto"
            defaultValue={0}
            disabled={!values.productId}
          />
          <NumberInput
            className={classes.numberField}
            source="discountRate"
            variant="outlined"
            helperText={false}
            label="Sconto (%)"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            defaultValue={0}
            disabled={!values.productId}
          />
          <NumberInput
            className={classes.numberField}
            source="total"
            variant="outlined"
            disabled
            helperText={false}
            label="Totale"
          />
        </div>
      )}
      <div className={classes.row}>
        <TextInput source="notes" fullWidth multiline variant="outlined" helperText={false} label="Note" />
        <div className={classes.buttonsWrapper}>
          <Button
            className={classes.undoButton}
            onClick={onUndo}
            variant="contained"
            size="small"
            disabled={mutationLoading}
          >
            Annulla
          </Button>
          <Button
            color="primary"
            onClick={handleSubmit}
            variant="contained"
            size="small"
            disabled={invalid || mutationLoading}
          >
            {mutationLoading ? <CircularProgress size={10} /> : `${isEdit ? 'Modifica' : 'Aggiungi'} riga`}
          </Button>
        </div>
      </div>
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  productField: {
    minWidth: '200px',
  },
  numberField: {
    maxWidth: '166px',
  },
  descriptionField: {
    marginLeft: theme.spacing(4),
  },
  buttonsWrapper: {
    minWidth: '30%',
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  undoButton: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  red: { color: 'red' },
  green: { color: 'green' },
}))

export default TransactionFormBody
