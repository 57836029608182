/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { usePermissions, useTranslate } from 'ra-core'
import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import SubMenu from './SubMenu'
import MenuItemLink from './MenuItemLink'
import clsx from 'clsx'

import { FiCalendar, FiFlag, FiShoppingCart, FiUser, FiBook } from 'react-icons/fi'
import { HiOutlineOfficeBuilding } from 'react-icons/hi'
import { BiBuildings, BiCar, BiEuro, BiStore } from 'react-icons/bi'
import { RiPriceTagLine, RiGroupLine } from 'react-icons/ri'
import { ImInsertTemplate } from 'react-icons/im'
import { GiGavel } from 'react-icons/gi'
import { VscGroupByRefType } from 'react-icons/vsc'
import { FaRegAddressCard, FaGraduationCap, FaTelegramPlane, FaChartLine, FaMoneyCheckAlt } from 'react-icons/fa'
import { GrStatusInfo } from 'react-icons/gr'
import { MdTextsms } from 'react-icons/md'
import { AiOutlineMail } from 'react-icons/ai'
import { RiShieldUserLine } from 'react-icons/ri'

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 64,
    height: 64,
    transition: theme.transitions.create(['width', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  closedAvatar: {
    width: 32,
    height: 32,
  },
  name: {
    transition: theme.transitions.create(['opacity', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  role: {
    transition: theme.transitions.create(['opacity', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuContainer: {
    overflowX: 'hidden',
  },
  userContainer: {
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  closedSidebarUserInfo: {
    height: 0,
    opacity: 0,
    overflow: 'hidden',
  },
}))

interface Props {
  dense: boolean
  logout: () => void
  onMenuClick: () => void
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout, ...rest }) => {
  const classes = useStyles()
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen)
  const translate = useTranslate()
  const { loading, permissions } = usePermissions()
  // if (permissions) console.log('permissions: ', permissions)

  useSelector((state: any) => state.theme) // force rerender on theme change
  const avatarClassName = useMemo(() => clsx(classes.avatar, { [classes.closedAvatar]: !open }), [open])
  const usernameClassName = useMemo(() => clsx(classes.name, { [classes.closedSidebarUserInfo]: !open }), [open])
  const userRoleClassName = useMemo(() => clsx(classes.role, { [classes.closedSidebarUserInfo]: !open }), [open])

  return loading || !permissions ? null : (
    <Box height="100%" display="flex" flexDirection="column" displayPrint="none">
      <Box className={classes.userContainer} alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar className={avatarClassName} src={'http://localhost:3000/static/images/avatars/avatar_6.png'} />
        {loading ? (
          <LinearProgress />
        ) : (
          <>
            <Typography className={usernameClassName} color="textPrimary" variant="h5">
              {permissions && permissions.user ? `${permissions.user.firstName} ${permissions.user.lastName}` : 'N.D.'}
            </Typography>
            <Typography color="textSecondary" variant="body2" className={userRoleClassName}>
              {permissions && permissions.roles && Array.isArray(permissions.roles)
                ? permissions.roles.map((role: any) => <span key={role.id}>{`${role.name} `}</span>)
                : 'N.D.'}
            </Typography>
          </>
        )}
      </Box>
      <Divider />
      <Box className={classes.menuContainer}>
        <List>
          {/* PLANNING */}
          <SubMenu sidebarIsOpen={open} name="menu.categories.planning" icon={FiCalendar} dense={dense}>
            <MenuItemLink
              to={'/PlannedEvent'}
              primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
              leftIcon={BiCar}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            {permissions.permissionsMap.eventTemplates && (
              <MenuItemLink
                to={'/EventTemplate'}
                primaryText={translate('resources.EventTemplate.name', { smart_count: 2 })}
                leftIcon={ImInsertTemplate}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {permissions.permissionsMap.eventStatuses && (
              <MenuItemLink
                to={'/EventStatus'}
                primaryText={translate('resources.EventStatus.name', { smart_count: 2 })}
                leftIcon={GrStatusInfo}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {permissions.permissionsMap.smsTemplates && (
              <MenuItemLink
                to={'/SmsTemplate'}
                primaryText={translate('resources.SmsTemplate.name', { smart_count: 2 })}
                leftIcon={MdTextsms}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {permissions.permissionsMap.emailTemplates && (
              <MenuItemLink
                to={'/EmailTemplate'}
                primaryText={translate('resources.EmailTemplate.name', { smart_count: 2 })}
                leftIcon={AiOutlineMail}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            <MenuItemLink
              to={'/TelegramTemplate'}
              primaryText={translate('resources.TelegramTemplate.name', { smart_count: 2 })}
              leftIcon={FaTelegramPlane}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>

          {/* ORDERS */}
          {(permissions.permissionsMap.quotes ||
            permissions.permissionsMap.orders ||
            permissions.permissionsMap.quoteTemplates ||
            permissions.permissionsMap.documents) && (
            <SubMenu sidebarIsOpen={open} name="menu.categories.order" icon={FiShoppingCart} dense={dense}>
              {permissions.permissionsMap.quotes && (
                <MenuItemLink
                  to={'/Quote'}
                  primaryText={translate('resources.Quote.name', { smart_count: 2 })}
                  leftIcon={RiPriceTagLine}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {permissions.permissionsMap.orders && (
                <MenuItemLink
                  to={'/Order'}
                  primaryText={translate('resources.Order.name', { smart_count: 2 })}
                  leftIcon={FiShoppingCart}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {permissions.permissionsMap.accountingCards && (
                <MenuItemLink
                  to={'/AccountingCard'}
                  primaryText={translate('resources.AccountingCard.name', { smart_count: 2 })}
                  leftIcon={FaMoneyCheckAlt}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {permissions.permissionsMap.quoteTemplates && (
                <MenuItemLink
                  to={'/QuoteTemplate'}
                  primaryText={translate('resources.QuoteTemplate.name', { smart_count: 2 })}
                  leftIcon={RiPriceTagLine}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {permissions.permissionsMap.documents && (
                <MenuItemLink
                  to={'/Document'}
                  // primaryText={translate('resources.Document.name', { smart_count: 2 })}
                  primaryText={'Template di Stampa'}
                  leftIcon={RiPriceTagLine}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
            </SubMenu>
          )}

          {/** COURSES & EXAMS */}
          {(permissions.permissionsMap.courses || permissions.permissionsMap.exams) && (
            <SubMenu sidebarIsOpen={open} name="menu.categories.coursesAndExams" icon={FiBook} dense={dense}>
              {/* <MenuItemLink
              to={'/CoursePlanning'}
              primaryText={translate('resources.CourseSchedule.name', { smart_count: 2 })}
              leftIcon={FiBook}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            /> */}
              {permissions.permissionsMap.courses && (
                <MenuItemLink
                  to={'/Course'}
                  primaryText={translate('resources.Course.name', { smart_count: 2 })}
                  leftIcon={FiBook}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {permissions.permissionsMap.exams && (
                <MenuItemLink
                  to={'/Exam'}
                  primaryText={translate('resources.Exam.name', { smart_count: 2 })}
                  leftIcon={FaGraduationCap}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
            </SubMenu>
          )}

          {/* CUSTOMERS */}
          {(permissions.permissionsMap.customers || permissions.permissionsMap.addresses) && (
            <SubMenu sidebarIsOpen={open} name="menu.categories.customers" icon={RiGroupLine} dense={dense}>
              {permissions.permissionsMap.customers && (
                <MenuItemLink
                  to={'/Customer'}
                  primaryText={translate('resources.Customer.name', { smart_count: 2 })}
                  leftIcon={RiGroupLine}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {permissions.permissionsMap.addresses && (
                <MenuItemLink
                  to={'/Address'}
                  primaryText={translate('resources.Address.name', { smart_count: 2 })}
                  leftIcon={FaRegAddressCard}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
            </SubMenu>
          )}

          {/* DOCUMENTS */}
          {/* <MenuItemLink
            to={'/Document'}
            primaryText={translate('resources.Document.name', { smart_count: 2 })}
            leftIcon={TiDocument}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          /> */}

          {/* ORGANIZATIONS */}
          {(permissions.permissionsMap.offices ||
            permissions.permissionsMap.teamMembers ||
            permissions.permissionsMap.teamMemberWorkPlannings) && (
            <SubMenu sidebarIsOpen={open} name="menu.categories.organizations" icon={BiBuildings} dense={dense}>
              {/* <MenuItemLink
              to={'/Organization'}
              primaryText={translate('resources.Organization.name', { smart_count: 2 })}
              leftIcon={BiBuildings}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            /> */}
              {permissions.permissionsMap.offices && (
                <MenuItemLink
                  to={'/Office'}
                  primaryText={translate('resources.Office.name', { smart_count: 2 })}
                  leftIcon={HiOutlineOfficeBuilding}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {permissions.permissionsMap.teamMembers && (
                <MenuItemLink
                  to={'/TeamMember'}
                  primaryText={translate('resources.TeamMember.name', { smart_count: 2 })}
                  leftIcon={FiUser}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {permissions.permissionsMap.teamMemberWorkPlannings && (
                <MenuItemLink
                  to={'/TeamMemberWorkPlanning'}
                  primaryText={translate('resources.TeamMemberWorkPlanning.name', { smart_count: 2 })}
                  leftIcon={FiUser}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {permissions.permissionsMap.plannedEvent && (
                <MenuItemLink
                  to={'/WorkReport'}
                  // primaryText={translate('resources.TeamMemberWorkPlanning.name', { smart_count: 2 })}
                  primaryText="Rendicontazione"
                  leftIcon={FaChartLine}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
            </SubMenu>
          )}

          {/* VEHICLES */}
          {(permissions.permissionsMap.vehicles ||
            permissions.permissionsMap.vehicleModels ||
            permissions.permissionsMap.vehicleManufacturers ||
            permissions.permissionsMap.vehicleFuels ||
            permissions.permissionsMap.vehicleTypes) && (
            <SubMenu sidebarIsOpen={open} name="menu.categories.vehicles" icon={BiCar} dense={dense}>
              {permissions.permissionsMap.vehicles && (
                <MenuItemLink
                  to={'/Vehicle'}
                  primaryText={translate('resources.Vehicle.name', { smart_count: 2 })}
                  leftIcon={BiCar}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {permissions.permissionsMap.vehicleModels && (
                <MenuItemLink
                  to={'/VehicleModel'}
                  primaryText={translate('resources.VehicleModel.name', { smart_count: 2 })}
                  leftIcon={BiCar}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {permissions.permissionsMap.vehicleManufacturers && (
                <MenuItemLink
                  to={'/VehicleManufacturer'}
                  primaryText={translate('resources.VehicleManufacturer.name', { smart_count: 2 })}
                  leftIcon={BiCar}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {permissions.permissionsMap.vehicleFuels && (
                <MenuItemLink
                  to={'/VehicleFuel'}
                  primaryText={translate('resources.VehicleFuel.name', { smart_count: 2 })}
                  leftIcon={BiCar}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {permissions.permissionsMap.vehicleTypes && (
                <MenuItemLink
                  to={'/VehicleType'}
                  primaryText={translate('resources.VehicleType.name', { smart_count: 2 })}
                  leftIcon={BiCar}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
            </SubMenu>
          )}

          {/* CATALOGO */}
          {(permissions.permissionsMap.products || permissions.permissionsMap.productCategories) && (
            <SubMenu sidebarIsOpen={open} name="menu.categories.catalog" icon={BiStore} dense={dense}>
              {permissions.permissionsMap.products && (
                <MenuItemLink
                  to={'/Product'}
                  primaryText={translate('resources.Product.name', { smart_count: 2 })}
                  leftIcon={BiStore}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {/* <MenuItemLink
          to={'/Raffle'}
          primaryText={translate('menu.resources.Raffle', {
            smart_count: 2
          })}
          leftIcon={<InsertInvitationIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={'/Brand'}
          primaryText={translate('menu.resources.Brand', {
            smart_count: 2
          })}
          leftIcon={<StorefrontIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        /> */}
              {permissions.permissionsMap.productCategories && (
                <MenuItemLink
                  to={'/ProductCategory'}
                  primaryText={translate('resources.ProductCategory.name', { smart_count: 2 })}
                  leftIcon={VscGroupByRefType}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {/* <MenuItemLink
              to={'/ProductAttribute'}
              primaryText={translate('resources.ProductAttribute.name', { smart_count: 2 })}
              leftIcon={VscGroupByRefType}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            /> */}
              {/* <MenuItemLink
              to={'/ProductFeature'}
              primaryText={translate('resources.ProductFeature.name', { smart_count: 2 })}
              leftIcon={VscGroupByRefType}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            /> */}
            </SubMenu>
          )}

          {/* LOCALIZATION */}
          {(permissions.permissionsMap.countries ||
            permissions.permissionsMap.states ||
            permissions.permissionsMap.currencies ||
            permissions.permissionsMap.taxes) && (
            <SubMenu sidebarIsOpen={open} name="menu.categories.localization" icon={FiFlag} dense={dense}>
              {permissions.permissionsMap.countries && (
                <MenuItemLink
                  to={'/Country'}
                  primaryText={translate('resources.Country.name', {
                    smart_count: 2,
                  })}
                  leftIcon={FiFlag}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {permissions.permissionsMap.states && (
                <MenuItemLink
                  to={'/State'}
                  primaryText={translate('resources.State.name', {
                    smart_count: 2,
                  })}
                  leftIcon={FiFlag}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {/* <MenuItemLink
          to={'/Zone'}
          // primaryText={translate('menu.resources.Zone', {
          //   smart_count: 2
          // })}
          primaryText="Zones"
          leftIcon={<TripOriginIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        /> */}
              {permissions.permissionsMap.currencies && (
                <MenuItemLink
                  to={'/Currency'}
                  primaryText={translate('resources.Currency.name', {
                    smart_count: 2,
                  })}
                  leftIcon={BiEuro}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {permissions.permissionsMap.taxes && (
                <MenuItemLink
                  to={'/Tax'}
                  primaryText={translate('resources.Tax.name', {
                    smart_count: 2,
                  })}
                  leftIcon={GiGavel}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
            </SubMenu>
          )}
          {(permissions.permissionsMap.users || permissions.permissionsMap.roles) && (
            <SubMenu sidebarIsOpen={open} name="menu.categories.account" icon={RiShieldUserLine} dense={dense}>
              {permissions.permissionsMap.users && (
                <MenuItemLink
                  to={'/User'}
                  primaryText={translate('resources.User.name')}
                  leftIcon={RiGroupLine}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {permissions.permissionsMap.roles && (
                <MenuItemLink
                  to={'/Role'}
                  primaryText={translate('resources.Role.name', {
                    smart_count: 2,
                  })}
                  leftIcon={RiShieldUserLine}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
            </SubMenu>
          )}
        </List>
      </Box>
    </Box>
  )
}

export default Menu
