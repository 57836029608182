import React, { FC, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  ReferenceInput,
  Toolbar,
  SelectInput,
} from 'react-admin'
import { useNotify, useRefresh, useDataProvider } from 'ra-core'
import { useFormState } from 'react-final-form'
import Edit from '../../components/Edit'
import PlannedEventDatesInput from '../../components/PlannedEventDatesInput'
import { useHistory, useLocation } from 'react-router-dom'
import PlannedEventStatusField from '../../components/PlannedEventStatusField'
import SendEventInviteButton from '../../components/SendEventInviteButton'
import { useApolloClient } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { QUERY_GET_PLANNED_EVENT, QUERY_GET_EVENT_STATUSES_CONFIGURATION } from '../../queries'
import PlannedEventSelectCustomerStatus from '../../components/PlannedEventSelectCustomerStatus'
import gql from 'graphql-tag'
import DeleteEventButton from '../../components/DeleteEventButton'
import EditPlannedEventButton from './EditPlannedEventButton'
import SelectDsEventType from './SelectDsEventType'
import DeleteAllRecurringEventsButton from '../../components/DeleteAllRecurringEventsButton'
import RecurringEventFormSection from './RecurringEventFormSection'
import EditRecurringEventButton from './EditRecurringEventButton'

const QUERY_GET_TELEGRAM_TEMPLATE_UPDATE_EVENT = gql`
  query GetTelegramTemplateUpdateEvent {
    getConfiguration(key: "CHANGED_EVENT_TELEGRAM_TEMPLATE_ID") {
      id
      key
      value
    }
  }
`

const MUTATION_SEND_TELEGRAM_INVITE_UPDATE = gql`
  mutation SendTelegramInviteUpdate($data: TelegramInputData!) {
    sendTelegramMessage(data: $data)
  }
`
const MUTATION_UPDATE_CUSTOMER_EVENT_STATUS = gql`
  mutation updateCustomerEventStatus($eventStatusId: ID!, $eventCustomerId: ID!) {
    updateCustomerEventStatus(eventStatusId: $eventStatusId, eventCustomerId: $eventCustomerId) {
      id
    }
  }
`

type Props = any

const CustomToolbar: FC<any> = (props) => {
  const { search } = useLocation()
  const classes = useStyles()
  const { values } = useFormState()

  return (
    <Toolbar {...props} className={classes.toolbar}>
      {!values?.isRecurringEvent ? (
        <EditPlannedEventButton plannedEvent={props.plannedEvent} disabled={props.isRecurringEventInvalid} />
      ) : (
        <EditRecurringEventButton
          plannedEvent={props.plannedEvent}
          disabled={props.isRecurringEventInvalid}
          customRedirect={`/PlannedEvent${search}`}
        />
      )}
      <DeleteEventButton redirect={`/PlannedEvent${search}`} plannedEvent={props.plannedEvent} />
      <SendEventInviteButton plannedEvent={props.plannedEvent} loadingPlannedEvent={props.loadingPlannedEvent} />
      {props?.plannedEvent?.plannedEvent?.recurringEventId && (
        <DeleteAllRecurringEventsButton
          redirect={`/PlannedEvent${search}`}
          plannedEvent={props.plannedEvent?.plannedEvent}
        />
      )}
    </Toolbar>
  )
}

const vehicleFilterToQuery = (licensePlate: string): any => ({
  licensePlate,
})

const officeFilterToQuery = (name: string): any => ({
  name,
})

const useStyles = makeStyles((theme) => ({
  suggestionsContainer: {
    zIndex: 1300,
  },
  toolbar: {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: `${theme.spacing(2)}px`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))

const PlannedEventEdit: FC<Props> = (props) => {
  // const [plannedState, setPlannedState] = useState<Record<string, any>>({})
  const classes = useStyles(props)
  const notify = useNotify()
  const history = useHistory()
  const { search } = useLocation()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const apolloClient = useApolloClient()
  const { loading: loadingPlannedEvent, data: plannedEvent } = useQuery(QUERY_GET_PLANNED_EVENT, {
    variables: {
      id: props.id,
    },
    skip: !props.id,
  })
  const [isRecurringEventInvalid, setIsRecurringEventInvalid] = useState<boolean>(false)

  const onSuccess = useCallback(({ data }) => {
    notify('Event Updated successfully')
    history.replace({
      pathname: '/PlannedEvent',
      search: search,
    })
    refresh()
  }, [])

  const transform = useCallback(async ({ ...record }) => {
    // console.log('PASSO DALLA TRANSFORM', record)
    // const newStartDate = new Date(record.startDate)
    // const newEndDate = new Date(record.startDate)
    // console.log('ARRIVEDERCI', record)

    // qui dentro plannedEvent risulta undefined
    // neanche se lo salvo nello state non viene letto qui dentro :/
    // ultimo approccio brutale: rifaccio la query per vedere le date vecchie
    // const { data: oldRecord } = await dataProvider.getOne('Event', { id: record.eventId })

    const { data: plannedEvent } = await apolloClient.query({
      query: QUERY_GET_PLANNED_EVENT,
      variables: { id: record.id },
    })

    const { data: eventStatusConfiguration } = await apolloClient.query({
      query: QUERY_GET_EVENT_STATUSES_CONFIGURATION,
    })

    // TODO: FIX ME
    if (
      plannedEvent &&
      plannedEvent.plannedEvent &&
      plannedEvent.plannedEvent.customerEvents &&
      plannedEvent.plannedEvent.customerEvents.length > 0 &&
      plannedEvent.plannedEvent.customerEvents[0].status.id !== record.customerEventStatus
    ) {
      // qui query updateCustomerEventStatus
      try {
        const y = await apolloClient.mutate({
          mutation: MUTATION_UPDATE_CUSTOMER_EVENT_STATUS,
          variables: {
            eventStatusId: record.customerEventStatus,
            eventCustomerId: plannedEvent.plannedEvent.customerEvents[0].id,
          },
        })
      } catch (err) {
        notify('ra.message.error')
        console.log('error updating status: ', err)
      }
    }

    if (
      plannedEvent &&
      plannedEvent.plannedEvent &&
      plannedEvent.plannedEvent.customerEvents &&
      plannedEvent.plannedEvent.customerEvents.length > 0 &&
      record.customerIds.length > 0 &&
      plannedEvent.plannedEvent.customerEvents[0].customer.id !== record.customerIds[0] &&
      (plannedEvent.plannedEvent.customerEvents[0].status.id ===
        eventStatusConfiguration.getConfiguration.value.confirmed ||
        plannedEvent.plannedEvent.customerEvents[0].status.id ===
          eventStatusConfiguration.getConfiguration.value.waitingConfirmation)
    ) {
      // avvisare il cliente se il suo stato è confermato, quando viene sostituito da un altro cliente
      const { data: contacts } = await dataProvider.getList('CustomerContact', {
        filter: {
          customerId: plannedEvent.plannedEvent.customerEvents[0].customer.id,
          contactType: 'telegram',
        },
        pagination: { perPage: 10, page: 1 },
        sort: { field: 'id', order: 'ASC' },
      })
      // console.log('contacts?? ', contacts, plannedEvent.plannedEvent.customerEvents[0].customer.id)
      if (contacts) {
        try {
          const { data: telegramTemplate } = await apolloClient.query({
            query: QUERY_GET_TELEGRAM_TEMPLATE_UPDATE_EVENT,
          })
          if (telegramTemplate) {
            const x = await apolloClient.mutate({
              mutation: MUTATION_SEND_TELEGRAM_INVITE_UPDATE,
              variables: {
                data: {
                  telegramId: parseInt(contacts[0].contact),
                  templateId: telegramTemplate.getConfiguration.value,
                },
              },
            })
            // console.log('CIAOOOOOOO SENDTGUPDATE', x)
          }
        } catch (err) {
          notify('ra.message.error', 'warning')
        }
      } // contacts[0].contact
    } else {
      if (
        plannedEvent &&
        plannedEvent.plannedEvent &&
        plannedEvent.plannedEvent.customerEvents &&
        plannedEvent.plannedEvent.customerEvents.length > 0 &&
        (eventStatusConfiguration.getConfiguration.value.confirmed.includes(
          plannedEvent.plannedEvent.customerEvents[0].status.id
        ) ||
          plannedEvent.plannedEvent.customerEvents[0].status.id ===
            eventStatusConfiguration.getConfiguration.value.waitingConfirmation) &&
        (record.startDate !== plannedEvent.plannedEvent.event.startDate ||
          record.endDate !== plannedEvent.plannedEvent.event.endDate)
      ) {
        // console.log('MANDIAMO UN MESSAGGIO ')
        const { data: contacts } = await dataProvider.getList('CustomerContact', {
          filter: {
            customerId: record.customerIds[0],
            contactType: 'telegram',
          },
          pagination: { perPage: 10, page: 1 },
          sort: { field: 'id', order: 'ASC' },
        })
        // console.log('contacts?? ', contacts, record.customerIds)
        if (contacts) {
          try {
            const { data: telegramTemplate } = await apolloClient.query({
              query: QUERY_GET_TELEGRAM_TEMPLATE_UPDATE_EVENT,
            })
            if (telegramTemplate) {
              const x = await apolloClient.mutate({
                mutation: MUTATION_SEND_TELEGRAM_INVITE_UPDATE,
                variables: {
                  data: {
                    telegramId: parseInt(contacts[0].contact),
                    templateId: telegramTemplate.getConfiguration.value,
                  },
                },
              })
              // console.log('CIAOOOOOOO SENDTGUPDATE', x)
            }
          } catch (err) {
            notify('ra.message.error', 'warning')
          }
        } // contacts[0].contact
      }
    }

    if (plannedEvent.plannedEvent?.recurringEventId && !record.isRecurringEvent) {
      // this means that now the isRecurringEvent boolean is false
      // we have to remove the relation and than delete the recurringEvent
      // this will happen on backend
      record.recurringEventId = 0
    }

    // WIRED CODE, sorry
    if (plannedEvent.plannedEvent?.event?.additionalData?.backgroundColor && !record.backgroundColor) {
      return {
        ...record,
        additionalData: {},
      }
    }

    if (record.backgroundColor) {
      return {
        ...record,
        additionalData: {
          backgroundColor: record.backgroundColor,
        },
      }
    }

    return record
  }, [])

  return (
    <Edit {...props} transform={transform} onSuccess={onSuccess}>
      <SimpleForm
        variant="outlined"
        toolbar={
          <CustomToolbar
            plannedEvent={plannedEvent}
            loadingPlannedEvent={loadingPlannedEvent}
            isRecurringEventInvalid={isRecurringEventInvalid}
          />
        }
      >
        <PlannedEventDatesInput {...props} />
        <RecurringEventFormSection {...props} setIsRecurringEventInvalid={setIsRecurringEventInvalid} />
        <ReferenceInput
          resource="PlannedEvent"
          source="officeId"
          reference="Office"
          filterToQuery={officeFilterToQuery}
          fullWidth
        >
          <AutocompleteInput
            options={{
              suggestionsContainerProps: {
                className: classes.suggestionsContainer,
              },
            }}
            optionText="name"
            optionValue="id"
          />
        </ReferenceInput>
        <ReferenceArrayInput source="teamMemberIds" reference="TeamMember" fullWidth>
          <AutocompleteArrayInput
            options={{
              suggestionsContainerProps: {
                className: classes.suggestionsContainer,
              },
            }}
            optionText="fullName"
            optionValue="id"
          />
        </ReferenceArrayInput>
        <SelectInput
          fullWidth
          label=""
          source="isWorkEvent"
          initialValue={true}
          choices={[
            { id: true, name: 'Lavoro' },
            { id: false, name: 'Ferie / Pausa' },
          ]}
        />
        <SelectDsEventType />
        <ReferenceArrayInput source="customerIds" reference="Customer" fullWidth>
          <AutocompleteArrayInput
            options={{
              suggestionsContainerProps: {
                className: classes.suggestionsContainer,
              },
            }}
            optionText="fullName"
            optionValue="id"
          />
        </ReferenceArrayInput>
        <ReferenceInput
          resource="PlannedEvent"
          source="vehicleId"
          reference="Vehicle"
          filterToQuery={vehicleFilterToQuery}
          fullWidth
        >
          <AutocompleteInput
            options={{
              suggestionsContainerProps: {
                className: classes.suggestionsContainer,
              },
            }}
            optionText="licensePlate"
            optionValue="id"
          />
        </ReferenceInput>
        <TextInput resource="PlannedEvent" source="description" fullWidth />
        <PlannedEventStatusField />
        <PlannedEventSelectCustomerStatus plannedEvent={plannedEvent} />
      </SimpleForm>
    </Edit>
  )
}

export default PlannedEventEdit
