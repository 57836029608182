import React, { FC, useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import { useTranslate } from 'ra-core'
import { SelectableGroup } from 'react-selectable-fast'
import Skeleton from '@material-ui/lab/Skeleton'
import ListItem from '@material-ui/core/ListItem/ListItem'
import OfficeResourceListItem from './OfficeResourceListItem'

const useStyles = makeStyles((theme) => ({
  root: {},
  list: {
    width: '100%',
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
}))

type Props = {
  selectedResourceIds: string[]
  onResourcesSelected: (selectedResourceIds: string[]) => void
  officeHashTable?: { [id: string]: any }
  offices?: any[]
  loading?: boolean
}

const OfficesSelectableList: FC<Props> = ({
  onResourcesSelected,
  selectedResourceIds,
  officeHashTable = {},
  offices = [],
  loading,
}) => {
  const translate = useTranslate()
  const classes = useStyles()

  const handleResourcesSelected = useCallback(
    (selectedComponents: any[]) => {
      if (onResourcesSelected) {
        onResourcesSelected(
          selectedComponents.filter((item: any) => item.props && item.props.record).map((item) => item.props.record.id)
        )
      }
    },
    [onResourcesSelected]
  )

  return (
    <SelectableGroup
      className={classes.root}
      enableDeselect
      globalMouse={true}
      allowClickWithoutSelected={true}
      onSelectionFinish={handleResourcesSelected}
    >
      <List
        subheader={
          <ListSubheader className={classes.subheader}>
            {translate('PlanningResourceConfiguration.selectVisibleOffices')}
          </ListSubheader>
        }
        className={classes.list}
      >
        {loading ? (
          <Skeleton>
            <ListItem />
            <ListItem />
            <ListItem />
          </Skeleton>
        ) : (
          offices.map((office, index) => {
            return (
              <OfficeResourceListItem
                key={`office-resource-list-${office.id}`}
                selectedResourceIds={selectedResourceIds}
                record={office}
                isSelected={selectedResourceIds.indexOf(office.id) > -1}
              />
            )
          })
        )}
      </List>
    </SelectableGroup>
  )
}

export default OfficesSelectableList
