import React, { FC } from 'react'
import List from '../../components/List'
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  ReferenceManyField,
  Datagrid,
  ShowButton,
  FunctionField
} from 'react-admin'
import CreateSubcategoryButton from '../../components/CreateProductSubcategoryButton'
import { Link } from '@material-ui/core'

const ProductCategoryShow = (props: any): any => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      {/* <TextField source="description" /> */}
      <FunctionField
        source="slug"
        render={(record: any): React.ReactNode => (
          <Link
            target="_blank"
            href={`http://localhost:3005/${record['slug']}`}
          >{`http://localhost:3005/${record['slug']}`}</Link>
        )}
      />
      <ReferenceField label="Parent" source="parentId" reference="ProductCategory">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceManyField
        reference="ProductCategory"
        target="parentId"
        label="Sub categories"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <ShowButton />
        </Datagrid>
      </ReferenceManyField>
      <CreateSubcategoryButton />
    </SimpleShowLayout>
  </Show>
)

export default ProductCategoryShow
