import React, { FC, useCallback, useMemo } from 'react'
import {
  ReferenceManyField,
  Datagrid,
  TextField,
  FunctionField,
  ReferenceField,
  SimpleList,
  Pagination,
} from 'react-admin'
import moment from 'moment'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import UserPreviewField from './UserPreviewField'
import Divider from '@material-ui/core/Divider'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import CustomerAvatarField from './CustomerAvatarField'
import { AiOutlineUser } from 'react-icons/ai'
import QuickCreateCustomerCoursePlanningButton from './QuickCreateCustomerCoursePlanningButton'
import MoreVert from '@material-ui/icons/MoreVert'
import DeleteIcon from '@material-ui/icons/Delete'
import GetAppIcon from '@material-ui/icons/GetApp'
import CardField from './CardField'
import { useGetOne, useTranslate } from 'ra-core'
import PrintCoursePlanningButton from './PrintCoursePlanningButton'

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    list: {
      minHeight: 400,
    },
    title: {},
    icon: {
      marginRight: theme.spacing(1),
    },
  }
})

type Props = {
  record?: any
  [x: string]: any
}

const CoursePlanningCourseOverviewCard: FC<Props> = ({ record, ...rest }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const { loading, data } = useGetOne('Course', record.courseId)

  const emptyValueLabel = useMemo(() => translate('custom.noData'), [translate])

  const startDateStr = useMemo(() => (data ? moment(record.startDate).format('L') : emptyValueLabel), [
    data,
    emptyValueLabel,
  ])
  const endDateStr = useMemo(() => (data ? moment(record.endDate).format('L') : emptyValueLabel), [
    data,
    emptyValueLabel,
  ])

  // if (error) {
  //   return (
  //     <Card className={classes.root}>
  //       <Typography color="error">{error}</Typography>
  //     </Card>
  //   )
  // }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <AiOutlineUser size="1.2rem" className={classes.icon} />
          {!loading && data && (
            <Typography variant="h4" className={classes.title}>
              {data.name}
            </Typography>
          )}
        </Box>
        <Divider />
        {/* <Box display="flex" alignItems="center" mb={2}>
          {!loading && data && (
            <Typography variant="h4" className={classes.title}>
              Informazioni {data.name}
            </Typography>
          )}
        </Box> */}
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CardField label={translate('resources.CoursePlanning.fields.startDate')}>{startDateStr}</CardField>
            </Grid>
            <Grid item xs={12} md={6}>
              <CardField label={translate('resources.CoursePlanning.fields.endDate')}>{endDateStr}</CardField>
            </Grid>
            <Grid item xs={12} md={12}>
              <CardField label={translate('resources.CoursePlanning.fields.teamMemberId')}>
                {!loading && data && (
                  <Box mt={1}>
                    <ReferenceField reference="TeamMember" source="teamMemberId" record={record} {...rest}>
                      <UserPreviewField avatarSize={50} />
                    </ReferenceField>
                  </Box>
                )}
              </CardField>
            </Grid>
          </Grid>
        </Box>
        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      </CardContent>
      <Divider />
      <CardActions>
        <PrintCoursePlanningButton
          color="primary"
          fullWidth
          variant="text"
          record={record}
          coursePlanningId={record.id}
          // onChange={onDocumentUploaded}
          // disabled={loading}
        />
        {/* <Button color="primary" fullWidth variant="text">
          Upload picture
        </Button> */}
      </CardActions>
    </Card>
  )
}

export default CoursePlanningCourseOverviewCard
