import React, { FC } from 'react'
import { TextInput } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Create from '../../components/Create'

type Props = {}

const DrivingSchoolCreate: FC<Props> = props => (
  <Create {...props} component="div">
    <SectionedForm variant="outlined" redirect="list">
      <FormSection title="General Info">
        <TextInput source="name" />
        <TextInput source="vat" />
      </FormSection>
      <FormSection title="Contact Info">
        <TextInput source="email" />
        <TextInput source="phone" />
      </FormSection>
    </SectionedForm>
  </Create>
)

export default DrivingSchoolCreate
