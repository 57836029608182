import React, { FC } from 'react'
import { useQueryWithStore, LinearProgress } from 'react-admin'

const AccountingCardTitle: FC<any> = ({ record }: any) => {
  const { loading, data, error } = useQueryWithStore({
    type: 'getOne',
    resource: 'Customer',
    payload: {
      id: record.customerId,
    },
  })
  return loading || error ? <LinearProgress /> : <span>{`Scheda contabile di ${data.fullName}`}</span>
}

export default AccountingCardTitle
