import React, { FC, useCallback } from 'react'
import {
  ReferenceInput,
  AutocompleteInput,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
  TextInput,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

import FormSection from '../../components/forms/FormSection'
import Create from '../../components/Create'
import SectionedForm from '../../components/forms/SectionedForm'
import CreateQuoteItem from './CreateQuoteItem'
import QuoteSummary from './QuoteSummary'
import HiddenOrganizationField from '../../components/forms/HiddenOrganizationField'
import ImporQuoteTemplate from './ImportQuoteTemplate'
import BaseButton from '@material-ui/core/Button'
import ContentAdd from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { required } from 'ra-core'

const filterToQueryCustomer = (q: string): Record<string, string> => ({ q })
const filterToQueryPrintTemplate = (description: string): Record<string, string> => ({ description })

const sortCustomer = { field: 'lastName', order: 'ASC' }
const sortPrintTemplate = { field: 'description', order: 'ASC' }

const QuoteForm: FC<Record<string, any>> = (props: any) => {
  const { form, line } = useStyles()

  return (
    <>
      <FormSection title="Cliente" subtitle="Scegli un cliente per questo preventivo" {...props}>
        <HiddenOrganizationField />
        <ReferenceInput
          label="Cliente"
          reference="Customer"
          source="customerId"
          filterToQuery={filterToQueryCustomer}
          sort={sortCustomer}
          validate={required('Il Cliente è obbligatorio')}
        >
          <AutocompleteInput optionText="fullName" />
        </ReferenceInput>
      </FormSection>

      <FormSection
        title="Importa da modello"
        subtitle="Genera velocemente un preventivo importando un modello"
        {...props}
      >
        <ImporQuoteTemplate />
      </FormSection>

      <FormSection
        title="Template di stampa"
        subtitle="Scegli quale template Word (.docx) utilizzare per stampare questo preventivo"
        {...props}
      >
        <ReferenceInput
          label="Template di stampa"
          reference="Document"
          source="printTemplateId"
          filterToQuery={filterToQueryPrintTemplate}
          filter={{ tag: 'quote_print_template' }}
          sort={sortPrintTemplate}
        >
          <AutocompleteInput optionText="description" />
        </ReferenceInput>
      </FormSection>
      <FormSection
        title="Prodotti"
        subtitle="Aggiungi e modifica i prodotti che fanno parte di questo preventivo"
        fullWidth
        {...props}
      >
        <ArrayInput source="quoteItems" label={false}>
          <SimpleFormIterator
            classes={{ form, line }}
            addButton={
              <BaseButton startIcon={<ContentAdd />} color="primary" variant="contained">
                {'AGGIUNGI'}
              </BaseButton>
            }
            removeButton={
              <BaseButton startIcon={<DeleteOutlineIcon />} color="primary" variant="contained">
                {'RIMUOVI'}
              </BaseButton>
            }
          >
            <FormDataConsumer>{(formDataProps: any): any => <CreateQuoteItem {...formDataProps} />}</FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </FormSection>
      <FormSection title="Informazione Aggiuntive" {...props}>
        <TextInput label="Note" source="notes" fullWidth multiline />
      </FormSection>
      <FormSection title="Riepilogo">
        <QuoteSummary />
      </FormSection>
    </>
  )
}

type Props = {}

const QuoteCreate: FC<Props> = (props) => {
  const transform = useCallback((data) => {
    console.log(data, 'ciao')
    return {
      ...data,
      quoteItems: data.quoteItems.map((item: any, index: number) => ({ ...item, itemPosition: index + 1 })),
    }
  }, [])

  return (
    <Create {...props} component="div" transform={transform}>
      <SectionedForm variant="outlined" redirect="edit">
        <QuoteForm {...props} />
      </SectionedForm>
    </Create>
  )
}

const useStyles = makeStyles({
  form: {
    display: 'flex',
    alignItems: 'baseline',
  },
  line: {
    display: 'flex',
    alignItems: 'stretch',
    alignContent: 'center',
    borderBottom: 0,
  },
})

export default QuoteCreate
