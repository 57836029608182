import ProductCategoryList from './ProductCategoryList'
import ProductCategoryCreate from './ProductCategoryCreate'
import ProductCategoryEdit from './ProductCategoryEdit'
import ProductCategoryShow from './ProductCategoryShow'

export default {
  name: 'ProductCategory',
  list: ProductCategoryList,
  create: ProductCategoryCreate,
  edit: ProductCategoryEdit,
  show: ProductCategoryShow,
  options: { label: 'Categories' }
}
