import React, { FC } from 'react'
import { GridListTile, Typography, GridListTileBar } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useTranslate } from 'ra-core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  tile: {
    backgroundColor: theme.palette.grey[300],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    // flex: '1 0 120px',
    minHeight: 180
  },
  icon: {
    alignSelf: 'center',
    verticalAlign: 'center',
    fontSize: 48,
    minWidth: 175
    // minHeight: 180
  },
  caption: {
    position: 'absolute',
    bottom: '10px'
  },
  tileBar: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    opacity: 0.4
  }
}))

type Props = {
  onClick: ((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void) | undefined
  label?: string
}

const GalleryTileButton: React.ForwardRefExoticComponent<Props & React.RefAttributes<unknown>> = React.forwardRef(
  ({ onClick, label, ...rest }) => {
    const translate = useTranslate()
    const classes = useStyles()

    return (
      <GridListTile classes={{ tile: classes.tile }} onClick={onClick}>
        <AddIcon fontSize="large" classes={{ fontSizeLarge: classes.icon }} />
        {label && <GridListTileBar className={classes.tileBar} title={translate(label)} />}
      </GridListTile>
    )
  }
)

export default GalleryTileButton
