import { useTranslate } from 'ra-core'
import React, { FC } from 'react'
import { TextInput } from 'react-admin'
import Filter from '../../components/Filter'

type Props = {
  //
}

const CustomerFilters: FC<Props> = (props) => {
  const translate = useTranslate()
  return (
    <Filter {...props}>
      <TextInput label={translate('resources.Customer.fields.fullName')} source="q" alwaysOn />
    </Filter>
  )
}

export default CustomerFilters
