import React, { FC } from 'react'
import { Create } from 'react-admin'
import ProductForm from './ProductForm'

type Props = {}

const ProductCreate: FC<Props> = props => {
  return (
    <Create {...props}>
      <ProductForm />
    </Create>
  )
}

export default ProductCreate
