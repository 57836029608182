import OfficeList from './OfficeList'
import OfficeCreate from './OfficeCreate'
import OfficeEdit from './OfficeEdit'

export default {
  name: 'Office',
  list: OfficeList,
  create: OfficeCreate,
  edit: OfficeEdit
}
