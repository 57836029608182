import React, { FC } from 'react'
import { TextInput } from 'react-admin'
import FormSection from '../../components/forms/FormSection'
import HiddenOrganizationField from '../../components/forms/HiddenOrganizationField'
import CountryStateDoubleInput from './../../components/forms/CountryStateDoubleInput'

type Props = {}

const OfficeForm: FC<Props> = (props: any) => {
  return (
    <>
      <FormSection title="Info ufficio" {...props}>
        {props.record && props.record.id && <TextInput source="id" disabled />}
        <HiddenOrganizationField />
        <TextInput source="name" fullWidth />
        <TextInput source="phone" fullWidth />
        <TextInput source="email" fullWidth />
      </FormSection>
      <FormSection title="Indirizzo" {...props}>
        <CountryStateDoubleInput {...props} />
        <TextInput source="city" fullWidth />
        <TextInput source="street" fullWidth />
        <TextInput source="postCode" fullWidth />
      </FormSection>
    </>
  )
}

export default OfficeForm
