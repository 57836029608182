import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/Create'
import SMSTemplateForm from './SMSTemplateForm'

type Props = {
  //
}

const SMSTemplateCreate: FC<Props> = (props) => (
  <Create {...props} component="div">
    <SectionedForm variant="outlined" redirect="list">
      <SMSTemplateForm {...props} />
    </SectionedForm>
  </Create>
)

export default SMSTemplateCreate
