import React, { FC } from 'react'
import { Edit as RAEdit } from 'react-admin'

type Props = {
  component?: string
  id?: string
  basePath?: string
  resource?: string
  hasList?: boolean
  hasCreate?: boolean
  hasShow?: boolean
  hasEdit?: boolean
  onSuccess?: (data: Record<string, any>) => void
  transform?: any
}

const Edit: FC<Props> = (props) => {
  return <RAEdit {...props} undoable={false} />
}

export default Edit
