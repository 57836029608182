import React, { FC } from 'react'
import RAList from '../../components/List'
import { TextInput, TextField, NumberField, Datagrid, Filter } from 'react-admin'
import EditButtonWPermission from '../../components/EditButtonWPermission'

const Filters: FC = (props) => {
  return (
    <Filter variant="outlined" {...props}>
      <TextInput source="name" alwaysOn />
    </Filter>
  )
}

type Props = {}

const List: FC<Props> = (props) => {
  return (
    <RAList filters={<Filters />} {...props}>
      <Datagrid>
        <NumberField source="id" />
        <TextField source="name" />
        <TextField source="vat" />
        <TextField source="email" />
        <TextField source="phone" />
        <EditButtonWPermission {...props} />
      </Datagrid>
    </RAList>
  )
}

export default List
