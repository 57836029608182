import React, { FC, useCallback, useState } from 'react'
import { ButtonProps } from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconCancel from '@material-ui/icons/Cancel'
import ContentAdd from '@material-ui/icons/Add'
import { useTranslate, useCreate, useNotify, useRefresh, useUpdate, useDataProvider } from 'ra-core'
import {
  FormWithRedirect,
  SaveButton,
  Button as RAButton,
  Button,
  TextInput,
  SelectInput,
  BooleanInput,
} from 'react-admin'
import { useForm } from 'react-final-form'

const useStyles = makeStyles((theme) => ({
  suggestionsContainer: {
    zIndex: 1300,
  },
}))

type Props = {
  id: string
  label?: string
  onChange?: (data: any) => void
  record: any
  onClose: () => void
  open: boolean
} & DialogProps

const QuickEditCustomerContactModal: FC<Props> = ({
  label = 'QuickCreateCustomerContactButton.label',
  onChange,
  id,
  record,
  onClose,
  open,
  ...rest
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const [loading, setLoading] = useState<boolean>(false)
  const notify = useNotify()
  const refresh = useRefresh()
  const filterToQuery = useCallback(() => (fullName: string): Record<string, string> => ({ fullName }), [])

  const handleSubmit = useCallback(
    (values: any) => {
      dataProvider.update(
        'CustomerContact',
        {
          id,
          data: {
            ...values,
          },
          previousData: record,
        },
        {
          onSuccess: ({ data }: any) => {
            onClose()
            refresh()
            notify('ra.notification.updated', 'info', {
              smart_count: 1,
            })
          },
          onFailure: ({ error }: any) => {
            notify(error.message, 'error')
          },
        }
      )
    },
    [onClose, record]
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{translate(label)}</DialogTitle>
      <FormWithRedirect
        resource="CustomerContact"
        save={handleSubmit}
        record={record}
        render={({ handleSubmitWithRedirect, pristine, saving }: any): React.ReactNode => (
          <>
            <DialogContent>
              {/* <TextInput variant="outlined" resource="CustomerContact" fullWidth source="description" /> */}
              <SelectInput
                fullWidth
                resourceCustomerContact
                variant="outlined"
                source="contactType"
                resource="CustomerContact"
                choices={[
                  { id: 'sms', name: 'Cellulare' },
                  { id: 'email', name: 'E-mail' },
                  { id: 'telegram', name: 'Telegram ID' },
                ]}
              />

              <TextInput variant="outlined" resource="CustomerContact" fullWidth source="contact" />
              <BooleanInput initialValue={true} resource="CustomerContact" fullWidth source="notificationEnabled" />
            </DialogContent>
            <DialogActions>
              <RAButton label="ra.action.cancel" onClick={onClose} disabled={loading}>
                <IconCancel />
              </RAButton>
              <SaveButton
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
                saving={saving}
                disabled={loading}
              />
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  )
}

export default QuickEditCustomerContactModal
