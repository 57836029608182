import React, { FC, Children, isValidElement, useCallback, useMemo } from 'react'
import { createPortal } from 'react-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Theme, Fade, useTheme } from '@material-ui/core'
import MuiAppBar, { AppBarProps } from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSidebar, useTranslate } from 'ra-core'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import Tooltip from '@material-ui/core/Tooltip'
import DateRangeIcon from '@material-ui/icons/DateRange'
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation'
import moment from 'moment'
import PlanningViewModeMenu, { PlanningViewModeMenuProps } from './PlanningViewModeMenu'
import PlanningResourceConfiguration, { PlanningResourceConfigurationProps } from './PlanningResourceConfiguration'
import Box from '@material-ui/core/Box'
import PrintCalendarPlanningButton from './PrintCalendarPlanningButton'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    title: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      // color: theme.palette.text.primary,
    },
    menuButton: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    leftButtonsContainer: {
      position: 'relative',
      marginLeft: theme.spacing(8),
    },
    prevNextButtonsContainer: {
      position: 'relative',
      marginLeft: theme.spacing(2),
    },
    dateTitleContainer: {
      position: 'relative',
      marginLeft: theme.spacing(4),
      color: theme.palette.text.primary,
    },
    todayButton: {
      textTransform: 'none',
    },
    switchCalendarSidebarButton: {
      marginRight: theme.spacing(4),
    },
    rightButtonsContainer: {
      display: 'flex',
      flex: '1',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    '@media print': {
      root: {
        display: 'none',
      },
    },
  }),
  { name: 'CalendarToolbar' }
)

type Props = AppBarProps &
  PlanningViewModeMenuProps &
  PlanningResourceConfigurationProps & {
    title?: string
    onCalToday: () => void
    onCalPrev: () => void
    onCalNext: () => void
    dateTitle?: string
    calendarSidebarOpen?: boolean
    onToggleCalendarSidebarOpen: () => void
    selectedResourceIds: string[]
    onResourcesSelected: (selectedResourceIds: string[]) => void
    vehicleHashTable?: { [id: string]: any }
    vehicles?: any[]
    vehiclesLoading?: boolean
    teamMembersHashTable?: { [id: string]: any }
    teamMembers?: any[]
    teamMembersLoading?: boolean
    officesHashTable?: { [id: string]: any }
    offices?: any[]
    officesLoading?: boolean
    calendarContainerRef?: any
  }

const CalendarToolbarView: FC<Props> = ({
  className,
  title = 'CalendarPlanning.title',
  onCalToday,
  onCalPrev,
  onCalNext,
  dateTitle,
  onToggleCalendarSidebarOpen,
  calendarSidebarOpen,
  onTwoWeeksClick,
  onWeekClick,
  onDayClick,
  calendarRef,
  resourceMode,
  onResourceModeChange,
  selectedResourceIds,
  onResourcesSelected,
  vehicleHashTable,
  vehicles,
  vehiclesLoading,
  teamMembersHashTable,
  teamMembers,
  teamMembersLoading,
  officeHashTable,
  offices,
  officesLoading,
  initialCalendarView,
  calendarContainerRef,
  ...rest
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen)
  const todayString = useMemo(() => moment().format('LL'), [])

  const handleToggleSidebar = useCallback(() => {
    dispatch(toggleSidebar())
  }, [])

  return (
    <Fade in={true} timeout={{ enter: 500, exit: 500 }} appear>
      <MuiAppBar elevation={0} className={clsx(classes.root, className)} {...rest}>
        <Toolbar>
          {/* <Hidden lgUp> */}
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleToggleSidebar}
            className={classes.menuButton}
          >
            <MenuIcon
              classes={{
                root: open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed,
              }}
            />
          </IconButton>
          {/* </Hidden> */}
          <Typography variant="h6" color="textPrimary" className={classes.title}>
            {translate(title)}
          </Typography>
          <div className={classes.leftButtonsContainer}>
            <Tooltip
              title={translate(
                calendarSidebarOpen ? 'CalendarToolbar.closeCalendarSidebar' : 'CalendarToolbar.openCalendarSidebar'
              )}
            >
              <IconButton
                onClick={onToggleCalendarSidebarOpen}
                className={classes.switchCalendarSidebarButton}
                size="small"
              >
                {!calendarSidebarOpen ? <DateRangeIcon /> : <CancelPresentationIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title={todayString}>
              <Button className={classes.todayButton} variant="outlined" onClick={onCalToday}>
                {translate('CalendarToolbar.today')}
              </Button>
            </Tooltip>
          </div>
          <div className={clsx(classes.prevNextButtonsContainer)}>
            <Tooltip title={translate('CalendarToolbar.previousDay')}>
              <IconButton size="small" onClick={onCalPrev}>
                <NavigateBeforeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={translate('CalendarToolbar.nextDay')}>
              <IconButton size="small" onClick={onCalNext}>
                <NavigateNextIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.dateTitleContainer}>
            <Typography variant="h4">{dateTitle}</Typography>
          </div>
          <div className={classes.rightButtonsContainer}>
            <Box mr={2}>
              <PlanningResourceConfiguration
                selectedResourceIds={selectedResourceIds}
                resourceMode={resourceMode}
                onResourceModeChange={onResourceModeChange}
                onResourcesSelected={onResourcesSelected}
                vehicleHashTable={vehicleHashTable}
                vehicles={vehicles}
                vehiclesLoading={vehiclesLoading}
                teamMembersHashTable={teamMembersHashTable}
                teamMembers={teamMembers}
                teamMembersLoading={teamMembersLoading}
                officeHashTable={officeHashTable}
                offices={offices}
                officesLoading={officesLoading}
              />
            </Box>
            <Box mr={4}>
              <PrintCalendarPlanningButton contentRef={calendarContainerRef} />
            </Box>
            <PlanningViewModeMenu
              onTwoWeeksClick={onTwoWeeksClick}
              onWeekClick={onWeekClick}
              onDayClick={onDayClick}
              calendarRef={calendarRef}
              initialCalendarView={initialCalendarView}
            />
          </div>
          {/* <LoadingIndicator /> */}
        </Toolbar>
      </MuiAppBar>
    </Fade>
  )
}

const CalendarToolbar: FC<Props> = (props) => {
  const container = typeof document !== 'undefined' ? document.getElementById('react-admin-sub-app-bar') : null

  if (!container) {
    return null
  }

  return createPortal(<CalendarToolbarView {...props} />, container)
}

export default CalendarToolbar
