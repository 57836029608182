import React, { FC } from 'react'
import { NumberInput, TextInput } from 'react-admin'
import Filter from '../../components/Filter'

type Props = {}

const QuoteTemplateFilters: FC<Props> = (props) => {
  return (
    <Filter {...props}>
      <NumberInput source="id" />
      <TextInput source="name" alwaysOn />
    </Filter>
  )
}

export default QuoteTemplateFilters
