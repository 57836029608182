import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
// import Edit from '../../components/details/Edit'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'
// import SwitchCardInput from '../../components/SwitchCardInput'
import { RadioButtonGroupInput } from 'react-admin'
import FormSwitchInput from '../../components/forms/FormSwitchInput'
import HiddenOrganizationField from '../../components/forms/HiddenOrganizationField'
import Edit from '../../components/Edit'

type Props = {
  [x: string]: any
}

const UserEdit: FC<Props> = (props) => {
  return (
    <Edit {...props} component="div">
      <SectionedForm variant="outlined" redirect="list" title="Modifica Account">
        <FormSection title="Informazioni personali" subtitle="Inserisci le informazioni personali dell'utente">
          <FormTextInput {...props} source="firstName" />
          <FormTextInput {...props} source="lastName" />
        </FormSection>
        <FormSection title="Informazioni per il login" subtitle="Inserisci i dati necessari per effettuare login">
          <FormTextInput {...props} source="email" />
          <FormTextInput {...props} source="phone" />
        </FormSection>
        <FormSection title="Login Abilitato">
          <FormSwitchInput {...props} source="active" helperText="questo account è attivo e può fare login" />
        </FormSection>
        <FormSection
          title="Eliminazione Bloccata"
          subtitle="ATTENZIONE! Disabilitando questo parametro potrebbe non essere più possibile accedere"
        >
          <FormSwitchInput {...props} source="isLocked" helperText="questo account non può essere eliminato" />
        </FormSection>
      </SectionedForm>
    </Edit>
  )
}

export default UserEdit
