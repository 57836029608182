import React, { FC } from 'react'
import { TextInput, NumberInput, ReferenceInput, SelectInput } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Create from '../../components/Create'
import HiddenOrganizationField from './../../components/forms/HiddenOrganizationField'

const sort = { field: 'name', order: 'ASC' }

type Props = {}

const VehicleCreate: FC<Props> = (props) => {
  return (
    <Create {...props} component="div">
      <SectionedForm variant="outlined" redirect="list">
        <FormSection title="Dettagli veicolo">
          <HiddenOrganizationField />
          <ReferenceInput reference="VehicleModel" source="vehicleModelId" {...{ sort }}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="licensePlate" fullWidth />
          <NumberInput source="registrationYear" fullWidth />
          <ReferenceInput reference="VehicleFuel" source="vehicleFuelId" {...{ sort }}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <NumberInput source="numberOfSeats" />
          <TextInput source="color" fullWidth />
          <NumberInput source="yearOfManufacture" fullWidth />
          <NumberInput source="yearOfRestyling" fullWidth />
          <TextInput source="description" multiline fullWidth />
        </FormSection>
      </SectionedForm>
    </Create>
  )
}

export default VehicleCreate
