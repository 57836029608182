import RoleList from './RoleList'
import RoleCreate from './RoleCreate'
import RoleEdit from './RoleEdit'
import RoleShow from './RoleShow'

export default {
  name: 'Role',
  list: RoleList,
  create: RoleCreate,
  edit: RoleEdit,
  show: RoleShow,
}
