import React, { FC, ReactNode } from 'react'
import { Filter as RAFilter } from 'react-admin'

type Props = {
  children?: ReactNode
  classes?: unknown
  context?: 'form' | 'button'
  displayedFilters?: unknown
  filterValues?: unknown
  hideFilter?: Function
  setFilters?: Function
  showFilter?: Function
  resource?: string
}

const Filter: FC<Props> = props => {
  return <RAFilter {...props} variant="outlined" />
}

export default Filter
