import React, { FC, useCallback, useState } from 'react'
import { ButtonProps } from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconCancel from '@material-ui/icons/Cancel'
import ContentAdd from '@material-ui/icons/Add'
import { useTranslate, useCreate, useNotify, useRefresh } from 'ra-core'
import {
  FormWithRedirect,
  SaveButton,
  Button as RAButton,
  DateTimeInput,
  Button,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin'
import { useForm } from 'react-final-form'

const useStyles = makeStyles((theme) => ({
  suggestionsContainer: {
    zIndex: 1300,
  },
}))

type Props = {
  label?: string
  onChange?: (data: any) => void
  examId?: string | number
} & ButtonProps

const QuickCreateExamPlanningButton: FC<Props> = ({ label = 'ra.action.create', onChange, examId, ...rest }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [create, { loading }] = useCreate('ExamPlanning')
  const notify = useNotify()
  const refresh = useRefresh()
  const filterToQuery = useCallback(() => (fullName: string): Record<string, string> => ({ fullName }), [])

  const handleModalOpen = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleSubmit = useCallback((values: any) => {
    console.log(values)
    create(
      {
        payload: {
          data: {
            startDate: values.startDate ? values.startDate.toISOString() : undefined,
            endDate: values.endDate ? values.endDate.toISOString() : undefined,
            teamMembers: values.teamMemberIds.map((id: any): any => ({ id })),
            examId,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          setIsModalOpen(false)
          if (onChange) {
            onChange(data)
          } else {
            refresh()
          }
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error')
        },
      }
    )
  }, [])

  return (
    <>
      <Button label={translate(label)} {...(rest as any)} onClick={handleModalOpen}>
        <ContentAdd />
      </Button>

      <Dialog open={isModalOpen} onClose={handleModalClose} fullWidth maxWidth="md">
        <DialogTitle>{translate(label)}</DialogTitle>
        <FormWithRedirect
          resource="ExamPlanning"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }: any): React.ReactNode => (
            <>
              <DialogContent>
                <ReferenceArrayInput
                  resource="ExamPlanning"
                  reference="TeamMember"
                  source="teamMemberIds"
                  {...{ filterToQuery }}
                >
                  <AutocompleteArrayInput
                    options={{
                      suggestionsContainerProps: {
                        className: classes.suggestionsContainer,
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    optionText="fullName"
                  />
                </ReferenceArrayInput>
                <DateTimeInput variant="outlined" resource="ExamPlanning" fullWidth source="startDate" />
                <DateTimeInput variant="outlined" resource="ExamPlanning" fullWidth source="endDate" />
              </DialogContent>
              <DialogActions>
                <RAButton label="ra.action.cancel" onClick={handleModalClose} disabled={loading}>
                  <IconCancel />
                </RAButton>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}

export default QuickCreateExamPlanningButton
