import React, { FC, useCallback, useState, useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { useDataProvider, useGetList } from 'ra-core'
import addYears from 'date-fns/addYears'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { QUERY_GET_EVENT_STATUSES_CONFIGURATION } from '../../queries'
import { GET_CUSTOMER_CONFIRMED_EVENTS } from '../Order/OrderProductsTable'
import { AccountingCardTransaction } from './SimpleTransactionRow'
import { SelectInput } from 'react-admin'
import LinearProgress from '@material-ui/core/LinearProgress'

type Props = {
  record: Record<string, any>
  isPayment: boolean
  examAttributes?: 'THEORYEXAM' | 'DRIVINGEXAM' | 'MEDICAL'
  // examAttributes: Record<string, any>
  isEdit: boolean
  selectedId?: string
  formChange: <F extends string>(name: F, value?: any) => void
  transactionId?: string // only in edit mode
  productId?: string
}

const SelectOtherEventsInput: FC<Props> = ({
  examAttributes,
  record,
  isPayment,
  isEdit,
  transactionId,
  selectedId,
  formChange,
  productId,
}: Props) => {
  const client = useApolloClient()
  const dataProvider = useDataProvider()
  const [choices, setChoices] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [allEvents, setAllEvents] = useState<any[]>([])
  const { loading: loadingTranz, ids } = useGetList<AccountingCardTransaction>(
    'AccountingCardTransaction',
    { perPage: 1000, page: 1 },
    { field: 'date', order: 'DESC' },
    { accountingCardId: record.id, type: isPayment ? 'INCOME' : 'OUTCOME' }
  )

  const getEvents = useCallback(async () => {
    try {
      let confirmedStatuses: any[] = []
      const allEvts: any[] = []
      let alreadySelectedEvents: any[] = []
      const dsEventType = examAttributes

      // step 1: getting confirmed statuses
      const configurationResult = await client.query({ query: QUERY_GET_EVENT_STATUSES_CONFIGURATION })
      if (
        configurationResult?.data?.getConfiguration?.value &&
        configurationResult.data.getConfiguration.value.confirmed &&
        configurationResult.data.getConfiguration.value.confirmed.length > 0
      ) {
        // setConfirmedStatusIds(configurationResult.data.getConfiguration.value.confirmed)
        const statusesResult = await dataProvider.getMany('EventStatus', {
          ids: configurationResult.data.getConfiguration.value.confirmed,
        })

        if (statusesResult?.data) {
          confirmedStatuses = [...statusesResult.data]
        }
      }

      // step 2: getting customer's events
      const { data: resp } = await client.query({
        query: GET_CUSTOMER_CONFIRMED_EVENTS,
        variables: {
          filters: {
            customerIds: [record.customerId],
            dsEventType,
            dateRange: {
              startDate: record.openingDate,
              endDate: record.expiringDate
                ? record.expiringDate
                : addYears(new Date(record.openingDate), 1).toISOString(),
            },
          },
        },
        fetchPolicy: 'no-cache',
      })

      if (resp?.plannedEvents?.data?.length) {
        resp.plannedEvents.data.forEach((eventPlanned: any) => {
          // taking only confirmed events
          const confirmed: any[] = eventPlanned.customerEvents.filter((xxx: any) => {
            if (xxx.customerId === record.customerId) {
              return !!confirmedStatuses.find((item: any) => item.id === xxx.statusId)
            }
            return false
          })

          const foundedStatus = confirmedStatuses.find((item: any) => {
            if (eventPlanned?.customerEvents?.length > 0) {
              return eventPlanned.customerEvents[0].statusId === item.id
            }
            return false
          })

          if (confirmed?.length) {
            const duration = differenceInMinutes(
              new Date(eventPlanned.event.endDate),
              new Date(eventPlanned.event.startDate)
            )

            const fullEvent = {
              id: eventPlanned.id,
              combinedId: `${eventPlanned.id}-${duration}`,
              startDate: eventPlanned.event.startDate,
              endDate: eventPlanned.event.endDate,
              teamMemberId: eventPlanned.teamMemberIds[0],
              // status: confirmedStatuses.find((item: any) => item.id === item.customerEvents[0].statusId),
              status: foundedStatus,
              label: `${eventPlanned?.event?.title} - ${new Date(eventPlanned?.event?.startDate).toLocaleString()}`,
            }
            allEvts.push(fullEvent)
          }
        })
        setAllEvents(allEvts)

        // getting already used plannedEvents
        if (!loadingTranz && ids && ids.length > 0) {
          const accountingCardTransactionIds =
            isEdit && transactionId ? ids.filter((elem: any) => elem !== transactionId) : ids
          const existing = await dataProvider.getList('TransactionPlannedEvent', {
            filter: { accountingCardTransactionIds },
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'id', order: 'DESC' },
          })
          if (existing && existing.data) {
            alreadySelectedEvents = [...existing.data.map((elem: any) => elem.plannedEventId)]
          }
        }

        const final = isPayment
          ? allEvts
          : allEvts.filter((elem: any) => {
              return !alreadySelectedEvents.includes(elem.id)
            })
        setChoices(final)
        setLoading(false)
      } else {
        setChoices([])
        setLoading(false)
      }
    } catch (error) {
      console.error('getEvents error: ', error)
    }
  }, [examAttributes, ids, loadingTranz])

  useEffect(() => {
    if (!loadingTranz) {
      getEvents()
    }
  }, [loadingTranz, productId, examAttributes])

  useEffect(() => {
    if (selectedId && allEvents?.length) {
      const newId = allEvents.filter((elem) => elem.id === selectedId)[0].combinedId
      formChange('plannedEventId', newId)
    }
  }, [allEvents])

  return loading ? (
    <LinearProgress />
  ) : (
    <SelectInput
      source="plannedEventId"
      choices={choices}
      optionText="label"
      optionValue="combinedId"
      variant="outlined"
      label="Evento associato"
      disabled={isPayment || choices.length === 0}
      helperText={choices.length === 0 ? 'Nessun evento disponibile' : false}
      fullWidth
    />
  )
}

export default SelectOtherEventsInput
