import React, { FC } from 'react'
import { DateInput, ReferenceField, TextField } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/Edit'
import { DefaultDriveTrainingSection } from './AccountingCardCreate'

type Props = {}

const AccountingCardEdit: FC<Props> = (props) => (
  <Edit {...props} component="div">
    <SectionedForm variant="outlined" redirect="show">
      <FormSection title={'Studente'} {...props}>
        <ReferenceField source="customerId" reference="Customer">
          <TextField source="fullName" />
        </ReferenceField>
      </FormSection>
      <FormSection title={'Date'} {...props}>
        <DateInput source="openingDate" />
        <DateInput source="expiringDate" />
        <DateInput source="closingDate" />
      </FormSection>
      <DefaultDriveTrainingSection {...props} />
    </SectionedForm>
  </Edit>
)

export default AccountingCardEdit
