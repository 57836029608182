import React, { FC, useCallback } from 'react'
import TreeView, { TreeViewProps } from '@material-ui/lab/TreeView'
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { Typography, makeStyles } from '@material-ui/core'

const useProductCategoryTreeStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  item: {
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label, &$selected:focus > $content $label, &$selected > $content $label:hover': {
      backgroundColor: 'transparent'
    },
    '&$selected > $content $selectedIcon, &$selected:focus > $content $selectedIcon': {
      display: 'block'
    },
    '&$selected > $content $unselectedIcon, &$selected:focus > $content $unselectedIcon': {
      display: 'none'
    }
  },
  content: {},
  selected: {},
  selectedIcon: {
    display: 'none',
    marginRight: theme.spacing(0.5)
  },
  unselectedIcon: {
    marginRight: theme.spacing(0.5)
  },
  label: {
    padding: theme.spacing(0.5, 0)
  },
  labelContent: {
    display: 'flex',
    alignItems: 'center'
  }
}))

type ItemClassesProp = {
  labelContent: string
  unselectedIcon: string
  selectedIcon: string
  item: string
  content: string
  selected: string
  label: string
}

type ItemProps = TreeItemProps & {
  classes: ItemClassesProp
  label: string
  selectionDisabled: boolean
  onSelect: (e: any) => void
}

const ProductCategoryTreeItem: FC<ItemProps> = ({ classes, label, onSelect, selectionDisabled, ...others }) => {
  const labelNode = (
    <div
      className={classes.labelContent}
      onClick={(e: any): void => {
        e.stopPropagation()
        e.preventDefault()
        onSelect(e)
      }}
    >
      <CheckBoxOutlineBlankIcon classes={{ root: classes.unselectedIcon }} color="inherit" />
      <CheckBoxIcon classes={{ root: classes.selectedIcon }} color="secondary" />
      <Typography variant="body2">{label}</Typography>
    </div>
  )

  return (
    <TreeItem
      {...others}
      classes={{
        root: classes.item,
        content: classes.content,
        selected: classes.selected,
        label: classes.label
      }}
      label={selectionDisabled ? label : labelNode}
    />
  )
}

type Props = {
  selectionDisabled?: boolean
  data: any[]
  onNodeSelect: (event: React.ChangeEvent<{}>, nodeId: string) => void
  selected: string[]
  defaultExpanded: any
  multiSelect?: true | undefined
}

const ProductCategoryTree: FC<Props> = ({ selectionDisabled = false, onNodeSelect, data, ...others }) => {
  const { root, ...classes } = useProductCategoryTreeStyles()

  const renderItem = (node: any): any => {
    const { subCategories } = node
    const children = subCategories && subCategories.map(renderItem)
    return (
      <ProductCategoryTreeItem
        key={node.id}
        nodeId={node.id}
        label={node.name}
        classes={classes}
        selectionDisabled={selectionDisabled}
        onSelect={(e: any): any => onNodeSelect(e, node.id)}
      >
        {children}
      </ProductCategoryTreeItem>
    )
  }

  return (
    <TreeView
      classes={{ root }}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      {...others}
    >
      {data && data.map(renderItem)}
    </TreeView>
  )
}

export default ProductCategoryTree
