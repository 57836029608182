import React, { FC } from 'react'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { usePermissions, useShowController } from 'ra-core'
import { Loading } from 'react-admin'
import { ShowProps } from 'ra-core/esm/controller/useShowController'
import CoursePlanningCustomersCard from '../../components/CoursePlanningCustomersCard'
import CoursePlanningCourseOverviewCard from '../../components/CoursePlanningCourseOverviewCard'
import CoursePlanningScheduleCard from '../../components/CoursePlanningScheduleCard'
import CoursePlanningLessonsCard from '../../components/CoursePlanningLessonsCard'

type Props = {
  //
  [x: string]: any
} & ShowProps

const CoursePlanningShow: FC<Props> = (props) => {
  const {
    basePath, // deduced from the location, useful for action buttons
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
    resource, // the resource name, deduced from the location. e.g. 'posts'
    version, // integer used by the refresh feature
  } = useShowController(props)
  const { loaded, permissions } = usePermissions()

  if (loading || !loaded || !permissions) {
    return <Loading />
  }

  return (
    <Container maxWidth="lg">
      {/* <pre>{JSON.stringify(record, null, 2)}</pre> */}
      <Grid spacing={3} container>
        <Grid item lg={4} md={6} xs={12}>
          <Box mt={3}>
            <CoursePlanningCourseOverviewCard resource={resource} basePath={basePath} record={record} />
          </Box>
          {permissions.permissionsMap.customerCoursePlannings && (
            <Box mt={3}>
              <CoursePlanningCustomersCard
                resource={resource}
                basePath={basePath}
                record={record}
                permissionsMap={permissions.permissionsMap}
              />
            </Box>
          )}
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          {permissions && permissions.permissionsMap.courseSchedules && (
            <Box mt={3}>
              <CoursePlanningScheduleCard
                resource={resource}
                basePath={basePath}
                record={record}
                permissionsMap={permissions.permissionsMap}
              />
            </Box>
          )}
          {permissions && permissions.permissionsMap.courseLessons && (
            <Box mt={3}>
              <CoursePlanningLessonsCard
                resource={resource}
                basePath={basePath}
                record={record}
                permissionsMap={permissions.permissionsMap}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default CoursePlanningShow
