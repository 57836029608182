import React, { FC, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import get from 'lodash/get'
import Avatar, { AvatarProps } from '@material-ui/core/Avatar'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'

type Props = {
  source?: string
  record?: any
  renderLetters?: (record: any) => string
  size?: number | string
  badgeColor?: string
} & AvatarProps

const useStyles = makeStyles((theme: any) => ({
  root: (props: any): any => ({
    width: props.size || 50,
    height: props.size || 50,
  }),
  badge: (props: any): any => ({
    color: props.badgeColor,
    backgroundColor: props.badgeColor,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  }),
}))

const AvatarField: FC<Props> = (props) => {
  const { source, record = {}, size, renderLetters, className, badgeColor, ...rest } = props
  const classes = useStyles(props)
  const letters = useMemo(() => (renderLetters && record ? renderLetters(record) : null), [renderLetters, record])

  if (!record) {
    return null
  }

  if (letters) {
    if (badgeColor) {
      return (
        <Badge
          classes={{ badge: classes.badge }}
          overlap="circle"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          variant="dot"
        >
          <Avatar className={clsx(classes.root, className)} {...rest}>
            {letters}
          </Avatar>
        </Badge>
      )
    }

    return (
      <Avatar className={clsx(classes.root, className)} {...rest}>
        {letters}
      </Avatar>
    )
  }

  if (badgeColor) {
    return (
      <Badge
        classes={{ badge: classes.badge }}
        overlap="circle"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        variant="dot"
      >
        <Avatar src={get(record, source || '')} className={clsx(classes.root, className)} {...rest} />
      </Badge>
    )
  }

  return <Avatar src={get(record, source || '')} className={clsx(classes.root, className)} {...rest} />
}

export default AvatarField
