import React, { FC } from 'react'
import { TextInput, DateInput, ReferenceInput, AutocompleteInput } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import Edit from '../../components/Edit'
import FormSection from '../../components/forms/FormSection'
import WorkingHoursForm from '../../components/forms/WorkingHoursForm'

type Props = {
  //
}

const TeamMemberWorkPlanningEdit: FC<Props> = (props) => (
  <Edit {...props} component="div">
    <SectionedForm redirect="list">
      <FormSection title="Informazioni generali" {...props}>
        <ReferenceInput reference="TeamMember" source="teamMemberId">
          <AutocompleteInput
            // options={{
            //   suggestionsContainerProps: {
            //     className: classes.suggestionsContainer,
            //   },
            // }}
            variant="outlined"
            fullWidth
            optionText="fullName"
          />
        </ReferenceInput>
        <DateInput source="startDate" />
        <DateInput source="endDate" />
      </FormSection>
      <FormSection title="Orari di lavoro">
        <WorkingHoursForm {...props} />
      </FormSection>
    </SectionedForm>
  </Edit>
)

export default TeamMemberWorkPlanningEdit
