import React, { FC } from 'react'
import { TextInput } from 'react-admin'
import SectionedForm from '../SectionedForm'
import FormSection from '../FormSection'
import Create from '../../Create'

type Props = {
  sectionName: string
}

const NameOnlyCreate: FC<Props> = props => {
  return (
    <Create {...props} component="div">
      <SectionedForm variant="outlined" redirect="list">
        <FormSection title={props.sectionName}>
          <TextInput source="name" />
        </FormSection>
      </SectionedForm>
    </Create>
  )
}

export default NameOnlyCreate
