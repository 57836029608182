import React, { FC } from 'react'
import { TextField } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/Edit'
import TeamMemberForm from './TeamMemberForm'

type Props = {
  //
}

const TeamMemberEdit: FC<Props> = (props) => (
  <Edit {...props} component="div">
    <SectionedForm variant="outlined" redirect="list">
      <FormSection title="ID">
        <TextField source="id" addLabel={false} />
      </FormSection>
      <TeamMemberForm />
      {/* <WorkingHoursForm {...props} /> */}
    </SectionedForm>
  </Edit>
)

export default TeamMemberEdit
