import React, { FC } from 'react'
import { TextInput, NumberInput, TextField } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/Edit'

type Props = {}

const AddressCreate: FC<Props> = props => {
  return (
    <Edit {...props} component="div">
      <SectionedForm variant="outlined">
        <FormSection title="ID">
          <TextField source="id" />
        </FormSection>
        <FormSection title="General Info">
          <TextInput source="name" />
          <TextInput source="vat" />
        </FormSection>
        <FormSection title="Contact Info">
          <TextInput source="email" />
          <TextInput source="phone" />
        </FormSection>
      </SectionedForm>
    </Edit>
  )
}

export default AddressCreate
