import React, { FC, useRef, useState } from 'react'
import { useShowController, TitleForRecord } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import { usePermissions } from 'ra-core'
import AccountingCardTitle from './AccountingCardTitle'
import DatesSection from './DatesSection'
import AmountSection from './AmountSection'
import AccountingCardTransactionsTable from './AccountingCardTransactionsTable'
import { TransactionForm } from './TransactionForm'
import AccountingCardNotesSection from './AccountingCardNotesSection'
import AccountingCardTransactionFilters from './AccountingCardTransactionsFilters'
import CustomerSection from './CustomerSection'

export const AccountingCardShow: FC<any> = (props: any) => {
  const { defaultTitle, loading, loaded, ...data } = useShowController(props)
  const [isCreateFormOpen, setIsCreateFormOpen] = useState<boolean>(false)
  const [createFormData, setCreateFormData] = useState<Record<string, any> | undefined>(undefined)
  const { loaded: permissionsLoaded, permissions } = usePermissions()
  const classes = useStyles()
  const portalContainer = useRef(null)

  const toggleCreateForm = (visible: boolean) => (): void => setIsCreateFormOpen(visible)

  const onAddPayment = (record: Record<string, any>): void => {
    setCreateFormData(record)
    setIsCreateFormOpen(true)
  }

  const onAddPaymentClose = (): void => {
    setIsCreateFormOpen(false)
    setCreateFormData(undefined)
  }

  const onAddFreeCredit = (): void => {
    const newRecord = {
      accountingCardId: data.record.id,
      isPayment: true,
      taxRate: 0,
      netPrice: 0,
      salePrice: 0,
      isFreeCredit: true,
      method: 'CASH',
    }
    setCreateFormData(newRecord)
    setIsCreateFormOpen(true)
  }

  return (
    <Container maxWidth={false}>
      {data && data.record && <TitleForRecord {...data} title={<AccountingCardTitle />} />}
      <Grid container item xs={12} justify="space-between" alignItems="flex-start">
        <Grid item spacing={2} container xs={9} md={9}>
          {data && data.record && <DatesSection record={data.record} />}
          {data && data.record && data.record.id && (
            <>
              <AccountingCardTransactionFilters />
              <AccountingCardTransactionsTable
                accountingCardId={data.record.id}
                closeCreateForm={onAddPaymentClose}
                portalContainer={portalContainer}
                onAddPayment={onAddPayment}
                isAccountClosed={data.record.isClosed}
                accountingCardRecord={data.record}
              />
            </>
          )}
          {data &&
            data.record &&
            !data.record.isClosed &&
            (isCreateFormOpen ? (
              <div className={classes.formContainer}>
                <TransactionForm
                  onUndo={onAddPaymentClose}
                  accountingCardId={data.record.id}
                  startValues={createFormData}
                  record={data.record}
                />
              </div>
            ) : (
              <div className={classes.ctaContainer}>
                <Button
                  color="primary"
                  onClick={onAddFreeCredit}
                  variant="contained"
                  className={classes.freeCreditButton}
                >
                  Aggiungi credito
                </Button>
                <Button color="primary" onClick={toggleCreateForm(true)} variant="contained">
                  Aggiungi riga
                </Button>
              </div>
            ))}
          <div ref={portalContainer} className={classes.formContainer} />
        </Grid>
        <Grid item spacing={2} container xs={3} md={3} justify="flex-end" direction="column">
          {data && data.record && <AmountSection record={data.record} />}
          {data && data.record && <CustomerSection record={data.record} />}
          {data && data.record && <AccountingCardNotesSection accountingCardId={data.record.id} />}
        </Grid>
      </Grid>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2, 0),
  },
  content: {
    padding: theme.spacing(0, 3),
  },
  fieldLabel: {
    dispay: 'inline',
    marginRight: 8,
  },
  ctaContainer: {
    width: 'inherit',
    display: 'flex',
    justifyContent: 'end',
    marginTop: theme.spacing(3),
  },
  formContainer: {
    width: 'inherit',
    marginTop: theme.spacing(3),
  },
  freeCreditButton: {
    backgroundColor: 'green',
    marginRight: theme.spacing(2),
  },
}))

export default AccountingCardShow
