import React, { FC, useEffect, useState } from 'react'
import { NumberInput, useMutation } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({ root: { maxWidth: 128, marginLeft: 16 } })

const TaxRateOverride: FC<Record<string, any>> = ({ formData, scopedFormData, getSource, changeTax, ...rest }) => {
  const { root } = useStyles()
  const [noTax, setNoTax] = useState<boolean>(false)
  const [retrieveTaxId, { loading: loadingTaxId, loaded: loadedTaxId, data: dataTaxId }] = useMutation({
    type: 'getOne',
    resource: 'Product',
    payload: { id: scopedFormData.productId },
  })

  const [retrieveTaxRate, { loading: loadingTaxRate, loaded: loadedTaxRate, data: dataTaxRate }] = useMutation({
    type: 'getOne',
    resource: 'Tax',
    payload: { id: dataTaxId?.taxId },
  })

  useEffect(() => {
    if (scopedFormData) retrieveTaxId()
  }, [scopedFormData.productId])

  useEffect(() => {
    if (!loadingTaxId && loadedTaxId && dataTaxId) {
      if (dataTaxId.taxId) retrieveTaxRate()
      else {
        setNoTax(true)
        changeTax(rest.id, 0)
      }
    }
  }, [dataTaxId?.id])

  useEffect(() => {
    if (!loadingTaxRate && loadedTaxRate && dataTaxRate) {
      changeTax(rest.id, dataTaxRate.rate)
    }
  }, [dataTaxRate?.rate])

  if ((!loadingTaxRate && loadedTaxRate && dataTaxRate) || noTax)
    return <NumberInput {...rest} source={getSource('taxRateOverride')} classes={{ root }} />
  else return null
}

export default TaxRateOverride
