import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/Create'
import CustomerForm from './CustomerForm'
import HiddenOrganizationField from './../../components/forms/HiddenOrganizationField'

type Props = {}

const CustomerCreate: FC<Props> = (props) => {
  // COMMENTED APPROACH TO GET ORGANIZATION ID
  // const dataProvider = useDataProvider()
  // const transform = useCallback(
  //   (data: any) =>
  //     dataProvider
  //       .getList('Organization', {
  //         pagination: { page: 1, perPage: 1 },
  //         sort: { field: 'id', order: 'DESC' },
  //         filter: {},
  //       })
  //       .then(({ data: organiData }: any) => {
  //         console.log('dataaaaa: ', organiData[0].id)
  //         if (organiData.length > 0) return { ...data, organizationId: organiData[0].id }
  //         else throw new Error('Organization not found')
  //       })
  //       .catch((error: any) => console.log('EEEROROR; ', error)),
  //   []
  // )

  return (
    <Create {...props} component="div">
      <SectionedForm variant="outlined" redirect="show">
        <HiddenOrganizationField />
        <CustomerForm {...props} />
      </SectionedForm>
    </Create>
  )
}
export default CustomerCreate
