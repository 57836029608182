import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/Create'
import CourseForm from './CourseForm'

type Props = {
  //
}

const CourseCreate: FC<Props> = (props) => (
  <Create {...props} component="div">
    <SectionedForm redirect="list">
      <CourseForm {...props} />
    </SectionedForm>
  </Create>
)

export default CourseCreate
