import { createMuiTheme, colors } from '@material-ui/core'
import shadows from './shadows'
import typography from './typography'

const theme = createMuiTheme({
  palette: {
    background: {
      // dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: colors.indigo[500],
    },
    secondary: {
      main: colors.indigo[50],
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
  shadows,
  typography,
  overrides: {
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: 'transparent',
      },
      transitionContainer: {
        '& p': {
          textAlign: 'left',
        },
      },
      switchHeader: {
        '& div': {
          order: 0,
        },
        '& button': {
          order: 1,
        },
        '& svg': {
          width: '0.985rem',
          height: '0.985rem',
        },
      },
    },
    MuiPickersDay: {
      day: {
        fontSize: 8,
      },
    },
  },
})

export default theme
