import React, { FC, useMemo, useState, useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Loading } from 'react-admin'
import { useNotify, useDataProvider } from 'ra-core'
// import get from 'lodash/get'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import EmailIcon from '@material-ui/icons/Email'
import Divider from '@material-ui/core/Divider'
import { Button, CircularProgress, TextField } from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'
import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/client'
import OrderMessageItem from './OrderMessageItem'

const MUTATION_SEND_ORDER_MESSAGE = gql`
  mutation SendOrderMessage($message: String!, $orderId: ID!, $isPrivate: Boolean!) {
    sendOrderMailToCustomer(message: $message, orderId: $orderId, isPrivate: $isPrivate)
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(0, 1, 1),
  },
  heading: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    alignItems: 'center',
  },
  headIcon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(0, 1, 0, 0),
    padding: theme.spacing(0, 1),
  },
  messagesContainer: {
    padding: theme.spacing(0, 1),
    maxHeight: 400,
    overflowY: 'scroll',
  },
  label: {
    fontWeight: 'bold',
  },
  sendMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  sendMessageButton: {
    minWidth: 120,
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sendButtonIcon: {
    marginRight: theme.spacing(1),
  },
  textarea: {
    margin: theme.spacing(2, 0, 1),
  },
}))

type Props = {
  record?: any
  onUpdateMessages?: () => void
  permissionsMap: Record<string, boolean>
}

const OrderMessagesField: FC<Props> = ({ record, onUpdateMessages, permissionsMap }) => {
  const classes = useStyles()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [sending, setSending] = useState<boolean>(false)
  const [messageContent, setMessageContent] = useState<string>('')
  const [sendMailToCustomer, setSendMailToCustomer] = useState<boolean>(false)
  const [orderMessagesData, setOrderMessagesData] = useState<any>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const apolloClient = useApolloClient()

  const onMessageContentChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMessageContent(e.target.value)
  }, [])

  // const onSendMailToCustomerChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
  //   setSendMailToCustomer(checked)
  // }, [])

  const fetchMessages = useCallback(async (): Promise<void> => {
    try {
      const result = await dataProvider.getList('OrderMessage', {
        filter: {
          orderId: record.id,
        },
        pagination: {
          page: 1,
          perPage: 100,
        },
        sort: {
          field: 'createdAt',
          order: 'DESC',
        },
      })

      if (result && result.data) {
        setOrderMessagesData(result)
      }
      // console.log('ciao', result)
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }, [dataProvider, record])

  const onSendMessage = useCallback(async () => {
    if (sending) {
      return
    }

    try {
      setSending(true)
      const result = await apolloClient.mutate({
        mutation: MUTATION_SEND_ORDER_MESSAGE,
        variables: {
          orderId: record!.id,
          message: messageContent,
          isPrivate: !sendMailToCustomer,
        },
      })
      if (result.data && result.data.sendOrderMailToCustomer) {
        notify('message.mail_sent_correctly', 'info')
        fetchMessages()
        if (onUpdateMessages) {
          onUpdateMessages()
        }
        setMessageContent('')
        setSendMailToCustomer(false)
      } else {
        notify('message.mail_send_error', 'warning')
      }
    } catch (e) {
      notify('message.mail_send_error', 'warning')
    } finally {
      setSending(false)
    }
  }, [record, messageContent, sendMailToCustomer])

  const chipClasses = useMemo(
    () => ({
      label: classes.label,
    }),
    []
  )

  useEffect(() => {
    if (!loading && typeof orderMessagesData === 'undefined' && record && record.id) {
      setLoading(true)
      fetchMessages()
    }
  }, [fetchMessages])

  if (loading) {
    return <Loading />
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.heading}>
        <EmailIcon className={classes.headIcon} />
        <Typography className={classes.title} variant="button">
          NOTE ORDINE
        </Typography>
        <Chip
          size="small"
          classes={chipClasses}
          label={orderMessagesData ? orderMessagesData.total : 0}
          className={classes.chip}
          variant="outlined"
        />
      </div>
      <Divider variant="fullWidth" />
      <div className={classes.messagesContainer}>
        {orderMessagesData &&
          orderMessagesData.data &&
          orderMessagesData.data.map((item: any) => <OrderMessageItem key={item.id} record={item} />)}
      </div>
      {permissionsMap.sendOrderMailToCustomer && <Divider variant="fullWidth" />}
      {permissionsMap.sendOrderMailToCustomer && (
        <div className={classes.sendMessageContainer}>
          <TextField
            className={classes.textarea}
            variant="outlined"
            rows={3}
            multiline
            aria-label="empty textarea"
            placeholder="Scrivi il messaggio..."
            value={messageContent}
            onChange={onMessageContentChange}
          />
          {/* <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={sendMailToCustomer}
                onChange={onSendMailToCustomerChange}
                value="checkedB"
                color="primary"
              />
            }
            label="Send Mail to Customer?"
          />
        </FormGroup> */}
          <Button
            onClick={onSendMessage}
            disabled={sending || !messageContent}
            variant="outlined"
            className={classes.sendMessageButton}
          >
            {sending && (
              <div className={classes.loadingOverlay}>
                <CircularProgress size={18} />
              </div>
            )}
            <SendIcon className={classes.sendButtonIcon} />
            Salva Nota
          </Button>
        </div>
      )}
    </Paper>
  )
}

export default OrderMessagesField
