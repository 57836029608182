import React, { FC, useState, useEffect } from 'react'
import { useDataProvider, useRefresh, useNotify } from 'ra-core'
import { ReferenceField, TextField } from 'react-admin'
import Button from '@material-ui/core/Button'
import MuiTextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'

type Props = {
  record: {
    id: any
    [x: string]: any
  }
}

function myRound(value: number, maximumFractionDigits: number): number {
  const formattedValue = value.toLocaleString('en', {
    useGrouping: false,
    maximumFractionDigits,
  })
  return Number(formattedValue)
}

const CloseAccountingCardButton: FC<Props> = ({ record }: Props) => {
  const classes = useStyles()
  const refresh = useRefresh()
  const notify = useNotify()
  const { create, update } = useDataProvider()

  const [loading, setLoading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [discountAmount, setDiscountAmount] = useState<number>(
    record.remainingAmount - Math.floor(record.remainingAmount / 10) * 10
  )
  const [description, setDescription] = useState<string>('CHIUSURA PRATICA')
  const [transactionValue, setTransactionValue] = useState<number>(record.remainingAmount - discountAmount)

  const onButtonClick = (): void => setIsOpen(true)

  const handleClose = (): void => setIsOpen(false)
  const handleDiscountChange = (e: any): void => {
    setDiscountAmount(parseFloat(e.target.value))
    setTransactionValue(record.remainingAmount - e.target.value)
  }
  const handleDescriptionChange = (e: any): void => setDescription(e.target.value)

  const onSubmit = async (): Promise<void> => {
    try {
      setLoading(true)
      if (transactionValue) {
        const data = {
          date: new Date().toString(),
          description,
          netPrice: transactionValue,
          paymentMethod: 'CASH',
          productId: '0',
          salePrice: transactionValue,
          total: transactionValue,
          type: 'INCOME',
          accountingCardId: record.id,
          taxRate: 0,
        }
        const res1 = await create('AccountingCardTransaction', { data })
        if (!res1) throw new Error('Errore nella creazione della transazione di chiusura pratica')
      }
      const res2 = update('AccountingCard', {
        id: record.id,
        previousData: { ...record },
        data: {
          isClosed: true,
          closingDate: new Date().toString(),
          discountAmount,
          remainingAmount: 0,
        },
      })
      if (!res2) throw new Error('Errore nella chiusura della pratica')
      notify('Scheda contabile chiusa con successo')
      setIsOpen(false)
      refresh()
    } catch (error) {
      notify(error?.message, 'error')
      console.error(error)
    }
  }

  useEffect(() => {
    if (!isNaN(record.remainingAmount)) {
      const val = myRound(Math.floor(record.remainingAmount / 10) * 10, 2)
      setDiscountAmount(myRound(record.remainingAmount - val, 2))
      setTransactionValue(val)
    }
  }, [record.remainingAmount])

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={onButtonClick}
        size="small"
        fullWidth
        className={classes.button}
        disabled={record.isClosed || (!record.remainingAmount && !record.totalAmount && !record.paidAmount)}
      >
        {record.isClosed ? 'PRATICA CHIUSA!' : 'CHIUDI PRATICA'}
      </Button>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Chiusura pratica</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'Sei sicuro di voler chiudere questa scheda contabile? Cliente: '}
            <ReferenceField
              basePath="/Customer"
              resource="Customer"
              record={record}
              reference="Customer"
              source="customerId"
              link={false}
            >
              <TextField source="fullName" variant="h6" />
            </ReferenceField>
          </DialogContentText>
          {record.remainingAmount > 0 && (
            <DialogContentText>
              <p>
                {'Restano da pagare: '}
                <b>{`${record.remainingAmount} €`}</b>
              </p>
              <p>
                {'Aggiungere una transazione di '}
                <b>{`${transactionValue} €`}</b>
                {', applicando uno sconto finale di'}
                <b>{` ${discountAmount} €`}</b>
                {' ?'}
              </p>
            </DialogContentText>
          )}
          <div className={classes.inputContainer}>
            {record.remainingAmount > 0 && (
              <MuiTextField
                margin="dense"
                id="discountAmount"
                label="Sconto finale"
                type="number"
                variant="outlined"
                value={discountAmount}
                onChange={handleDiscountChange}
              />
            )}
            <MuiTextField
              margin="dense"
              id="description"
              label="Descrizione"
              variant="outlined"
              value={description}
              onChange={handleDescriptionChange}
              error={description === ''}
              className={classes.description}
              fullWidth
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" size="small" disabled={loading}>
            Annulla
          </Button>
          <Button
            onClick={onSubmit}
            color="primary"
            variant="contained"
            size="small"
            disabled={loading || description === ''}
          >
            {loading ? <CircularProgress size={10} /> : 'Chiudi pratica'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  button: { marginTop: theme.spacing(2) },
  inputContainer: { display: 'flex' },
  description: { marginLeft: theme.spacing(2) },
}))

export default CloseAccountingCardButton
