import React, { useEffect } from 'react'
import { SelectInput } from 'react-admin'
import { useFormState, useForm } from 'react-final-form'

const choices = [
  { id: 'DEFAULT', label: 'Default' },
  { id: 'MEDICAL', label: 'Visita medica' },
  { id: 'THEORYEXAM', label: 'Esame di teoria' },
  { id: 'DRIVINGEXAM', label: 'Esame di guida' },
]

const SelectDsEventType = () => {
  const { values } = useFormState()
  const { change } = useForm()

  useEffect(() => {
    if (!values.id) {
      switch (values.dsEventType) {
        case 'MEDICAL':
          change('title', 'Visita medica')
          break
        case 'THEORYEXAM':
          change('title', 'Esame di teoria')
          break
        case 'DRIVINGEXAM':
          change('title', 'Esame di guida')
          break

        default:
          break
      }
    }
  }, [values.dsEventType])

  return values.isWorkEvent ? (
    <SelectInput
      defaultValue={'DEFAULT'}
      source="dsEventType"
      choices={choices}
      optionText="label"
      optionValue="id"
      variant="outlined"
      label="Tipologia dell'appuntamento"
      fullWidth
    />
  ) : null
}

export default SelectDsEventType
