import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useDataProvider, useNotify, useQuery, useQueryWithStore, useRefresh, useTranslate } from 'ra-core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import CardActions from '@material-ui/core/CardActions'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { SimpleForm, TextInput, required, ReferenceArrayInput, SelectArrayInput, PasswordInput } from 'react-admin'
import { GrUserAdmin } from 'react-icons/gr'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { createForm } from 'final-form'
import { useFormState } from 'react-final-form'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import ErrorIcon from '@material-ui/icons/Error'

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    title: {},
    icon: {
      marginRight: theme.spacing(1),
    },
    dialog: {
      width: 300,
    },
  }
})

type Props = {
  record?: any
  permissionsMap: Record<string, boolean>
  [x: string]: any
}

const TeamMemberAccountCard: FC<Props> = ({ record, permissionsMap }) => {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [user, setUser] = useState<Record<string, any>>({})
  const { loading, error, data } = useQuery({
    resource: 'User',
    type: 'getOne',
    payload: {
      id: record.accountId,
    },
  })

  const formCreate = createForm({
    onSubmit: () => {},
    initialValues: {
      firstName: record.firstName,
      lastName: record.lastName,
      email: record.email,
      phone: record.phone,
      active: true,
    },
  })

  const formUpdate = useMemo(
    () =>
      createForm({
        onSubmit: () => {},
        initialValues: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone,
          rolesIds: user.rolesIds,
          active: true,
        },
      }),
    [user]
  )

  const handleModalOpen = useCallback(() => setIsModalOpen(true), [])

  const handleModalClose = useCallback(() => setIsModalOpen(false), [])

  return (
    <>
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center" mb={3}>
            <GrUserAdmin size="1rem" className={classes.icon} />
            <Typography variant="h4" className={classes.title}>
              Account associato
            </Typography>
          </Box>
          {loading ? (
            <CircularProgress />
          ) : record.accountId && data && data.id ? (
            <EmailAccountField id={record.accountId} setUser={setUser} />
          ) : (
            <Typography>Nessun account associato</Typography>
          )}
        </CardContent>
        <Divider />
        {((permissionsMap.updateTeamMember && permissionsMap.deleteUser) ||
          (permissionsMap.createTeamMember && permissionsMap.createUser)) && (
          <CardActions>
            {loading ? (
              <CircularProgress />
            ) : record.accountId && data && data.id && permissionsMap.updateTeamMember && permissionsMap.deleteUser ? (
              <Button onClick={handleModalOpen} color="primary" fullWidth variant="text">
                Modifica account associato
              </Button>
            ) : (
              <Button onClick={handleModalOpen} color="primary" fullWidth variant="text">
                Crea e associa account
              </Button>
            )}
          </CardActions>
        )}
      </Card>
      <Dialog open={isModalOpen} onClose={handleModalClose}>
        {loading ? (
          <>
            <DialogTitle>Caricamento in corso</DialogTitle>
            <DialogContent>
              <CircularProgress />
            </DialogContent>
          </>
        ) : record.accountId && data && data.id ? (
          Object.keys(user).length > 0 ? (
            <>
              <DialogTitle>Modifica account</DialogTitle>
              <DialogContent>
                <SimpleForm form={formUpdate} resource="User" toolbar={null} variant="outlined" submitOnEnter={false}>
                  <TextInput validate={required()} source="firstName" fullWidth />
                  <TextInput validate={required()} source="lastName" fullWidth />
                  <TextInput validate={required()} source="email" fullWidth />
                  <TextInput source="phone" fullWidth />
                  <ReferenceArrayInput validate={required()} source="rolesIds" reference="Role">
                    <SelectArrayInput optionText="name" />
                  </ReferenceArrayInput>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {permissionsMap.deleteUser && (
                      <DeleteUser handleModalClose={handleModalClose} teamMemberId={record.id} />
                    )}
                    <UpdateUser handleModalClose={handleModalClose} />
                  </div>
                </SimpleForm>
              </DialogContent>
            </>
          ) : null
        ) : (
          <>
            <DialogTitle>Crea un nuovo account</DialogTitle>
            <DialogContent>
              <SimpleForm form={formCreate} resource="User" toolbar={null} variant="outlined" submitOnEnter={false}>
                {/* <TextInput validate={required()} source="firstName" fullWidth /> */}
                {/* <TextInput validate={required()} source="lastName" fullWidth /> */}
                <TextInput validate={required()} source="email" fullWidth />
                <PasswordInput validate={required()} source="password" />
                {/* <TextInput source="phone" fullWidth /> */}
                <ReferenceArrayInput validate={required()} source="rolesIds" reference="Role">
                  <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
                <SaveUser teamMemberId={record.id} handleModalClose={handleModalClose} />
              </SimpleForm>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  )
}

type SaveProps = {
  teamMemberId: string
  handleModalClose: () => void
}

const SaveUser: FC<SaveProps> = ({ teamMemberId, handleModalClose }) => {
  const { values: data } = useFormState()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [saving, setSaving] = useState<boolean>(false)

  const isValid = useMemo(() => data.email && data.email !== '' && data.rolesIds && data.rolesIds.length > 0, [data])

  const onSubmit = useCallback(async () => {
    setSaving(true)
    try {
      const userResult = await dataProvider.create('User', { data })
      if (!userResult.data.id) throw new Error()
      const teamMemberResult = await dataProvider.update('TeamMember', {
        id: teamMemberId,
        data: { accountId: userResult.data.id },
        previousData: { id: teamMemberId },
      })
      handleModalClose()
      notify('Account creato ed associato con successo')
    } catch (error) {
      console.log('submiterror: ', error)
      notify(error, 'error')
    } finally {
      console.log('finally')
      setSaving(false)
    }
  }, [data])

  return (
    <Button variant="contained" fullWidth color="primary" onClick={onSubmit} disabled={saving || !isValid}>
      {saving ? <CircularProgress size={28} /> : 'Crea e associa account'}
    </Button>
  )
}

const UpdateUser: FC<any> = ({ handleModalClose }) => {
  const { values } = useFormState()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const [saving, setSaving] = useState<boolean>(false)

  const isValid = useMemo(() => values.email && values.email !== '' && values.rolesIds && values.rolesIds.length > 0, [
    values,
  ])

  const onSubmit = useCallback(async () => {
    setSaving(true)
    try {
      const userResult = await dataProvider.update('User', {
        id: values.id,
        data: values,
        previousData: { id: values.id },
      })
      handleModalClose()
      notify('Account modificato con successo')
      refresh()
    } catch (error) {
      console.log('submiterror: ', error)
      notify(error, 'error')
    } finally {
      console.log('finally')
      setSaving(false)
    }
  }, [values])

  return (
    <Button variant="contained" color="primary" onClick={onSubmit} disabled={saving || !isValid}>
      {saving ? <CircularProgress size={28} /> : 'Modifica'}
    </Button>
  )
}

const DeleteUser: FC<SaveProps> = ({ teamMemberId, handleModalClose }) => {
  const { values } = useFormState()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [deleting, setDeleting] = useState<boolean>(false)

  const onDelete = useCallback(async () => {
    setDeleting(true)
    try {
      const tmResult = await dataProvider.update('TeamMember', {
        id: teamMemberId,
        data: { accountId: '' },
        previousData: { id: teamMemberId, accountId: values.id },
      })
      // console.log('tmResult', tmResult)
      const userResult = await dataProvider.delete('User', { id: values.id, previousData: { id: values.id } })
      // console.log('userResult: ', userResult)
      handleModalClose()
      notify('Account eliminato e disassociato con successo')
    } catch (error) {
      console.log('delete error: ', error)
      notify(error, 'error')
    } finally {
      setDeleting(false)
    }
  }, [values])

  return (
    <Button variant="contained" color="secondary" onClick={onDelete} disabled={deleting}>
      {deleting ? <CircularProgress size={28} /> : 'Elimina'}
    </Button>
  )
}

type EmailAccountFieldProps = {
  id: string
  setUser: Dispatch<SetStateAction<Record<string, any>>>
}

const EmailAccountField: FC<EmailAccountFieldProps> = ({ id, setUser }) => {
  const { loading, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'User',
    payload: { id },
  })

  useEffect(() => {
    if (data) setUser(data)
  }, [data])

  if (error) return <ErrorIcon color="error" fontSize="small" />
  if (loading) return <LinearProgress />
  if (data && data.email) return <Typography>{data.email}</Typography>
  return null
}

export default TeamMemberAccountCard
