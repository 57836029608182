import React, { FC } from 'react'
import { useQueryWithStore, LinearProgress } from 'react-admin'

const OrderTitle: FC<any> = ({ record }: any) => {
  const { loading, data, error } = useQueryWithStore({
    type: 'getOne',
    resource: 'Customer',
    payload: {
      id: record.customerId,
    },
  })
  return loading || error ? <LinearProgress /> : <span>{`Ordine ${record.reference} of ${data.fullName}`}</span>
}

export default OrderTitle
