import React, { FC, useMemo, useState } from 'react'
import { useGetList } from 'ra-core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'
import SimpleTransactionRow, { AccountingCardTransaction } from './SimpleTransactionRow'
import ListIcon from '@material-ui/icons/List'
import ListAltIcon from '@material-ui/icons/ListAlt'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import GroupedTransactionRow, { TransactionGroup } from './GroupedTransactionRow'
import SimpleTransactionHeader from './SimpleTransactionHeader'
import { group_trans_sort_name_asc, transactionGroupsGenerator } from '../../utils/accountingCard'
import { useLocation } from 'react-router-dom'
import { parse as parseQS } from 'query-string'
import SummaryTable from './SummaryTable'
import PrintAccountingCardTransactionsButton from './PrintAccountingCardTransactionsButton'

type Props = {
  accountingCardRecord: Record<string, any>
  accountingCardId: string
  closeCreateForm: () => void
  portalContainer: React.MutableRefObject<any>
  onAddPayment: (record: Record<string, any>) => void
  isAccountClosed: boolean
}

type TableMode = 'GROUPED' | 'RAW'

const sortTransactionsByDateDesc = (a: AccountingCardTransaction, b: AccountingCardTransaction): number => {
  if (a.date > b.date) return -1
  if (a.date < b.date) return 1
  return 0
}

const AccountingCardTransactionsTable: FC<Props> = ({
  accountingCardId,
  closeCreateForm,
  portalContainer,
  onAddPayment,
  isAccountClosed,
  accountingCardRecord,
}: Props) => {
  const location = useLocation()
  const { description, type, productId } = parseQS(location.search)

  const [tableMode, setTableMode] = useState<TableMode>('RAW')
  const [transIdToEdit, setTransIdToEdit] = useState<string | null>(null)

  const classes = useStyles()
  const { loading, data, total } = useGetList<AccountingCardTransaction>(
    'AccountingCardTransaction',
    { perPage: 1000, page: 1 },
    { field: 'date', order: 'ASC' },
    { accountingCardId, description, type, productId }
  )
  const { loading: loadingProds, data: dataProds } = useGetList(
    'Product',
    { perPage: 1000, page: 1 },
    { field: 'name', order: 'ASC' },
    {}
  )

  const rawDataArray: AccountingCardTransaction[] = useMemo(() => {
    if (loading || !data || total === 0) return []
    // else return Object.values(data).sort(sortTransactionsByDateDesc)
    else return Object.values(data)
  }, [data, loading, total])

  const groupedData: TransactionGroup[] = useMemo(() => {
    if (rawDataArray.length > 0 && !loadingProds && dataProds) {
      return transactionGroupsGenerator(rawDataArray, dataProds).sort(group_trans_sort_name_asc)
    } else return []
  }, [rawDataArray, dataProds, loadingProds])

  const handleTableModeChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, newMode: TableMode): void => {
    if (newMode !== null) {
      setTransIdToEdit(null)
      setTableMode(newMode)
    }
  }

  return loading ? (
    <>
      <Skeleton width="inherit" height={90} />
      <Skeleton width="inherit" height={60} />
      <Skeleton width="inherit" height={40} />
      <Skeleton width="inherit" height={30} />
      <Skeleton width="inherit" height={30} />
    </>
  ) : (
    <TableContainer component={Paper} className={classes.root}>
      <Box my={1} px={2} justifyContent="space-between" alignItems="center" display="flex">
        <Typography variant="h6">SCHEDA CONTABILE</Typography>
        <Box display="flex" alignItems="center">
          <PrintAccountingCardTransactionsButton items={rawDataArray} record={accountingCardRecord} />
          <ToggleButtonGroup value={tableMode} exclusive onChange={handleTableModeChange} size="small">
            <ToggleButton value="GROUPED">
              <ListAltIcon />
            </ToggleButton>
            <ToggleButton value="RAW">
              <ListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
      <Divider />
      <Table size={tableMode === 'RAW' ? 'small' : undefined}>
        {tableMode === 'RAW' && rawDataArray.length > 0 && <SimpleTransactionHeader />}
        {rawDataArray.length === 0 && <caption>Nessuna riga disponibile</caption>}
        <TableBody>
          {tableMode === 'RAW' &&
            rawDataArray.map((elem) => {
              return (
                <SimpleTransactionRow
                  key={`simple-${elem.id}`}
                  record={elem}
                  closeCreateForm={closeCreateForm}
                  transIdToEdit={transIdToEdit}
                  setTransIdToEdit={setTransIdToEdit}
                  onAddPayment={onAddPayment}
                  isAccountClosed={isAccountClosed}
                  accountingCardRecord={accountingCardRecord}
                />
              )
            })}
          {tableMode === 'GROUPED' &&
            groupedData.map((elem: any) => {
              return (
                <GroupedTransactionRow
                  key={`group-${elem.groupId}`}
                  record={elem}
                  closeCreateForm={closeCreateForm}
                  transIdToEdit={transIdToEdit}
                  setTransIdToEdit={setTransIdToEdit}
                  onAddPayment={onAddPayment}
                  isAccountClosed={isAccountClosed}
                  filterType={type}
                  accountingCardRecord={accountingCardRecord}
                />
              )
            })}
        </TableBody>
      </Table>
      {rawDataArray.length > 0 && (
        <SummaryTable
          container={portalContainer}
          rawDataArray={rawDataArray}
          isAccountClosed={isAccountClosed}
          filterType={type}
        />
      )}
    </TableContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}))

export default AccountingCardTransactionsTable
