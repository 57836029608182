import React, { FC, useCallback, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import ProductImageName from './ProductImageName'
import { numToEUR } from '../../utils/prices'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const OrderProductRow: FC<any> = (props: any) => {
  const [open, setOpen] = useState<boolean>(false)
  const classes = useStyles()

  const onArrowClick = useCallback(() => setOpen(!open), [open])

  return (
    <>
      <TableRow className={props.isExceedingTrainingProduct || props.isFakeExam ? classes.exceedingRow : undefined}>
        {!props.noCollapsible ? (
          <TableCell>
            <IconButton size="small" onClick={onArrowClick} disabled={!props.isDrivingTraining}>
              {props.isDrivingTraining ? open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> : null}
            </IconButton>
          </TableCell>
        ) : null}
        <TableCell>
          {props.isExceedingTrainingProduct ? (
            <Typography variant="h6">Guide non contabilizzate</Typography>
          ) : props.isFakeExam || props.forceName ? (
            props.orderProductName
          ) : (
            <ProductImageName orderProductId={props.productId} hideImage />
          )}
        </TableCell>
        <TableCell align="right">
          {props.isExceedingTrainingProduct ? props.exceedingTime / props.driveTrainingMinutesDuration : props.quantity}
        </TableCell>
        <TableCell align="right">
          {props.isExceedingTrainingProduct || props.isFakeExam ? '' : `${props.taxRateOverride} %`}
        </TableCell>
        <TableCell align="right">
          {props.isFakeExam
            ? ''
            : props.isExceedingTrainingProduct
            ? numToEUR(props.retailPrice)
            : numToEUR(props.productPriceOverride)}
        </TableCell>
        <TableCell align="right">
          {props.isExceedingTrainingProduct || props.isFakeExam ? '' : numToEUR(props.quantity * props.finalPrice)}
        </TableCell>
      </TableRow>
      {props.isDrivingTraining && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  {props.isExceedingTrainingProduct
                    ? `Minuti eccedenti: ${props.exceedingTime}`
                    : `Minuti rimanenti: ${props.remainingTime}`}
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Targa Veicolo</TableCell>
                      <TableCell>Istruttore</TableCell>
                      <TableCell align="right">Durata</TableCell>
                      <TableCell>Inizio</TableCell>
                      <TableCell>Fine</TableCell>
                      <TableCell>Stato</TableCell>
                      <TableCell align="right">Prezzo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.eventsForThisItem.map((event: any, idx: number) => (
                      <TableRow key={`${props.id}-${idx}`}>
                        <TableCell>{event.licensePlate}</TableCell>
                        <TableCell>{event.teamMemberName}</TableCell>
                        <TableCell align="right">{event.duration}</TableCell>
                        <TableCell>{new Date(event.startDate).toLocaleString()}</TableCell>
                        <TableCell>{new Date(event.endDate).toLocaleString()}</TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <Box width="12px" height="12px" borderRadius="50%" bgcolor={event.status?.color} mr="8px" />
                            {event.status?.name}
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          {props.isExceedingTrainingProduct
                            ? numToEUR(props.retailPrice * (event.duration / props.driveTrainingMinutesDuration))
                            : numToEUR((props.finalPrice * event.duration) / props.driveTrainingMinutesDuration)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  exceedingRow: {
    backgroundColor: 'rgba(255,5,13,0.3)',
  },
}))

export default OrderProductRow
