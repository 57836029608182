import React, { FC, useMemo } from 'react'
import { ReferenceManyField, ReferenceField, SingleFieldList } from 'react-admin'
import moment from 'moment'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import UserPreviewField from './UserPreviewField'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { AiOutlineUser } from 'react-icons/ai'
import CardField from './CardField'
import { useGetOne, useTranslate } from 'ra-core'
import QuickEditExamPlanningButton from './QuickEditExamPlanningButton'
import { CardActions } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    list: {
      minHeight: 400,
    },
    title: {},
    icon: {
      marginRight: theme.spacing(1),
    },
    teamMembersFieldList: {
      padding: '10px',
    },
  }
})

type Props = {
  record?: any
  [x: string]: any
}

const ExamPlanningExamOverviewCard: FC<Props> = ({ record, ...rest }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const { loading, data } = useGetOne('Exam', record.examId)

  const emptyValueLabel = useMemo(() => translate('custom.noData'), [translate])

  const startDateStr = useMemo(() => (data ? moment(record.startDate).format('L LT') : emptyValueLabel), [
    data,
    emptyValueLabel,
  ])
  const endDateStr = useMemo(() => (data ? moment(record.endDate).format('L LT') : emptyValueLabel), [
    data,
    emptyValueLabel,
  ])

  // if (error) {
  //   return (
  //     <Card className={classes.root}>
  //       <Typography color="error">{error}</Typography>
  //     </Card>
  //   )
  // }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <AiOutlineUser size="1.2rem" className={classes.icon} />
          {!loading && data && (
            <Typography variant="h4" className={classes.title}>
              {data.name}
            </Typography>
          )}
        </Box>
        <Divider />
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CardField label={translate('resources.ExamPlanning.fields.startDate')}>{startDateStr}</CardField>
            </Grid>
            <Grid item xs={12} md={6}>
              <CardField label={translate('resources.ExamPlanning.fields.endDate')}>{endDateStr}</CardField>
            </Grid>
            <Grid item xs={12} md={12}>
              <CardField label={translate('resources.ExamPlanning.fields.teamMemberIds')}>
                {!loading && data && (
                  <Box mt={1}>
                    <ReferenceManyField
                      basePath="/ExamPlanning"
                      resource="ExamPlanning"
                      record={record}
                      label={translate('resources.ExamPlanning.fields.teamMemberIds')}
                      reference="ExamPlanningTeamMember"
                      target="examPlanningId"
                      link={false}
                      filter={{ examPlanningId: record.examPlanningId }}
                    >
                      <SingleFieldList cellClassName={classes.teamMembersFieldList}>
                        <ReferenceField link={false} source="teamMemberId" reference="TeamMember">
                          <UserPreviewField />
                        </ReferenceField>
                      </SingleFieldList>
                    </ReferenceManyField>
                  </Box>
                )}
              </CardField>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <QuickEditExamPlanningButton record={record} id={record.id} {...rest} />
      </CardActions>
    </Card>
  )
}

export default ExamPlanningExamOverviewCard
