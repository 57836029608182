import React, { FC } from 'react'
import { DeleteButton } from 'react-admin'

type Props = {
  [x: string]: any
}

const DeleteButtonWPermission: FC<Props> = (props) => {
  return props &&
    props.permissions &&
    props.permissions.permissionsMap &&
    props.permissions.permissionsMap[`delete${props.resource}`] ? (
    <DeleteButton {...props} />
  ) : null
}

export default DeleteButtonWPermission
