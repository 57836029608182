import React, { FC } from 'react'
import { FormDataConsumer } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/Create'
import OfficeForm from './OfficeForm'

type Props = {}

const OfficeCreate: FC<Props> = props => {
  return (
    <Create {...props} component="div">
      <SectionedForm variant="outlined" redirect="list">
        <FormDataConsumer>{(formDataProps: any): any => <OfficeForm {...formDataProps} />}</FormDataConsumer>
      </SectionedForm>
    </Create>
  )
}

export default OfficeCreate
