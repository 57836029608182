import React, { FC } from 'react'
import RAList from './../../components/List'
import { TextField, NumberField, Datagrid, ReferenceField, DateField, useTranslate } from 'react-admin'
import EditButtonWPermission from '../../components/EditButtonWPermission'
import { PriceField } from './../../components/CustomFields'
import AccountingCardFilters from './AccountingCardFilters'
import IsClosedField from './IsClosedField'

type Props = {
  //
}

const sort = { field: 'createdAt', order: 'DESC' }
const filterDefaultValues = { isClosed: false }

const AccountingCardList: FC<Props> = (props) => {
  const translate = useTranslate()
  return (
    <RAList
      {...props}
      filters={<AccountingCardFilters />}
      filterDefaultValues={filterDefaultValues}
      actions={false}
      {...{ sort }}
    >
      <Datagrid rowClick="show">
        <NumberField source="id" />
        <DateField source="openingDate" />
        <DateField source="expiringDate" />
        <ReferenceField
          label={translate('resources.Order.fields.customer')}
          source="customerId"
          reference="Customer"
          link={false}
        >
          <TextField source="fullName" />
        </ReferenceField>
        {/* <ColoredNullableBooleanField source="isClosed" /> */}
        <PriceField tableField source="remainingAmount" color="red" />
        <PriceField tableField source="paidAmount" color="green" />
        <PriceField tableField source="totalAmount" />
        <IsClosedField source="isClosed" label="Stato" />
        <EditButtonWPermission {...props} />
      </Datagrid>
    </RAList>
  )
}

export default AccountingCardList
