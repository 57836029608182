import React, { FC, useCallback } from 'react'
import { useQueryWithStore, ReferenceField, TextField, useRedirect } from 'react-admin'
import { Typography, makeStyles, LinearProgress, Card, CardContent, CardActions, Button } from '@material-ui/core'
import CustomerAddresses from './CustomerAddresses'
import CustomerAvatarField from './../../components/CustomerAvatarField'
import CustomerContactsField from './../../components/CustomFields/CustomerContactsField'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(5),
    marginBottom: 20,
    // display: 'flex',
    // justifyContent: 'space-between',
    width: '100%',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
    paddingBottom: 0,
  },
  avatar: {
    alignSelf: 'center',
  },
  row: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(1),
  },
  rowTitle: {
    marginRight: 8,
    fontWeight: 'bold',
  },
  section: {
    marginRight: 20,
  },
}))

type Props = {
  id: string
}

const CustomerSection: FC<Props> = ({ id }: Props) => {
  const classes = useStyles()
  const redirect = useRedirect()
  const { loading, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'Customer',
    payload: { id },
  })
  // console.log('userData: ', data)
  const goToProfile = useCallback(() => redirect(`/Customer/${id}/show`), [])

  return loading || error ? (
    <LinearProgress />
  ) : (
    // <Grid item container direction="row" justify="space-between" className={classes.container}>
    <Card className={classes.container}>
      <CardContent className={classes.textContainer}>
        {/* <Avatar className={classes.avatar}>{`${data.firstName.toUpperCase()[0]}${
          data.lastName.toUpperCase()[0]
        }`}</Avatar> */}
        <CustomerAvatarField record={data} source="profilePictureId" className={classes.avatar} />
        <div className={classes.section}>
          <div className={classes.row}>
            <Typography variant="caption" className={classes.rowTitle}>
              {'Nome:'}
            </Typography>
            <Typography variant="body2">{data.fullName}</Typography>
          </div>
          <div className={classes.row}>
            <Typography variant="caption" className={classes.rowTitle}>
              {'Codice Fiscale:'}
            </Typography>
            <Typography variant="body2">{data.fiscalCode}</Typography>
          </div>
          <div className={classes.row}>
            <Typography variant="caption" className={classes.rowTitle}>
              {'Nazione:'}
            </Typography>
            <ReferenceField
              basePath="/Country"
              source="countryId"
              record={data}
              reference="Country"
              label={undefined}
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.row}>
            <Typography variant="caption" className={classes.rowTitle}>
              {'Email:'}
            </Typography>
            <CustomerContactsField record={data} contactType="email" />
          </div>
          <div className={classes.row}>
            <Typography variant="caption" className={classes.rowTitle}>
              {'Telefono:'}
            </Typography>
            <CustomerContactsField record={data} contactType="sms" />
          </div>
          {/* <div className={classes.row}>
            <Typography variant="caption" className={classes.rowTitle}>
              {'Indirizzo:'}
            </Typography>
          </div> */}
          {data.addressesIds.length > 0 && <CustomerAddresses ids={data.addressesIds} />}
        </div>
        <div className={classes.section}>
          <div className={classes.row}>
            <Typography variant="caption" className={classes.rowTitle}>
              {'Sede di appartenenza:'}
            </Typography>
            <ReferenceField
              basePath="/Office"
              source="subscriptionOfficeId"
              record={data}
              reference="Office"
              label={undefined}
            >
              <TextField source="name" />
            </ReferenceField>
          </div>
          <div className={classes.row}>
            <Typography variant="caption" className={classes.rowTitle}>
              {'Istruttore associato:'}
            </Typography>
            <ReferenceField
              basePath="/TeamMember"
              source="mainReferenceTeamMemberId"
              record={data}
              reference="TeamMember"
              label={undefined}
              link="edit"
            >
              <TextField source="fullName" />
            </ReferenceField>
          </div>
        </div>
      </CardContent>
      <CardActions>
        <Button color="primary" fullWidth variant="text" onClick={goToProfile}>
          VAI AL PROFILO UTENTE
        </Button>
      </CardActions>
    </Card>
  )
}

export default CustomerSection
