import React, { FC, useMemo, useEffect, useState } from 'react'
import { DateInput, ReferenceInput, AutocompleteInput, NumberInput } from 'react-admin'
import { useLocation } from 'react-router-dom'
import { parse as parseQS } from 'query-string'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Create from '../../components/Create'
import { useFormState, useForm } from 'react-final-form'
import { useGetOne } from 'ra-core'

type Props = {}

const filterToQuery = (q: string): any => ({ q })
const filterToQueryProduct = (name: string): any => ({ name })

export const DefaultDriveTrainingSection: FC<any> = ({ ...props }) => {
  const { values } = useFormState()
  const form = useForm()
  const { loading, data } = useGetOne('Product', values?.driveTrainingProductId, {
    enabled: values?.driveTrainingProductId !== undefined,
  })
  const [hasNewValue, setHasNewValue] = useState<boolean>(false)

  useEffect(() => {
    if (hasNewValue && values.driveTrainingProductId && data) {
      form.change('driveTrainingDefaultPrice', data.retailPrice)
    }
  }, [values.driveTrainingProductId, data, hasNewValue])

  useEffect(() => {
    if (props.record.driveTrainingProductId !== values.driveTrainingProductId && !hasNewValue) {
      setHasNewValue(true)
    }
  }, [props.record.driveTrainingProductId, values.driveTrainingProductId, hasNewValue])

  return (
    <FormSection title={'Guide'} {...props}>
      <ReferenceInput
        required
        source="driveTrainingProductId"
        reference="Product"
        filterToQuery={filterToQueryProduct}
        label="Prodotto di default per la guida *"
      >
        <AutocompleteInput optionText="name" variant="outlined" required />
      </ReferenceInput>
      <NumberInput source="driveTrainingDefaultPrice" label="Prezzo di default per la guida *" />
      <ReferenceInput
        required
        source="medicalExamProductId"
        reference="Product"
        filterToQuery={filterToQueryProduct}
        label="Prodotto per la visita medica *"
      >
        <AutocompleteInput optionText="name" variant="outlined" required />
      </ReferenceInput>
      <ReferenceInput
        required
        source="theoryExamProductId"
        reference="Product"
        filterToQuery={filterToQueryProduct}
        label="Prodotto per l'esame di teoria *"
      >
        <AutocompleteInput optionText="name" variant="outlined" required />
      </ReferenceInput>
      <ReferenceInput
        required
        source="drivingExamProductId"
        reference="Product"
        filterToQuery={filterToQueryProduct}
        label="Prodotto per l'esame di guida *"
      >
        <AutocompleteInput optionText="name" variant="outlined" required />
      </ReferenceInput>
    </FormSection>
  )
}

const AccountingCardCreate: FC<Props> = (props) => {
  const { search } = useLocation()

  const customerId = parseQS(search).customerId

  const record = useMemo(() => (customerId ? { customerId } : undefined), [customerId])

  return (
    <Create {...props} component="div" record={record}>
      <SectionedForm variant="outlined" redirect="show">
        <FormSection title={'Studente'} {...props}>
          <ReferenceInput source="customerId" reference="Customer" variant="outlined" filterToQuery={filterToQuery}>
            <AutocompleteInput optionText="fullName" variant="outlined" />
          </ReferenceInput>
        </FormSection>
        <FormSection title={'Date'} {...props}>
          <DateInput source="openingDate" defaultValue={new Date().toString()} />
          <DateInput source="expiringDate" />
          <DateInput source="closingDate" />
        </FormSection>
        <DefaultDriveTrainingSection {...props} />
      </SectionedForm>
    </Create>
  )
}

export default AccountingCardCreate
