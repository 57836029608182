import React, { FC, useCallback } from 'react'
import { SaveButton, useNotify, useRedirect } from 'react-admin'
import { useDataProvider } from 'ra-core'

const ApproveAndCreateAccountingCardButton: FC<any> = (props) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()

  const transform = useCallback((values) => {
    return {
      ...values,
      approved: true,
      quoteItems: values.quoteItems.map((item: any, index: number) => {
        return {
          id: item.id,
          productId: item.productId,
          quantity: item.quantity,
          productPriceOverride: item.productPriceOverride,
          taxRateOverride: item.taxRateOverride,
          itemPosition: index + 1,
        }
      }),
    }
  }, [])

  const onSuccess = async ({ data }: any): Promise<void> => {
    try {
      const result = await dataProvider.create('AccountingCard', {
        data: { customerId: data.customerId, openingDate: new Date().toUTCString() },
      })
      if (result) {
        notify('Preventivo approvato e scheda contabile creata')
        redirect(`/AccountingCard/${result.data.id}/show`)
      } else throw new Error()
    } catch (error) {
      notify('Preventivo approvato')
      redirect(`/AccountingCard/create?customerId=${data.customerId}`)
    }
  }

  return <SaveButton {...props} label="Salva e crea scheda contabile" {...{ transform, onSuccess }} />
}

export default ApproveAndCreateAccountingCardButton
