import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import { NumberField } from 'react-admin'

const options = { style: 'currency', currency: 'EUR' }

const useStyles = makeStyles({
  price: {
    display: (props: any): string | undefined => (props.tableField ? 'flex' : undefined),
    justifyContent: (props: any): string | undefined => (props.tableField ? 'flex-end' : undefined),
    textAlign: 'right',
    color: (props: any): string | undefined => (props.color ? props.color : undefined),
  },
})
// TODO: FIXME: ALIGNMENT FOR HEADER NOT WORKING!
// (tried textAlign and custom style inside headerClassName prop)
const PriceField: FC<any> = (props: any) => {
  const classes = useStyles(props)
  // console.log('price props: ', props)

  return <NumberField {...props} {...{ options }} locales="it-IT" className={classes.price} />
}

export default PriceField
