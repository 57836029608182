import React, { FC } from 'react'
import { NumberInput, TextInput, NullableBooleanInput } from 'react-admin'
import Filter from '../../components/Filter'

type Props = {}

const CountryFilters: FC<Props> = props => (
  <Filter {...props}>
    <NumberInput source="id" />
    <TextInput source="name" alwaysOn />
    <TextInput source="isoAlpha3" alwaysOn />
    {/* <ReferenceInput source="zoneId" reference="Zone" alwaysOn>
        <SelectInput optionText="name" />
      </ReferenceInput> */}
    <NullableBooleanInput source="active" alwaysOn />
  </Filter>
)

export default CountryFilters
