import React, { FC } from 'react'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { usePermissions, useShowController } from 'ra-core'
import { Loading } from 'react-admin'
import UserPicturePreviewCard from '../../components/UserPicturePreviewCard'
import TeamMemberProfilePreviewCard from '../../components/TeamMemberProfilePreviewCard'
import WorkingHoursCard from './../../components/WorkingHoursCard'
import TeamMemberCustomersCard from './../../components/TeamMemberCustomersCard'
import TeamMemberVehiclesCard from './../../components/TeamMemberVehiclesCard'
import TeamMemberAccountCard from './../../components/TeamMemberAccountCard'
import { TeamMemberWorkingPlanningCard } from '../../components/TeamMemberWorkingPlanningCard'

const TeamMemberShow: FC<any> = (props) => {
  const {
    basePath, // deduced from the location, useful for action buttons
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
    resource, // the resource name, deduced from the location. e.g. 'posts'
    version, // integer used by the refresh feature
  } = useShowController(props)
  const { loaded, permissions } = usePermissions()

  if (loading || !loaded || !permissions) {
    return <Loading />
  }

  return (
    <Container maxWidth="lg">
      <Grid spacing={3} container>
        <Grid item lg={4} md={6} xs={12}>
          <Box mt={3}>
            {record && (
              <UserPicturePreviewCard
                {...props}
                resource="TeamMember"
                record={record}
                permissionsMap={permissions.permissionsMap}
              />
            )}
          </Box>
          <Box mt={3}>
            {record && (
              <TeamMemberProfilePreviewCard {...props} record={record} permissionsMap={permissions.permissionsMap} />
            )}
          </Box>
          <Box mt={3}>
            {record && <TeamMemberAccountCard {...props} record={record} permissionsMap={permissions.permissionsMap} />}
          </Box>
          {permissions.permissionsMap.vehicles && (
            <Box mt={3}>{record && <TeamMemberVehiclesCard {...props} record={record} />}</Box>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mt={3}>
            {record && <TeamMemberWorkingPlanningCard record={record} permissionsMap={permissions.permissionsMap} />}
          </Box>
          {/* <Box mt={3}>{record && <WorkingHoursCard teamMemberId={record.id} />}</Box> */}
          {permissions.permissionsMap.customers && (
            <Box mt={3}>{record && <TeamMemberCustomersCard {...props} record={record} />}</Box>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default TeamMemberShow
