import React, { FC } from 'react'
import { Create } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
// import Create from '../../components/details/Create'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'
// import SwitchCardInput from '../../components/SwitchCardInput'
// import { RadioButtonGroupInput } from 'react-admin'
import FormSwitchInput from '../../components/forms/FormSwitchInput'
import HiddenOrganizationField from '../../components/forms/HiddenOrganizationField'

type Props = {
  [x: string]: any
}

const UserCreate: FC<Props> = (props) => {
  return (
    <Create {...props} component="div">
      <SectionedForm variant="outlined" redirect="list" title="Crea Account">
        <FormSection title="Informazioni personali" subtitle="Inserisci le informazioni personali dell'utente">
          <FormTextInput {...props} required source="firstName" />
          <FormTextInput {...props} required source="lastName" />
        </FormSection>
        <FormSection title="Informazioni per il login" subtitle="Inserisci i dati necessari per effettuare login">
          <FormTextInput {...props} required source="email" />
          <FormTextInput {...props} source="phone" />
          <FormTextInput {...props} required type="password" source="password" />
        </FormSection>
        <FormSection title="Login Abilitato">
          <FormSwitchInput {...props} source="active" helperText="questo account è attivo e può fare login" />
        </FormSection>
        <FormSection
          title="Eliminazione Bloccata"
          subtitle="ATTENZIONE! Disabilitando questo parametro potrebbe non essere più possibile accedere"
        >
          <FormSwitchInput {...props} source="isLocked" helperText="questo account non può essere eliminato" />
        </FormSection>
      </SectionedForm>
    </Create>
  )
}

export default UserCreate
