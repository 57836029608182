import React, { FC } from 'react'
import OrderShowComponent from './OrderShowComponent'

type Props = {
  //
}

const OrderShow: FC<Props> = (props) => {
  return <OrderShowComponent {...props} />
}

export default OrderShow
