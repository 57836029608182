import React, { FC, useEffect, useState } from 'react'
import { useQueryWithStore, useMutation, useNotify, useRefresh } from 'ra-core'
import { format } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'

import InputAdornment from '@material-ui/core/InputAdornment'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import LinearProgress from '@material-ui/core/LinearProgress'
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import ReceiptIcon from '@material-ui/icons/Receipt'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PaymentIcon from './PaymentIcon'

import { numToEUR } from './../../utils/prices'
import UserField from '../../components/CustomFields/UserField'
import { IconButton, Menu } from '@material-ui/core'
import { OrderTransactionItem } from './OrderTransactionItem'

const OrderTransactionSection: FC<any> = ({ orderId, permissionsMap }: any) => {
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()

  const [paymentMethod, setPaymentMethod] = useState<string>('CASH')
  const [note, setNote] = useState<string>('')
  const [transactionDate, setTransactionDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'))
  const [amount, setAmount] = useState<number>(0)

  const { loading, error, data } = useQueryWithStore({
    type: 'getList',
    resource: 'OrderTransaction',
    payload: {
      filter: { orderId },
      pagination: { page: 1, perPage: 1000 },
      sort: { createdAt: 'DESC' }, // TODO: CHANGE SORT BY transactionDate
    },
  })

  const [onClick, { loading: loadingTrs, error: errorTrs, data: dataTrs }] = useMutation({
    type: 'create',
    resource: 'OrderTransaction',
    payload: { data: { orderId, amount, paymentMethod, note, transactionDate } },
  })

  const onPaymentMethodChange = ({ target }: any): void => setPaymentMethod(target.value)

  const onNoteChange = ({ target }: any): void => setNote(target.value)

  const onTransactionDateChange = ({ target }: any): void => setTransactionDate(target.value)

  // fuck damned float input converted into string, i hate everyone
  const onValueChange = (event: any): void => setAmount(parseFloat(event.target.value))

  useEffect(() => {
    if (!loadingTrs && !errorTrs && dataTrs) {
      notify('Transazione aggiunta con successo')
      setAmount(0)
      setTransactionDate(format(new Date(), 'yyyy-MM-dd'))
      refresh()
    }
  }, [dataTrs, loadingTrs])

  return (
    <Paper className={classes.container}>
      {loading || error ? (
        <LinearProgress />
      ) : (
        <>
          <div className={classes.row}>
            <ReceiptIcon />
            <Typography variant="body1">TRANSAZIONI</Typography>
            <Chip size="small" variant="outlined" label={data.length} />
          </div>
          {data.length > 0 &&
            data.map((transaction: any) => {
              return <OrderTransactionItem transaction={transaction} />
            })}
        </>
      )}
      {permissionsMap.createOrderTransaction && (
        <>
          <Divider variant="fullWidth" className={classes.divider} />
          <Typography variant="h6">NUOVA TRANSAZIONE</Typography>
          <div className={classes.row}>
            <Typography variant="body1">Importo:</Typography>
            <TextField
              type="number"
              variant="outlined"
              value={amount}
              onChange={onValueChange}
              inputProps={{ step: 0.01, min: 0 }}
              className={classes.valueInput}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          </div>
          <div className={classes.row}>
            <Typography variant="body1">Data transazione:</Typography>
            <TextField
              type="date"
              value={transactionDate}
              onChange={onTransactionDateChange}
              variant="outlined"
              className={classes.valueInput}
            />
          </div>
          <TextField
            className={classes.textArea}
            variant="outlined"
            rows={2}
            multiline
            placeholder="Note"
            value={note}
            onChange={onNoteChange}
          />
          <div className={classes.ctaRow}>
            <FormControl variant="outlined">
              <InputLabel id="paymentMethodLabel">{'Modalità'}</InputLabel>
              <Select
                labelId="paymentMethodLabel"
                label="Modalità"
                id="paymentMethod"
                value={paymentMethod}
                onChange={onPaymentMethodChange}
              >
                <MenuItem value="CASH">Contanti</MenuItem>
                <MenuItem value="TRANSFER">Transfer</MenuItem>
                <MenuItem value="POS">POS</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              endIcon={<AttachMoneyIcon />}
              disabled={amount === 0 || isNaN(amount)}
              onClick={onClick}
            >
              {'AGGIUNGI'}
            </Button>
          </div>
        </>
      )}
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
    padding: 10,
    minWidth: 250,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 6,
    marginBottom: 6,
  },
  ctaRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    marginTop: 10,
    marginBottom: 6,
  },
  textArea: {
    display: 'flex',
    marginBottom: 18,
  },
  divider: { margin: 6 },
  accordionSummary: {
    justifyContent: 'space-between',
  },
  accordionDetail: {
    display: 'flex',
    flexDirection: 'column',
  },
  writtenBy: {
    display: 'block',
    textAlign: 'right',
    marginTop: 10,
  },
  notes: {
    display: 'block',
    marginBottom: 8,
  },
  amount: {
    display: 'flex',
  },
  valueInput: {
    width: '45%',
    maxWidth: '45%',
  },
}))

export default OrderTransactionSection
