import React, { FC, useEffect, useMemo } from 'react'
import { useQuery } from 'ra-core'
import { LinearProgress, DateTimeInput, TextInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { ColorInput } from 'react-admin-color-input'
import { makeStyles } from '@material-ui/core'

type Props = {
  id?: string
  record?: any
}

const useStyles = makeStyles((theme: any) => ({
  colorInput: (values: any): any => ({
    width: '100%',
    paddingTop: 0,
    height: '40px',
    marginBottom: '30px',
    // '&:after': {
    //   // eslint-disable-next-line quotes
    //   content: "''",
    //   position: 'absolute',
    //   bottom: 7,
    //   right: 2,
    //   width: 20,
    //   height: 20,
    //   background: 'red',
    //   pointerEvents: 'none',
    //   // backgroundColor: values.record.color,
    // },
  }),
}))

const parseDate = (v: any): any => {
  console.log(v)
  return new Date(v).toISOString()
}

const PlannedEventDatesInput: FC<Props> = (props) => {
  const classes = useStyles(props)
  const form = useForm()
  const formState = useFormState()
  const { loading, data } = useQuery({
    type: 'getOne',
    resource: 'Event',
    payload: {
      id: props.record.eventId,
    },
  })

  const newRecord = useMemo(() => {
    return {
      ...(props.record ? props.record : {}),
      ...(data ? data : {}),
    }
  }, [data, props.record])

  useEffect(() => {
    if (newRecord.endDate && !props.record.endDate) {
      form.change('endDate', newRecord.endDate)
    }
  }, [newRecord])

  useEffect(() => {
    if (newRecord.startDate && !props.record.startDate) {
      form.change('startDate', newRecord.startDate)
    }
  }, [newRecord])

  useEffect(() => {
    if (newRecord.title && !props.record.title) {
      form.change('title', newRecord.title)
    }
  }, [newRecord])

  useEffect(() => {
    console.log(newRecord, 'newRecord')
    if (newRecord.additionalData?.backgroundColor && !props.record.backgroundColor) {
      form.change('backgroundColor', newRecord.additionalData.backgroundColor)
    }
  }, [newRecord])

  if (loading) {
    return <LinearProgress />
  }

  return (
    <>
      <TextInput variant="outlined" source="title" resource="PlannedEvent" fullWidth />
      <DateTimeInput variant="outlined" parse={parseDate} source="startDate" resource="PlannedEvent" fullWidth />
      <DateTimeInput variant="outlined" parse={parseDate} source="endDate" resource="PlannedEvent" fullWidth />
      <ColorInput
        label="Colore in calendario personalizzato"
        variant="outlined"
        className={classes.colorInput}
        resource="PlannedEvent"
        source="backgroundColor"
        picker="Sketch"
      />
    </>
  )
}

export default PlannedEventDatesInput
