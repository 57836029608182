import React, { FC, useMemo } from 'react'
import Card from '@material-ui/core/Card'
import EuroIcon from '@material-ui/icons/EuroSymbol'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useTranslate } from 'react-admin'

import CardIcon from './CardIcon'

export type SummaryCardProps = {
  resource?: string
  record?: any
  render: (record: any) => React.ReactNode
  title?: string
  translateChoice?: boolean
  icon?: React.ReactNode
  bgColor?: string
  menu?: React.ReactNode
}

const useStyles = makeStyles((theme) => ({
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 40,
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 135,
  },
  title: {},
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
    minHeight: 30,
  },
  result: {
    // minHeight: 39,
    // display: 'inline-block',
    // verticalAlign: 'bottom',
  },
}))

const SummaryCard: FC<SummaryCardProps> = ({
  render,
  record = {},
  title,
  translateChoice = true,
  icon = EuroIcon,
  bgColor = '#3f51b5',
  menu,
}) => {
  const translate = useTranslate()
  const classes = useStyles()
  const renderResult = useMemo(() => render(record), [render, record])

  return (
    <div className={classes.main}>
      <CardIcon Icon={icon as any} bgColor={bgColor} />
      <Card className={classes.card}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title} color="textSecondary">
            {translateChoice || !title ? translate(title || 'customFields.WalletSummaryCard.defaultTitle') : title}
          </Typography>
          {menu && menu}
        </div>
        {typeof renderResult === 'string' ? (
          <Typography variant="h5" component="h2" className={classes.result}>
            {renderResult}
          </Typography>
        ) : (
          renderResult
        )}
      </Card>
    </div>
  )
}

export default SummaryCard
