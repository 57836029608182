import React, { FC } from 'react'
import { Create as RACreate } from 'react-admin'

type Props = {
  component?: string
  id?: string
  basePath?: string
  resource?: string
  hasList?: boolean
  hasCreate?: boolean
  hasShow?: boolean
  hasEdit?: boolean
  record?: Record<string, any>
  transform?: (data: any) => Record<string, any>
}

const Create: FC<Props> = (props) => {
  return <RACreate {...props} />
}

export default Create
