import React, { FC, useCallback } from 'react'
import { useFormState, useForm } from 'react-final-form'
import { ReferenceInput, AutocompleteInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { useDataProvider, useNotify } from 'ra-core'

type Props = {}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  autocompleteContainer: {
    flexGrow: 0,
    marginRight: theme.spacing(1),
    width: '50%',
  },
  autocompleteRoot: {
    width: '100% !important',
  },
  submitButton: {
    marginTop: theme.spacing(1.2),
  },
}))

const filterToQueryQuoteTemplate = (name: string): Record<string, string> => ({ name })
const sortQuoteTemplate = { field: 'name', order: 'ASC' }

const ImporQuoteTemplate: FC<Props> = (props) => {
  const classes = useStyles()
  const { values } = useFormState()
  const form = useForm()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const onClick = useCallback(async () => {
    try {
      const template = await dataProvider.getOne('QuoteTemplate', { id: values.quoteTemplateId })
      console.log('template', template)

      if (!template.data || !template.data.content) {
        throw new Error('Dati necessari mancanti')
      }

      form.change('printTemplateId', template.data.printTemplateId)
      if (template.data.content && template.data.content.quoteItems) {
        form.change('quoteItems', template.data.content.quoteItems)
      }

      form.change('quoteTemplateId', undefined)
    } catch (e) {
      console.log('Errore nella generazione del preventivo', e)
      notify('Errore nella generazione del preventivo')
    }
  }, [values.quoteTemplateId, notify, dataProvider, form])

  return (
    <div className={classes.root}>
      <ReferenceInput
        {...props}
        label="Seleziona modello"
        reference="QuoteTemplate"
        source="quoteTemplateId"
        filterToQuery={filterToQueryQuoteTemplate}
        sort={sortQuoteTemplate}
        classes={{ container: classes.autocompleteContainer }}
        fullWidth
      >
        <AutocompleteInput
          className={classes.autocompleteRoot}
          optionText="name"
          fullWidth
          options={{
            InputProps: {
              className: classes.autocompleteRoot,
            },
          }}
        />
      </ReferenceInput>
      <Button
        color="primary"
        disabled={!values.quoteTemplateId}
        className={classes.submitButton}
        size="medium"
        variant="outlined"
        onClick={onClick}
      >
        Genera Preventivo
      </Button>
    </div>
  )
}

export default ImporQuoteTemplate
