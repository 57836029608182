import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/Create'
import EmailTemplateForm from './EmailTemplateForm'

type Props = {
  //
}

const EmailTemplateCreate: FC<Props> = (props) => (
  <Create {...props} component="div">
    <SectionedForm variant="outlined" redirect="list">
      <EmailTemplateForm {...props} />
    </SectionedForm>
  </Create>
)

export default EmailTemplateCreate
