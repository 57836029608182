import React, { FC } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import CardActions from '@material-ui/core/CardActions'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'
import { useQueryWithStore, useRedirect } from 'ra-core'
import { Link } from 'react-router-dom'
import { GrShop } from 'react-icons/gr'

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    title: {},
    icon: {
      marginRight: theme.spacing(1),
    },
  }
})

type Props = {
  record?: any
  permissionsMap: Record<string, boolean>
  [x: string]: any
}

const CustomerAccountingCardPreviewCard: FC<Props> = (props) => {
  const classes = useStyles(props)
  const { record, permissionsMap } = props
  const redirect = useRedirect()

  const { data, loading } = useQueryWithStore({
    type: 'getList',
    resource: 'AccountingCard',
    payload: {
      filter: {
        customerId: record.id,
        isClosed: false,
      },
      pagination: {
        page: 1,
        perPage: 1,
      },
      sort: {
        field: 'id',
        order: 'DESC',
      },
    },
  })

  const onCreateClick = (e: any): void => redirect(`/AccountingCard/create?customerId=${record.id}`)

  return !loading ? (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center">
          <GrShop size="1.2rem" className={classes.icon} />
          <Typography variant="h4" className={classes.title}>
            {data && data.length > 0 ? (
              <>Scheda contabile creata il {new Date(data[0].createdAt).toLocaleDateString()}</>
            ) : (
              <>Nessuna scheda contabile presente su questo utente</>
            )}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        {permissionsMap.accountingCard && data.length > 0 && (
          <Button component={Link} to={`/AccountingCard/${data[0].id}/show`} color="primary" fullWidth variant="text">
            Visualizza Scheda contabile
          </Button>
        )}
        {permissionsMap.createAccountingCard && data.length === 0 && (
          <Button color="primary" fullWidth variant="text" onClick={onCreateClick}>
            Crea nuova scheda contabile
          </Button>
        )}
      </CardActions>
    </Card>
  ) : (
    <LinearProgress />
  )
}

export default CustomerAccountingCardPreviewCard
