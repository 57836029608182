import React, { FC } from 'react'
import RAList from '../../components/List'
import { TextField, NumberField, Datagrid, ReferenceField, BooleanField, ListGuesser } from 'react-admin'
import EditButtonWPermission from '../../components/EditButtonWPermission'
import DrivingLicenseFilters from './DrivingLicenseFilters'

const sort = { field: 'id', order: 'DESC' }
const filterDefaultValues = {}

type Props = {
  //
}

const DrivingLicenseList: FC<Props> = (props) => (
  <RAList {...{ sort, filterDefaultValues }} {...props} filters={<DrivingLicenseFilters />}>
    <Datagrid rowClick="show">
      <NumberField source="id" />
      <TextField source="name" />
      <EditButtonWPermission {...props} />
    </Datagrid>
  </RAList>
)

export default ListGuesser
