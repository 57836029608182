import React, { FC, useCallback, useEffect } from 'react'
import {
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
  DateInput,
} from 'react-admin'
import { useForm, useFormState } from './../../../node_modules/react-final-form'
import { makeStyles } from '@material-ui/core/styles'

import FormSection from '../../components/forms/FormSection'
import ProductNetPrice from './ProductNetPrice'
import TaxRateOverride from './TaxRateOverride'
import BaseButton from '@material-ui/core/Button'
import ContentAdd from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { calculateTax, numToEUR } from './../../utils/prices'
import { useTranslate } from 'ra-core'
import ProductImageName from './ProductImageName'

const renderSummary = ({ formData }: any): any => {
  let totalNet = 0
  let totalTaxes = 0

  if (formData && formData.orderItems && formData.orderItems.length > 0) {
    formData.orderItems.forEach((elem: any) => {
      if (elem && elem.quantity && elem.productPriceOverride) {
        const singleNet = elem.quantity * elem.productPriceOverride
        const singleTax = calculateTax(singleNet, elem.taxRateOverride)
        totalNet += singleNet
        totalTaxes += singleTax
      }
    })
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <div
          style={{
            minWidth: '300px',
            marginTop: '5px',
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h4>TOTALE NETTO</h4>
          <span>{numToEUR(totalNet)}</span>
        </div>
        <div
          style={{
            minWidth: '300px',
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h4>TOTALE TASSE</h4>
          <span>{!isNaN(totalTaxes) && numToEUR(totalTaxes)}</span>
        </div>
        <div
          style={{
            minWidth: '300px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h2>TOTALE</h2>
          <h3>{!isNaN(totalTaxes) && numToEUR(totalTaxes + totalNet)}</h3>
        </div>
      </div>
    )
  } else return null
}

const sort = { field: 'email', order: 'ASC' }

const useStyles = makeStyles({
  form: {
    display: 'flex',
  },
  root: {
    maxWidth: 128,
    marginLeft: 16,
  },
  line: {
    display: 'flex',
    alignItems: 'stretch',
    alignContent: 'center',
    borderBottom: 0,
  },
})

const OrderForm: FC<Record<string, any>> = (props: any) => {
  const dataForm = useForm()
  const { form, root, line } = useStyles()
  const translate = useTranslate()
  const { values } = useFormState()

  const filterToQueryQ = useCallback((q: string): Record<string, string> => ({ q }), [])
  const filterToQueryName = useCallback((name: string): Record<string, string> => ({ name }), [])

  const changePrice = useCallback(
    (field: string, value: number): void => dataForm.change(`${field}.productPriceOverride`, value),
    []
  )

  const changeTax = useCallback(
    (field: string, value: number): void => dataForm.change(`${field}.taxRateOverride`, value),
    []
  )

  useEffect(() => dataForm.change('orderDate', new Date()), [])

  return (
    <>
      <FormSection title="Cliente" {...props}>
        <ReferenceInput label="Cliente" reference="Customer" source="customerId" {...{ sort, filterToQueryQ }}>
          <AutocompleteInput optionText="fullName" />
        </ReferenceInput>
        <DateInput source="orderDate" />
        <DateInput source="expiringDate" />
      </FormSection>
      <FormSection title="Dettagli" fullWidth {...props}>
        <ArrayInput source="orderItems" label={false}>
          <SimpleFormIterator
            classes={{ form, line }}
            addButton={
              <BaseButton startIcon={<ContentAdd />} color="primary" variant="contained">
                {'AGGIUNGI'}
              </BaseButton>
            }
            removeButton={
              <BaseButton startIcon={<DeleteOutlineIcon />} color="primary" variant="contained">
                {'RIMUOVI'}
              </BaseButton>
            }
          >
            <ReferenceInput
              filterToQuery={filterToQueryName}
              reference="Product"
              source="productId"
              label="Prodotto"
              helperText={false}
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <NumberInput source="quantity" initialValue={1} classes={{ root }} helperText={false} />
            <FormDataConsumer>
              {(props: any): any =>
                props?.scopedFormData?.productId && (
                  <ProductNetPrice
                    {...props}
                    {...{ changePrice }}
                    label={translate('resources.Order.fields.price')}
                    helperText={false}
                  />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {(props: any): any =>
                props?.scopedFormData?.productId && (
                  <TaxRateOverride
                    {...props}
                    {...{ changeTax }}
                    label={translate('resources.Order.fields.tax')}
                    helperText={false}
                  />
                )
              }
            </FormDataConsumer>
            {/* <FormDataConsumer>
              {(props: any): any =>
                props?.scopedFormData?.productId && (
                  <div style={{ display: 'inline-block' }}>
                    <ProductImageName orderProductId={props.scopedFormData.productId} isInIterator hideName />
                  </div>
                )
              }
            </FormDataConsumer> */}
          </SimpleFormIterator>
        </ArrayInput>
      </FormSection>
      <FormSection title="Resoconto">
        <FormDataConsumer>{renderSummary}</FormDataConsumer>
      </FormSection>
    </>
  )
}

const useStyles2 = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  row: {
    minWidth: '300px',
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:last-child': {
      marginBottom: 0,
    },
    '&:first-child': {
      marginTop: theme.spacing(1),
    },
  },
}))

export default OrderForm
