import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { withStyles } from '@material-ui/core/styles'

export const Accordion = withStyles((theme) => ({
  root: {
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',

    marginTop: theme.spacing(1),
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginTop: theme.spacing(1),
      // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
  },
  expanded: {},
}))(MuiAccordion)

export const AccordionSummary = withStyles((theme) => ({
  root: {
    // backgroundColor: 'rgba(0, 0, 0, .03)',
    // borderBottom: '1px solid ' + theme.palette.divider,
    marginBottom: 0,
    minHeight: 30,
    maxHeight: 35,
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
    '&$expanded': {
      minHeight: 20,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary)
