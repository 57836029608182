/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, Fragment, ReactElement, useState, useCallback, useEffect } from 'react'
import ExpandMore from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslate } from 'react-admin'
import clsx from 'clsx'

// const useStyles = makeStyles((theme) => ({
//   icon: { minWidth: theme.spacing(5) },
//   sidebarIsOpen: {
//     paddingLeft: 25,
//     transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
//   },
//   sidebarIsClosed: {
//     paddingLeft: 0,
//     transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
//   },
// }))

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: (props: any): any => ({
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // paddingLeft: props.sidebarIsOpen ? theme.spacing(2) : theme.spacing(0),
    textTransform: 'none',
    whiteSpace: 'nowrap',
    width: '100%',
  }),
  icon: {
    minWidth: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: 'auto',
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
  sidebarIsOpen: {
    '& a': {
      paddingLeft: theme.spacing(4),
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
  sidebarIsClosed: {
    '& a': {
      paddingLeft: theme.spacing(1),
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
}))

interface Props {
  dense: boolean
  handleToggle?: () => void
  icon?: any
  isOpen?: boolean
  name: string
  sidebarIsOpen: boolean
}

const SubMenu: FC<Props> = (props) => {
  const { handleToggle, sidebarIsOpen, isOpen: isOpenProp, name, icon: Icon, children, dense } = props
  const translate = useTranslate()
  const classes = useStyles(props)
  const [isOpen, setIsOpen] = useState(isOpenProp)

  const toggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  useEffect(() => {
    setIsOpen(isOpenProp)
  }, [isOpenProp])

  // const header = (
  //   <MenuItem dense={dense} button onClick={handleToggle ? handleToggle : toggle}>
  //     <ListItemIcon className={classes.icon}>
  //       <>{isOpen ? <ExpandMore /> : icon}</>
  //     </ListItemIcon>
  //     <Typography variant="inherit" color="textSecondary">
  //       {translate(name)}
  //     </Typography>
  //   </MenuItem>
  // )

  const header = (
    <ListItem className={clsx(classes.item)} disableGutters>
      <Button className={classes.button} onClick={handleToggle ? handleToggle : toggle}>
        {/* {Icon && <Icon className={classes.icon} />} */}
        {/* {isOpen ? <ExpandMore /> : Icon ? Icon : undefined} */}
        {isOpen ? (
          <ExpandMore className={classes.icon} />
        ) : Icon ? (
          <Icon className={classes.icon} size={20} />
        ) : undefined}
        <Typography variant="inherit" color="textSecondary" className={classes.title}>
          {translate(name)}
        </Typography>
      </Button>
    </ListItem>
  )

  return (
    <>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={translate(name)} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          className={sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed}
        >
          {children}
        </List>
        <Divider />
      </Collapse>
    </>
  )
}

export default SubMenu
