import React, { FC, useCallback, useState, useMemo } from 'react'
import { FormRenderProps, Form } from 'react-final-form'
import { useGetList, useDataProvider, useRefresh, useNotify, useGetOne } from 'ra-core'
import createDecorator from 'final-form-calculate'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import TransactionFormBody from './TransactionFormBody'

type Props = {
  id?: string
  onUndo: () => void
}

const validate = (values: Record<string, any>): Record<string, any> => {
  const errors: Record<string, any> = {}

  if (!values.date) errors.date = 'Campo obbligatorio'
  if (!values.isFreeCredit && !values.productId) errors.productId = 'Campo obbligatorio'
  if (!values.description) errors.description = 'Campo obbligatorio'
  if (values.salePrice <= 0) errors.salePrice = 'Deve essere > 0'
  if (values.salePrice < values.netPrice) errors.salePrice = 'Deve essere > dell\'imponibile'
  if (values.salePrice < values.discountAmount) errors.discountAmount = 'Deve essere < del lordo'

  return errors
}

const twoArraysIdsComparison = (start: Array<string | number>, end: Array<string | number>) => {
  const toDelete = start.filter((elem: any) => {
    return !end.includes(elem)
  })
  const toCreate = end.filter((elem: any) => {
    return !start.includes(elem)
  })
  const untouched = start.filter((elem: any) => {
    return end.includes(elem)
  })
  return { untouched, toCreate, toDelete }
}

export const TransactionForm: FC<any> = ({ id, onUndo, accountingCardId, startValues, record }) => {
  const classes = useStyles()
  const refresh = useRefresh()
  const notify = useNotify()
  const [mutationLoading, setMutationLoading] = useState<boolean>(false)
  const [currentActiveField, setCurrentActiveField] = useState<string | undefined>(undefined)
  const { create, update, delete: deleteOne, getList } = useDataProvider()
  const { loading, error, data } = useGetList('Product', { page: 1, perPage: 1000 }, { field: 'id', order: 'DESC' }, {})
  const { loading: loadingTax, data: dataTax } = useGetList(
    'Tax',
    { page: 1, perPage: 1000 },
    { field: 'id', order: 'ASC' },
    {}
  )

  const { loading: loadingTrans, data: dataTrans } = useGetOne('AccountingCardTransaction', id, {
    enabled: id !== undefined,
  })

  const { loading: loadingAttrz, data: dataAttrz } = useGetList(
    'ProductDrivingSchoolAttribute',
    { page: 1, perPage: 1000 },
    { field: 'id', order: 'ASC' },
    {}
  )

  const initialValues: Record<string, any> = useMemo(() => {
    if (startValues) return startValues
    if (!id) {
      return {
        method: 'CASH',
        discountAmount: 0,
        discountRate: 0,
      }
    } else {
      if (loadingTrans || !dataTrans) return {}
      else
        return {
          ...dataTrans,
          isPayment: dataTrans.type === 'INCOME' ? true : false,
        }
    }
  }, [id, loadingTrans, dataTrans, startValues])

  const relatedPackageTransactionId = useMemo(() => {
    if (startValues && startValues.isDrivingPackage && startValues.isPayment) {
      return startValues.relatedPackageTransactionId
    } else return undefined
  }, [startValues])

  const createCalculator = useCallback(
    createDecorator(
      {
        field: 'productId',
        updates: {
          description: (value: string | number): string => (data && value ? data[`${value}`].name : ''),
          salePrice: (value: string | number): number =>
            data && value ? parseFloat(data[`${value}`].retailPrice.toFixed(2)) : 0,
          taxRate: (value: string | number): number =>
            data && value && data[`${value}`].taxId && dataTax ? dataTax[data[`${value}`].taxId].rate : 0,
        },
      },
      {
        field: 'salePrice',
        updates: {
          total: (value: number, allValues: any): number => value - allValues.discountAmount,
          netPrice: (salePrice: number, allValues: any): number =>
            currentActiveField === 'netPrice'
              ? allValues.netPrice
              : salePrice
              ? parseFloat(((salePrice * 100) / (100 + allValues.taxRate)).toFixed(2))
              : 0,
          discountAmount: (salePrice: number, allValues: any): number =>
            !allValues.discountRate && !salePrice
              ? 0
              : parseFloat(((salePrice * allValues.discountRate) / 100).toFixed(2)),
        },
      },
      {
        field: 'netPrice',
        updates: {
          salePrice: (netPrice: number, allValues: any): number =>
            currentActiveField === 'salePrice'
              ? allValues.salePrice
              : netPrice
              ? parseFloat(((netPrice * (100 + allValues.taxRate)) / 100).toFixed(2))
              : 0,
        },
      },
      {
        field: 'discountRate',
        updates: {
          discountAmount: (value: number, allValues: any): number =>
            currentActiveField === 'discountAmount'
              ? allValues.discountAmount
              : value === 0
              ? 0
              : parseFloat(((value * allValues.salePrice) / 100).toFixed(2)),
        },
      },
      {
        field: 'discountAmount',
        updates: {
          discountRate: (value: number, allValues: any): number =>
            currentActiveField === 'discountRate'
              ? allValues.discountRate
              : value === 0
              ? 0
              : (value * 100) / allValues.salePrice,
          total: (value: number, allValues: any): number => parseFloat((allValues.salePrice - value).toFixed(2)),
        },
      }
    ),
    [data, dataTax, currentActiveField]
  )

  const editCalculator = useCallback(
    createDecorator(
      {
        field: 'salePrice',
        updates: {
          total: (value: number, allValues: any): number =>
            allValues.discountAmount ? parseFloat((value - allValues.discountAmount).toFixed(2)) : value,
          netPrice: (salePrice: number, allValues: any): number =>
            !salePrice || !allValues.taxRate || currentActiveField === 'netPrice'
              ? allValues.netPrice
              : parseFloat(((salePrice * 100) / (100 + allValues.taxRate)).toFixed(2)),
          discountAmount: (salePrice: number, allValues: any): number =>
            !allValues.discountRate || !salePrice
              ? allValues.discountAmount
              : parseFloat(((salePrice * allValues.discountRate) / 100).toFixed(2)),
        },
      },
      {
        field: 'netPrice',
        updates: {
          salePrice: (netPrice: number, allValues: any): number => {
            if (isNaN(netPrice) || isNaN(allValues.taxRate) || currentActiveField === 'salePrice' || !netPrice) {
              return allValues.salePrice
            } else {
              const calc = (netPrice * (100 + allValues.taxRate)) / 100
              const diff = allValues.salePrice - calc
              if (diff > -0.5 && diff < 0.5) return allValues.salePrice
              else return parseFloat(calc.toFixed(2))
            }
          },
        },
      },
      {
        field: 'discountAmount',
        updates: {
          discountRate: (value: number, allValues: any): number | string =>
            currentActiveField === 'discountRate' || isNaN(value) || isNaN(allValues.salePrice)
              ? allValues.discountRate || 0
              : (value * 100) / allValues.salePrice,
          total: (value: number, allValues: any): number => parseFloat((allValues.salePrice - value).toFixed(2)),
        },
      },
      {
        field: 'discountRate',
        updates: {
          discountAmount: (value: number, allValues: any): number =>
            currentActiveField === 'discountAmount' || !value || !allValues.salePrice
              ? allValues.discountAmount
              : parseFloat(((value * allValues.salePrice) / 100).toFixed(2)),
        },
      }
    ),
    [data, currentActiveField]
  )

  const onCreateSubmit = async (values: any): Promise<void> => {
    try {
      setMutationLoading(true)
      const {
        date,
        description,
        discountAmount,
        isPayment,
        netPrice,
        notes,
        method,
        productId,
        salePrice,
        taxRate,
        plannedEventId,
        plannedEventIds,
        examPlanningId,
        total,
        isFreeCredit,
        outcomeTransactionId,
      } = values

      const attributes = Object.values(dataAttrz!).filter(
        (elem: any) => elem.isDrivingTraining && elem.productId === productId
      )[0]

      const isDrivingPackage =
        !attributes || isPayment
          ? undefined
          : attributes.isDrivingTraining && attributes.driveTrainingMinutesDuration > 90
          ? true
          : undefined
      const remainingDuration = isPayment || !isDrivingPackage ? undefined : attributes.driveTrainingMinutesDuration

      const realTotal = isFreeCredit && !total ? salePrice : total

      const dataToSubmit = {
        date,
        description,
        discountAmount,
        netPrice,
        notes,
        method,
        productId,
        salePrice,
        type: isPayment ? 'INCOME' : 'OUTCOME',
        accountingCardId,
        taxRate,
        examPlanningId,
        remainingDuration,
        isDrivingPackage,
        relatedPackageTransactionId,
        total: realTotal,
        isFreeCredit,
        outcomeTransactionId,
        totalDuration: remainingDuration,
      }

      const result = await create('AccountingCardTransaction', { data: dataToSubmit })

      if (plannedEventId) {
        const realPlannedEventId = plannedEventId.split('-')[0]
        const duration = parseInt(plannedEventId.split('-')[1])
        const res1 = await create('TransactionPlannedEvent', {
          data: {
            accountingCardTransactionId: result.data.id,
            plannedEventId: realPlannedEventId,
            duration,
          },
        })
      } else if (plannedEventIds && plannedEventIds.length > 0) {
        for (let i = 0; i < plannedEventIds.length; i++) {
          const realPlannedEventId = plannedEventIds[i].split('-')[0]
          const duration = parseInt(plannedEventIds[i].split('-')[1])
          const res2 = await create('TransactionPlannedEvent', {
            data: {
              accountingCardTransactionId: result.data.id,
              plannedEventId: realPlannedEventId,
              duration,
            },
          })
        }
      }

      onUndo()
      refresh()
      notify('Transazione aggiunta correttamente')
    } catch (error) {
      console.error('createError; ', error)
      notify('Errore nella creazione della transazione', 'error')
      setMutationLoading(false)
    }
  }

  const onEditSubmit = async (values: any): Promise<void> => {
    try {
      setMutationLoading(true)
      const {
        date,
        description,
        discountAmount,
        isPayment,
        netPrice,
        notes,
        method,
        productId,
        salePrice,
        plannedEventId,
        plannedEventIds,
        total,
        isFreeCredit,
        outcomeTransactionId,
        isDrivingPackage,
      } = values

      const dataToSubmit = {
        date,
        description,
        discountAmount,
        netPrice,
        notes,
        method,
        productId,
        salePrice,
        type: isPayment ? 'INCOME' : 'OUTCOME',
        accountingCardId,
        total: isDrivingPackage ? undefined : total,
        isFreeCredit,
        outcomeTransactionId,
      }

      const result = await update('AccountingCardTransaction', { id, data: dataToSubmit, previousData: { id } })

      const attributes = Object.values(dataAttrz!).filter(
        (elem: any) =>
          (elem.isDrivingTraining || elem.isMedicalExam || elem.isTheoryExam || elem.isDrivingExam) &&
          elem.productId === productId
      )[0]

      if (!isPayment && attributes) {
        const newValues = []
        const eventsDurationsMap: Record<string, any> = {}
        if (plannedEventId) {
          const realPlannedEventId = plannedEventId.split('-')[0]
          const duration = parseInt(plannedEventId.split('-')[1])
          newValues.push(realPlannedEventId)
          eventsDurationsMap[realPlannedEventId] = duration
        } else if (plannedEventIds && plannedEventIds.length > 0) {
          plannedEventIds.forEach((elem: string) => {
            const realPlannedEventId = elem.split('-')[0]
            const duration = parseInt(elem.split('-')[1])
            newValues.push(realPlannedEventId)
            eventsDurationsMap[realPlannedEventId] = duration
          })
        }

        let initialIds: string[] = []
        if (initialValues.plannedEventId) {
          initialIds.push(initialValues.plannedEventId)
        } else if (initialValues.plannedEventIds) {
          initialIds = [...initialValues.plannedEventIds]
        }

        const { toCreate, toDelete } = twoArraysIdsComparison(initialIds, newValues)

        if (toDelete.length > 0) {
          for (let i = 0; i < toDelete.length; i++) {
            const plannedEventId = toDelete[i]
            const x = await getList('TransactionPlannedEvent', {
              pagination: { page: 1, perPage: 1 },
              sort: { field: 'id', order: 'ASC' },
              filter: { plannedEventId, accountingCardTransactionId: id },
            })
            await deleteOne('TransactionPlannedEvent', { id: x.data[0].id, previousData: { ...x.data[0] } })
          }
        }
        if (toCreate.length > 0) {
          for (let i = 0; i < toCreate.length; i++) {
            await create('TransactionPlannedEvent', {
              data: {
                accountingCardTransactionId: id,
                plannedEventId: toCreate[i],
                duration: eventsDurationsMap[toCreate[i]],
              },
            })
          }
        }
      }

      onUndo()
      refresh()
      notify('Transazione modificata correttamente')
    } catch (error) {
      console.error('createError; ', error)
      notify('Errore nella creazione della transazione', 'error')
      setMutationLoading(false)
    }
  }

  return (
    <Paper className={!loading && data ? classes.container : classes.loadingContainer}>
      {(id &&
        !loadingTrans &&
        dataTrans &&
        !loadingTax &&
        dataTax &&
        Object.keys(initialValues).length > 0 &&
        !loadingAttrz &&
        dataAttrz) ||
      (!loading &&
        data &&
        !loadingTax &&
        dataTax &&
        Object.keys(initialValues).length > 0 &&
        !loadingAttrz &&
        dataAttrz) ? (
        <Form
          onSubmit={id ? onEditSubmit : onCreateSubmit}
          decorators={
            startValues?.isFreeCredit
              ? undefined
              : id
              ? [editCalculator as any]
              : startValues
              ? [editCalculator as any]
              : [createCalculator as any]
          }
          initialValues={initialValues}
          validate={id ? undefined : validate}
          render={(props) => (
            <TransactionFormBody
              {...props}
              onUndo={onUndo}
              mutationLoading={mutationLoading}
              isEdit={!!id}
              currentActiveField={currentActiveField}
              setCurrentActiveField={setCurrentActiveField}
              record={record}
              allDrivingAttributes={dataAttrz}
            />
          )}
        />
      ) : (
        <CircularProgress size={40} />
      )}
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  loadingContainer: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default TransactionForm
