import React, { FC, useEffect, useState, MouseEvent } from 'react'
import { GridListTile, GridListTileBar } from '@material-ui/core'
import { useTranslate, useDataProvider } from 'ra-core'
import { makeStyles } from '@material-ui/core/styles'
import GalleryTileButton from './GalleryTileButton'

const getLabelByType = (type: 'primary' | 'secondary' | 'featured' | 'default'): string => {
  switch (type) {
    case 'primary':
      return 'customFields.galleryGrid.catalogImage'
    case 'secondary':
      return 'customFields.galleryGrid.catalogSecondaryImage'
    case 'featured':
      return 'customFields.galleryGrid.featuredImage'
  }

  return ''
}

const getSetLabelByType = (type: 'primary' | 'secondary' | 'featured' | 'default'): string => {
  switch (type) {
    case 'primary':
      return 'customFields.galleryGrid.setCatalogImage'
    case 'secondary':
      return 'customFields.galleryGrid.setCatalogSecondaryImage'
    case 'featured':
      return 'customFields.galleryGrid.setFeaturedImage'
  }

  return ''
}

const useStyles = makeStyles((theme) => ({
  tileBar: {
    backgroundColor: 'rgba(9, 77, 222, 0.8)',
    opacity: 0.4,
  },
}))

type Props = {
  onClick: (event: MouseEvent) => void
  image: any
  type: 'primary' | 'secondary' | 'featured' | 'default'
}

const GalleryTileSingleImage: React.ForwardRefExoticComponent<Props> = React.forwardRef(
  ({ onClick, type, image, ...rest }) => {
    const classes = useStyles()
    const translate = useTranslate()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<any>(null)
    const [error, setError] = useState(null)

    const dataProvider = useDataProvider()

    useEffect(() => {
      const fetchImage = async (): Promise<void> => {
        try {
          setLoading(true)
          const result = await dataProvider.getOne('Picture', {
            id: image,
          })
          setData(result.data)
        } catch (e) {
          setError(e)
        } finally {
          setLoading(false)
        }
      }
      if (image) {
        fetchImage()
      }
    }, [image])

    if (loading) {
      return <GridListTile onClick={onClick} {...rest}></GridListTile>
    }

    if (error) {
      return (
        <GridListTile onClick={onClick} {...rest}>
          {type && type !== 'default' && <GridListTileBar className={classes.tileBar} title={'Error'} />}
        </GridListTile>
      )
    }

    if (data) {
      return (
        <GridListTile {...rest}>
          <img onClick={onClick} src={data.urlMedium} alt={data.description} />
          {type && type !== 'default' && (
            <GridListTileBar className={classes.tileBar} title={translate(`${getLabelByType(type)}`)} />
          )}
        </GridListTile>
      )
    } else {
      return <GalleryTileButton onClick={onClick} label={getSetLabelByType(type)} {...rest} />
    }
  }
)

export default GalleryTileSingleImage
