import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/Create'
import TelegramTemplateForm from './TelegramTemplateForm'
import FormSection from '../../components/forms/FormSection'
import { Typography } from '@material-ui/core'

type Props = {
  //
}

const TelegramTemplateCreate: FC<Props> = (props) => (
  <Create {...props} component="div">
    <SectionedForm variant="outlined" redirect="list">
      <FormSection title="Elenco variabili disponibili">
        <Typography variant="body1">{"{{title}} - il titolo dell'evento"}</Typography>
        <Typography variant="body1">{'{{firstName}} - il nome del cliente'}</Typography>
        <Typography variant="body1">{"{{startDate}} - data e ora di inizio dell'evento"}</Typography>
        <Typography variant="body1">{"{{endDate}} - data e ora di fine dell'evento"}</Typography>
      </FormSection>
      <TelegramTemplateForm {...props} />
    </SectionedForm>
  </Create>
)

export default TelegramTemplateCreate
