import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/Create'
import TaxForm from './TaxForm'

type Props = {}

const TaxCreate: FC<Props> = props => (
  <Create {...props} component="div">
    <SectionedForm variant="outlined" redirect="list">
      <TaxForm {...props} />
    </SectionedForm>
  </Create>
)

export default TaxCreate
