import { createStyles, makeStyles } from '@material-ui/core'
import { FC } from 'react'

const useStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        backgroundColor: '#f4f6f8',
        height: '100%',
        width: '100%',
      },
      a: {
        textDecoration: 'none',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
      '.fc-theme-standard th': {
        // border: 'none',
      },
      '.fc-timegrid-axis': {
        borderWidth: '0px !important',
      },
      '.fc-theme-standard td': {
        borderBottomWidth: '0px',
        borderTopWidth: '0px',
      },
      '.fc .fc-timegrid-slot-minor': {
        borderTop: `1px solid ${theme.palette.divider}`,
      },
      '.fc .fc-scrollgrid-section td': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      '.fc-timegrid-slot-label .fc-scrollgrid-shrink': {
        borderBottomWidth: '0px !important',
      },
      '.fc-timegrid-slot-minor': {
        // borderBottomColor: 'transparent !important',
        borderTop: '1px solid transparent !important',
      },
    },
  })
)

const GlobalStyles: FC = () => {
  useStyles()

  return null
}

export default GlobalStyles
