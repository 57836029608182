import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/Create'
import QuoteTemplateForm from './QuoteTemplateForm'
import { useTranslate } from 'ra-core'

type Props = {}

const transform = (data: any): any => ({
  name: data.name,
  printTemplateId: data.printTemplateId,
  content: { quoteItems: data.quoteItems },
})

const DocumentCreate: FC<Props> = (props) => {
  return (
    <Create {...props} transform={transform} component="div">
      <SectionedForm redirect="list">
        <QuoteTemplateForm {...props} />
      </SectionedForm>
    </Create>
  )
}

export default DocumentCreate
