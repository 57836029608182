import React, { FC } from 'react'
import { TextInput, NumberInput, BooleanInput, SelectInput } from 'react-admin'
import FormSection from './../../components/forms/FormSection'

type Props = {}

const CurrencyForm: FC<Props> = props => (
  <FormSection title="Member Info" {...props}>
    <TextInput source="name" />
    <TextInput source="isoCode" />
    <NumberInput source="isoCodeNumber" />
    <TextInput source="sign" />
    <SelectInput
      source="format"
      optionValue="value"
      choices={[
        { value: 'X0,000.00', name: 'X0,000.00' },
        { value: '0 000,00X', name: '0 000,00X' },
        { value: 'X0.000,00', name: 'X0.000,00' },
        { value: '0,000.00X', name: '0,000.00X' },
        // eslint-disable-next-line quotes
        { value: "0'000.00X", name: "0'000.00X" }
      ]}
    />
    <NumberInput source="conversionRate" />
    <BooleanInput source="showDecimals" />
    <BooleanInput source="showSpace" />
    <BooleanInput source="active" />
  </FormSection>
)

export default CurrencyForm
