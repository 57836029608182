import React, { cloneElement, FC, isValidElement, useCallback } from 'react'
import { Datagrid, ListToolbar, BulkActionsToolbar, Pagination, TextField } from 'react-admin'
import { useListContext, ListBase } from 'ra-core'
import Card from '@material-ui/core/Card'
import Drawer from '@material-ui/core/Drawer'
import { Route, useLocation, useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
import PlannedEventEdit from './PlannedEventEdit'
import PlannedEventCreate from './PlannedEventCreate'
import { makeStyles } from '@material-ui/core/styles'
import CalendarPlanning from '../../components/CalendarPlanning'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '30vw',
    minWidth: 300,
  },
}))

const PlannedEventList: FC = (props) => {
  const classes = useStyles(props)
  const history = useHistory()
  const { search } = useLocation()
  const handleClose = useCallback(() => {
    history.push({
      pathname: '/PlannedEvent',
      search: search,
    })
  }, [search])

  return (
    <>
      <CalendarPlanning {...props} />
      <Route path="/PlannedEvent/:id">
        {({ match }): React.ReactNode => {
          const isEdit = match && match.params && match.params.id !== 'create'
          const isCreate = match && match.params && match.params.id === 'create'

          return (
            <Drawer open={isEdit || isCreate} anchor="right" onClose={handleClose} classes={{ paper: classes.drawer }}>
              {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
              {isEdit && <PlannedEventEdit id={match!.params.id} onCancel={handleClose} {...props} />}
              {isCreate && <PlannedEventCreate onCancel={handleClose} {...props} />}
            </Drawer>
          )
        }}
      </Route>
    </>
  )
}

export default PlannedEventList
