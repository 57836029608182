import React, { FC, useMemo } from 'react'
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, makeStyles, TextField } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles(theme => ({
  deleteButton: {
    color: theme.palette.error.main
  }
}))

const getProductCombinationLabel = (data: any, values: any): any =>
  sortBy(data.attributeValues, 'productAttributeId')
    .reduce((carry: any[], value: any): any[] => {
      const attributeValue = values[value.id]

      if (attributeValue) {
        carry.push(attributeValue.value)
      }

      return carry
    }, [])
    .join('-')

const ProductCombinationsInputTable: FC<any> = ({
  combinations,
  attributeValues,
  onChangeCombination,
  onDeleteCombination
}) => {
  const classes = useStyles()
  const lookupAttributeValues = useMemo(() => keyBy(attributeValues, 'id'), [attributeValues])

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Id</TableCell>
          <TableCell>Combination</TableCell>
          <TableCell size="small" style={{ width: 180 }}>
            Quantity
          </TableCell>
          <TableCell align="right" padding="none" style={{ width: 80 }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {combinations.map((combination: any): any => (
          <TableRow key={combination.id}>
            <TableCell component="th" scope="row">
              {combination.id}
            </TableCell>
            <TableCell>{getProductCombinationLabel(combination, lookupAttributeValues)}</TableCell>
            {/* <TableCell size="small">
              <TextField
                type="number"
                defaultValue={combination.quantity}
                onBlur={(e: any): void => {
                  onChangeCombination(combination.id, { quantity: parseInt(e.target.value) })
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </TableCell> */}
            <TableCell align="right" padding="none">
              <IconButton
                aria-label="delete feature"
                component="span"
                classes={{ root: classes.deleteButton }}
                onClick={(): any => onDeleteCombination(combination.id)}
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default React.memo(ProductCombinationsInputTable)
