import React, { FC, useCallback, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import Button, { ButtonProps } from '@material-ui/core/Button'
import { saveAs } from 'file-saver'
import { useDataProvider, useNotify } from 'ra-core'
import XLSX from 'xlsx'
import moment from 'moment'

type Props = {
  record?: any
  [x: string]: any
} & ButtonProps

// useful to fill month header with real words
const fullMonths: Record<string, any> = {
  0: 'Gennaio',
  1: 'Febbraio',
  2: 'Marzo',
  3: 'Aprile',
  4: 'Maggio',
  5: 'Giugno',
  6: 'Luglio',
  7: 'Agosto',
  8: 'Settembre',
  9: 'Ottobre',
  10: 'Novembre',
  11: 'Dicembre',
}

// starting from F because is the cell where month headers starts
const alphabet: string[] = 'FGHIJKLMNOPQRSTUVWXYZ'.split('')

const PrintCoursePlanningButton: FC<Props> = ({ record, ...props }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const generateExcelDocument = useCallback(async () => {
    try {
      setLoading(true)
      const { data: course } = await dataProvider.getOne('Course', { id: record.courseId })
      const customerCoursePlannings = await dataProvider.getList('CustomerCoursePlanning', {
        filter: {
          coursePlanningId: record.id,
        },
        pagination: { perPage: 1000, page: 1 },
        sort: { field: 'id', order: 'ASC' },
      })

      const customerIds = customerCoursePlannings.data.map(({ customerId }: any) => customerId)

      const customers = await dataProvider.getMany('Customer', { ids: customerIds })

      const offices = await dataProvider.getMany('Office', {
        ids: customers.data
          .filter((item) => !!item.subscriptionOfficeId)
          .map(({ subscriptionOfficeId }: any): any => subscriptionOfficeId),
      })

      const officesMap: any = offices.data.reduce((acc, item) => {
        return { ...acc, [item.id]: item }
      }, {})

      const { data: orders } = await dataProvider.getList('Order', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'id', order: 'ASC' },
        filter: { userIds: customerIds },
      })

      const ordersMap: any = orders.reduce((acc, item) => {
        return { ...acc, [item.userId]: item }
      }, {})

      const { data: courseLessons } = await dataProvider.getList('CourseLesson', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'date', order: 'ASC' },
        filter: { coursePlanningId: record.id },
      })

      // useful for checking attendances for every single student
      const lessonsAndDays: any[] = []
      // useful for creating month header and merged cells
      const calendar: any = {}

      courseLessons.forEach((lesson: any) => {
        const parsedDate = new Date(lesson.date)
        lessonsAndDays.push({
          id: lesson.id,
          date: parsedDate.toLocaleDateString(),
          attendances: [],
        })
        if (!calendar[parsedDate.getMonth()]) calendar[parsedDate.getMonth()] = []
        calendar[parsedDate.getMonth()].push(parsedDate.getDate())
      })

      // building month header and mechanism to merge month cells
      const monthHeader: string[] = []
      const mergeMonths: any[] = []
      Object.keys(calendar).forEach((monthId: any) => {
        const startIdx = monthHeader.length
        monthHeader.push(fullMonths[monthId])
        const cellsToMerge = calendar[monthId].length - 1
        for (let i = 0; i < cellsToMerge; i++) {
          monthHeader.push('')
        }
        const endIdx = monthHeader.length - 1
        if (cellsToMerge > 0) mergeMonths.push(`${alphabet[startIdx]}2:${alphabet[endIdx]}2`)
      })

      for (let i = 0; i < courseLessons.length; i++) {
        const { data: attendanceRegister } = await dataProvider.getList('CourseAttendanceRegister', {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'customerId', order: 'ASC' },
          filter: { courseLessonId: courseLessons[i].id },
        })
        // console.log('attendance: ', attendanceRegister)
        attendanceRegister.forEach((atten: any) => {
          if (atten.present) {
            const idx = lessonsAndDays.findIndex((lsn: any) => lsn.id === atten.courseLessonId)
            if (idx > -1) {
              lessonsAndDays[idx].attendances.push(atten.customerId)
            }
          }
        })
      }

      // editing the number of tableHeader content will break the mergin-cell system
      // if you edit this, edit also the "alphabet" array and make it start from the proper letter
      const tableHeader: any[] = ['N.', 'Cognome Nome', 'Sede', 'Età', 'Data Iscr.']
      Object.values(calendar).forEach((monthArr: any) => {
        monthArr.forEach((dayNumber: any) => tableHeader.push(dayNumber))
      })

      const final: any[] = []
      customers.data.map((student: any, i: number) => {
        const finalObj: any = {}
        finalObj['N.'] = i
        finalObj['Cognome Nome'] = student.fullName
        finalObj['Sede'] = officesMap[student.subscriptionOfficeId].name
        finalObj['Età'] = student.birthday ? moment().diff(moment(student.birthday), 'years', false) : ''
        finalObj['data iscr.'] = new Date(ordersMap[student.id].createdAt).toLocaleDateString()
        lessonsAndDays.forEach((lesson: any) => {
          finalObj[`z-${lesson.date.split('/')[0]}`] = lesson.attendances.includes(student.id) ? '1' : ''
        })
        final.push(finalObj)
      })
      /////////
      const json = [...final]
      const objectMaxLength: any[] = []
      for (let i = 0; i < json.length; i++) {
        const value: any[] = Object.values(json[i])
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] == 'number' || !value[j]) {
            objectMaxLength[j] = 5
          } else {
            objectMaxLength[j] = objectMaxLength[j] >= value[j].length ? objectMaxLength[j] : value[j].length + 2
          }
        }
        // not totally right cause we have a custom header now
        const keys = Object.keys(json[i])
        for (let j = 0; j < keys.length; j++) {
          objectMaxLength[j] = objectMaxLength[j] >= keys[j].length ? objectMaxLength[j] : keys[j].length
        }
      }
      const wscols: any[] = []
      objectMaxLength.forEach((width: any) => wscols.push({ width }))

      const firstRow = [{ a: course.name }]
      const mergeTitle = XLSX.utils.decode_range('A1:B1')

      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(firstRow, { sheetStubs: true, skipHeader: true })
      XLSX.utils.sheet_add_aoa(ws, [monthHeader], { origin: 'F2', sheetStubs: true })
      XLSX.utils.sheet_add_aoa(ws, [tableHeader], { origin: 'A3', sheetStubs: true })
      XLSX.utils.sheet_add_json(ws, json, { origin: 'A4', sheetStubs: true, skipHeader: true })
      if (!ws['!merges']) ws['!merges'] = []
      ws['!merges'].push(mergeTitle)
      mergeMonths.forEach((xxx: any) => ws['!merges'] && ws['!merges'].push(XLSX.utils.decode_range(xxx)))
      ws['!cols'] = wscols

      const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      saveAs(data, `presenze-${course.name.replace(/[^a-z0-9]/gi, '_')}-${record.courseId}.xlsx`)
    } catch (e) {
      console.log('errore print', e)
      notify('ra.error', 'error')
    } finally {
      setLoading(false)
    }
  }, [record])

  return (
    <>
      <Button {...props} onClick={generateExcelDocument} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : 'Stampa Presenze'}
      </Button>
    </>
  )
}

export default PrintCoursePlanningButton
