import React, { FC } from 'react'
import { useGetOne } from 'ra-core'
import { ReferenceField, TextField } from 'react-admin'
import LinearProgress from '@material-ui/core/LinearProgress'
import { MdError } from 'react-icons/md'

type Props = {
  basePath: string
  source: string
  record?: Record<string, any>
  label?: string
}

/**
 * @description Custom field to show VehicleManufacturer name from an external referenced VehicleModel
 */
const ReferenceModelManufacturerField: FC<Props> = ({ source, record = {}, basePath }) => {
  const { loading, data, error } = useGetOne('VehicleModel', record[source])

  if (loading) return <LinearProgress />
  if (error) return <MdError color="red" size="1.4rem" />
  if (data)
    return (
      <ReferenceField
        basePath={basePath}
        source="vehicleManufacturerId"
        record={data}
        reference="VehicleManufacturer"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
    )
  return <div></div>
}

export default ReferenceModelManufacturerField
