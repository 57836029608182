import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Datagrid as RaDatagrid } from 'ra-ui-materialui'

const useStyles = makeStyles((theme) => {
  return {
    headerCell: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  }
})

type Props = {
  [x: string]: any
}

const Datagrid: FC<Props> = (props) => {
  const classes = useStyles()
  return <RaDatagrid {...props} classes={classes} />
}

export default Datagrid
