import React, { FC, useCallback } from 'react'
import { NumberInput, TextInput, ReferenceInput, NullableBooleanInput, AutocompleteInput } from 'react-admin'
import Filter from '../../components/Filter'

type Props = {
  //
}

const DrivingLicenseCategoryFilters: FC<Props> = (props) => {
  // const filterToQuery = useCallback(() => (name: string): Record<string, string> => ({ name }), [])
  return (
    <Filter {...props}>
      <NumberInput source="id" />
      <TextInput source="name" alwaysOn />
      {/* <TextInput source="iso" alwaysOn />
      <ReferenceInput source="countryId" reference="Country" alwaysOn {...{ filterToQuery }}>
        <AutocompleteInput optionText="name" emptyText="ra.boolean.null" />
      </ReferenceInput>
      <NullableBooleanInput source="active" alwaysOn /> */}
    </Filter>
  )
}

export default DrivingLicenseCategoryFilters
