import React, { FC } from 'react'
import { TextInput, TextField } from 'react-admin'
import SectionedForm from '../SectionedForm'
import FormSection from '../FormSection'
import Edit from '../../Edit'

type Props = {
  sectionName: string
}

const NameOnlyEdit: FC<Props> = props => {
  return (
    <Edit {...props} component="div">
      <SectionedForm variant="outlined">
        <FormSection title={props.sectionName}>
          <TextField source="id" />
          <TextInput source="name" />
        </FormSection>
      </SectionedForm>
    </Edit>
  )
}

export default NameOnlyEdit
