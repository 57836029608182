import React, { FC, Children, isValidElement } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import { FormInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    overflow: 'visible',
  },
  inputsContainer: (props: any): any => ({
    padding: theme.spacing(props.fullWidth ? 4 : 4),
    margin: theme.spacing(props.fullWidth ? 4 : 4, 0),
  }),
  divider: {
    marginBottom: theme.spacing(1),
  },
}))

type Props = {
  children: React.ReactNode
  title: string
  subtitle?: string
  resource?: string
  basePath?: string
  record?: any
  variant?: 'outlined' | 'standard' | 'filled'
  fullWidth?: boolean
}

const FormSection: FC<Props> = (props) => {
  const classes = useStyles(props)
  const { children, title, subtitle, basePath, record, resource, variant, fullWidth, ...rest } = props

  return (
    <Box mt={3}>
      <Card className={classes.card}>
        <CardHeader title={title} subheader={subtitle} />
        <Divider />
        <CardContent>
          {Children.map(
            children,
            (input) =>
              input &&
              isValidElement(input) && (
                <FormInput
                  basePath={basePath}
                  input={input}
                  record={record}
                  resource={resource}
                  margin={input.props.margin}
                  variant={input.props.variant || variant}
                />
              )
          )}
        </CardContent>
      </Card>
      {/* <Grid container className={classes.root}>
        <Grid item xs={12} md={fullWidth ? 12 : 4}>
          <Box paddingY="2rem">
            <Typography variant="h6">{title}</Typography>
            {subtitle && <Typography variant="body2">{subtitle}</Typography>}
          </Box>
        </Grid>
        <Grid item xs={12} md={fullWidth ? 12 : 8}>
          <Paper className={classes.inputsContainer}>
            
          </Paper>
        </Grid>
      </Grid>
      <Divider className={classes.divider} /> */}
    </Box>
  )
}

export default FormSection
