import React, { FC } from 'react'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { usePermissions, useShowController } from 'ra-core'
import { Loading } from 'react-admin'
import { ShowProps } from 'ra-core/esm/controller/useShowController'
import ExamPlanningExamOverviewCard from '../../components/ExamPlanningExamOverviewCard'
import ExamPlanningCustomersCard from '../../components/ExamPlanningCustomersCard'
import ExamPlanningVehiclesCard from '../../components/ExamPlanningVehiclesCard'

type Props = {
  //
  [x: string]: any
} & ShowProps

const ExamPlanningShow: FC<Props> = (props) => {
  const {
    basePath, // deduced from the location, useful for action buttons
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
    resource, // the resource name, deduced from the location. e.g. 'posts'
    version, // integer used by the refresh feature
  } = useShowController(props)
  const { loaded, permissions } = usePermissions()

  if (loading || !record || !loaded || !permissions) {
    return <Loading />
  }

  return (
    <Container maxWidth="lg">
      {/* <pre>{JSON.stringify(record, null, 2)}</pre> */}
      <Grid spacing={3} container>
        <Grid item lg={4} md={6} xs={12}>
          <Box mt={3}>
            <ExamPlanningExamOverviewCard
              resource={resource}
              basePath={basePath}
              record={{
                ...record,
                teamMemberIds: record.teamMembers.map((item: any) => item.id),
                startDate: new Date(record.startDate),
                endDate: new Date(record.endDate),
              }}
            />
          </Box>
          <Box mt={3}>
            <ExamPlanningVehiclesCard
              resource={resource}
              basePath={basePath}
              record={record}
              permissionsMap={permissions.permissionsMap}
            />
          </Box>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          {permissions.permissionsMap && permissions.permissionsMap.examPlanningCustomers && (
            <Box mt={3}>
              <ExamPlanningCustomersCard
                resource={resource}
                basePath={basePath}
                record={record}
                permissionsMap={permissions.permissionsMap}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default ExamPlanningShow
