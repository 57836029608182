import React, { FC, useEffect } from 'react'
import { useMutation } from 'react-admin'
import { IconButton, CircularProgress } from '@material-ui/core'
import { CheckCircle, Error } from '@material-ui/icons'

const HourUpdater: FC<Record<string, any>> = ({
  id,
  openingTime,
  closingTime,
  saveOpening,
  saveClosing,
  dayNumber,
  resetSave,
}) => {
  const [update, { loading, loaded, error }] = useMutation({
    type: 'update',
    resource: 'OpeningHour',
    payload: { id, data: saveOpening ? { openingTime } : { closingTime } },
  })

  useEffect(() => {
    if (saveOpening || saveClosing) update()
  }, [])

  useEffect(() => {
    if (loaded) {
      const field = saveOpening ? 'saveOpening' : 'saveClosing'
      resetSave(dayNumber, id, field)
    }
  }, [loaded])

  return loading ? (
    <CircularProgress />
  ) : (
    <IconButton disabled>
      {error && <Error />}
      {loaded && !error && <CheckCircle />}
    </IconButton>
  )
}

export default HourUpdater
