import * as React from 'react'
import { cloneElement, useMemo, FC, ReactElement } from 'react'
import PropTypes from 'prop-types'
import { sanitizeListRestProps, Identifier, Sort, Exporter, useListContext, usePermissions } from 'ra-core'
import { ToolbarProps } from '@material-ui/core'

// import TopToolbar from '../layout/TopToolbar'
// import { CreateButton, ExportButton } from '../button'
import { CreateButton, ExportButton, TopToolbar } from 'ra-ui-materialui'

const ListActions: FC<ListActionsProps> = (props) => {
  const { className, exporter, filters, ...rest } = props
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext(props)
  const { loaded, permissions } = usePermissions()

  const hasPermissionToCreate = useMemo((): boolean => {
    if (!hasCreate || !loaded || !permissions || !permissions.permissionsMap) return false
    else if (permissions.permissionsMap[`create${resource}`]) return true
    else return false
  }, [loaded, permissions, hasCreate, resource])

  return useMemo(
    () => (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
        {hasPermissionToCreate && <CreateButton basePath={basePath} />}
        {exporter !== false && (
          <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} />
        )}
      </TopToolbar>
    ),
    [resource, displayedFilters, filterValues, selectedIds, filters, total, hasPermissionToCreate] // eslint-disable-line react-hooks/exhaustive-deps
  )
}

ListActions.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  currentSort: PropTypes.any,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  resource: PropTypes.string,
  onUnselectItems: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  showFilter: PropTypes.func,
  total: PropTypes.number,
}

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
}

interface ListActionsProps extends ToolbarProps {
  currentSort?: Sort
  className?: string
  resource?: string
  filters?: ReactElement<any>
  displayedFilters?: any
  exporter?: Exporter | boolean
  filterValues?: any
  permanentFilter?: any
  hasCreate?: boolean
  basePath?: string
  selectedIds?: Identifier[]
  onUnselectItems?: () => void
  showFilter?: (filterName: string, defaultValue: any) => void
  total?: number
}

export default ListActions
