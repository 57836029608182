import React, { FC, Children, isValidElement, useCallback } from 'react'
import { createPortal } from 'react-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Theme, Fade, useTheme } from '@material-ui/core'
import { HideOnScroll, SaveButton, DeleteButton } from 'react-admin'
import MuiAppBar, { AppBarProps } from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSidebar } from 'ra-core'

const valueOrDefault = (value: any, defaultValue: any): any => (typeof value === 'undefined' ? defaultValue : value)

const useStyles = makeStyles(
  (theme) => ({
    appBar: {
      // backgroundColor: theme.palette.common.white,
    },
    toolbar: {
      paddingRight: 24,
    },
    defaultToolbar: {},
    toolbarContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    defaultSaveButtonIcon: {
      margin: '0px 0px',
    },
    menuButton: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
  }),
  { name: 'RaFormToolbarAppBar' }
)

type Props = AppBarProps & {
  handleSubmitWithRedirect?: any
  handleSubmit?: any
  basePath?: string
  pristine?: boolean
  undoable?: boolean
  record?: any
  saving?: boolean
  redirect?: any
  invalid?: boolean
  submitOnEnter?: boolean
}

const AppBarFormToolbarView: FC<Props> = ({
  className,
  children,
  handleSubmitWithRedirect,
  handleSubmit,
  invalid,
  redirect,
  saving,
  submitOnEnter = true,
  record,
  resource,
  basePath,
  pristine,
  undoable,
  ...rest
}) => {
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen)

  const handleToggleSidebar = useCallback(() => {
    dispatch(toggleSidebar())
  }, [])

  return (
    <Fade in={true} timeout={{ enter: 500, exit: 500 }} appear>
      <MuiAppBar elevation={0} className={clsx(className)} color="secondary" {...rest}>
        <Toolbar color={theme.palette.background.paper} className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleToggleSidebar}
            className={classes.menuButton}
          >
            <MenuIcon
              classes={{
                root: open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed,
              }}
            />
          </IconButton>
          <div className={classes.toolbarContainer}>
            {Children.count(children) === 0 ? (
              <div className={classes.defaultToolbar}>
                {/* {record && typeof record.id !== 'undefined' && (
                    <DeleteButton basePath={basePath} record={record} resource={resource} undoable={undoable} />
                  )} */}
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect || handleSubmit}
                  invalid={invalid}
                  redirect={redirect}
                  variant="text"
                  saving={saving}
                  submitOnEnter={submitOnEnter}
                />
              </div>
            ) : (
              Children.map(children, (button) =>
                button && isValidElement(button)
                  ? React.cloneElement(button, {
                      basePath,
                      handleSubmit: valueOrDefault(button.props.handleSubmit, handleSubmit),
                      handleSubmitWithRedirect: valueOrDefault(
                        button.props.handleSubmitWithRedirect,
                        handleSubmitWithRedirect
                      ),
                      onSave: button.props.onSave,
                      invalid,
                      pristine,
                      record,
                      resource,
                      saving,
                      submitOnEnter: valueOrDefault(button.props.submitOnEnter, submitOnEnter),
                      undoable: valueOrDefault(button.props.undoable, undoable),
                    })
                  : null
              )
            )}
          </div>
        </Toolbar>
      </MuiAppBar>
    </Fade>
  )
}

const AppBarFormToolbar: FC<Props> = (props) => {
  const container = typeof document !== 'undefined' ? document.getElementById('react-admin-sub-app-bar') : null

  if (!container) {
    return null
  }

  return createPortal(<AppBarFormToolbarView {...props} />, container)
}

export default AppBarFormToolbar
