/* eslint-disable @typescript-eslint/camelcase */
import englishMessages from 'ra-language-english'

export default {
  ...englishMessages,
  ra: {
    ...englishMessages.ra,
    boolean: {
      ...englishMessages.ra.boolean,
      null: 'All',
    },
  },
  menu: {
    categories: {
      planning: 'Planning',
      organizations: 'Organizations',
      vehicles: 'Vehicles',
      users: 'Users',
      localization: 'Localization',
      catalog: 'Catalog',
      order: 'Order',
    },
  },
  resources: {
    DrivingSchool: {
      name: 'Driving School |||| Driving Schools',
      fields: {
        name: 'Name',
      },
    },
    Vehicle: {
      name: 'Vehicles',
    },
    Office: {
      name: 'Offices',
    },
    Organization: {
      name: 'Driving Schools',
    },
    OpeningHour: {
      name: 'Opening Hours',
    },
    EventTemplate: {
      name: 'Event Templates',
    },
    EventStatus: {
      name: 'Event Status',
    },
    PlannedEvent: {
      name: 'Calendar Planning',
      invites: {
        success: 'Invites sent successfully',
        failure: 'Failed to sent some invites',
        error: 'Error sending invites',
      },
      fields: {
        customerIds: 'Students',
        teamMemberIds: 'Team members',
      },
    },
    TeamMember: {
      name: 'Istruttore |||| Istruttori',
      fields: {
        firstName: 'First name',
        lastName: 'Last name',
        birthday: 'Birth date',
        calendarColor: 'Calendar color',
        fiscalCode: 'Fiscal code',
      },
    },
  },
  customFields: {
    galleryInput: {
      title: 'Catalog Pictures',
      mobileTitle: 'Mobile Catalog Pictures',
      galleryTitle: 'Product Details Gallery',
      modal: {
        error: 'Error uploading picture',
        success: 'Picture uploaded correctly',
        title: {
          addToGallery: 'Add new to Gallery',
          setCatalog: 'Set Primary Picture',
          setSecondaryCatalog: 'Set Secondary Catalog Picture',
        },
      },
    },
    galleryGrid: {
      setFeaturedImage: 'Set Featured Picture',
      catalogImage: 'Primary',
      catalogSecondaryImage: 'Secondary',
      featured: 'Featured',
      featuredImage: 'Featured',
      addToGallery: 'Add to gallery',
      setCatalogImage: 'Set Catalog Image',
      setCatalogSecondaryImage: 'Set Secondary Image',
    },
    priceSection: {
      title: 'Price',
    },
    discountSection: {
      title: 'Sale',
    },
    orderProductTableField: {
      subtotal: 'Subtotal',
      shippingCost: 'Shipping',
      tax: 'Tax',
      total: 'Total',
    },
    orderCustomerField: {
      // eslint-disable-next-line quotes
      noRaffleParticipations: "User hasn't participated to any raffle yet",
      nbPartecipations: '%{total} raffle partecipation |||| %{total} raffle partecipations',
      noPurchases: 'No purchases',
      nbPurchases: '%{total} purchase |||| %{total} purchases',
      customerAgreeNewsletter: 'Accept newsletter e-mails',
    },
  },
  products: {
    edit: {
      tabs: {
        basicSettings: 'Basic Settings',
        options: 'Options',
        pricing: 'Pricing',
        shipping: 'Shipping',
        combinations: 'Combinations',
      },
    },
  },
}
