import React, { FC } from 'react'
import RAList from '../../components/List'
import { TextField, NumberField, Datagrid, UrlField } from 'react-admin'
import DocumentFilters from './DocumentFilters'

type Props = {
  [x: string]: any
}

const DocumentList: FC<Props> = (props) => (
  <RAList {...props} filters={<DocumentFilters />} filter={{ tag: 'quote_print_template' }}>
    <Datagrid
      rowClick={
        props.permissions && props.permissions.permissionsMap && props.permissions.permissionsMap.updateDocument
          ? 'edit'
          : undefined
      }
    >
      <NumberField source="id" />
      <TextField source="description" />
      <UrlField source="url" />
    </Datagrid>
  </RAList>
)

export default DocumentList
