import React, { FC } from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'
import ProductCategoryInput from '../../components/ProductCategoryInput'

type Props = {}

const ProductCategoryEdit: FC = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      {/* <TextInput multiline fullWidth source="description" /> */}
      <TextInput source="slug" />
      <ProductCategoryInput source="parentId" label="Parent" exclude={props.id} />
    </SimpleForm>
  </Edit>
)

export default ProductCategoryEdit
