import React, { FC } from 'react'
import RAList from '../../components/List'
import { TextField, NumberField, Datagrid, ReferenceField } from 'react-admin'
import EditButtonWPermission from '../../components/EditButtonWPermission'

type Props = {}

const List: FC<Props> = (props) => {
  return (
    <RAList {...props}>
      <Datagrid>
        <NumberField source="id" />
        <TextField source="name" />
        <TextField source="phone" sortable={false} />
        <TextField source="email" sortable={false} />
        <ReferenceField source="organizationId" reference="Organization">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="countryId" reference="Country" sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="stateId" reference="State" sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="city" sortable={false} />
        <TextField source="street" sortable={false} />
        <TextField source="postCode" sortable={false} />
        <EditButtonWPermission {...props} />
      </Datagrid>
    </RAList>
  )
}

export default List
