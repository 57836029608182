import React, { FC } from 'react'
import { useQueryWithStore, ReferenceField, ImageField } from 'react-admin'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'

type Props = {
  orderProductId: string
  isInIterator?: boolean
  hideName?: boolean
  hideImage?: boolean
}

// const imgPlaceholder =
//   'https://scontent.fblq3-1.fna.fbcdn.net/v/t1.0-9/71683794_1585846968223905_5577955511434739712_n.jpg?_nc_cat=111&_nc_sid=85a577&_nc_ohc=rHf_ZESpLuAAX-ifi90&_nc_ht=scontent.fblq3-1.fna&oh=be108ce2439e7f7c8e7e5c6309a762d8&oe=5F986B05'

const useImgStyles = makeStyles({
  image: {
    marginLeft: 0,
    marginRight: 10,
    maxHeight: 66,
  },
})

const useImgStyles2 = makeStyles({
  image: {
    marginLeft: 16,
    maxHeight: 40,
  },
})

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
})

const ProductImageName: FC<Props> = ({
  orderProductId,
  isInIterator = false,
  hideName = false,
  hideImage = false,
}: Props) => {
  const imgClasses = useImgStyles()
  const imgClasses2 = useImgStyles2()
  const classes = useStyles()
  const { loading, error, data } = useQueryWithStore({
    type: 'getOne',
    // resource: isInIterator ? 'Product' : 'OrderProduct',
    resource: 'Product',
    payload: { id: orderProductId },
  })

  if (error) return <span>ERROR</span>
  else {
    if (loading) return <LinearProgress />
    else if (data)
      return (
        <div className={classes.wrapper}>
          <div>
            {!hideImage && data.catalogPictureId ? (
              <ReferenceField
                basePath="/Product"
                resource="Product"
                record={data}
                label="Picture"
                source="catalogPictureId"
                reference="Picture"
                sortable={false}
                link={false}
              >
                <ImageField classes={isInIterator ? imgClasses2 : imgClasses} source="urlSmall" />
              </ReferenceField>
            ) : (
              <span />
            )}
          </div>
          {!hideName && <div>{data.name}</div>}
        </div>
      )
    else return null
  }
}

export default ProductImageName
