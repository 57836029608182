import React, { FC } from 'react'
import Filter from '../../components/Filter'
import { TextInput, NumberInput, NullableBooleanInput, ReferenceInput, AutocompleteInput } from 'react-admin'

type Props = {}

const ProductFilters: FC<Props> = (props) => {
  return (
    <Filter {...props}>
      <TextInput source="slug" />
      <TextInput source="barcode" alwaysOn />
      <NumberInput source="id" />
      <TextInput source="name" alwaysOn />
      <TextInput source="sku" />
      {/* <ReferenceInput source="brandIds" reference="Brand" filterToQuery={(name: string): any => ({ name })} alwaysOn>
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput> */}
      <ReferenceInput
        source="categoryIds"
        reference="ProductCategory"
        filterToQuery={(name: string): any => ({ name })}
        alwaysOn
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <NullableBooleanInput source="active" displayNull />
    </Filter>
  )
}

export default ProductFilters
