import { IconButtonTypeMap } from '@material-ui/core/IconButton'
import { OverrideProps } from '@material-ui/core/OverridableComponent'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { Calendar, useStaticState } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

type Props = {
  value: Date
  onChange: (date: MaterialUiPickersDate) => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  smallIcon: {
    width: '0.965rem',
    height: '0.965rem',
  },
}))

const MonthlyPreviewPicker: FC<Props> = ({ value, onChange }) => {
  const classes = useStyles()
  useSelector((state: any) => state.admin.ui.sidebarOpen) // need re-render on sidebar open/close

  const { pickerProps, wrapperProps } = useStaticState({
    value,
    onChange,
  })

  const leftArrowButtonProps: Partial<OverrideProps<IconButtonTypeMap, 'button'>> = useMemo(
    () => ({
      size: 'small',
      className: classes.smallIcon,
    }),
    []
  )

  const rightArrowButtonProps: Partial<OverrideProps<IconButtonTypeMap, 'button'>> = useMemo(
    () => ({
      size: 'small',
      className: classes.smallIcon,
    }),
    []
  )

  return (
    <div className={classes.root}>
      <Calendar
        {...pickerProps}
        leftArrowButtonProps={leftArrowButtonProps}
        rightArrowButtonProps={rightArrowButtonProps}
      />
    </div>
  )
}

export default MonthlyPreviewPicker
