import React, { FC } from 'react'
import { Datagrid, TextField, ReferenceField, NumberField, useTranslate } from 'react-admin'
import EditButtonWPermission from '../../components/EditButtonWPermission'
import RAList from '../../components/List'

type Props = {}

const AddressList: FC<Props> = (props) => {
  const translate = useTranslate()
  return (
    <RAList {...props}>
      <Datagrid>
        <NumberField source="id" />
        <ReferenceField source="customerId" reference="Customer" label={translate('resources.Address.fields.customer')}>
          <TextField source="email" />
        </ReferenceField>
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="street" />
        <TextField source="postCode" />
        <TextField source="city" />
        <ReferenceField source="countryId" reference="Country" label={translate('resources.Address.fields.country')}>
          <TextField source="name" />
        </ReferenceField>
        <EditButtonWPermission {...props} />
      </Datagrid>
    </RAList>
  )
}

export default AddressList
