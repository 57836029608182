import React, { FC, useMemo } from 'react'
import { ReferenceField, TextField } from 'react-admin'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { useQuery } from '@apollo/client'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { DateField } from 'react-admin'
import { GET_CUSTOMER_CONFIRMED_EVENTS } from '../Order/OrderProductsTable'

type Props = {
  id: string
}

const PlannedEventRow: FC<Props> = ({ id }: Props) => {
  const { data } = useQuery(GET_CUSTOMER_CONFIRMED_EVENTS, { variables: { filters: { id } } })

  const record = useMemo(
    () => (data ? { ...data.plannedEvents.data[0], teamMemberId: data.plannedEvents.data[0].teamMemberIds[0] } : null),
    [data]
  )

  return record ? (
    <TableRow>
      <TableCell>
        {record.dsEventType === 'DEFAULT' ? 'Guida del: ' : `${record?.event?.title} - `}
        <DateField record={record} source="event.startDate" showTime variant="h6" />
      </TableCell>
      <TableCell>
        {`Durata: ${differenceInMinutes(new Date(record.event.endDate), new Date(record.event.startDate))} min`}
      </TableCell>
      {record.dsEventType === 'DEFAULT' && (
        <TableCell>
          {'Istruttore: '}
          <ReferenceField
            link={false}
            record={record}
            reference="TeamMember"
            source="teamMemberId"
            basePath="/TeamMember"
          >
            <TextField source="fullName" variant="h6" />
          </ReferenceField>
        </TableCell>
      )}
      {record.dsEventType === 'DEFAULT' && (
        <TableCell>
          {'Targa: '}
          <ReferenceField link={false} record={record} reference="Vehicle" source="vehicleId" basePath="/Vehicle">
            <TextField source="licensePlate" variant="h6" />
          </ReferenceField>
        </TableCell>
      )}
    </TableRow>
  ) : null
}

export default PlannedEventRow
