import React, { FC } from 'react'
import { useGetOne } from 'ra-core'
import { LinearProgress } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'

type Props = {
  record?: Record<string, any>
  source?: string
  valueSource?: string
  label?: string
}

const UserField: FC<Props> = ({ record = {}, source = 'accountId', valueSource = 'fullName', label }) => {
  const { loading, error, data } = useGetOne('User', record[source])

  if (!record[source]) return 'N.D.'
  if (loading) return <LinearProgress />
  if (error) return <ErrorIcon color="error" />
  if (data) return data[valueSource]
  return null
}

export default UserField
