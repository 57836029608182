import React, { FC, useCallback } from 'react'
import { NumberInput, TextInput } from 'react-admin'
import Filter from '../../components/Filter'

type Props = {}

const DocumentFilters: FC<Props> = (props) => {
  return (
    <Filter {...props}>
      <NumberInput source="id" />
      <TextInput source="description" alwaysOn />
    </Filter>
  )
}

export default DocumentFilters
