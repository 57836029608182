import { useState, useEffect } from 'react'
import { useQueryWithStore } from 'react-admin'

const useOrganization = (): Record<string, any> => {
  const [organizationId, setOrganizationId] = useState(null)
  const [loading, setLoading] = useState<boolean>(true)
  const { loading: loadingQuery, error, data } = useQueryWithStore({
    type: 'getList',
    resource: 'Organization',
    payload: {
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'id', order: 'DESC' },
      filter: {},
    },
  })

  useEffect(() => {
    if (!loadingQuery && !error && data && data.length > 0) {
      // console.log('useORganizationHOOK: ', data)
      setOrganizationId(data[0].id)
      setLoading(false)
    }
  }, [loadingQuery, error, data])

  return { loading, error, organizationId }
}

export default useOrganization
