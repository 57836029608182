import React, { FC } from 'react'
import { TextField } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/Edit'
import CourseForm from './CourseForm'

type Props = {
  //
}

const CourseEdit: FC<Props> = (props) => (
  <Edit {...props} component="div">
    <SectionedForm>
      <FormSection title="ID">
        <TextField source="id" addLabel={false} />
      </FormSection>
      <CourseForm />
    </SectionedForm>
  </Edit>
)

export default CourseEdit
