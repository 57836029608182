import React, { cloneElement, FC, useMemo } from 'react'
import { TextField, EditContextProvider } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/Edit'
import QuoteTemplateForm from './QuoteTemplateForm'
import { useEditController } from 'ra-core'

type Props = {}

const transform = (data: any): any => ({
  name: data.name,
  printTemplateId: data.printTemplateId,
  content: { quoteItems: data.quoteItems },
})

const QuoteTemplateEditController: FC<any> = (props) => {
  const controllerProps = useEditController(props)
  const {
    basePath, // deduced from the location, useful for action buttons
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    loaded, // boolean that is false until the record is available
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
    redirect, // the default redirection route. Defaults to 'list'
    resource, // the resource name, deduced from the location. e.g. 'posts'
    save, // the update callback, to be passed to the underlying form as submit handler
    saving, // boolean that becomes true when the dataProvider is called to update the record
    version, // integer used by the refresh feature
  } = controllerProps

  const derivatedRecord = useMemo(() => {
    if (record) {
      return {
        ...record,
        quoteItems: record.content.quoteItems,
      }
    }

    return record
  }, [record])

  return (
    <div>
      {/* <h1>{defaultTitle}</h1> */}
      {cloneElement(props.children, {
        basePath,
        record: derivatedRecord,
        redirect,
        resource,
        save,
        saving,
        version,
      })}
    </div>
  )
}

const DocumentEdit: FC<Props> = (props) => (
  <QuoteTemplateEditController {...props} transform={transform} component="div">
    <SectionedForm>
      <FormSection title="ID">
        <TextField source="id" addLabel={false} />
      </FormSection>
      <QuoteTemplateForm />
    </SectionedForm>
  </QuoteTemplateEditController>
)

export default DocumentEdit
