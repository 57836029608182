import React, { FC } from 'react'
import { TextField } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/Edit'
import CurrencyForm from './CurrencyForm'

type Props = {}

const CurrencyEdit: FC<Props> = props => (
  <Edit {...props} component="div">
    <SectionedForm variant="outlined">
      <FormSection title="ID">
        <TextField source="id" addLabel={false} />
      </FormSection>
      <CurrencyForm />
    </SectionedForm>
  </Edit>
)

export default CurrencyEdit
