import React, { FC } from 'react'
import { Create } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
// import Create from '../../components/details/Create'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'
// import SwitchCardInput from '../../components/SwitchCardInput'
// import { RadioButtonGroupInput } from 'react-admin'
import FormSwitchInput from '../../components/forms/FormSwitchInput'
import HiddenOrganizationField from '../../components/forms/HiddenOrganizationField'

type Props = {
  [x: string]: any
}

const RoleCreate: FC<Props> = (props) => {
  return (
    <Create {...props} component="div">
      <SectionedForm variant="outlined" redirect="show" title="Nuovo Ruolo">
        <FormSection title="Ruolo">
          <FormTextInput {...props} source="name" helperText="Nome del ruolo" />
        </FormSection>
        <FormSection title="Ruolo Pubblico">
          <FormSwitchInput
            {...props}
            source="isPublic"
            helperText="questo ruolo viene assegnato automaticamente agli utenti non loggati"
          />
        </FormSection>
        <FormSection title="Super Amministratore">
          <FormSwitchInput
            {...props}
            source="isAdmin"
            helperText="questo ruolo riceve accesso automatico a tutti i permessi"
          />
        </FormSection>
        <FormSection title="Ruolo Bloccato">
          <FormSwitchInput {...props} source="isLocked" helperText="questo ruolo non può essere eliminato" />
        </FormSection>
      </SectionedForm>
    </Create>
  )
}

export default RoleCreate
