import React, { FC } from 'react'
import { TextInput, ReferenceArrayInput, SelectArrayInput, ReferenceInput, AutocompleteInput } from 'react-admin'
import FormSection from './../../components/forms/FormSection'
import HiddenOrganizationField from './../../components/forms/HiddenOrganizationField'
import InputColor from './../../components/InputColor'

type Props = {
  [x: string]: any
}

const filterToQuery = (name: string): Record<string, string> => ({ name })

// TODO: add association with addresses
const TeamMemberForm: FC<Props> = (props) => (
  <FormSection title="Dettaglio dipendente" {...props}>
    <HiddenOrganizationField />
    <ReferenceInput source="officeId" reference="Office" filterToQuery={filterToQuery}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <TextInput source="firstName" fullWidth />
    <TextInput source="lastName" fullWidth />
    <TextInput source="email" fullWidth />
    <TextInput source="phone" fullWidth />
    <TextInput source="fax" fullWidth />
    <TextInput source="pec" fullWidth />
    <TextInput source="fiscalCode" fullWidth />
    <TextInput source="telegramId" fullWidth />
    <TextInput source="note" fullWidth multiline />
    <ReferenceArrayInput
      parse={(v: any): any => {
        return v.map((id: any): any => ({
          id,
        }))
      }}
      format={(v: any): any => {
        if (v) {
          return v.map((item: any) => item.id)
        }
        return []
      }}
      reference="Vehicle"
      source="vehicles"
    >
      <SelectArrayInput optionText="licensePlate" />
    </ReferenceArrayInput>
    <InputColor source="calendarColor" onTop={props.record && Object.keys(props.record).length > 1 ? false : true} />
    <InputColor source="holidayCalendarColor" onTop label="Colore per ferie / pausa" />
  </FormSection>
)

export default TeamMemberForm
