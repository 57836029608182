import React, { FC } from 'react'
import Edit from '../../components/Edit'
import ProductForm from './ProductForm'

type Props = {}

const ProductEdit: FC<Props> = props => {
  return (
    <Edit {...props}>
      <ProductForm {...props} />
    </Edit>
  )
}

export default ProductEdit
