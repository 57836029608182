import React, { FC, useEffect } from 'react'
import { useForm } from 'react-final-form'
import useOrganization from './../../hooks/useOrganization'

const HiddenOrganizationField: FC = (): null => {
  const form = useForm()
  const { organizationId } = useOrganization()

  useEffect(() => {
    if (organizationId) {
      form.change('organizationId', organizationId)
    }
  }, [organizationId])

  return null
}

export default HiddenOrganizationField
