import React, { FC, useCallback } from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { PriceField } from './../../components/CustomFields'
import SummaryCard from './../../components/SummaryCard'
import CloseAccountingCardButton from './CloseAccountingCardButton'
import ReopenAccountingCardButton from './ReopenAccountingCardButton'

type Props = {
  record: {
    id: any
    totalAmount: string
    paidAmount: string
    remainingAmount: string
    isClosed: boolean
  }
}

const AmountSection: FC<Props> = ({ record }: Props) => {
  const classes = useStyles()

  const renderTotalSummary = useCallback(() => {
    return (
      <div>
        <div>
          <Typography className={classes.fieldLabel}>Da pagare:</Typography>
          <PriceField variant="h5" source="remainingAmount" record={record} color="red" />
        </div>
        <div>
          <Typography className={classes.fieldLabel}>Totale:</Typography>
          <PriceField variant="h5" source="totalAmount" record={record} />
        </div>
        <div>
          <Typography className={classes.fieldLabel}>Pagato:</Typography>
          <PriceField variant="h5" source="paidAmount" record={record} color="green" />
        </div>
        {record.isClosed && (
          <div>
            <Typography className={classes.fieldLabel}>Sconto chiusura:</Typography>
            <PriceField variant="h5" source="discountAmount" record={record} />
          </div>
        )}
        {record.isClosed ? (
          <ReopenAccountingCardButton record={record} />
        ) : (
          <CloseAccountingCardButton record={record} />
        )}
      </div>
    )
  }, [record])

  return <SummaryCard title="Resoconto" render={renderTotalSummary} />
}

const useStyles = makeStyles((theme) => ({
  fieldLabel: {
    display: 'inline',
    marginRight: 8,
  },
}))

export default AmountSection
