import React, { FC, useEffect, useState, useCallback, useMemo } from 'react'
import { useInput, InputProps, useQuery, useDataProvider, useNotify } from 'ra-core'
import { Labeled } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { ColorInput } from 'react-admin-color-input'
import { InputAdornment } from '@material-ui/core'

const useStyles = makeStyles({
  colorInput: (values: any): any => ({
    '&:after': {
      // eslint-disable-next-line quotes
      content: "''",
      position: 'absolute',
      bottom: 7,
      right: 2,
      width: 20,
      height: 20,
      background: 'red',
      pointerEvents: 'none',
      backgroundColor: values.color,
    },
  }),
})

const AttributeValueColorInput: FC<InputProps> = (props) => {
  // const {} = useForm()
  const { values } = useFormState()
  const styleProps = useMemo(() => values || {}, [values])
  const classes = useStyles(styleProps)
  const dataProvider = useDataProvider()
  const [productAttribute, setProductAttribute] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const notify = useNotify()

  useEffect(() => {
    const fetchProductAttribute = async (): Promise<void> => {
      try {
        setLoading(true)
        const { data } = await dataProvider.getOne('ProductAttribute', { id: values.productAttributeId })
        setProductAttribute(data)
      } catch (e) {
        notify('Error fetching ProductAttribute', 'error')
      }
      setLoading(false)
    }

    if (values.productAttributeId) {
      fetchProductAttribute()
    }
  }, [values.productAttributeId])

  // if (loading && values.productAttributeId) {
  //   return <div>Loading...</div>
  // }

  if (!loading && productAttribute && productAttribute.type === 'COLOR_OR_TEXTURE') {
    return <ColorInput className={classes.colorInput} source={props.source} picker="Sketch" />
  }

  return null
}

export default AttributeValueColorInput
