import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDataProvider, useGetList } from 'ra-core'
import {
  Loading,
  ReferenceField,
  DateField,
  TextField,
  FunctionField,
  ReferenceManyField,
  SingleFieldList,
} from 'react-admin'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import UserPreviewField from './UserPreviewField'
import moment from 'moment'
import CustomerAvatarField from './CustomerAvatarField'

type Props = {
  record?: any
  onDirtyChange?: (value: boolean) => void
  onChange?: (values: any) => void
}

const CourseAttendanceRegisterForm: FC<Props> = ({ record, onDirtyChange, onChange }) => {
  const dataProvider = useDataProvider()
  const [error, setError] = useState()
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<any[] | undefined>()
  const [dirty, setDirty] = useState<boolean>(false)
  const [tempRegistryMap, setTempRegistryMap] = useState<any>({})
  // const { loading, data, ids, error } = useGetList(
  //   'CustomerCoursePlanning',
  //   { perPage: 1000, page: 1 },
  //   { field: 'id', order: 'ASC' },
  //   {}
  // )

  const onRegistryChange = useCallback(
    (customerId: string) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      if (!dirty) {
        setDirty(true)
        if (onDirtyChange) {
          onDirtyChange(true)
        }
      }

      setTempRegistryMap({
        ...tempRegistryMap,
        [customerId]: checked,
      })
      if (onChange) {
        onChange({
          ...tempRegistryMap,
          [customerId]: checked,
        })
      }
    },
    [tempRegistryMap, onDirtyChange]
  )

  useEffect(() => {
    const initData = async (): Promise<void> => {
      try {
        setLoading(true)
        const customerCoursePlannings = await dataProvider.getList('CustomerCoursePlanning', {
          filter: {
            coursePlanningId: record.coursePlanningId,
          },
          pagination: { perPage: 1000, page: 1 },
          sort: { field: 'id', order: 'ASC' },
        })

        const customers = await dataProvider.getMany('Customer', {
          ids: customerCoursePlannings.data.map(({ customerId }: any) => customerId),
        })

        const customersMap: any = customers.data.reduce((acc, item) => {
          return {
            ...acc,
            [item.id]: item,
          }
        }, {})
        const finalResult = customerCoursePlannings.data
          .map((item, index) => {
            return {
              ...item,
              customer: customersMap[item.customerId],
            }
          })
          .sort((a: any, b: any): any => {
            if (!a.customer || !b.customer) {
              return -1
            }
            const nameA = a.customer.lastName.toUpperCase() // ignora maiuscole e minuscole
            const nameB = b.customer.lastName.toUpperCase() // ignora maiuscole e minuscole
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
          })

        setTempRegistryMap(
          finalResult.reduce((acc, item) => {
            return {
              ...acc,
              [item.customer.id]: false,
            }
          }, {})
        )
        console.log(finalResult, 'ciaciascas')
        setData(finalResult)
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    }

    if (record) {
      initData()
    }
  }, [record])

  if (error) {
    return (
      <Typography variant="body2" color="error">
        {JSON.stringify(error)}
      </Typography>
    )
  }

  if (loading || !data) {
    return <Loading />
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Presente/Assente</TableCell>
              <TableCell>Studente</TableCell>
              <TableCell>Sede</TableCell>
              <TableCell>Età</TableCell>
              <TableCell>Data creazione ordine</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: any, index) => {
              return (
                <TableRow key={`customer-planning-${item.id}`}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      onChange={onRegistryChange(item.customer.id)}
                      checked={tempRegistryMap[item.customer.id]}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <UserPreviewField record={item.customer} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.customer && item.customer.subscriptionOfficeId ? (
                      <ReferenceField
                        basePath="/CoursePlanning"
                        resource="CoursePlanning"
                        record={item.customer}
                        link={false}
                        reference="Office"
                        source="subscriptionOfficeId"
                      >
                        <TextField source="name" />
                      </ReferenceField>
                    ) : (
                      'N.D.'
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.customer && item.customer.birthday
                      ? moment().diff(moment(item.customer.birthday), 'years', false)
                      : 'N.D.'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.customer && item.customer.id ? (
                      <ReferenceManyField
                        linkType={false}
                        basePath="/CoursePlanning"
                        resource="CoursePlanning"
                        record={item.customer}
                        reference="Order"
                        target="userId"
                      >
                        <SingleFieldList>
                          <DateField source="createdAt" />
                        </SingleFieldList>
                      </ReferenceManyField>
                    ) : (
                      'N.D.'
                    )}
                  </TableCell>
                  {/* <TableCell component="th" scope="row">
                    {item.customer && item.customer.mainReferenceTeamMemberId ? (
                      <ReferenceField
                        basePath="/CoursePlanning"
                        resource="CoursePlanning"
                        record={item.customer}
                        link={false}
                        reference="TeamMember"
                        source="mainReferenceTeamMemberId"
                      >
                        <UserPreviewField />
                      </ReferenceField>
                    ) : (
                      'N.D.'
                    )}
                  </TableCell> */}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default CourseAttendanceRegisterForm
