import React, { FC, useCallback, useState } from 'react'
import Button, { ButtonProps } from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconCancel from '@material-ui/icons/Cancel'
import { useTranslate, useCreate, useNotify, useRefresh } from 'ra-core'
import { FormWithRedirect, SaveButton, Button as RAButton, ImageInput, ImageField } from 'react-admin'
import { useForm } from 'react-final-form'

type Props = {
  label?: string
  onChange?: (data: any) => void
} & ButtonProps

const UploadPictureButton: FC<Props> = ({ label = 'uploadPictureButton.label', onChange, ...rest }) => {
  const translate = useTranslate()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [create, { loading }] = useCreate('Picture')
  const notify = useNotify()
  const refresh = useRefresh()

  const handleModalOpen = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleSubmit = useCallback((values: any) => {
    console.log(values)
    create(
      {
        payload: {
          data: values,
        },
      },
      {
        onSuccess: ({ data }) => {
          setIsModalOpen(false)
          if (onChange) {
            onChange(data)
          } else {
            refresh()
          }
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error')
        },
      }
    )
  }, [])

  return (
    <>
      <Button {...rest} onClick={handleModalOpen}>
        {translate(label)}
      </Button>

      <Dialog open={isModalOpen} onClose={handleModalClose}>
        <DialogTitle>{translate(label)}</DialogTitle>
        <FormWithRedirect
          resource="Picture"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }: any): React.ReactNode => (
            <>
              <DialogContent>
                <ImageInput multiple={false} source="file" accept="image/*">
                  <ImageField source="src" title="title" />
                </ImageInput>
              </DialogContent>
              <DialogActions>
                <RAButton label="ra.action.cancel" onClick={handleModalClose} disabled={loading}>
                  <IconCancel />
                </RAButton>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}

export default UploadPictureButton
