import { useTranslate } from 'ra-core'
import React, { FC, useCallback } from 'react'
import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
} from 'react-admin'
import FormSection from '../../components/forms/FormSection'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import CreateQuoteItem from './CreateQuoteItem'
import QuoteSummary from '../Quote/QuoteSummary'
import BaseButton from '@material-ui/core/Button'
import ContentAdd from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

type Props = {}

const useStyles = makeStyles({
  form: {
    display: 'flex',
    alignItems: 'baseline',
  },
  line: {
    display: 'flex',
    alignItems: 'stretch',
    alignContent: 'center',
    borderBottom: 0,
  },
})

const filterToQueryPrintTemplate = (description: string): Record<string, string> => ({ description })
const sortPrintTemplate = { field: 'description', order: 'ASC' }

const QuoteTemplateForm: FC<Props> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const { values } = useFormState()

  return (
    <>
      <FormSection
        title={translate('resources.QuoteTemplate.sections.base')}
        subtitle="Informazioni di base del modello"
        {...props}
      >
        {/* <HiddenDocumentTagField /> */}
        <TextInput source="name" fullWidth />
      </FormSection>

      <FormSection
        title="Template di stampa"
        subtitle="Scegli quale template Word (.docx) verrà utilizzato nel preventivo"
        {...props}
      >
        <ReferenceInput
          label="Template di stampa"
          reference="Document"
          source="printTemplateId"
          filterToQuery={filterToQueryPrintTemplate}
          filter={{ tag: 'quote_print_template' }}
          sort={sortPrintTemplate}
        >
          <AutocompleteInput optionText="description" />
        </ReferenceInput>
      </FormSection>

      <FormSection
        title="Prodotti"
        subtitle="Gestisci i prodotti che verranno importati nel preventivo"
        fullWidth
        {...props}
      >
        <ArrayInput source="quoteItems" label={false}>
          <SimpleFormIterator
            classes={classes}
            addButton={
              <BaseButton startIcon={<ContentAdd />} color="primary" variant="contained">
                {'AGGIUNGI'}
              </BaseButton>
            }
            removeButton={
              <BaseButton startIcon={<DeleteOutlineIcon />} color="primary" variant="contained">
                {'RIMUOVI'}
              </BaseButton>
            }
          >
            <FormDataConsumer>{(formDataProps: any): any => <CreateQuoteItem {...formDataProps} />}</FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </FormSection>

      <FormSection title="Riepilogo">
        <QuoteSummary />
      </FormSection>
    </>
  )
}

export default QuoteTemplateForm
