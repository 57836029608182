import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/Create'
import TaxForm from './EventTemplateForm'

type Props = {}

const EventTemplateCreate: FC<Props> = (props) => {
  // const transform = (data: any) => ({
  //   ...data,
  //   additionalData: {
  //     backgroundColor: data.backgroundColor,
  //   },
  // })

  return (
    <Create {...props} component="div">
      <SectionedForm variant="outlined" redirect="list">
        <TaxForm {...props} />
      </SectionedForm>
    </Create>
  )
}

export default EventTemplateCreate
