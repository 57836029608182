import React, { FC, useEffect } from 'react'
import { useMutation } from 'react-admin'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import { DeleteForever, Error } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

const HourDeleter: FC<Record<string, any>> = ({ id, confirmAction }) => {
  const classes = useStyles()
  const [onClick, { loading, loaded, error }] = useMutation({
    type: 'delete',
    resource: 'WorkingHour',
    payload: { id },
  })

  useEffect(() => {
    if (!loading && loaded) {
      confirmAction()
    }
  }, [loading, loaded])

  return loading ? (
    <CircularProgress />
  ) : (
    <IconButton {...{ onClick }} className={classes.button}>
      {error ? <Error /> : <DeleteForever />}
    </IconButton>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    alignSelf: 'center',
    color: theme.palette.error.dark,
    borderRadius: 6,
    padding: theme.spacing(1),
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
}))

export default HourDeleter
