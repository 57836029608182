import React, { FC } from 'react'
import RAList from '../../components/List'
import { TextField, NumberField, Datagrid, ReferenceField, BooleanField, DateField, useTranslate } from 'react-admin'
import QuoteFilters from './QuoteFilters'

type Props = {
  [x: string]: any
}

const options = { style: 'currency', currency: 'EUR' }
const sort = { field: 'createdAt', order: 'DESC' }

const OrderList: FC<Props> = (props) => {
  const translate = useTranslate()
  return (
    <RAList {...props} filters={<QuoteFilters />} {...{ sort }}>
      <Datagrid
        rowClick={
          props.permissions && props.permissions.permissionsMap && props.permissions.permissionsMap.updateQuote
            ? 'edit'
            : undefined
        }
      >
        <NumberField source="id" />
        <TextField source="reference" />
        <DateField source="createdAt" />
        <ReferenceField
          source="customerId"
          link="show"
          reference="Customer"
          label={translate('resources.Quote.fields.customer')}
        >
          <TextField source="fullName" />
        </ReferenceField>
        <NumberField source="totalAmount" locales="it-IT" {...{ options }} />
        <BooleanField source="approved" />
      </Datagrid>
    </RAList>
  )
}

export default OrderList
