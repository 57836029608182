import React, { FC, useState, useCallback, useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { useDataProvider, useGetList } from 'ra-core'
import { GET_CUSTOMER_CONFIRMED_EVENTS } from '../Order/OrderProductsTable'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { QUERY_GET_EVENT_STATUSES_CONFIGURATION } from '../../queries'
import addYears from 'date-fns/addYears'
import { AccountingCardTransaction } from './SimpleTransactionRow'
import { SelectArrayInput, SelectInput } from 'react-admin'
import LinearProgress from '@material-ui/core/LinearProgress'

type Props = {
  record: Record<string, any>
  isPayment: boolean
  trainingDuration: number
  selectedId?: string
  selectedIds?: string[]
  isEdit: boolean
  formChange: <F extends string>(name: F, value?: any) => void
  transactionId?: string // only in edit mode
}

const SelectDriveTrainingsInput: FC<Props> = ({
  record,
  isPayment,
  trainingDuration,
  selectedId,
  selectedIds,
  isEdit,
  formChange,
  transactionId,
}: Props) => {
  const client = useApolloClient()
  const dataProvider = useDataProvider()
  const [choices, setChoices] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [allTrainings, setAllTrainings] = useState<any[]>([])
  const { loading: loadingTranz, data, ids, error, total } = useGetList<AccountingCardTransaction>(
    'AccountingCardTransaction',
    { perPage: 1000, page: 1 },
    { field: 'date', order: 'DESC' },
    { accountingCardId: record.id, type: isPayment ? 'INCOME' : 'OUTCOME' }
  )
  const { loading: loadingVehicles, data: dataVehicles } = useGetList(
    'Vehicle',
    { perPage: 1000, page: 1 },
    { field: 'id', order: 'DESC' },
    {}
  )
  const { loading: loadingTeamMembers, data: dataTeamMembers } = useGetList(
    'TeamMember',
    { perPage: 1000, page: 1 },
    { field: 'id', order: 'DESC' },
    {}
  )

  const getTrainings = useCallback(async (): Promise<void> => {
    try {
      let confirmedStatuses: any[] = []
      const allDrivingTrainings: any[] = []
      let alreadySelectedTrainings: any[] = []

      // step 1: getting confirmed statuses
      const configurationResult = await client.query({ query: QUERY_GET_EVENT_STATUSES_CONFIGURATION })
      if (
        configurationResult?.data?.getConfiguration?.value &&
        configurationResult.data.getConfiguration.value.confirmed &&
        configurationResult.data.getConfiguration.value.confirmed.length > 0
      ) {
        // setConfirmedStatusIds(configurationResult.data.getConfiguration.value.confirmed)
        const statusesResult = await dataProvider.getMany('EventStatus', {
          ids: configurationResult.data.getConfiguration.value.confirmed,
        })

        if (statusesResult?.data) {
          confirmedStatuses = [...statusesResult.data]
        }
      }

      // step 2: getting customer's events
      const { data: resp } = await client.query({
        query: GET_CUSTOMER_CONFIRMED_EVENTS,
        variables: {
          filters: {
            customerIds: [record.customerId],
            dateRange: {
              startDate: record.openingDate,
              endDate: record.expiringDate
                ? record.expiringDate
                : addYears(new Date(record.openingDate), 1).toISOString(),
            },
          },
        },
        fetchPolicy: 'no-cache',
      })

      // step 3: filtering events for confirmed and vehicleId teamMemberIds not NULL
      if (resp && resp.plannedEvents && resp.plannedEvents.data && resp.plannedEvents.data.length > 0) {
        // get all vehicles and teammembers
        resp.plannedEvents.data.forEach((eventPlanned: any) => {
          if (
            eventPlanned.vehicleId &&
            eventPlanned.customerEvents.length > 0 &&
            eventPlanned.teamMemberIds &&
            eventPlanned.teamMemberIds.length > 0
          ) {
            // taking only confirmed driving trainings
            const confirmed: any[] = eventPlanned.customerEvents.filter((xxx: any) => {
              if (xxx.customerId === record.customerId) {
                return !!confirmedStatuses.find((item: any) => item.id === xxx.statusId)
              }
              return false
              // return xxx.statusId === confirmedStatusId && xxx.customerId === record.customerId
            })

            const foundedStatus = confirmedStatuses.find((item: any) => {
              if (eventPlanned?.customerEvents?.length > 0) {
                return eventPlanned.customerEvents[0].statusId === item.id
              }
              return false
            })

            if (confirmed.length > 0) {
              const duration = differenceInMinutes(
                new Date(eventPlanned.event.endDate),
                new Date(eventPlanned.event.startDate)
              )
              const fullEvent = {
                id: eventPlanned.id,
                combinedId: `${eventPlanned.id}-${duration}`,
                startDate: eventPlanned.event.startDate,
                endDate: eventPlanned.event.endDate,
                vehicleId: eventPlanned.vehicleId,
                status: foundedStatus,
                teamMemberId: eventPlanned.teamMemberIds[0],
                // status: confirmedStatuses.find((item: any) => item.id === item.customerEvents[0].statusId),
                duration,
                label: `${new Date(eventPlanned.event.startDate).toLocaleString()} - ${duration} min - ${
                  dataVehicles![eventPlanned.vehicleId].licensePlate
                } - ${dataTeamMembers![eventPlanned.teamMemberIds[0]].fullName}`,
              }
              allDrivingTrainings.push(fullEvent)
            }
          }
        })
        setAllTrainings(allDrivingTrainings)

        // getting already used plannedEvents
        if (!loadingTranz && ids && ids.length > 0) {
          const accountingCardTransactionIds =
            isEdit && transactionId ? ids.filter((elem: any) => elem !== transactionId) : ids
          const existing = await dataProvider.getList('TransactionPlannedEvent', {
            filter: { accountingCardTransactionIds },
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'id', order: 'DESC' },
          })
          if (existing && existing.data) {
            alreadySelectedTrainings = [...existing.data.map((elem: any) => elem.plannedEventId)]
          }
        }

        const final = isPayment
          ? allDrivingTrainings
          : allDrivingTrainings.filter((elem: any) => {
              return !alreadySelectedTrainings.includes(elem.id)
            })
        setChoices(final)
        setLoading(false)
      }
    } catch (error) {
      console.error('getTrainings error: ', error)
    }
  }, [ids, loadingTranz, loadingTeamMembers, loadingVehicles])

  useEffect(() => {
    if (!loadingTranz && !loadingVehicles && !loadingTeamMembers) {
      getTrainings()
    }
  }, [loadingTranz, loadingVehicles, loadingTeamMembers])

  useEffect(() => {
    if (selectedIds && selectedIds.length > 0 && allTrainings && allTrainings.length > 0) {
      const newIds: string[] = []
      for (let i = 0; i < selectedIds.length; i++) {
        const planned = allTrainings.filter((elem) => elem.id === selectedIds[i])[0]
        newIds.push(planned.combinedId)
      }
      formChange('plannedEventIds', newIds)
    }

    if (selectedId && allTrainings && allTrainings.length > 0) {
      const newId = allTrainings.filter((elem) => elem.id === selectedId)[0].combinedId
      formChange('plannedEventId', newId)
    }
  }, [allTrainings])

  if (loading) return <LinearProgress />
  else if (trainingDuration > 90)
    return (
      <SelectArrayInput
        source="plannedEventIds"
        choices={choices}
        optionText="label"
        optionValue={isPayment ? 'id' : 'combinedId'}
        variant="outlined"
        label="Guide associate"
        helperText={choices.length === 0 ? 'Nessuna guida disponibile' : false}
        disabled={isPayment || choices.length === 0}
        fullWidth
      />
    )
  else
    return (
      <SelectInput
        source="plannedEventId"
        choices={choices}
        optionText="label"
        optionValue="combinedId"
        variant="outlined"
        label="Guida associata"
        disabled={isPayment || choices.length === 0}
        helperText={choices.length === 0 ? 'Nessuna guida disponibile' : false}
        fullWidth
      />
    )
}

export default SelectDriveTrainingsInput
