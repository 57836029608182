import React, { FC } from 'react'
import { useGetMany } from 'ra-core'
import { TextField } from 'react-admin'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'
import ErrorIcon from '@material-ui/icons/Error'

type Props = {
  source?: string
  record?: Record<string, any>
  contactType: 'sms' | 'email' | 'telegram'
}

const CustomerContactsField: FC<Props> = ({ source = 'contactsIds', record = {}, contactType }) => {
  const classes = useStyles()
  const { data, loading, error } = useGetMany('CustomerContact', record[source])

  if (loading) return <LinearProgress />
  if (error) return <ErrorIcon />
  if (data) {
    return (
      <span>
        {data
          .filter((contact: Record<string, any>) => contact.contactType === contactType)
          .map((record: Record<string, any>) => (
            <TextField key={record.id} record={record} source="contact" label={false} className={classes.field} />
          ))}
      </span>
    )
  }
  return null
}

const useStyles = makeStyles((theme) => ({
  field: {
    marginRight: '1rem',
    '&:last-child': {
      marginRight: 0,
    },
  },
}))

export default CustomerContactsField
