import React, { FC } from 'react'
import { TextInput, NumberInput } from 'react-admin'
import { useTranslate } from 'ra-core'
import FormSection from '../../components/forms/FormSection'
import InputColor from './../../components/InputColor'

// type Props = {}

const EventTemplateForm: FC<any> = (props) => {
  const translate = useTranslate()

  return (
    <FormSection title={translate('resources.EventTemplate.name', { smart_count: 1 })} {...props}>
      <TextInput source="title" />
      <NumberInput source="duration" />
      <TextInput source="description" />
      <InputColor source="color" />
    </FormSection>
  )
}

export default EventTemplateForm
