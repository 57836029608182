import React, { FC } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Radio from '@material-ui/core/Radio'

type Props = {
  onClick?: () => void
  checked?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  value: string
  name?: string
  label: string
}

const ResourceModeRadioListItem: FC<Props> = ({ onClick, checked, onChange, value, name, label }) => {
  return (
    <ListItem button onClick={onClick}>
      <ListItemText primary={label} />
      <ListItemSecondaryAction>
        <Radio
          color="primary"
          checked={checked}
          onChange={onChange}
          value={value}
          name={name}
          inputProps={{ 'aria-label': label }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default ResourceModeRadioListItem
