import React, { FC, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import inflection from 'inflection'
import { addField, FieldTitle, useInput } from 'ra-core'
import TextField from '@material-ui/core/TextField'
import * as ReactColor from 'react-color'

require('./ColorInput.css')

export const RAColorInput: FC<any> = (props) => {
  const { label, source, className, options, picker, input, resource, helperText, inputProps, onTop = false } = props
  const [show, setShow] = useState<boolean>(false)

  const Picker = (ReactColor as any)[`${picker}Picker`]

  const {
    input: { name, onChange, value, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  const handleOpen = useCallback(() => {
    setShow(true)
  }, [])

  const handleClose = useCallback(() => {
    setShow(false)
  }, [])

  const handleChange = useCallback(({ hex }: any): void => {
    onChange(hex)
  }, [])

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
      }}
    >
      <TextField
        value={value}
        margin="dense"
        onFocus={handleOpen}
        label={<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
        error={!!(touched && error)}
        helperText={(touched && error) || helperText}
        className={className}
        {...(inputProps ? inputProps : {})}
        {...input}
      />
      {show ? (
        <div className={onTop ? 'ColorInput-popup-top' : 'ColorInput-popup'}>
          <div className="ColorInput-cover" onClick={handleClose} />
          <Picker {...options} color={value} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  )
}

// class ColorInputComponent extends React.Component<any, { show: boolean }> {
//   state = {
//     show: false,
//   }

//   handleOpen = (): void => this.setState({ show: true })
//   handleClose = (): void => this.setState({ show: false })
//   handleChange = ({ hex }: any): void => {
//     this.props.input.onChange(hex)
//     this.forceUpdate()
//   }

//   render(): any {
//     const {
//       label,
//       source,
//       meta,
//       className,
//       options,
//       picker,
//       input,
//       resource,
//       helperText,
//       isRequired,
//       inputProps,
//     } = this.props

//     const { touched, error } = meta

//     const Picker = (ReactColor as any)[`${picker}Picker`]

//     return (
//       <div>
//         <TextField
//           {...input}
//           {...(inputProps ? inputProps : {})}
//           margin="normal"
//           onFocus={this.handleOpen}
//           label={<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
//           error={!!(touched && error)}
//           helperText={(touched && error) || helperText}
//           className={className}
//         />
//         {this.state.show ? (
//           <div className="ColorInput-popup">
//             <div className="ColorInput-cover" onClick={this.handleClose} />
//             <Picker {...options} color={input.value} onChange={this.handleChange} />
//           </div>
//         ) : null}
//       </div>
//     )
//   }
// }

// export const RAColorInput = addField(ColorInputComponent)
