import React, { FC, useMemo } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import Portal from '@material-ui/core/Portal'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { PriceField } from '../../components/CustomFields'
import { AccountingCardTransaction } from './SimpleTransactionRow'

type Props = {
  rawDataArray: AccountingCardTransaction[]
  container: React.MutableRefObject<any>
  isAccountClosed: boolean
  filterType?: 'INCOME' | 'OUTCOME' | null | string | string[]
}

const SummaryTable: FC<Props> = ({ rawDataArray, container, isAccountClosed, filterType }: Props) => {
  const totalRecord: Record<string, number> = useMemo(
    () => ({
      totalSaleIncome:
        rawDataArray
          .filter((elem: AccountingCardTransaction) => elem.type === 'INCOME')
          .reduce((acc: number, curr: AccountingCardTransaction) => (acc += curr.salePrice), 0) || 0,
      totalIncome:
        rawDataArray
          .filter((elem: AccountingCardTransaction) => elem.type === 'INCOME')
          .reduce((acc: number, curr: AccountingCardTransaction) => (acc += curr.total), 0) || 0,
      totalSaleOutcome:
        rawDataArray
          .filter((elem: AccountingCardTransaction) => elem.type === 'OUTCOME')
          .reduce((acc: number, curr: AccountingCardTransaction) => (acc += curr.salePrice), 0) || 0,
      totalNetOutcome:
        rawDataArray
          .filter((elem: AccountingCardTransaction) => elem.type === 'OUTCOME')
          .reduce((acc: number, curr: AccountingCardTransaction) => (acc += curr.netPrice), 0) || 0,
      totalDiscountOutcome:
        rawDataArray
          .filter((elem: AccountingCardTransaction) => elem.type === 'OUTCOME')
          .reduce((acc: number, curr: AccountingCardTransaction) => (acc += curr.discountAmount), 0) || 0,
      totalOutcome:
        rawDataArray
          .filter((elem: AccountingCardTransaction) => elem.type === 'OUTCOME')
          .reduce((acc: number, curr: AccountingCardTransaction) => (acc += curr.total), 0) || 0,
    }),
    [rawDataArray]
  )

  const totalVirtual: Record<string, number> = useMemo(
    () => ({
      totalTaxRateOutcome:
        (100 * (totalRecord.totalSaleOutcome - totalRecord.totalNetOutcome)) / totalRecord.totalSaleOutcome || 0,
      totalToPay: totalRecord.totalOutcome - totalRecord.totalIncome,
    }),
    [totalRecord]
  )

  return (
    <Portal container={container.current}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="right">Sconto</TableCell>
              <TableCell align="right">Prezzo Lordo</TableCell>
              <TableCell align="right">Imponibile</TableCell>
              <TableCell align="right">TOTALE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterType !== 'INCOME' && (
              <TableRow>
                <TableCell align="right">
                  <Typography variant="h5">{'Totale USCITA'}</Typography>
                </TableCell>
                <TableCell align="right">
                  <PriceField variant="h5" record={totalRecord} source="totalDiscountOutcome" color="red" />
                </TableCell>
                <TableCell align="right">
                  {'-'}
                  <PriceField variant="h5" record={totalRecord} source="totalSaleOutcome" color="red" />
                </TableCell>
                <TableCell align="right">
                  <PriceField variant="h5" record={totalRecord} source="totalNetOutcome" color="red" />
                </TableCell>
                <TableCell align="right">
                  {<b>-</b>}
                  <PriceField record={totalRecord} source="totalOutcome" color="red" variant="h4" />
                </TableCell>
              </TableRow>
            )}
            {filterType !== 'OUTCOME' && (
              <TableRow>
                <TableCell align="right">
                  <Typography variant="h5">{'Totale INGRESSO'}</Typography>
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right">
                  {'+'}
                  <PriceField variant="h5" record={totalRecord} source="totalSaleIncome" color="green" />
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right">
                  {<b>+</b>}
                  <PriceField record={totalRecord} source="totalIncome" color="green" variant="h4" />
                </TableCell>
              </TableRow>
            )}
            {!isAccountClosed && !filterType && (
              <TableRow>
                <TableCell align="right">
                  <Typography variant="h5">{'Totale DA PAGARE'}</Typography>
                </TableCell>
                <TableCell align="right" colSpan={4}>
                  <PriceField record={totalVirtual} source="totalToPay" variant="h4" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Portal>
  )
}

export default SummaryTable
