import React, { FC } from 'react'
import RAList from '../../components/List'
import { TextField, NumberField, Datagrid } from 'react-admin'
import DocumentFilters from './QuoteTemplateFilters'

const sort = { field: 'name', order: 'ASC' }

type Props = {
  [x: string]: any
}

const QuoteTemplateList: FC<Props> = (props) => (
  <RAList {...props} filters={<DocumentFilters />} sort={sort}>
    <Datagrid
      rowClick={
        props.permissions && props.permissions.permissionsMap && props.permissions.permissionsMap.updateQuoteTemplate
          ? 'edit'
          : undefined
      }
    >
      <NumberField source="id" />
      <TextField source="name" />
    </Datagrid>
  </RAList>
)

export default QuoteTemplateList
