import React, { FC, useState } from 'react'
import { useDataProvider, useNotify, useRefresh } from 'ra-core'
import { FormRenderProps, Form } from 'react-final-form'
import { TextInput } from 'react-admin'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'

type Props = {
  accountingCardId: number
  total?: number
}

const validate = (values: Record<string, any>): Record<string, any> => {
  const errors: Record<string, any> = {}

  if (!values.body) errors.body = 'Campo obbligatorio'

  return errors
}

const CreateNoteForm: FC<Props> = ({ accountingCardId, total }: Props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async (values: Record<string, any>): Promise<void> => {
    try {
      setLoading(true)
      const data = { ...values, accountingCardId }
      const result = await dataProvider.create('AccountingCardNote', { data })
      refresh()
      notify('Nota aggiunta correttamente')
    } catch (error) {
      console.error('CreateNote ERROR: ', error)
      notify('Errore nella creazione della nota', 'error')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card className={classes.container}>
      <Typography variant="h5">
        Nota ordine
        <Chip label={total} className={classes.chip} />
      </Typography>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={(props: FormRenderProps): React.ReactElement => (
          <form onSubmit={props.handleSubmit}>
            <TextInput source="body" label={false} multiline fullWidth variant="outlined" />
            <Button
              color="primary"
              onClick={props.handleSubmit}
              variant="contained"
              disabled={props.invalid || loading}
              fullWidth
            >
              Aggiungi nota
            </Button>
          </form>
        )}
      />
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chip: {
    marginLeft: theme.spacing(3),
  },
}))

export default CreateNoteForm
