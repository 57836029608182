import React, { FC, useEffect } from 'react'
import { NumberInput, useMutation } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({ root: { maxWidth: 128, marginLeft: 16 } })

const ProductNetPrice: FC<Record<string, any>> = ({ formData, scopedFormData, getSource, changePrice, ...rest }) => {
  const { root } = useStyles()
  const [retrievePrice, { loading, loaded, data }] = useMutation({
    type: 'getOne',
    resource: 'Product',
    payload: { id: scopedFormData.productId },
  })

  useEffect(() => {
    if (scopedFormData) retrievePrice()
  }, [scopedFormData.productId])

  useEffect(() => {
    if (!loading && loaded && data) changePrice(rest.id, data.netPrice)
  }, [data?.netPrice])

  if (!loading && loaded && data)
    return <NumberInput {...rest} source={getSource('productPriceOverride')} classes={{ root }} />
  else return null
}

export default ProductNetPrice
