import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import { useTranslate } from 'ra-core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { makeStyles } from '@material-ui/core/styles'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
  },
}))

export type PlanningViewModeMenuProps = {
  //
  onTwoWeeksClick: () => void
  onWeekClick: () => void
  onDayClick: () => void
  calendarRef?: any
  initialCalendarView: string
}

const PlanningViewModeMenu: FC<PlanningViewModeMenuProps> = ({
  onTwoWeeksClick,
  onWeekClick,
  onDayClick,
  calendarRef,
  initialCalendarView,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const anchorEl = useRef<HTMLButtonElement>(null)
  const currentView = useMemo<'twoWeeks' | 'week' | 'day' | undefined>(() => {
    switch (initialCalendarView) {
      case 'resourceTimeGridTwoWeeksDay':
        return 'twoWeeks'
      case 'resourceTimeGridWeek':
        return 'week'
      case 'resourceTimeGridDay':
        return 'day'
      default:
        return undefined
    }
  }, [initialCalendarView])
  // const open = useMemo(() => Boolean(anchorEl), [anchorEl])
  const translate = useTranslate()
  const viewModeLabel = useMemo(() => (currentView ? translate(`CalendarPlanning.viewMode.${currentView}`) : ''), [
    currentView,
    translate,
  ])

  const handleToggle = useCallback(() => {
    setOpen(!open)
  }, [open])

  const handleClose = useCallback((event?: React.MouseEvent<EventTarget>) => {
    if (anchorEl.current && event && anchorEl.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }, [])

  const handleSelect = useCallback(
    (view: 'twoWeeks' | 'week' | 'day') => (): void => {
      switch (view) {
        case 'twoWeeks':
          onTwoWeeksClick()
          // setCurrentView('twoWeeks')
          break
        case 'week':
          onWeekClick()
          // setCurrentView('week')
          break
        case 'day':
          onDayClick()
          // setCurrentView('day')
          break
      }
      handleClose()
    },
    [onTwoWeeksClick, onWeekClick, onDayClick]
  )

  const handleListKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }, [])

  // useEffect(() => {
  //   if (calendarRef.current && !currentView) {
  //     switch (calendarRef.current.getApi().view.type) {
  //       case 'resourceTimeGridTwoWeeksDay':
  //         setCurrentView('twoWeeks')
  //         break
  //       case 'resourceTimeGridWeek':
  //         setCurrentView('week')
  //         break
  //       case 'resourceTimeGridDay':
  //         setCurrentView('day')
  //         break

  //       default:
  //         break
  //     }
  //   }
  // }, [calendarRef, currentView])

  return (
    <>
      <Button
        ref={anchorEl}
        variant="outlined"
        className={classes.button}
        onClick={handleToggle}
        endIcon={<ArrowDropDownIcon fontSize="small" />}
      >
        {viewModeLabel}
      </Button>
      {/* <Menu id={'calendar-planning-view-mode-menu'} anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}> */}
      <Popper open={open} anchorEl={anchorEl.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }: any): React.ReactNode => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="calendar-planning-view-mode-menu" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={handleSelect('twoWeeks')}>
                    {translate('CalendarPlanning.viewMode.twoWeeks')}
                  </MenuItem>
                  <MenuItem onClick={handleSelect('week')}>{translate('CalendarPlanning.viewMode.week')}</MenuItem>
                  <MenuItem onClick={handleSelect('day')}>{translate('CalendarPlanning.viewMode.day')}</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {/* </Menu> */}
    </>
  )
}

export default PlanningViewModeMenu
