import React, { FC } from 'react'
import { TextInput, NumberInput, ReferenceInput, SelectInput, TextField } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/Edit'

const sort = { field: 'name', order: 'ASC' }

type Props = {}

const VehicleEdit: FC<Props> = (props) => {
  return (
    <Edit {...props} component="div">
      <SectionedForm>
        <FormSection title="ID">
          <TextField addLabel={false} source="id" />
        </FormSection>
        <FormSection title="Dettagli veicolo">
          <ReferenceInput reference="VehicleModel" source="vehicleModelId" {...{ sort }}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="licensePlate" fullWidth />
          <NumberInput source="registrationYear" fullWidth />
          <ReferenceInput reference="VehicleFuel" source="vehicleFuelId" {...{ sort }}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <NumberInput source="numberOfSeats" />
          <TextInput source="color" fullWidth />
          <TextInput source="yearOfManufacture" fullWidth />
          <TextInput source="yearOfRestyling" fullWidth />
          <TextInput source="description" multiline fullWidth />
        </FormSection>
      </SectionedForm>
    </Edit>
  )
}

export default VehicleEdit
