import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/Create'
import DocumentForm from './DocumentForm'
import { useTranslate } from 'ra-core'

type Props = {}

const transform = (data: any): any => ({
  ...data,
  tag: 'quote_print_template',
})

const DocumentCreate: FC<Props> = (props) => {
  return (
    <Create {...props} transform={transform} component="div">
      <SectionedForm redirect="list">
        <DocumentForm {...props} />
      </SectionedForm>
    </Create>
  )
}

export default DocumentCreate
