import React, { FC } from 'react'
import { NumberInput, TextInput } from 'react-admin'
import Filter from '../../components/Filter'

const ProductCategoryFilters: FC = (props: any) => (
  <Filter {...props}>
    <NumberInput label="ID" source="id" alwaysOn />
    <TextInput label="Name" source="name" alwaysOn />
  </Filter>
)

export default ProductCategoryFilters
