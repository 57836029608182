import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import CardActions from '@material-ui/core/CardActions'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import IconButton from '@material-ui/core/IconButton'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import { makeStyles } from '@material-ui/core/styles'
import { useDataProvider, useNotify, useQueryWithStore, useRefresh } from 'ra-core'
import { ReferenceField } from 'react-admin'
import UploadDocumentButton from './UploadDocumentButton'
import { TiDocument } from 'react-icons/ti'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import MoreVert from '@material-ui/icons/MoreVert'
import DeleteIcon from '@material-ui/icons/Delete'
import GetAppIcon from '@material-ui/icons/GetApp'
import moment from 'moment'

const QUERY_GET_CUSTOMER_DOCUMENTS = gql`
  query GetCustomerDocuments($filters: CustomerDocumentFiltersInput, $sort: CustomerDocumentSortInput) {
    customerDocuments(filters: $filters, sort: $sort) {
      data {
        id
        documentId
        document {
          id
          description
          createdAt
          url
        }
      }
    }
  }
`

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    title: {},
    icon: {
      marginRight: theme.spacing(1),
    },
  }
})

type Props = {
  record?: any
  [x: string]: any
}

const ListItemField: FC<{ record?: any }> = ({ record }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const formattedDate = useMemo(() => moment(record.createdAt).format('L LT'), [record])
  const onItemClick = useCallback(() => {
    window.open(record.url, '_blank')
  }, [record])

  const onMoreClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const onMoreClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onMoreDownloadClick = useCallback(() => {
    onItemClick()
    onMoreClose()
  }, [record])

  return (
    <>
      <ListItem button onClick={onItemClick}>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={record.description ? record.description : record.url} secondary={formattedDate} />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={onMoreClick}>
            <MoreVert />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Menu id={`more-${record.id}`} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onMoreClose}>
        <MenuItem onClick={onMoreDownloadClick}>
          <ListItemIcon>
            <GetAppIcon fontSize="small" />
          </ListItemIcon>
          Apri documento
        </MenuItem>
        <MenuItem onClick={onMoreClose}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Elimina
        </MenuItem>
      </Menu>
    </>
  )
}

const CustomerDocumentsCard: FC<Props> = (props) => {
  const classes = useStyles(props)
  const { record } = props
  const [submitting, setSubmitting] = useState<boolean>(false)
  const dataProvider = useDataProvider()
  const { loading, data, refetch } = useQuery(QUERY_GET_CUSTOMER_DOCUMENTS, {
    variables: {
      filters: {
        customerId: record.id,
      },
    },
    skip: !record || !record.id,
  })
  const notify = useNotify()
  const refresh = useRefresh()

  const onDocumentUploaded = useCallback(
    (data: any) => {
      setSubmitting(true)
      dataProvider.create(
        'CustomerDocument',
        {
          data: { customerId: record.id, documentId: data.id },
        },
        {
          onSuccess: ({ data }: any) => {
            setSubmitting(false)
            refresh()
            refetch()
          },
          onFailure: (error: any) => {
            setSubmitting(false)
            notify(error.message, 'error')
          },
        }
      )
    },
    [dataProvider, record, notify]
  )

  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center" mb={3}>
          <TiDocument size="1.2rem" className={classes.icon} />
          <Typography variant="h4" className={classes.title}>
            Documenti
          </Typography>
        </Box>
        {!loading && data && data.customerDocuments && data.customerDocuments.data && (
          <List>
            {data.customerDocuments.data.map((item: any) => {
              return (
                <ReferenceField
                  link={false}
                  resource="CustomerDocument"
                  basePath="/Customer"
                  record={item}
                  source="documentId"
                  reference="Document"
                >
                  <ListItemField />
                </ReferenceField>
              )
            })}
          </List>
        )}
      </CardContent>
      <Divider />
      <CardActions>
        <UploadDocumentButton
          color="primary"
          fullWidth
          variant="text"
          onChange={onDocumentUploaded}
          disabled={loading}
        />
        {/* <Button color="primary" fullWidth variant="text">
          Upload picture
        </Button> */}
      </CardActions>
    </Card>
  )
}

export default CustomerDocumentsCard
