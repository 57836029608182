import React, { FC, useCallback } from 'react'
import { TextInput, ReferenceInput, AutocompleteInput, NumberInput } from 'react-admin'
import FormSection from './../../../components/forms/FormSection'

const sort = { field: 'name', order: 'ASC' }
type Props = {}

const VehicleModelForm: FC<Props> = (props) => {
  const filterToQuery = useCallback((name: string): Record<string, string> => ({ name }), [])
  return (
    <FormSection title="Model" {...props}>
      <ReferenceInput
        label="Brand"
        reference="VehicleManufacturer"
        source="vehicleManufacturerId"
        sort={sort}
        filterToQuery={filterToQuery}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" fullWidth />
      <ReferenceInput
        label="Type"
        reference="VehicleType"
        source="vehicleTypeId"
        {...{ sort }}
        sort={sort}
        filterToQuery={filterToQuery}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Original Power Supply"
        reference="VehicleFuel"
        source="vehicleFuelId"
        sort={sort}
        filterToQuery={filterToQuery}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="modelYear" />
    </FormSection>
  )
}

export default VehicleModelForm
