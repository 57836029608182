import React, { useEffect } from 'react'
import { withStyles, Theme, createStyles } from '@material-ui/core/styles'
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch'
import { useFormState, useForm } from 'react-final-form'

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string
}

interface Props extends SwitchProps {
  classes: Styles
  checked: boolean
  disabled?: boolean
  onChange: (event: any, value: boolean) => void
  [x: string]: any
}

const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 60,
      height: 30,
      padding: 0,
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(30px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: theme.palette.primary.main,
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 28,
      height: 28,
      // '&:before': {
      //   color: 'black',
      //   content: '"si"',
      // },
    },
    track: {
      borderRadius: 30,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
    disabled: {
      backgroundColor: 'blue',
    },
  })
)(({ classes, onChange, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      onChange={onChange}
      {...props}
    />
  )
})

export default IOSSwitch
