import React, { FC } from 'react'
import RAList from './../../components/List'
import { TextField, NumberField, Datagrid, ReferenceField, BooleanField } from 'react-admin'
import EditButtonWPermission from '../../components/EditButtonWPermission'
import StateFilters from './StateFilters'

const sort = { field: 'name', order: 'ASC' }
const filterDefaultValues = { active: true }

type Props = {}

const StateList: FC<Props> = (props) => (
  <RAList {...{ sort, filterDefaultValues }} {...props} filters={<StateFilters />}>
    <Datagrid>
      <NumberField source="id" />
      <TextField source="name" />
      <TextField source="iso" />
      <ReferenceField source="countryId" reference="Country">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="active" sortable={false} />
      <EditButtonWPermission {...props} />
    </Datagrid>
  </RAList>
)

export default StateList
