import React, { FC } from 'react'
import RAList from './../../components/List'
import { TextField, NumberField, Datagrid, BooleanField } from 'react-admin'
import EditButtonWPermission from '../../components/EditButtonWPermission'
import CountryFilters from './CountryFilters'

const sort = { field: 'name', order: 'ASC' }
const filterDefaultValues = { active: true }

type Props = {}

const CountryList: FC<Props> = (props) => (
  <RAList {...{ sort, filterDefaultValues }} {...props} filters={<CountryFilters />}>
    <Datagrid>
      <NumberField source="id" />
      <TextField source="name" />
      <TextField source="isoAlpha2" />
      <TextField source="isoAlpha3" />
      <TextField source="phonePrefix" sortable={false} />
      <BooleanField source="taxIncluded" sortable={false} />
      {/* <ReferenceField source="zoneId" reference="Zone" sortable={false}>
          <TextField source="name" />
        </ReferenceField> */}
      <BooleanField source="active" sortable={false} />
      <EditButtonWPermission {...props} />
    </Datagrid>
  </RAList>
)

export default CountryList
