import { TransactionGroup } from '../resources/AccountingCard/GroupedTransactionRow'
import { AccountingCardTransaction } from '../resources/AccountingCard/SimpleTransactionRow'

export function event_dates_sort_asc(e1: AccountingCardTransaction, e2: AccountingCardTransaction): number {
  if (e1.date > e2.date) return 1
  if (e1.date < e2.date) return -1
  return 0
}

export function group_trans_sort_name_asc(e1: TransactionGroup, e2: TransactionGroup): number {
  if (e1.productName > e2.productName) return 1
  if (e1.productName < e2.productName) return -1
  return 0
}

export const transactionGroupsGenerator = (
  data: AccountingCardTransaction[],
  productsMap: Record<string, any>
): TransactionGroup[] => {
  const outcomesIdsMap: { [x: string]: AccountingCardTransaction[] } = {}

  const outcomes: AccountingCardTransaction[] = data.filter(
    (elem: AccountingCardTransaction) => elem.type === 'OUTCOME'
  )
  const incomes: AccountingCardTransaction[] = data.filter((elem: AccountingCardTransaction) => elem.type === 'INCOME')

  outcomes.forEach((elem: AccountingCardTransaction) => {
    outcomesIdsMap[elem.id] = [{ ...elem }]
  })

  incomes.forEach((elem: AccountingCardTransaction) => {
    if (elem.outcomeTransactionId) {
      if (!outcomesIdsMap[elem.outcomeTransactionId]) {
        outcomesIdsMap[elem.outcomeTransactionId] = []
      }
      outcomesIdsMap[elem.outcomeTransactionId].push(elem)
    } else {
      if (!outcomesIdsMap['0']) {
        outcomesIdsMap['0'] = []
      }
      outcomesIdsMap['0'].push(elem)
    }
  })

  const finalArray: TransactionGroup[] = []

  const finalEntries = Object.entries(outcomesIdsMap)
  finalEntries.forEach((entry) => {
    const groupId = entry[0]
    const array = entry[1]
    const prodId = array[0].productId
    const productName = productsMap[array[0]?.productId]?.name || ''
    const plannedEventIds = array.filter((elem) => elem.type === 'OUTCOME')[0]?.plannedEventIds
    const remainingDuration = array.filter((elem) => elem.type === 'OUTCOME')[0]?.remainingDuration
    const examPlanningId = array[0].examPlanningId
    finalArray.push({ groupId, prodId, array, plannedEventIds, examPlanningId, remainingDuration, productName })
  })

  return finalArray
}

export const calculateGroupTotal = (array: AccountingCardTransaction[]): number => {
  let total = 0
  array.forEach((elem) => {
    switch (elem.type) {
      case 'INCOME':
        total -= elem.total
        break
      case 'OUTCOME':
        total += elem.total
        break
      default:
        break
    }
  })
  return total
}

export const renderColor = (record: AccountingCardTransaction): 'red' | 'green' | undefined => {
  switch (record.type) {
    case 'INCOME':
      return 'green'
    case 'OUTCOME':
      return 'red'
    default:
      return undefined
  }
}
