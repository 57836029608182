import React, { FC, useCallback } from 'react'
import { TextInput, ReferenceInput, FormDataConsumer, AutocompleteInput, useTranslate } from 'react-admin'
import FormSection from './../../components/forms/FormSection'
import CountryStateDoubleInput from './../../components/forms/CountryStateDoubleInput'
import { parse } from 'query-string'

type Props = {
  [x: string]: any
}

const sort = { field: 'lastName', order: 'ASC' }

const CustomerForm: FC<Props> = (props) => {
  const translate = useTranslate()
  const filterToQuery = useCallback((q: string): Record<string, string> => ({ q }), [])
  const parsedQuery = parse(props.location?.search)

  return (
    <FormDataConsumer>
      {(formDataProps: any) => (
        <>
          <FormSection title={translate('resources.Address.sections.associatedCustomer')} {...props}>
            <ReferenceInput
              label="Customer"
              reference="Customer"
              source="customerId"
              filterToQuery={filterToQuery}
              sort={sort}
              initialValue={parsedQuery && parsedQuery.customerId ? parsedQuery.customerId : undefined}
            >
              <AutocompleteInput optionText="fullName" />
            </ReferenceInput>
          </FormSection>
          <FormSection title={translate('resources.Address.sections.addressInfo')} {...props}>
            <TextInput source="firstName" fullWidth required />
            <TextInput source="lastName" fullWidth required />
            <TextInput source="company" fullWidth />
            <TextInput source="phone" fullWidth required />
            <CountryStateDoubleInput {...formDataProps} />
            <TextInput source="city" fullWidth required />
            <TextInput source="street" fullWidth required />
            <TextInput source="postCode" fullWidth required />
            <TextInput source="alias" label="Alias" fullWidth required />
          </FormSection>
        </>
      )}
    </FormDataConsumer>
  )
}

export default CustomerForm
