import React, { FC, useCallback } from 'react'
import { useNotify, useRedirect } from 'ra-core'
import { TextField } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/Edit'
import AddressForm from './AddressForm'

type Props = {}

const TeamMemberEdit: FC<Props> = (props) => {
  const redirect = useRedirect()
  const notify = useNotify()
  const onSuccess = useCallback(({ data }) => {
    notify('ra.notification.created')
    redirect(`/Customer/${data.customerId}/show`)
  }, [])

  return (
    <Edit {...props} component="div" onSuccess={onSuccess}>
      <SectionedForm variant="outlined">
        <FormSection title="ID">
          <TextField source="id" addLabel={false} />
        </FormSection>
        <AddressForm />
      </SectionedForm>
    </Edit>
  )
}

export default TeamMemberEdit
