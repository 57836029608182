import React, { FC, Fragment } from 'react'
import { FormDataConsumer } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/Edit'
import OpeningHoursForm from './../../components/forms/OpeningHoursForm'
import OfficeForm from './OfficeForm'

type Props = {}

const OfficeEdit: FC<Props> = (props) => {
  return (
    <Edit {...props} component="div">
      <SectionedForm variant="outlined">
        <FormDataConsumer>
          {(formDataProps: any): any => (
            <Fragment>
              <OfficeForm {...formDataProps} />
              <FormSection title="Orari di apertura" fullWidth>
                <OpeningHoursForm {...formDataProps} />
              </FormSection>
            </Fragment>
          )}
        </FormDataConsumer>
      </SectionedForm>
    </Edit>
  )
}

export default OfficeEdit
