import React, { FC } from 'react'
import { EditButton } from 'react-admin'

type Props = {
  [x: string]: any
}

const EditButtonWPermission: FC<Props> = (props) => {
  // if (props && props.permissions && props.permissions.permissionsMap)
  //   console.log(
  //     'EditButtonWPermission: ',
  //     `update${props.resource}`,
  //     props.permissions.permissionsMap[`update${props.resource}`]
  //   )

  return props &&
    props.permissions &&
    props.permissions.permissionsMap &&
    props.permissions.permissionsMap[`update${props.resource}`] ? (
    <EditButton {...props} />
  ) : null
}

export default EditButtonWPermission
