import { Box } from '@material-ui/core'
import { Card, CardContent, Typography, Divider, CardActions, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from 'ra-core'
import React, { FC } from 'react'
import { useMemo } from 'react'
import { GrShop } from 'react-icons/gr'
import { Link } from 'react-router-dom'
import WorkingHoursCard from './WorkingHoursCard'

type Props = {
  record?: any
  permissionsMap?: any
}

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    title: {},
    icon: {
      marginRight: theme.spacing(1),
    },
  }
})

export const TeamMemberWorkingPlanningCard: FC<Props> = ({ record, permissionsMap }) => {
  const classes = useStyles()
  const now = useMemo(() => new Date().toISOString(), [])
  const { loading, data, error } = useQuery({
    resource: 'TeamMemberWorkPlanning',
    type: 'getList',
    payload: {
      pagination: {
        page: 1,
        perPage: 1,
      },
      sort: { field: 'id', order: 'DESC' },
      filter: {
        teamMemberId: record.id,
        startDate: now,
        endDate: now,
      },
    },
  })

  console.log('ciao ciao', data, error)

  if (loading || !data) {
    return null
  }

  if (data.length === 0) {
    return (
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center">
            <GrShop size="1.2rem" className={classes.icon} />
            <Typography variant="h4" className={classes.title}>
              Nessuna Pianificazione presente
            </Typography>
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            component={Link}
            to={{
              pathname: '/TeamMemberWorkPlanning/create',
              state: { record: { teamMemberId: record.id } },
            }}
            color="primary"
            fullWidth
            variant="text"
          >
            Crea nuova pianificazione
          </Button>
        </CardActions>
      </Card>
    )
  }

  return (
    <>
      {/* <pre>{JSON.stringify(data[0], null, 2)}</pre> */}
      <WorkingHoursCard teamMemberWorkPlanning={data[0]} permissionsMap={permissionsMap} />
    </>
  )
}
