import React, { FC } from 'react'
import { useQueryWithStore } from 'react-admin'
import { Grid, Typography, Paper, makeStyles, Avatar, LinearProgress } from '@material-ui/core'

const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'baseline',
  },
  rowTitle: {
    marginRight: 8,
  },
})

const CustomerAddresses = ({ ids }: any) => {
  const classes = useStyles()
  const { loading, error, data } = useQueryWithStore({
    type: 'getMany',
    resource: 'Address',
    payload: { ids },
  })

  if (data) console.log('addressdata: ', data)
  if (data)
    return data.map((addr: any) => (
      <div className={classes.row} key={addr.id}>
        <Typography variant="caption" className={classes.rowTitle}>
          {'Indirizzo:'}
        </Typography>
        <Typography variant="body2">{`${addr.street}, ${addr.city}`}</Typography>
      </div>
    ))
  else return null
}

export default CustomerAddresses
