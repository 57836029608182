import React, { FC, useState } from 'react'
import { useDataProvider, useRedirect } from 'ra-core'
import { useFormState } from 'react-final-form'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ContentSave from '@material-ui/icons/Save'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'
import { makeStyles } from '@material-ui/core/styles'
import EditPlannedEventButton from './EditPlannedEventButton'

type Props = {
  [x: string]: any
}

const EditRecurringEventButton: FC<Props> = (props) => {
  const classes = useStyles(props)
  const dataProvider = useDataProvider()
  const redirect = useRedirect()
  const { values } = useFormState()

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [isDialogSaving, setIsDialogSaving] = useState<boolean>(false)

  const onDialogClose = (): void => setIsDialogOpen(false)
  const onDialogOpen = (): void => setIsDialogOpen(true)

  const onSaveRecurringClick = async (): Promise<void> => {
    try {
      // console.log('xxxx VALUES! ', values)
      setIsDialogSaving(true)
      const {
        title,
        startDate: eventStart,
        endDate: eventEnd,
        endMode,
        isRecurringEvent,
        recurringEvent,
        recurringEventId: id,
        ...rest
      } = values
      const weekDays = recurringEvent?.weekDays?.length ? recurringEvent.weekDays.join('-') : undefined
      const data = { ...recurringEvent, id, title, eventStart, eventEnd, weekDays, additionalData: { ...rest } }
      // console.log('xxx sending data: ', data)
      const result = await dataProvider.update('RecurringEvent', { id, data, previousData: { id } })
      // console.log('xxx update result: ', result)
      redirect(props?.customRedirect)
    } catch (error) {
      // console.log('xxx error: ', error)
      setIsDialogSaving(false)
    }
  }

  return (
    <>
      <Dialog open={isDialogOpen} onClose={onDialogClose} maxWidth="md">
        <DialogTitle id="alert-dialog-title">{'Modifica evento ricorrente'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {
              'Attenzione! Questo è un evento ricorrente. Vuoi modificare solo questo evento oppure tutte le ricorrenze? '
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose} color="primary">
            Annulla
          </Button>
          <EditPlannedEventButton {...props} label="Salva singolo evento" />
          <Button variant="contained" onClick={onSaveRecurringClick} color="primary" autoFocus>
            {isDialogSaving ? (
              <CircularProgress size={18} thickness={2} className={classes.leftIcon} />
            ) : (
              <DynamicFeedIcon className={classes.leftIcon} />
            )}
            Salva tutte le ricorrenze
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="contained"
        onClick={onDialogOpen}
        color="primary"
        // {...sanitizeButtonRestProps(rest)}
      >
        {props.saving ? (
          <CircularProgress size={18} thickness={2} className={classes.leftIcon} />
        ) : (
          <ContentSave className={classes.leftIcon} />
        )}
        {'Salva...'}
      </Button>
    </>
  )
}

const useStyles = makeStyles(
  (theme) => ({
    button: {
      position: 'relative',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    icon: {
      fontSize: 18,
    },
  }),
  { name: 'RaSaveButton' }
)

export default EditRecurringEventButton
