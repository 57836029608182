import React, { FC } from 'react'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { usePermissions, useShowController } from 'ra-core'
import { Loading } from 'react-admin'
import UserPicturePreviewCard from '../../components/UserPicturePreviewCard'
import CustomerProfilePreviewCard from '../../components/CustomerProfilePreviewCard'
import CustomerDocumentsCard from '../../components/CustomerDocumentsCard'
import CustomerContactsCard from '../../components/CustomerContactsCard'
import CustomerOrderPreviewCard from '../../components/CustomerOrderPreviewCard'
import CustomerDrivingLicensesCard from '../../components/CustomerDrivingLicensesCard'
import CustomerAccountingCardPreviewCard from '../../components/CustomerAccountingCardPreviewCard'

const CustomerShow: FC<any> = (props) => {
  const {
    basePath, // deduced from the location, useful for action buttons
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
    resource, // the resource name, deduced from the location. e.g. 'posts'
    version, // integer used by the refresh feature
  } = useShowController(props)
  const { loaded, permissions } = usePermissions()

  if (loading || !loaded || !permissions) {
    return <Loading />
  }

  return (
    <Container maxWidth="lg">
      <Grid spacing={3} container>
        <Grid item lg={4} md={6} xs={12}>
          <Box mt={3}>
            {record && (
              <UserPicturePreviewCard
                {...props}
                resource="Customer"
                record={record}
                permissionsMap={permissions.permissionsMap}
              />
            )}
          </Box>
          <Box mt={3}>
            {record && (
              <CustomerProfilePreviewCard {...props} record={record} permissionsMap={permissions.permissionsMap} />
            )}
          </Box>
          {permissions.permissionsMap.customerContacts && (
            <Box mt={3}>
              {record && (
                <CustomerContactsCard {...props} record={record} permissionsMap={permissions.permissionsMap} />
              )}
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mt={3}>
            {/* {record && (
              <CustomerOrderPreviewCard {...props} record={record} permissionsMap={permissions.permissionsMap} />
            )} */}
            {record && (
              <CustomerAccountingCardPreviewCard
                {...props}
                record={record}
                permissionsMap={permissions.permissionsMap}
              />
            )}
          </Box>
          {/* customerDocuments not in the permissionsMap */}
          <Box mt={3}>{record && <CustomerDocumentsCard {...props} record={record} />}</Box>
          <Box mt={3}>
            {record && (
              <CustomerDrivingLicensesCard {...props} record={record} permissionsMap={permissions.permissionsMap} />
            )}
          </Box>
        </Grid>
      </Grid>
      {/* <>{record && record.id && <pre>{JSON.stringify(record, null, 2)}</pre>}</> */}
    </Container>
  )
}

export default CustomerShow
