import React, { FC, useEffect, useState } from 'react'
import RAList from './../../components/List'
import {
  TextField,
  NumberField,
  ReferenceField,
  ReferenceArrayField,
  ReferenceInput,
  SingleFieldList,
  ChipField,
  DateField,
  AutocompleteInput,
  NullableBooleanInput,
} from 'react-admin'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { DateInput } from 'react-admin-date-inputs'
import Datagrid from '../../components/Datagrid'
import Filter from '../../components/Filter'
import { useListController, ListContextProvider, useListContext } from 'ra-core'
import { startOfMonth, endOfMonth, differenceInMinutes } from 'date-fns'
import { useForm } from 'react-final-form'
import { ColoredNullableBooleanField, EventDurationField } from '../../components/CustomFields'
import { gql, useApolloClient } from '@apollo/client'
import { formatMinutesDuration } from '../../components/CustomFields/EventDurationField'

type Props = {}

const GET_WORKINGTIMES = gql`
  query GetWorkingHours($filters: PlannedEventFilterInput) {
    plannedEvents(pagination: { disabled: true }, filters: $filters) {
      data {
        id
        event {
          id
          startDate
          endDate
        }
        isWorkEvent
      }
      total
      offset
    }
  }
`

const WorkingHourCalculator: FC<any> = () => {
  const client = useApolloClient()
  const { filterValues } = useListContext()
  const [totalMinutes, setTotalMinutes] = useState<number>(0)
  const classes = useStyles()
  // console.log('xxx listcontext:: ', filterValues)

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        // const result = await dataProvider.getList('PlannedEvent', {
        //   filter: filterValues,
        //   pagination: { page: 1, perPage: 50000 },
        //   sort: { field: 'id', order: 'DESC' },
        // })
        const result = await client.query({
          query: GET_WORKINGTIMES,
          variables: {
            filters: { ...filterValues },
          },
        })
        // console.log('xxx result: ', result)
        let sum = 0
        if (
          result &&
          result.data &&
          result.data.plannedEvents &&
          result.data.plannedEvents.data &&
          Array.isArray(result.data.plannedEvents.data)
        ) {
          result.data.plannedEvents.data.forEach((pEvent: any) => {
            sum += differenceInMinutes(new Date(pEvent.event.endDate), new Date(pEvent.event.startDate))
          })
        }
        setTotalMinutes(sum)
      } catch (err) {
        console.error('calculation error: ', err)
      }
    }
    if (filterValues && filterValues.teamMemberIds) fetchEvents()
  }, [filterValues?.teamMemberIds, filterValues.dateRangeStart, filterValues.dateRangeEnd, filterValues?.isWorkEvent])

  return (
    <Paper className={classes.summaryCard}>
      <Typography>
        Totale: <strong>{formatMinutesDuration(totalMinutes)}</strong>
      </Typography>
    </Paper>
  )
}

const Dumbo: FC<any> = (props) => {
  const form = useForm()

  useEffect(() => {
    form.change('dateRangeStart', startOfMonth(new Date()))
    form.change('dateRangeEnd', endOfMonth(new Date()))
  }, [])

  return (
    <>
      <NullableBooleanInput {...props} source="isWorkEvent" alwaysOn label="Lavorativo?" />
      <WorkingHourCalculator />
    </>
  )
}

const CustomFilters: FC<any> = (props) => {
  return (
    <Filter {...props}>
      {/* <ReferenceArrayInput source="teamMemberIds" reference="TeamMember" alwaysOn>
        <AutocompleteArrayInput optionText="fullName" size="small" />
      </ReferenceArrayInput> */}
      <ReferenceInput source="teamMemberIds" reference="TeamMember" alwaysOn>
        <AutocompleteInput optionText="fullName" />
      </ReferenceInput>
      <DateInput
        source="dateRangeStart"
        label="Range inizio"
        alwaysOn
        options={{ clearable: true, format: 'dd/MM/yyyy' }}
        // providerOptions={{ utils: DateFnsUtils, locale: itLocale }}
      />
      <DateInput
        source="dateRangeEnd"
        label="Range fine"
        alwaysOn
        options={{ clearable: true, format: 'dd/MM/yyyy' }}
        // providerOptions={{ utils: DateFnsUtils, locale: itLocale }}
      />
      <Dumbo {...props} alwaysOn />
    </Filter>
  )
}

const WorkReportComp: FC<any> = (props) => {
  // const filterDefaultValues = useMemo(
  //   () => ({
  //     dateRangeStart: startOfMonth(new Date()),
  //     dateRangeEnd: endOfMonth(new Date()),
  //   }),
  //   []
  // )

  return (
    <RAList
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      title="Rendicontazione dipendenti"
      bulkActionButtons={false}
      exporter={false}
      filters={<CustomFilters />}
      // filterDefaultValues={filterDefaultValues}
      // filterDefaultValues={{
      //   dateRangeStart: startOfMonth(new Date()),
      //   dateRangeEnd: endOfMonth(new Date()),
      // }}
    >
      <Datagrid>
        <NumberField source="id" />
        <ReferenceArrayField label="Dipendenti" reference="TeamMember" source="teamMemberIds">
          <SingleFieldList linkType="show">
            <ChipField source="fullName" size="small" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="description" label="Descrizione" />
        <ColoredNullableBooleanField source="isWorkEvent" label="E' lavorativo?" />
        <ReferenceField source="eventId" reference="Event" link={false} sortable={false} label="Inizio">
          <DateField source="startDate" showTime />
        </ReferenceField>
        <ReferenceField source="eventId" reference="Event" link={false} sortable={false} label="Fine">
          <DateField source="endDate" showTime />
        </ReferenceField>
        <ReferenceField source="eventId" reference="Event" link={false} sortable={false} label="Durata">
          <EventDurationField />
        </ReferenceField>
      </Datagrid>
    </RAList>
  )
}

const WorkReportList: FC<Props> = (props) => {
  const listContextValue = useListController({
    resource: 'WorkReport',
    basePath: '/WorkReport',
    sort: { field: 'id', order: 'DESC' },
    filters: <CustomFilters />,
  })

  return (
    <ListContextProvider value={listContextValue}>
      <WorkReportComp {...props} {...listContextValue} />
    </ListContextProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  summaryCard: {
    alignSelf: 'stretch',
    marginLeft: '20px',
    marginBottom: '10px',
    marginTop: '10px',
    paddingTop: '6px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
}))

export default WorkReportList
