import React, { FC, useState, useMemo } from 'react'
import { useDataProvider, useRefresh, useNotify, useGetList } from 'ra-core'
import { ReferenceField, TextField } from 'react-admin'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'

type Props = {
  record: {
    id: any
    [x: string]: any
  }
}

const ReopenAccountingCardButton: FC<Props> = ({ record }: Props) => {
  const classes = useStyles()
  const refresh = useRefresh()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const { loading: loadingTrans, data: dataTrans } = useGetList(
    'AccountingCardTransaction',
    { page: 1, perPage: 10 },
    { field: 'id', order: 'DESC' },
    { accountingCardId: record.id, productId: 0 }
  )

  const [loading, setLoading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onButtonClick = (): void => setIsOpen(true)

  const handleClose = (): void => setIsOpen(false)

  const idToDelete = useMemo(() => {
    if (!loadingTrans && dataTrans && Object.keys(dataTrans).length > 0) {
      return Object.keys(dataTrans)[0]
    } else return null
  }, [dataTrans, loadingTrans])

  const onSubmit = async (): Promise<void> => {
    try {
      setLoading(true)
      const res2 = dataProvider.update('AccountingCard', {
        id: record.id,
        previousData: { ...record },
        data: {
          isClosed: false,
          closingDate: null,
          discountAmount: 0,
          remainingAmount: record.discountAmount,
        },
      })
      if (!res2) throw new Error('Errore nella riapertura della pratica')
      if (idToDelete) {
        const res1 = await dataProvider.delete('AccountingCardTransaction', {
          id: idToDelete,
          previousData: { id: idToDelete },
        })
        if (!res1) throw new Error('Errore nella cancellazione della transazione di chiusura')
      }
      notify('Scheda contabile riaperta con successo')
      setIsOpen(false)
      refresh()
    } catch (error) {
      notify(error?.message, 'error')
      console.error(error)
    }
  }

  return !loadingTrans ? (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={onButtonClick}
        size="small"
        fullWidth
        className={classes.button}
      >
        {'RIAPRI PRATICA'}
      </Button>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Riapertura pratica</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'Sei sicuro di voler riaprire questa scheda contabile? Cliente: '}
            <ReferenceField
              basePath="/Customer"
              resource="Customer"
              record={record}
              reference="Customer"
              source="customerId"
              link={false}
            >
              <TextField source="fullName" variant="h6" />
            </ReferenceField>
          </DialogContentText>
          {record.discountAmount && record.discountAmount > 0 && (
            <DialogContentText>
              {'NOTA: verrà azzerato lo sconto finale di '}
              <b>{`${record.discountAmount} €`}</b>
            </DialogContentText>
          )}
          {idToDelete && dataTrans && (
            <DialogContentText>
              {'NOTA: verrà cancellata la transazione di chiusura pratica di '}
              <b>{`${dataTrans[idToDelete].salePrice} €`}</b>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" size="small" disabled={loading}>
            Annulla
          </Button>
          <Button onClick={onSubmit} color="primary" variant="contained" size="small" disabled={loading}>
            {loading ? <CircularProgress size={10} /> : 'Riapri pratica'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <CircularProgress size={30} />
  )
}

const useStyles = makeStyles((theme) => ({
  button: { marginTop: theme.spacing(2) },
  inputContainer: { display: 'flex' },
  description: { marginLeft: theme.spacing(2) },
}))

export default ReopenAccountingCardButton
