import React, { FC, useCallback, useState } from 'react'
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import { saveAs } from 'file-saver'
import { useDataProvider, useNotify } from 'ra-core'
import ExcelJS, { Borders } from 'exceljs'
import { AccountingCardTransaction } from './SimpleTransactionRow'
import { useApolloClient } from '@apollo/client'
import { GET_CUSTOMER_EVENT } from '../../queries'
import PrintIcon from '@material-ui/icons/Print'
import makeStyles from '@material-ui/core/styles/makeStyles'

const EXCEL_EURO_FORMAT2 = '_("€"* #,##0.00_);_("€"* -#,##0.00_);_("€"* "-"??_);_(@_)'

const border: Partial<Borders> = {
  top: { style: 'thin', color: { argb: '00000000' } },
  left: { style: 'thin', color: { argb: '00000000' } },
  bottom: { style: 'thin', color: { argb: '00000000' } },
  right: { style: 'thin', color: { argb: '00000000' } },
}

const defaultCellStyle: Partial<ExcelJS.Style> = {
  alignment: { horizontal: 'center' },
  border,
}

const defaultPriceCellStyle: Partial<ExcelJS.Style> = {
  border,
  numFmt: EXCEL_EURO_FORMAT2,
}

const isOutcome = (item: AccountingCardTransaction): boolean => item.type === 'OUTCOME'

type Props = {
  record?: any
  items: AccountingCardTransaction[]
  [x: string]: any
} & IconButtonProps

const PrintAccountingCardTransactionsButton: FC<Props> = ({ record, items, ...props }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const client = useApolloClient()
  const classes = useStyles()

  const generateExcelDocument = useCallback(async (): Promise<void> => {
    try {
      setLoading(true)
      const { data: fullCustomer } = await dataProvider.getOne('Customer', { id: record.customerId })
      const { data: customerContacts } = await dataProvider.getList('CustomerContact', {
        filter: {
          customerId: record.customerId,
        },
        sort: {
          field: 'id',
          order: 'ASC',
        },
        pagination: {
          page: 1,
          perPage: 10,
        },
      })

      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'DriveSystem'
      workbook.created = new Date()
      workbook.modified = new Date()

      const worksheet = workbook.addWorksheet('Scheda Contabile')

      worksheet.getRow(2).height = 15
      worksheet.getColumn('C').width = 45
      worksheet.getCell('C2').value = 'SCHEDA CONTABILE DEL CLIENTE'
      worksheet.getCell('C2').style = { ...defaultCellStyle }

      const customerCell = worksheet.getCell('C3')
      customerCell.value = fullCustomer.fullName
      customerCell.style = {
        font: { bold: true, size: 12 },
        alignment: { horizontal: 'center' },
        border,
      }

      worksheet.mergeCells('D2:E2')
      worksheet.mergeCells('D3:E3')
      worksheet.getCell('D2').value = 'Istruttore:'
      worksheet.getCell('D2').style = { font: { bold: true }, border }

      if (fullCustomer.mainReferenceTeamMemberId) {
        const { data: referenceTeamMember } = await dataProvider.getOne('TeamMember', {
          id: fullCustomer.mainReferenceTeamMemberId,
        })
        worksheet.getCell('D3').value = referenceTeamMember.fullName
        worksheet.getCell('D3').style = {
          font: { bold: true, size: 12 },
          alignment: { horizontal: 'left' },
          border,
        }
      }

      worksheet.getCell('E2').style = { border }
      worksheet.getCell('E3').style = { border }
      worksheet.getCell('F2').style = { border }
      worksheet.getCell('F3').style = { border }

      worksheet.mergeCells('F2:G2')
      worksheet.getCell('F2').value = 'TEL.'
      worksheet.getCell('F2').style = { font: { bold: true }, border }

      if (customerContacts && customerContacts.length > 0) {
        const foundedPhone = customerContacts.find((item) => item.contactType === 'sms')
        if (foundedPhone) {
          worksheet.mergeCells('F3:G3')
          // worksheet.getColumn('G').width = 18
          worksheet.getCell('F3').value = foundedPhone.contact
          worksheet.getCell('F3').style = {
            font: { bold: true, size: 12 },
            border,
            alignment: { horizontal: 'left' },
          }
        }
      }

      worksheet.getColumn('B').width = 12
      worksheet.getColumn('D').width = 14
      worksheet.getColumn('E').width = 14
      worksheet.getColumn('F').width = 14
      worksheet.getColumn('G').width = 14
      worksheet.getColumn('H').width = 14
      worksheet.getColumn('I').width = 14

      const itemsHeaderRow = worksheet.getRow(5)
      itemsHeaderRow.getCell('B').value = 'DATA'
      itemsHeaderRow.getCell('B').style = { ...defaultCellStyle }
      itemsHeaderRow.getCell('C').style = { border }
      itemsHeaderRow.getCell('D').value = 'SCONTO'
      itemsHeaderRow.getCell('D').style = { ...defaultCellStyle }
      itemsHeaderRow.getCell('E').value = 'LORDO'
      itemsHeaderRow.getCell('E').style = { ...defaultCellStyle }
      itemsHeaderRow.getCell('F').value = 'IMPONIBILE'
      itemsHeaderRow.getCell('F').style = { ...defaultCellStyle }
      itemsHeaderRow.getCell('G').value = 'IVA'
      itemsHeaderRow.getCell('G').style = { ...defaultCellStyle }
      itemsHeaderRow.getCell('H').value = 'TOTALE'
      itemsHeaderRow.getCell('H').style = { ...defaultCellStyle }

      itemsHeaderRow.getCell('I').value = 'ISTRUTTORE'

      let totalVat = 0

      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        const newRow = worksheet.getRow(6 + i)
        const eventDetails: Record<string, any> = {}

        if (item.plannedEventIds?.length && item.plannedEventIds.length === 1 && !item.isDrivingPackage) {
          const { data: evt } = await client.query({
            query: GET_CUSTOMER_EVENT,
            variables: { id: item.plannedEventIds[0] },
          })
          eventDetails.date = new Date(evt.plannedEvent.event.startDate)
          eventDetails.type = evt.plannedEvent.dsEventType

          if (eventDetails.type === 'DEFAULT' && evt.plannedEvent.teamMemberEvents?.length) {
            eventDetails.teamMemberName = evt.plannedEvent.teamMemberEvents[0].teamMember?.fullName
            if (evt.plannedEvent.vehicleId) {
              const { data: vehicle } = await dataProvider.getOne('Vehicle', { id: evt.plannedEvent.vehicleId })
              eventDetails.licensePlate = vehicle?.licensePlate
            }
          }
        }

        newRow.getCell('B').value = new Date(item.date).toLocaleDateString()
        newRow.getCell('B').style = { border }

        newRow.getCell('C').value = `${item.description}${item.relatedTransactionId ? ' ⚖' : ''}${
          item.isDrivingPackage && isOutcome(item) && item.remainingDuration
            ? ` (${item.remainingDuration} minuti rimanenti)`
            : eventDetails.date
            ? ` - ${eventDetails.date.toLocaleString().slice(0, -3)}${
                eventDetails.type === 'DEFAULT' ? ` (${eventDetails.licensePlate})` : ''
              }`
            : ''
        }`
        newRow.getCell('C').style = { border }

        newRow.getCell('D').value = isOutcome(item) ? item.discountAmount : null
        newRow.getCell('D').style = { ...defaultPriceCellStyle }

        newRow.getCell('E').value = item.salePrice
        newRow.getCell('E').style = { ...defaultPriceCellStyle }

        newRow.getCell('F').value = isOutcome(item) ? item.netPrice : null
        newRow.getCell('F').style = { ...defaultPriceCellStyle }

        newRow.getCell('G').value = isOutcome(item) ? `${item.taxRate}%` : null
        newRow.getCell('G').style = { ...defaultCellStyle }

        newRow.getCell('H').value = isOutcome(item) ? -item.total : item.total
        newRow.getCell('H').style = {
          ...defaultPriceCellStyle,
          font: { color: isOutcome(item) ? { argb: 'FFDD1717' } : undefined },
        }

        if (eventDetails.teamMemberName) {
          newRow.getCell('I').value = eventDetails.teamMemberName
        }

        if (isOutcome(item) && typeof item.salePrice === 'number' && typeof item.netPrice === 'number') {
          totalVat += item.salePrice - item.netPrice
        }
      }

      const totalVatRow = worksheet.getRow(6 + items.length + 1)
      totalVatRow.getCell('D').value = 'TOTALE IVA'
      totalVatRow.getCell('D').style = { border, font: { bold: true } }
      totalVatRow.getCell('E').style = { border }
      totalVatRow.getCell('F').style = { border }
      totalVatRow.getCell('G').style = { border }
      totalVatRow.getCell('H').value = totalVat
      totalVatRow.getCell('H').style = { ...defaultPriceCellStyle, font: { bold: true } }

      const remainingAmountRow = worksheet.getRow(6 + items.length + 2)
      remainingAmountRow.getCell('D').value = 'DA PAGARE'
      remainingAmountRow.getCell('D').style = { border, font: { bold: true } }
      remainingAmountRow.getCell('E').style = { border }
      remainingAmountRow.getCell('F').style = { border }
      remainingAmountRow.getCell('G').style = { border }
      remainingAmountRow.getCell('H').value = record.remainingAmount
      remainingAmountRow.getCell('H').style = { ...defaultPriceCellStyle, font: { bold: true } }

      const paidAmountRow = worksheet.getRow(6 + items.length + 3)
      paidAmountRow.getCell('D').value = 'PAGATO'
      paidAmountRow.getCell('D').style = { border, font: { bold: true } }
      paidAmountRow.getCell('E').style = { border }
      paidAmountRow.getCell('F').style = { border }
      paidAmountRow.getCell('G').style = { border }
      paidAmountRow.getCell('H').value = record.paidAmount
      paidAmountRow.getCell('H').style = { ...defaultPriceCellStyle, font: { bold: true } }

      const totalAmountRow = worksheet.getRow(6 + items.length + 4)
      totalAmountRow.getCell('D').value = 'TOTALE'
      totalAmountRow.getCell('D').style = { border, font: { bold: true } }
      totalAmountRow.getCell('E').style = { border }
      totalAmountRow.getCell('F').style = { border }
      totalAmountRow.getCell('G').style = { border }
      totalAmountRow.getCell('H').value = record.totalAmount
      totalAmountRow.getCell('H').style = { ...defaultPriceCellStyle, font: { bold: true } }

      if (record.isClosed && record.discountAmount) {
        const discountAmountRow = worksheet.getRow(6 + items.length + 5)
        discountAmountRow.getCell('D').value = 'SCONTO FINALE'
        discountAmountRow.getCell('D').style = { border, font: { bold: true } }
        discountAmountRow.getCell('E').style = { border }
        discountAmountRow.getCell('F').style = { border }
        discountAmountRow.getCell('G').style = { border }
        discountAmountRow.getCell('H').value = record.totalAmount
        discountAmountRow.getCell('H').style = { ...defaultPriceCellStyle, font: { bold: true } }
      }

      const buffer = await workbook.xlsx.writeBuffer()
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `scheda_contabile-${fullCustomer.lastName}_${fullCustomer.firstName}.xlsx`
      )
    } catch (err) {
      console.log('errore print', err)
      notify('ra.error', 'error')
    } finally {
      setLoading(false)
    }
  }, [items, record])

  return (
    <>
      <IconButton
        {...props}
        onClick={generateExcelDocument}
        disabled={loading || !record || !items.length}
        className={classes.button}
        size="small"
      >
        {loading ? <CircularProgress size={22} /> : <PrintIcon />}
      </IconButton>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '7px',
    marginRight: theme.spacing(2),
  },
}))

export default PrintAccountingCardTransactionsButton
