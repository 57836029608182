import React, { cloneElement, FC } from 'react'
import { useShowController, useTranslate, sanitizeListRestProps, useListContext } from 'ra-core'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import {
  Loading,
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  TopToolbar,
  ExportButton,
  ReferenceField,
  FunctionField,
  SingleFieldList,
  ReferenceManyField,
  ChipField,
} from 'react-admin'
import CustomerCoursePlanningsTotalField from '../../components/CustomerCoursePlanningsTotalField'
import QuickEditCoursePlanningButton from '../../components/QuickEditCoursePlanningButton'
import UserPreviewField from '../../components/UserPreviewField'
import QuickCreateExamPlanningButton from '../../components/QuickCreateExamPlanningButton'
import CustomerExamPlanningsTotalField from '../../components/CustomerExamPlanningsTotalField'

const useStyles = makeStyles((theme) => ({
  root: {},
  userAvatar: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

type Props = {
  //
}

const ListActions: FC<any> = (props) => {
  const { className, exporter, filters, maxResults, title, ...rest } = props
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <QuickCreateExamPlanningButton examId={props.examId} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  )
}

const fakeListProps = {
  basePath: '/ExamPlanning',
  hasCreate: false,
  hasEdit: false,
  hasList: true,
  hasShow: true,
  history: {},
  location: { pathname: '/', search: '', hash: '', state: undefined },
  match: { path: '/', url: '/', isExact: true, params: {} },
  options: {},
  permissions: null,
  resource: 'ExamPlanning',
}

const defaultPlanningSort = { field: 'startDate', order: 'DESC' }

const ExamShow: FC<any> = (props) => {
  const classes = useStyles(props)
  const {
    basePath, // deduced from the location, useful for action buttons
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    loaded, // boolean that is false until the record is available
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
    resource, // the resource name, deduced from the location. e.g. 'posts'
    version, // integer used by the refresh feature
  } = useShowController(props)
  const translate = useTranslate()

  if (loading || !record) {
    return <Loading />
  }

  return (
    <Container maxWidth={false}>
      {/* <pre>{JSON.stringify(record, null, 2)}</pre> */}
      {/* <Box mt={3}>
        <Typography variant="h1">{record.name}</Typography>
      </Box> */}
      <List
        {...fakeListProps}
        filterDefaultValues={{ examId: record.id }}
        actions={<ListActions title={record.name} examId={record.id} />}
        sort={defaultPlanningSort}
        title={`Pianificazioni per ${record.name}`}
      >
        <Datagrid rowClick="show">
          <TextField source="id" />
          <ReferenceManyField
            label={translate('resources.ExamPlanning.fields.teamMemberIds')}
            reference="ExamPlanningTeamMember"
            target="examPlanningId"
            link={false}
          >
            <SingleFieldList className={classes.userAvatar}>
              <ReferenceField link={false} source="teamMemberId" reference="TeamMember">
                <UserPreviewField />
              </ReferenceField>
            </SingleFieldList>
          </ReferenceManyField>
          <DateField showTime source="startDate" />
          <DateField showTime source="endDate" />
          <CustomerExamPlanningsTotalField
            record={record}
            label={translate('resources.ExamPlanning.fields.totalPartecipants')}
          />
          {/* <QuickEditCoursePlanningButton /> */}
        </Datagrid>
      </List>
    </Container>
  )
}

export default ExamShow
