import React, { FC } from 'react'
import { useQueryWithStore, ReferenceField, TextField, DateField } from 'react-admin'
import { Typography, makeStyles, LinearProgress, Card, CardContent } from '@material-ui/core'
import { Link } from 'react-router-dom'

type Props = {
  record: {
    customerId: string
    openingDate: string
    expiringDate: string
    isClosed: boolean
  }
}

const ExamField = ({ id, openingDate }: any): any => {
  const classes = useStyles()
  const { loading, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'ExamPlanning',
    payload: { id },
  })

  return loading || error || !data ? (
    <LinearProgress />
  ) : openingDate < data.startDate ? (
    <p className={classes.examField}>
      <Link to={`/ExamPlanning/${id}/show`}>
        <ReferenceField
          basePath="/ExamPlanning"
          source="examId"
          record={data}
          reference="Exam"
          label={undefined}
          link={false}
          variant="caption"
        >
          <TextField source="name" />
        </ReferenceField>
        <span>{': '}</span>
        <DateField record={data} source="startDate" showTime variant="caption" />
      </Link>
    </p>
  ) : null
}

const DatesSection: FC<Props> = ({ record }: Props) => {
  const classes = useStyles()
  const { data, loading } = useQueryWithStore({
    type: 'getList',
    resource: 'ExamPlanningCustomer',
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
      filter: { customerId: record.customerId },
    },
  })

  return (
    <Card className={classes.container}>
      <CardContent className={classes.content}>
        <div>
          <div>
            <Typography variant="caption">{'Istruzione protocollo:'}&nbsp;</Typography>
            <DateField variant="caption" record={record} source="openingDate" />
          </div>
          <div>
            <Typography variant="caption">{'Scadenza:'}&nbsp;</Typography>
            <DateField variant="caption" record={record} source="expiringDate" />
          </div>
          {record.isClosed && (
            <div>
              <Typography variant="h6">
                {'Pratica chiusa il:'}&nbsp;
                <DateField record={record} source="closingDate" variant="h6" />
              </Typography>
            </div>
          )}
        </div>
        <div>
          {!loading && data ? (
            data.map((elem: any, idx: any) => (
              <ExamField key={`examField-${idx}`} id={elem.examPlanningId} openingDate={record.openingDate} />
            ))
          ) : (
            <LinearProgress />
          )}
        </div>
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    width: 'inherit',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  examField: {
    textAlign: 'end',
  },
}))

export default DatesSection
