import React, { FC, useEffect } from 'react'
import { useForm, useFormState, Field } from 'react-final-form'
import { NumberInput, SelectInput, BooleanInput, DateInput, RadioButtonGroupInput } from 'react-admin'
import {
  getISODay,
  getDate as getDayOfMonth,
  getWeekOfMonth,
  startOfMonth,
  format as formatDate,
  addMonths,
  isSameDay,
  isBefore,
} from 'date-fns'
import { useGetOne } from 'ra-core'
import { it as itLocale } from 'date-fns/locale'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'

type Props = {
  // id?: string
  // record?: any
  setIsRecurringEventInvalid: React.Dispatch<React.SetStateAction<boolean>>
  [x: string]: any
}

const recurringModeChoices = [
  { id: 'DAILY', name: 'giorno,giorni' },
  { id: 'WEEKLY', name: 'settimana,settimane' },
  { id: 'MONTHLY', name: 'mese,mesi' },
]

const WEEKDAYS = [
  { label: 'L', value: 1 },
  { label: 'M', value: 2 },
  { label: 'M', value: 3 },
  { label: 'G', value: 4 },
  { label: 'V', value: 5 },
  { label: 'S', value: 6 },
  { label: 'D', value: 7 },
]

const monthMode = [{ id: 'SAMEDAY' }, { id: 'WEEKDAY' }]

const endsChoices = [
  { id: 'endsAfterOccurrencies', name: 'n. di occorrenze' },
  { id: 'endsAfterDate', name: 'giorno specifico' },
]

const minValue = (min: number, message = `Minimo ${min}`) => (value: any) =>
  !Number.isNaN(value) && value < min ? message : undefined

const maxValue = (max: number, message = `Massimo ${max}`) => (value: any) =>
  value && value > max ? message : undefined

const isDateBefore = (startDate: Date, message = '> Data di inizio') => (value: any) =>
  value && (isBefore(new Date(value), new Date(startDate)) || isSameDay(new Date(value), new Date(startDate)))
    ? message
    : undefined

const validateOccurrencies = [minValue(2), maxValue(99)]
const validateRepeatEvery = [minValue(1)]

const RecurringEventFormSection: FC<Props> = ({ setIsRecurringEventInvalid, ...props }) => {
  const classes = useStyles()
  const form = useForm()
  const { values } = useFormState()
  const ISODay = getISODay(new Date(values?.startDate))
  // console.log('xxx values: ', values)

  const { loading, data, error } = useGetOne('RecurringEvent', values?.recurringEventId, {
    enabled: values?.recurringEventId,
  })

  useEffect(() => {
    if (values?.recurringEventId && !loading && data) {
      form.change('isRecurringEvent', true)
      if (data?.recurringMode === 'WEEKLY') {
        const { weekDays: stringDays, ...rest } = data
        const strWeekDays = stringDays?.split('-')
        const weekDays = strWeekDays.map((e: string) => parseInt(e))
        form.change('recurringEvent', { ...rest, weekDays })
      } else {
        form.change('recurringEvent', data)
      }
    }
  }, [values?.recurringEventId, loading, data])

  // weekDays validation
  const weekDaysValidation = () => {
    if (values.recurringEvent.recurringMode === 'WEEKLY') {
      if (!values.recurringEvent?.weekDays?.length) {
        return 'Seleziona almeno un giorno'
      }
      if (!values.recurringEvent?.weekDays?.includes(ISODay)) {
        return 'Seleziona anche il giorno della settimana della data di inizio'
      }
    }
  }
  const weekDaysError =
    values?.recurringEvent?.recurringMode === 'WEEKLY' &&
    (!values?.recurringEvent?.weekDays?.length || !values.recurringEvent?.weekDays?.includes(ISODay))
      ? true
      : false

  // useEffect to check validity for the whole section
  useEffect(() => {
    if (
      values?.isRecurringEvent &&
      (values?.recurringEvent?.repeatEvery < 1 ||
        (values?.recurringEvent?.recurringMode === 'WEEKLY' && weekDaysError) ||
        (values?.endMode === 'endsAfterOccurrencies' &&
          (values?.recurringEvent?.endsAfterOccurrencies < 2 || values?.recurringEvent?.endsAfterOccurrencies > 99)) ||
        (values?.endMode === 'endsAfterDate' &&
          (isBefore(new Date(values?.recurringEvent?.endsAfterDate), new Date(values?.startDate)) ||
            isSameDay(new Date(values?.recurringEvent?.endsAfterDate), new Date(values?.startDate)))))
    ) {
      setIsRecurringEventInvalid(true)
    } else {
      setIsRecurringEventInvalid(false)
    }
  }, [
    values?.isRecurringEvent,
    weekDaysError,
    values?.recurringEvent?.repeatEvery,
    values?.recurringEvent?.recurringMode,
    values?.endMode,
    values?.recurringEvent?.endsAfterOccurrencies,
    values?.recurringEvent?.endsAfterDate,
  ])

  // useEffects to clean all related recurringEvent values
  useEffect(() => {
    if (values?.recurringEvent?.recurringMode !== 'WEEKLY') {
      form.change('recurringEvent.weekDays', undefined)
    }
    if (values?.recurringEvent?.recurringMode !== 'MONTHLY') {
      form.change('recurringEvent.monthMode', undefined)
    }
  }, [values?.recurringEvent?.recurringMode])

  useEffect(() => {
    if (values?.startDate && values?.endMode === 'endsAfterDate') {
      form.change('recurringEvent.endsAfterDate', addMonths(new Date(values?.startDate), 3))
    }
    if (values?.endMode === 'endsAfterOccurrencies') {
      form.change('recurringEvent.endsAfterDate', undefined)
    }
    if (values?.endMode === 'endsAfterDate') {
      form.change('recurringEvent.endsAfterOccurrencies', undefined)
    }
  }, [values?.startDate, values?.endMode])

  useEffect(() => {
    if (!values?.isRecurringEvent) {
      form.change('recurringEvent', undefined)
      form.change('isRecurringEvent', undefined)
    }
  }, [values?.isRecurringEvent])

  return values?.recurringEventId && loading ? (
    <LinearProgress />
  ) : (
    <>
      <BooleanInput label={'è un evento ricorrente?'} source="isRecurringEvent" />
      {values?.isRecurringEvent && (
        <>
          <div className={classes.row}>
            <span>{'Ripeti ogni:'}</span>
            <NumberInput
              variant="outlined"
              source="recurringEvent.repeatEvery"
              label={false}
              min={1}
              step={1}
              initialValue={1}
              className={classes.numberInput}
              validate={validateRepeatEvery}
            />
            <SelectInput
              variant="outlined"
              source="recurringEvent.recurringMode"
              label={false}
              choices={recurringModeChoices}
              initialValue="WEEKLY"
              optionText={({ name }: { name: string }): string =>
                values?.recurringEvent?.repeatEvery === 1 ? name.split(',')[0] : name.split(',')[1]
              }
            />
          </div>
          {values.recurringEvent?.recurringMode === 'WEEKLY' && (
            <div>
              <span>{'Si ripete nei giorni:'}</span>
              <FormControl error={weekDaysError}>
                <FormGroup row>
                  {WEEKDAYS.map(({ label, value }) => (
                    <FormControlLabel
                      key={`cb-${value}`}
                      name="weekDays"
                      value={value}
                      control={
                        <Field
                          type="checkbox"
                          name="recurringEvent.weekDays"
                          initialValue={[ISODay]}
                          render={({ input }): JSX.Element => <Checkbox {...input} color="primary" />}
                        />
                      }
                      label={label}
                      labelPlacement="top"
                      classes={{ root: classes.root }}
                    />
                  ))}
                </FormGroup>
                {weekDaysError && <FormHelperText>{weekDaysValidation()}</FormHelperText>}
              </FormControl>
            </div>
          )}
          {values.recurringEvent?.recurringMode === 'MONTHLY' && (
            <SelectInput
              fullWidth
              variant="outlined"
              source="recurringEvent.monthMode"
              label={false}
              choices={monthMode}
              initialValue="SAMEDAY"
              optionText={({ id }: { id: string }): string => {
                switch (id) {
                  case 'SAMEDAY':
                    return `Ogni mese il giorno ${getDayOfMonth(new Date(values?.startDate))}`
                  case 'WEEKDAY': {
                    const tmpStartWeekNumber = getWeekOfMonth(new Date(values?.startDate), { weekStartsOn: 1 })
                    // if weekNumber > 4, then it should be alway the last week of the month

                    const startDayOfWeek = getISODay(new Date(values?.startDate))
                    const startStartOfMonthDayOfWeek = getISODay(startOfMonth(new Date(values?.startDate)))

                    const startWeekNumber =
                      startDayOfWeek < startStartOfMonthDayOfWeek ? tmpStartWeekNumber - 1 : tmpStartWeekNumber

                    let weekNumberStr
                    switch (startWeekNumber) {
                      case 1:
                        weekNumberStr = startDayOfWeek !== 7 ? 'il primo' : 'la prima'
                        break

                      case 2:
                        weekNumberStr = startDayOfWeek !== 7 ? 'il secondo' : 'la seconda'
                        break

                      case 3:
                        weekNumberStr = startDayOfWeek !== 7 ? 'il terzo' : 'la terza'
                        break

                      case 4:
                        weekNumberStr = startDayOfWeek !== 7 ? 'il quarto' : 'la quarta'
                        break

                      default:
                        weekNumberStr = startDayOfWeek !== 7 ? "l'ultimo" : "l'ultima"
                        break
                    }
                    const weekDayStr = formatDate(new Date(values?.startDate), 'EEEE', { locale: itLocale })
                    return `Ogni mese ${weekNumberStr} ${weekDayStr}`
                  }
                  default:
                    return ''
                }
              }}
            />
          )}
          <div className={classes.row}>
            <span>{'Fine:'}</span>
            <RadioButtonGroupInput
              source="endMode"
              choices={endsChoices}
              label={false}
              initialValue="endsAfterOccurrencies"
              helperText={false}
            />
          </div>
          {values?.endMode === 'endsAfterOccurrencies' && (
            <div className={classes.row}>
              <span>{'dopo '}</span>
              <NumberInput
                variant="outlined"
                source="recurringEvent.endsAfterOccurrencies"
                label={false}
                min={2}
                max={99}
                step={1}
                initialValue={10}
                validate={validateOccurrencies}
              />
              <span>{' occorrenze'}</span>
            </div>
          )}
          {values?.endMode === 'endsAfterDate' && (
            <div className={classes.row}>
              <span>{'fino al '}</span>
              <DateInput
                variant="outlined"
                source="recurringEvent.endsAfterDate"
                label={false}
                validate={[isDateBefore(values.startDate)]}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(2),
  },
  row: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  numberInput: {
    maxWidth: '80px',
  },
}))

export default RecurringEventFormSection
