import React from 'react'
import { parse } from 'query-string'
import { NumberInput, TextField, Edit, SimpleForm, TextInput, Create, SelectInput, ReferenceInput } from 'react-admin'
import AttributeValueColorInput from './AttributeValueColorInput'

const edit = (props: any): any => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  const { productFeatureId } = parse(props.location.search)
  return (
    <Edit {...props}>
      <SimpleForm
        initialValues={{ productFeatureId: productFeatureId || '' }}
        redirect={(basePath: any, id: any, data: any): string => `/ProductFeature/${productFeatureId}/show`}
      >
        <TextField source="id" />
        <TextInput source="value" />
        <ReferenceInput label="Product Feature" source="productFeatureId" reference="ProductFeature">
          <SelectInput source="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  )
}

const create = (props: any): any => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  const { productFeatureId } = parse(props.location.search)
  return (
    <Create {...props}>
      <SimpleForm
        initialValues={{ productFeatureId: productFeatureId || '' }}
        redirect={(basePath: any, id: any, data: any): string => `/ProductFeature/${productFeatureId}/show`}
      >
        <TextInput source="value" />
        <ReferenceInput label="Product Feature" source="productFeatureId" reference="ProductFeature">
          <SelectInput source="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

export default { name: 'ProductFeatureValue', create, edit }
