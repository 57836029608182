import React, { FC, useCallback, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MoreVert from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { useDeleteWithConfirmController, useTranslate } from 'ra-core'
import { Confirm } from 'ra-ui-materialui'
import inflection from 'inflection'
import QuickEditCustomerContactModal from './QuickEditCustomerContactModal'

type Props = {
  record?: any
  [x: string]: any
}

const ContactMoreMenu: FC<Props> = ({ record }) => {
  const translate = useTranslate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)

  const onMoreClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const onMoreClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleEditModalClose = useCallback(() => {
    setEditModalOpen(false)
  }, [])

  const handleEditModalOpen = useCallback(() => {
    setEditModalOpen(true)
    onMoreClose()
  }, [onMoreClose])

  const {
    open,
    loading,
    handleDialogOpen: handleDeleteDialogOpen,
    handleDialogClose: handleDeleteModalClose,
    handleDelete,
  } = useDeleteWithConfirmController({
    resource: 'CustomerContact',
    record,
    redirect: false,
    basePath: '/Customer',
    onClick: undefined,
  })

  const handleDeleteModalOpen = useCallback((e: any) => {
    onMoreClose()
    handleDeleteDialogOpen(e)
  }, [])

  return (
    <>
      <IconButton edge="end" aria-label="delete" onClick={onMoreClick}>
        <MoreVert />
      </IconButton>
      <Menu id={`more-${record.id}`} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onMoreClose}>
        <MenuItem onClick={handleEditModalOpen}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Modifica Contatto
        </MenuItem>
        <MenuItem onClick={handleDeleteModalOpen}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Elimina
        </MenuItem>
      </Menu>

      <QuickEditCustomerContactModal
        open={editModalOpen}
        onClose={handleEditModalClose}
        record={record}
        id={record.id}
      />

      <Confirm
        isOpen={open}
        loading={loading}
        title="ra.message.delete_title"
        content="ra.message.delete_content"
        translateOptions={{
          name: translate('resources.CustomerContact.forcedCaseName', {
            smart_count: 1,
            _: inflection.humanize(
              translate('resources.CustomerContact.name', {
                smart_count: 1,
                _: inflection.singularize('CustomerContact'),
              }),
              true
            ),
          }),
          id: record.id,
        }}
        onConfirm={handleDelete}
        onClose={handleDeleteModalClose}
      />
    </>
  )
}

export default ContactMoreMenu
