import React, { FC, useState } from 'react'
import Button, { ButtonProps } from '@material-ui/core/Button'
import { useDataProvider, useNotify } from 'ra-core'
import createReport from 'docx-templates'
import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/client'

const QUERY_GET_COURSE_PLANNINGS_WITH_SCHEDULES = gql`
  query GetCourseSchedulesWithPlannings($filters: CoursePlanningFilterInput) {
    coursePlannings(filters: $filters) {
      data {
        id
        startDate
        endDate
        schedules {
          teamMemberId
          dayNumber
          startTime
          finishTime
        }
      }
    }
  }
`

type Props = {
  record?: any
  [x: string]: any
} & ButtonProps

// const TEMPLATE_URL = 'https://static.drivesystem.it/Sa4EKbrKb_newtest_quote_6.docx'
const MIMETYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

const PrintQuoteButton: FC<Props> = ({ record, ...props }) => {
  // console.log('sto ricevendo: ', record, props)
  const [loading, setLoading] = useState<boolean>(false)
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const apolloClient = useApolloClient()

  const readFileIntoArrayBuffer = async (fd: Blob): Promise<any> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onerror = reject
      reader.onload = (): void => {
        resolve(reader.result)
      }
      reader.readAsArrayBuffer(fd)
    })

  const downloadURL = (data: any, fileName: string): any => {
    const a = document.createElement('a')
    a.href = data
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    a.remove()
  }

  const saveDataToFile = (data: any, fileName: string, mimeType: string): any => {
    const blob = new Blob([data], { type: mimeType })
    const url = window.URL.createObjectURL(blob)
    downloadURL(url, fileName)
    setTimeout(() => {
      window.URL.revokeObjectURL(url)
    }, 1000)
  }

  // approach n.2
  const generateDocument2 = async (): Promise<any> => {
    setLoading(true)
    try {
      const document = await dataProvider.getOne('Document', { id: record.printTemplateId })
      const response = await fetch(document.data.url)
      const blooob = await response.blob()
      const template = await readFileIntoArrayBuffer(blooob)
      // getting every data
      const { data: fullCustomer } = await dataProvider.getOne('Customer', { id: record.customerId })
      // console.log('fullCustomer', fullCustomer)
      // const { data: quoteItems } = await dataProvider.getList('QuoteItem', {
      //   pagination: { page: 1, perPage: 100 },
      //   sort: { field: 'id', order: 'ASC' },
      //   filter: { ids: record.quoteItemsIds },
      // })
      const quoteItems = [...record.quoteItems.map((item: any) => ({ ...item }))]

      const { data: customerAddresses } = await dataProvider.getList('Address', {
        filter: {
          customerId: record.customerId,
        },
        pagination: {
          page: 1,
          perPage: 25,
        },
        sort: {
          field: 'id',
          order: 'DESC',
        },
      })
      // console.log('customer address: ', customerAddresses)
      let hasCourses = false
      let hasExams = false
      const foundCourses: any[] = []
      const foundExams: any[] = []

      for (let i = 0; i < quoteItems.length; i++) {
        // const { data: quoteProduct } = await dataProvider.getOne('QuoteProduct', {
        //   id: record.quoteItems[i].quoteProductId,
        // })
        const { data: product } = await dataProvider.getOne('Product', {
          id: quoteItems[i].productId,
        })
        // console.log('ESTIMATEPRODUCT: ', quoteProduct)
        // console.log('single est product: ', quoteProduct)
        quoteItems[i].name = product.name
        // record.quoteItems[i].productId = quoteProduct.originalId
        quoteItems[i].productPriceOverride = record.quoteItems[i].productPriceOverride.toFixed(2)

        // picture??
        if (product.catalogPictureId) {
          const { data: picture } = await dataProvider.getOne('Picture', { id: product.catalogPictureId })
          if (picture && picture.urlSmall) {
            quoteItems[i].picUrl = picture.urlSmall
          }
        }

        if (!hasCourses || !hasExams) {
          const { data: drivingSchoolAttribute } = await dataProvider.getList('ProductDrivingSchoolAttribute', {
            filter: { productId: product.id },
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'id', order: 'DESC' },
          })
          // console.log('ATTRIBUTES: ', drivingSchoolAttribute)

          if (drivingSchoolAttribute.length > 0) {
            if (!hasCourses && drivingSchoolAttribute[0].coursesIds.length > 0) {
              hasCourses = true
              for (let j = 0; j < drivingSchoolAttribute[0].coursesIds.length; j++) {
                // const element = array[index];
                const { data: course } = await dataProvider.getOne('Course', {
                  id: drivingSchoolAttribute[0].coursesIds[j],
                })
                // console.log('ESISTO??????: ', course)
                const { data: result } = await apolloClient.query({
                  query: QUERY_GET_COURSE_PLANNINGS_WITH_SCHEDULES,
                  variables: { filters: { courseId: course.id } },
                })
                // course.plannin
                // console.log('ESISTO22??????: ', result)
                if (
                  result &&
                  result.coursePlannings &&
                  result.coursePlannings.data &&
                  result.coursePlannings.data.length > 0
                ) {
                  // i dont know what to do here
                  // what if multiple plannings are associated to the same course?
                  course.plannings = result.coursePlannings.data[0]
                }
                foundCourses.push(course)
              }
            }
            if (!hasExams && drivingSchoolAttribute[0].examsIds.length > 0) {
              hasExams = true
              for (let j = 0; j < drivingSchoolAttribute[0].examsIds.length; j++) {
                // const element = array[index];
                const { data: exam } = await dataProvider.getOne('Exam', { id: drivingSchoolAttribute[0].examsIds[j] })
                // console.log('DATI ESAME: ', exam)
                foundExams.push(exam)
              }
            }
          }
        }
      }

      const { data: customerContacts } = await dataProvider.getList('CustomerContact', {
        filter: {
          customerId: record.customerId,
        },
        pagination: {
          page: 1,
          perPage: 25,
        },
        sort: {
          field: 'id',
          order: 'DESC',
        },
      })
      // console.log('customercontacts: ', customerContacts)
      let teamMember: any = {}
      if (fullCustomer.mainReferenceTeamMemberId) {
        const { data: teammember } = await dataProvider.getOne('TeamMember', {
          id: fullCustomer.mainReferenceTeamMemberId,
        })
        teamMember = { ...teammember }
      }

      const fullAddress: string =
        customerAddresses.length > 0
          ? `${customerAddresses[0].street}, ${customerAddresses[0].postCode}, ${customerAddresses[0].city}`
          : 'N.D.'
      const phoneContacts =
        customerContacts.length > 0 ? customerContacts.filter((cntct: any) => cntct.contactType === 'sms') : []
      const fullTelephone = phoneContacts.length > 0 ? phoneContacts[0].contact : 'N.D.'
      const mailContacts =
        customerContacts.length > 0 ? customerContacts.filter((cntct: any) => cntct.contactType === 'email') : []
      const fullEmail = mailContacts.length > 0 ? mailContacts[0].contact : 'N.D.'

      const finalData = {
        fullName: fullCustomer.fullName,
        fullAddress,
        fullTelephone,
        fullEmail,
        fullTeamMember: fullCustomer.mainReferenceTeamMemberId ? teamMember.fullName : 'N.D.',
        fullDate: new Date(record.approvalDate).toLocaleDateString(),
        estimateItems: quoteItems,
      }
      // if (foundCourses) console.log('HO TROVATO I CORSI: ', foundCourses)
      // if (foundCourses) console.log('HO TROVATO GLI ESAMI: ', foundExams)

      // console.log('FINALDATA: ', finalData)

      const report = await createReport({
        cmdDelimiter: ['{', '}'],
        template,
        data: finalData,
        additionalJsContext: {
          getImg: async (url: string): Promise<Record<string, any>> => {
            const response = await fetch(url)
            const data = await response.arrayBuffer()
            return { width: 3, height: 2, data, extension: '.jpg' }
          },
        },
      })
      saveDataToFile(report, `preventivo-${record.reference}.docx`, MIMETYPE)
    } catch (error: any) {
      console.log('errrrrr', error)
      notify('Errore nella stampa del preventivo')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Button {...props} onClick={generateDocument2} disabled={loading}>
        {loading ? 'Elaborazione...' : 'Stampa Preventivo'}
      </Button>
    </>
  )
}

export default PrintQuoteButton
