import React, { useContext, cloneElement, FC, ReactElement, SyntheticEvent, useState } from 'react'
// import PropTypes from 'prop-types'
import Button, { ButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import ContentSave from '@material-ui/icons/Save'
import classnames from 'classnames'
import {
  useTranslate,
  useNotify,
  RedirectionSideEffect,
  SideEffectContext,
  OnSuccess,
  OnFailure,
  TransformData,
  Record,
  FormContext,
  useDataProvider,
} from 'ra-core'
import { useFormState } from 'react-final-form'
import { QUERY_GET_EVENT_STATUSES_CONFIGURATION } from '../../queries'
import { useApolloClient } from '@apollo/client'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress'
import { PriceField } from '../../components/CustomFields'
import SimpleTransactionRow, { AccountingCardTransaction } from '../AccountingCard/SimpleTransactionRow'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TextField from '@material-ui/core/TextField'

const sanitizeButtonRestProps = ({
  // The next props are injected by Toolbar
  basePath,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  onSave,
  pristine,
  record,
  redirect,
  resource,
  saving,
  submitOnEnter,
  undoable,
  ...rest
}: any): any => rest

const EditPlannedEventButton: FC<SaveButtonProps> = (props) => {
  const {
    className,
    classes: classesOverride,
    invalid,
    label = 'ra.action.save',
    disabled,
    redirect,
    saving,
    submitOnEnter,
    variant = 'contained',
    icon = defaultIcon,
    onClick,
    handleSubmitWithRedirect,
    onSave,
    onSuccess,
    onFailure,
    transform,
    plannedEvent,
    ...rest
  } = props
  const classes = useStyles(props)
  const notify = useNotify()
  const translate = useTranslate()
  const { setOnSave } = useContext(FormContext)
  const { setOnSuccess, setOnFailure, setTransform } = useContext(SideEffectContext)

  const { values } = useFormState()
  const apolloClient = useApolloClient()
  const dataProvider = useDataProvider()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false)
  const [transactions, setTransactions] = useState<any[]>([])
  const [drivingPackages, setDrivingPackages] = useState<any[]>([])
  const [oldAccountingCard, setOldAccountingCard] = useState<any>({})

  const [eventStatusConfig, setEventStatusConfig] = useState<any>({})
  const [hasIncomeTransactions, setHasIncomeTransactions] = useState<boolean>(false)

  const [isDiscountMode, setIsDiscountMode] = useState<boolean>(false)
  const [discountPercentage, setDiscountPercentage] = useState<number>(0)
  const [discountAmount, setDiscountAmount] = useState<number>(0)

  const handleClick = async (event: any): Promise<void> => {
    // deprecated: use onSuccess and transform instead of onSave
    if (!handleSubmitWithRedirect) return
    if (typeof onSave === 'function' && setOnSave) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('<SaveButton onSave> prop is deprecated, use the onSuccess prop instead.')
      }
      setOnSave(onSave)
    } else {
      // we reset to the Form default save function
      if (setOnSave) setOnSave()
    }
    if (onSuccess && setOnSuccess) {
      setOnSuccess(onSuccess)
    }
    if (onFailure && setOnFailure) {
      setOnFailure(onFailure)
    }
    if (transform && setTransform) {
      setTransform(transform)
    }
    if (saving) {
      // prevent double submission
      event.preventDefault()
    } else {
      if (invalid) {
        notify('ra.message.invalid_form', 'warning')
      }
      // always submit form explicitly regardless of button type
      if (event) {
        event.preventDefault()
      }

      const { data: eventStatusConfiguration } = await apolloClient.query({
        query: QUERY_GET_EVENT_STATUSES_CONFIGURATION,
      })
      setEventStatusConfig(eventStatusConfiguration)
      // checking if new status is different from old status and old status is "confirmed"
      if (
        plannedEvent?.plannedEvent?.customerEvents?.length &&
        plannedEvent.plannedEvent.customerEvents[0].status.id !== values.customerEventStatus &&
        eventStatusConfiguration.getConfiguration.value.confirmed.includes(
          plannedEvent.plannedEvent.customerEvents[0].status.id
        )
      ) {
        // new status -> values.customerEventStatus
        const x = await dataProvider.getList('TransactionPlannedEvent', {
          filter: { plannedEventId: values.id },
          pagination: { page: 1, perPage: 2 },
          sort: { field: 'id', order: 'DESC' },
        })
        // console.log('XXX tpes involved?? ', x)
        if (x && x.total) {
          setIsDialogLoading(true)
          setIsDialogOpen(true)
          const ACTransactions = []

          for (let i = 0; i < x.data.length; i++) {
            const id = x.data[i].accountingCardTransactionId
            const accountingCardTransaction = await dataProvider.getOne('AccountingCardTransaction', { id })
            // console.log('xxx transaction: ', accountingCardTransaction)

            ACTransactions.push({ ...accountingCardTransaction.data, associationId: x.data[i].id })
          }
          const accountingCardId = (ACTransactions[0] as any).accountingCardId
          const card = await dataProvider.getOne('AccountingCard', { id: accountingCardId })
          // console.log('xxx found card: ', card)

          setOldAccountingCard(card.data)

          const onlyTransactions = [...ACTransactions.filter((elem: any) => !elem.isDrivingPackage)]
          const onlyPackages = [...ACTransactions.filter((elem: any) => elem.isDrivingPackage)]
          setHasIncomeTransactions(onlyTransactions.some((elem: any) => elem.type === 'INCOME'))
          setDrivingPackages(onlyPackages)
          const onlyTransOutcome = onlyTransactions.filter((elem: any) => elem.type === 'OUTCOME')
          setTransactions(onlyTransOutcome)
          setIsDialogLoading(false)

          if (eventStatusConfiguration.getConfiguration.value.sales?.includes(values.customerEventStatus)) {
            const status = await dataProvider.getOne('EventStatus', { id: values.customerEventStatus })
            if (status?.data?.name) {
              const discPerc: number = 100 - parseInt(status.data.name.split(' ')[1].split('%')[0])
              setDiscountPercentage(discPerc)
              const amount =
                ((onlyTransactions.filter((elem: any) => elem.type === 'OUTCOME')[0] as any).salePrice * discPerc) / 100
              setDiscountAmount(parseFloat(amount.toFixed(2)))
            }
            if (onlyTransOutcome.length) {
              setIsDiscountMode(true)
            } else {
              handleSubmitWithRedirect(redirect)
            }
          }
          // spezzare il codice qui
        } else {
          handleSubmitWithRedirect(redirect)
        }
      } else {
        handleSubmitWithRedirect(redirect)
      }
    }

    if (typeof onClick === 'function') {
      onClick(event)
    }
  }

  const onDialogClose = (): void => setIsDialogOpen(false)

  const onOkClick = async (): Promise<void> => {
    setIsDialogLoading(true)
    if (isDiscountMode) {
      // new status is part of "sales" status, should show a different dialog
      // including a small form to apply a discount on a selected accountingCardTransaction
      if (discountAmount > 0) {
        const updateRes = await dataProvider.update('AccountingCardTransaction', {
          id: transactions[0].id,
          previousData: { ...transactions[0] },
          data: { discountAmount },
        })
        // console.log('xxx ho aggiornato sta merda di transazione: ', updateRes)
      }
    } else {
      // following code is good only if new status is not in "sales"
      if (transactions?.length) {
        for (let i = 0; i < transactions.length; i++) {
          const element = transactions[i]
          const a = await dataProvider.delete('AccountingCardTransaction', {
            id: element.id,
            previousData: { id: element.id },
          })
          // console.log('xxx removing a whole transaction, id: ', element.id)
        }
      } else {
        if (drivingPackages.length) {
          for (let i = 0; i < drivingPackages.length; i++) {
            const element = drivingPackages[i]
            const a = await dataProvider.delete('TransactionPlannedEvent', {
              id: element.associationId,
              previousData: { id: element.associationId },
            })
            // console.log('xxx deleting only TPE from a package, ', element.associationId, element.id)
          }
        }
      }
    }
    setIsDialogOpen(false)
    if (handleSubmitWithRedirect) {
      handleSubmitWithRedirect(redirect)
    }
  }

  const onDiscountChange = (event: any): void => {
    const perc = event.target.value
    setDiscountPercentage(perc)
    const amount = (transactions[0].salePrice * perc) / 100
    setDiscountAmount(parseFloat(amount.toFixed(2)))
  }

  const type = submitOnEnter ? 'submit' : 'button'
  const displayedLabel = label && translate(label, { _: label })
  return (
    <>
      <Dialog open={isDialogOpen} onClose={onDialogClose} fullWidth maxWidth="md">
        <DialogTitle id="alert-dialog-title">{'Modifiche scheda contabile'}</DialogTitle>
        {isDiscountMode ? (
          <DialogContent>
            <DialogContentText>
              {
                'Attenzione! L\'evento appena modificato è associato ad una transazione in una scheda contabile, ed hai selezionato, come nuovo stato, uno stato che potrebbe prevedere uno sconto. Applicare lo sconto alla seguente transazione? '
              }
              <Table size="small">
                <TableBody>
                  <SimpleTransactionRow
                    record={transactions[0] || drivingPackages[0]}
                    hideMenu
                    accountingCardRecord={oldAccountingCard}
                  />
                </TableBody>
              </Table>
              <p className={classes.discountRow}>
                {'Percentuale di sconto: '}
                <TextField
                  id="discountPercentage"
                  type="number"
                  label="%"
                  value={discountPercentage}
                  onChange={onDiscountChange}
                  variant="outlined"
                  size="small"
                />
                <TextField
                  id="discountAmount"
                  type="number"
                  label="€"
                  value={discountAmount}
                  variant="outlined"
                  disabled
                  size="small"
                />
              </p>
              {hasIncomeTransactions
                ? 'Attenzione! Esiste anche una transazione in INGRESSO associata a questo evento, sistemarla manualmente nella scheda contabile.'
                : ''}
            </DialogContentText>
          </DialogContent>
        ) : (
          <DialogContent>
            {isDialogLoading ? (
              <LinearProgress />
            ) : transactions.length ? (
              <DialogContentText id="alert-dialog-description">
                {
                  'Attenzione! L\'evento appena modificato comporta una modifica di una scheda contabile. Le seguenti transazioni verranno eliminate:'
                }
                <Table size="small">
                  <TableBody>
                    {transactions.map((elem: AccountingCardTransaction) => (
                      <SimpleTransactionRow record={elem} hideMenu accountingCardRecord={oldAccountingCard} />
                    ))}
                  </TableBody>
                </Table>
                <p>
                  {hasIncomeTransactions &&
                    'Attenzione! Esiste una transazione in INGRESSO associata a questo evento, gestirla manualmente nella scheda contabile.'}
                </p>
                {'I totali prima della modifica sono: '}
                <p>
                  {'Totale: '}
                  <PriceField record={oldAccountingCard} source="totalAmount" />
                </p>
                <p>
                  {'Pagato: '}
                  <PriceField record={oldAccountingCard} source="paidAmount" color="green" />
                </p>
                <p>
                  {'Da pagare: '}
                  <PriceField record={oldAccountingCard} source="remainingAmount" color="red" />
                </p>
              </DialogContentText>
            ) : drivingPackages.length ? (
              <DialogContentText id="alert-dialog-description">
                {
                  'Attenzione! L\'evento appena modificato era associato ai seguenti pacchetti guida nella scheda contabile: '
                }
                <Table size="small">
                  <TableBody>
                    {transactions.map((elem: AccountingCardTransaction) => (
                      <SimpleTransactionRow record={elem} hideMenu accountingCardRecord={oldAccountingCard} />
                    ))}
                  </TableBody>
                </Table>
                {'Tale associazione verrà rimossa, senza comportare alcuna modifica dei totali.'}
              </DialogContentText>
            ) : null}
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={onDialogClose} color="primary">
            Annulla
          </Button>
          <Button onClick={onOkClick} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        className={classnames(classes.button, className)}
        variant={variant}
        type={type}
        onClick={handleClick}
        color={saving ? 'default' : 'primary'}
        aria-label={displayedLabel}
        disabled={disabled}
        {...sanitizeButtonRestProps(rest)}
      >
        {saving ? (
          <CircularProgress size={18} thickness={2} className={classes.leftIcon} />
        ) : (
          cloneElement(icon, {
            className: classnames(classes.leftIcon, classes.icon),
          })
        )}
        {displayedLabel}
      </Button>
    </>
  )
}

const defaultIcon = <ContentSave />

const useStyles = makeStyles(
  (theme) => ({
    button: {
      position: 'relative',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    icon: {
      fontSize: 18,
    },
    discountRow: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
  }),
  { name: 'RaSaveButton' }
)

interface Props {
  classes?: any
  className?: string
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void
  // @deprecated
  onSave?: (values: any, redirect: RedirectionSideEffect) => void
  onSuccess?: OnSuccess
  onFailure?: OnFailure
  transform?: TransformData
  icon?: ReactElement
  invalid?: boolean
  label?: string
  onClick?: () => void
  disabled?: boolean
  redirect?: RedirectionSideEffect
  saving?: boolean
  submitOnEnter?: boolean
  variant?: string
  // May be injected by Toolbar - sanitized in Button
  basePath?: string
  handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<any>
  record?: Record
  resource?: string
  undoable?: boolean
  plannedEvent?: any
  eventStatusConfiguration?: any
}

type SaveButtonProps = Props & ButtonProps

export default EditPlannedEventButton
