import React, { FC } from 'react'
import RAList from '../../../components/List'
import { TextField, NumberField, Datagrid, ReferenceField } from 'react-admin'
import EditButtonWPermission from '../../../components/EditButtonWPermission'
import VehicleModelFilters from './VehicleModelFilters'

type Props = {}

const VehicleModelList: FC<Props> = (props) => {
  return (
    <RAList {...props} filters={<VehicleModelFilters />}>
      <Datagrid>
        <NumberField source="id" />
        <ReferenceField source="vehicleManufacturerId" reference="VehicleManufacturer">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
        <ReferenceField source="vehicleTypeId" reference="VehicleType">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="vehicleFuelId" reference="VehicleFuel">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="modelYear" />
        <EditButtonWPermission {...props} />
      </Datagrid>
    </RAList>
  )
}

export default VehicleModelList
