import React, { FC, useCallback } from 'react'
import { TextInput, ReferenceInput, AutocompleteInput, BooleanInput } from 'react-admin'
import FormSection from '../../components/forms/FormSection'

type Props = {
  //
}

const CourseForm: FC<Props> = (props) => {
  const filterToQuery = useCallback(() => (name: string): Record<string, string> => ({ name }), [])
  return (
    <FormSection title="Nome" {...props}>
      <TextInput source="name" />
    </FormSection>
  )
}

export default CourseForm
