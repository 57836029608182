import React from 'react'
import {
  NumberInput,
  TextField,
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
  ReferenceInput,
  ReferenceField
} from 'react-admin'
import AttributeValueColorInput from './AttributeValueColorInput'
import Edit from './Edit'

const edit = (props: any): any => {
  return (
    <Edit {...props}>
      <SimpleForm
        redirect={(basePath: any, id: any, data: any): string => `/ProductAttribute/${data.productAttributeId}/show`}
      >
        <TextField source="id" />
        <TextInput source="value" />
        <TextInput source="label" />
        <AttributeValueColorInput source="color" />
        <NumberInput source="order" />

        <ReferenceInput label="Product Attribute" source="productAttributeId" reference="ProductAttribute">
          <SelectInput source="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  )
}

const create = (props: any): any => {
  console.log(props)
  return (
    <Create {...props}>
      <SimpleForm
        redirect={(basePath: any, id: any, data: any): string => `/ProductAttribute/${data.productAttributeId}/show`}
      >
        <TextInput source="value" />
        <TextInput source="label" />
        <AttributeValueColorInput source="color" />
        <NumberInput source="order" />
        <ReferenceInput label="Product Attribute" source="productAttributeId" reference="ProductAttribute">
          <SelectInput source="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

export default { name: 'ProductAttributeValue', create, edit }
