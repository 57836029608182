import { useTranslate } from 'ra-core'
import React, { FC, useCallback } from 'react'
import { TextInput, ReferenceInput, AutocompleteInput, BooleanInput, FileInput, FileField } from 'react-admin'
import FormSection from '../../components/forms/FormSection'
import HiddenDocumentTagField from '../../components/forms/HiddenDocumentTagField'

type Props = {}

const DocumentForm: FC<Props> = (props) => {
  const translate = useTranslate()
  return (
    <FormSection
      title="Template di Stampa"
      subtitle="Scegli un file Word (.docx) da usare come template di stampa per i preventivi"
      {...props}
    >
      {/* <HiddenDocumentTagField /> */}
      <TextInput source="description" />
      {/* <TextInput source="tag" /> */}

      <FileInput multiple={false} source="file" label="File associati">
        <FileField source="src" title="title" />
      </FileInput>
    </FormSection>
  )
}

export default DocumentForm
