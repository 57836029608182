import React, { FC } from 'react'
import { FunctionField, LinearProgress } from 'react-admin'
import gql from 'graphql-tag'

import { useQuery } from '@apollo/client'

const QUERY_GET_CUSTOMERS_PLANNING_TOTAL = gql`
  query GetCustomersPlanningTotal($filters: CustomerCoursePlanningFilterInput) {
    customerCoursePlannings(pagination: { offset: 0, limit: 1 }, filters: $filters) {
      total
    }
  }
`

type Props = {
  record?: any
  [x: string]: any
}

const CustomerCoursePlanningsTotalField: FC<Props> = ({ record, ...rest }) => {
  const { loading, data } = useQuery(QUERY_GET_CUSTOMERS_PLANNING_TOTAL, {
    variables: {
      filters: {
        coursePlanningId: record.id,
      },
    },
  })
  if (loading || !data.customerCoursePlannings) {
    return <LinearProgress />
  }
  // return <div>{JSON.stringify(data)}</div>
  return (
    <FunctionField
      la
      record={data.customerCoursePlannings}
      {...rest}
      render={(record: { total: number }): any => record.total}
    />
  )
}

export default CustomerCoursePlanningsTotalField
