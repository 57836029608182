import React, { FC, useEffect } from 'react'
import { useMutation } from 'react-admin'
import { CircularProgress, IconButton, makeStyles } from '@material-ui/core'
import { DeleteForever, Error } from '@material-ui/icons'

const HourDeleter: FC<Record<string, any>> = ({ id, confirmAction }) => {
  const classes = useStyles()
  const [onClick, { loading, loaded, error }] = useMutation({
    type: 'delete',
    resource: 'OpeningHour',
    payload: { id },
  })

  useEffect(() => {
    if (!loading && loaded) {
      confirmAction()
    }
  }, [loading, loaded])

  return loading ? (
    <CircularProgress />
  ) : (
    <IconButton className={classes.button} {...{ onClick }}>
      {error ? <Error /> : <DeleteForever />}
    </IconButton>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    alignSelf: 'center',
    color: theme.palette.error.dark,
    borderRadius: 6,
    padding: theme.spacing(1),
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
}))

export default HourDeleter
