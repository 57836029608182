export const calculateTax = (price: any, taxRate: any): number => {
  const p = parseFloat(price)
  const t = parseFloat(taxRate)

  return (p / 100) * t
}

export const EURformat = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
})

export const numToEUR = (value: number): string => EURformat.format(value)
