import React, { FC, useCallback } from 'react'
import { IconButton } from '@material-ui/core'
import { useReactToPrint } from 'react-to-print'
import PrintIcon from '@material-ui/icons/Print'
import { CalendarApi } from '@fullcalendar/core'

type Props = {
  contentRef: any
}

const PrintCalendarPlanningButton: FC<Props> = ({ contentRef }) => {
  const reactToPrintContent = React.useCallback(() => {
    return contentRef.current
  }, [contentRef.current])

  const handleCalendarPrint = useReactToPrint({
    content: reactToPrintContent,
    copyStyles: true,
    pageStyle:
      '@page { size: auto;  margin: 0mm; } @media print { body { max-height: 100vh; -webkit-print-color-adjust: exact; } }',
    onPrintError: (errorLocation, error) => {
      console.log('si spacca tutto', errorLocation, error)
    },
    onBeforeGetContent: async () => {
      return new Promise((resolve, reject) => {
        window.dispatchEvent(new Event('beforeprint'))
        setTimeout(() => {
          resolve()
        }, 500)
      })
    },
    onAfterPrint: () => {
      window.dispatchEvent(new Event('afterprint'))
    },
  })

  return (
    <IconButton onClick={handleCalendarPrint}>
      <PrintIcon />
    </IconButton>
  )
}

export default PrintCalendarPlanningButton
