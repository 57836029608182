import React, { FC, useCallback } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
// import Create from '../../components/Create'
import { Create } from 'react-admin'
import AddressForm from './AddressForm'
import { parse } from 'query-string'
import { useNotify, useRedirect } from 'ra-core'

type Props = {
  [x: string]: any
}

const AddressCreate: FC<Props> = (props) => {
  const redirect = useRedirect()
  const notify = useNotify()
  const { customerId } = parse(props.location.search)

  const onSuccess = useCallback(
    ({ data }) => {
      notify('ra.notification.created')
      const realCustomerId = customerId === undefined ? data.customerId : customerId
      redirect(`/Customer/${realCustomerId}/show`)
    },
    [customerId]
  )

  return (
    <Create {...props} component="div" onSuccess={onSuccess}>
      <SectionedForm variant="outlined" redirect={false}>
        <AddressForm {...props} />
      </SectionedForm>
    </Create>
  )
}

export default AddressCreate
