import React, { FC } from 'react'
import { TextInput } from 'react-admin'
import FormSection from '../../components/forms/FormSection'

type Props = {
  //
}

const TelegramTemplateForm: FC<Props> = (props) => (
  <FormSection title="Email Template" {...props}>
    <TextInput source="name" label="Nome template" />
    <TextInput fullWidth multiline source="content" label="Messaggio completo di invito con variabili" />
    <TextInput source="confirmLabel" label="Testo pulsante CONFERMA" />
    <TextInput source="rejectLabel" label="Testo pulsante RIFIUTA" />
    <TextInput fullWidth multiline source="afterConfirmLabel" label="Testo che appare dopo la conferma" />
    <TextInput fullWidth multiline source="afterRejectLabel" label="Testo che appare dopo il rifiuto" />
  </FormSection>
)

export default TelegramTemplateForm
