import React from 'react'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'

type Props = {
  source: string
  record?: Record<string, boolean | null>
  label?: string
}

const IsClosedField = ({ record = {} }: Props) => {
  const classes = useStyles()

  if (record.isClosed) {
    return <Typography className={classes.red}>CHIUSA</Typography>
  } else if (
    record.expiringDate &&
    typeof record.expiringDate === 'string' &&
    new Date() > new Date(record.expiringDate)
  ) {
    return <Typography className={classes.yellow}>APERTA (SCADUTA)</Typography>
  } else {
    return <Typography className={classes.green}>APERTA</Typography>
  }
}

const useStyles = makeStyles((theme) => ({
  green: { color: 'green' },
  yellow: { color: 'orange' },
  red: { color: 'red' },
}))

export default IsClosedField
