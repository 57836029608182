import React, { FC } from 'react'
import { Avatar, Box, Typography, makeStyles } from '@material-ui/core'
import CustomerAvatarField from './CustomerAvatarField'

type Props = {
  record?: any
  avatarSize?: number
  badgeColor?: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}))

const UserPreviewField: FC<Props> = ({ record = {}, avatarSize = 30, badgeColor, ...rest }) => {
  const classes = useStyles()
  return (
    <Box alignItems="center" display="flex">
      <CustomerAvatarField
        className={classes.avatar}
        avatarSize={avatarSize}
        record={record}
        source="profilePictureId"
        badgeColor={badgeColor}
      />
      <Typography variant="body2">{record.fullName}</Typography>
    </Box>
  )
}

export default UserPreviewField
