import React, { FC } from 'react'
import { TextField, NumberField, Datagrid, ReferenceField } from 'react-admin'
import EditButtonWPermission from '../../components/EditButtonWPermission'
import RAList from '../../components/List'
import VehicleFilters from './VehicleFilters'
import ReferenceModelManufacturerField from '../../components/CustomFields/ReferenceModelManufacturerField'

type Props = {
  [x: string]: any
}

const List: FC<Props> = (props) => {
  return (
    <RAList {...props} filters={<VehicleFilters />}>
      <Datagrid>
        <NumberField source="id" />
        <ReferenceModelManufacturerField basePath={props.basePath} source="vehicleModelId" label="Produttore" />
        <ReferenceField source="vehicleModelId" reference="VehicleModel" sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="licensePlate" sortable={false} />
        <TextField source="registrationYear" sortable={false} />
        <ReferenceField label="Power Supply" source="vehicleFuelId" reference="VehicleFuel">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="vehicleCategoryId" reference="VehicleCategory">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="numberOfSeats" sortable={false} />
        <TextField source="color" sortable={false} />
        <TextField source="yearOfManufacture" sortable={false} />
        <TextField source="yearOfRestyling" sortable={false} />
        <EditButtonWPermission {...props} />
      </Datagrid>
    </RAList>
  )
}

export default List
