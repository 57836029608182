import React, { FC } from 'react'
import { useQueryWithStore } from 'ra-core'
import AvatarField from './AvatarField'

type Props = {
  record?: any
  source: string
  avatarSize?: number
  imageSize?: 'Small' | 'Medium' | 'Large'
  className?: string
  badgeColor?: string
  [x: string]: any
}

const ReferencePictureAvatarField: FC<Props> = ({
  record,
  source,
  avatarSize = 50,
  className,
  imageSize = 'Medium',
  badgeColor,
}) => {
  const picture = useQueryWithStore({
    type: 'getOne',
    resource: 'Picture',
    payload: { id: record[source] },
  })

  if (picture.loading) {
    return <AvatarField record={{}} className={className} badgeColor={badgeColor} />
  }

  return (
    <AvatarField
      size={avatarSize}
      record={picture.data}
      source={`url${imageSize}`}
      className={className}
      badgeColor={badgeColor}
    />
  )
}

export default ReferencePictureAvatarField
