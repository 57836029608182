import React, { FC } from 'react'
import { TextField } from 'react-admin'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/Edit'
import TelegramTemplateForm from './TelegramTemplateForm'
import { Typography } from '@material-ui/core'

type Props = {
  //
}

const TelegramTemplateEdit: FC<Props> = (props) => (
  <Edit {...props} component="div">
    <SectionedForm variant="outlined">
      <FormSection title="ID">
        <TextField source="id" addLabel={false} />
      </FormSection>
      <FormSection title="Elenco variabili disponibili">
        <Typography variant="body1">{'{{title}} - il titolo dell\'evento'}</Typography>
        <Typography variant="body1">{'{{firstName}} - il nome del cliente'}</Typography>
        <Typography variant="body1">{'{{startDate}} - data e ora di inizio dell\'evento'}</Typography>
        <Typography variant="body1">{'{{endDate}} - data e ora di fine dell\'evento'}</Typography>
      </FormSection>
      <TelegramTemplateForm />
    </SectionedForm>
  </Edit>
)

export default TelegramTemplateEdit
