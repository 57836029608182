import React, { FC, useCallback, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { useTranslate } from 'ra-core'
import FilterListIcon from '@material-ui/icons/FilterList'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import { SelectableGroup } from 'react-selectable-fast'
import ResourceModeRadioListItem from './ResourceModeRadioListItem'
import Divider from '@material-ui/core/Divider'
import VehicleResourceListItem from './VehicleResourceListItem'
import VehiclesSelectableList from './VehiclesSelectableList'
import TeamMembersSelectableList from './TeamMembersSelectableList'
import OfficesSelectableList from './OfficesSelectableList'

const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    minWidth: 300,
    width: '25vw',
    marginBottom: theme.spacing(2),
  },
  filtersTopBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  drawerTitle: {
    marginLeft: theme.spacing(4),
  },
  list: {
    width: '100%',
  },
  resourcesContainer: {
    marginTop: theme.spacing(4),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
}))

export type PlanningResourceConfigurationProps = {
  onResourceModeChange: (resourceMode: 'vehicle' | 'teamMember' | 'office') => void
  resourceMode: 'vehicle' | 'teamMember' | 'office' | string
  selectedResourceIds: string[]
  onResourcesSelected: (selectedResourceIds: string[]) => void
  vehicleHashTable?: { [id: string]: any }
  vehicles?: any[]
  vehiclesLoading?: boolean
  teamMembersHashTable?: { [id: string]: any }
  teamMembers?: any[]
  teamMembersLoading?: boolean
  officeHashTable?: { [id: string]: any }
  offices?: any[]
  officesLoading?: boolean
}

const PlanningResourceConfiguration: FC<PlanningResourceConfigurationProps> = ({
  onResourceModeChange,
  resourceMode,
  selectedResourceIds,
  onResourcesSelected,
  vehicleHashTable,
  vehicles,
  vehiclesLoading,
  teamMembersHashTable,
  teamMembers,
  teamMembersLoading,
  officeHashTable,
  offices,
  officesLoading,
}) => {
  const translate = useTranslate()
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleResourceModeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onResourceModeChange) {
        onResourceModeChange(event.target.value as 'vehicle' | 'teamMember' | 'office')
      }
    },
    [onResourceModeChange]
  )

  const onResourceModeButtonClick = useCallback(
    (resourceMode: 'vehicle' | 'teamMember' | 'office') => (): void => {
      if (onResourceModeChange) {
        onResourceModeChange(resourceMode)
      }
    },
    [onResourceModeChange]
  )

  const handleResourcesSelected = useCallback((values: string[]) => {
    console.log(values)
  }, [])

  return (
    <>
      <Tooltip title={translate('PlanningResourceConfiguration.filterResources')}>
        <IconButton size="small" onClick={handleOpen}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      <Drawer anchor="right" open={open} onClose={handleClose}>
        <div className={classes.filtersContainer}>
          <div className={classes.filtersTopBar}>
            <IconButton onClick={handleClose}>
              <NavigateNextIcon />
            </IconButton>
            <Typography className={classes.drawerTitle} variant="h4">
              {translate('PlanningResourceConfiguration.filterResources')}
            </Typography>
          </div>
          <List
            subheader={
              <ListSubheader className={classes.subheader}>
                {translate('PlanningResourceConfiguration.changeCalendarResourceViewMode')}
              </ListSubheader>
            }
            className={classes.list}
          >
            <ResourceModeRadioListItem
              value="vehicle"
              name="resource-vehicle-radio"
              checked={resourceMode === 'vehicle'}
              onChange={handleResourceModeChange}
              onClick={onResourceModeButtonClick('vehicle')}
              label={translate('PlanningResourceConfiguration.groupByVehicles')}
            />
            <ResourceModeRadioListItem
              value="office"
              name="resource-office-radio"
              checked={resourceMode === 'office'}
              onChange={handleResourceModeChange}
              onClick={onResourceModeButtonClick('office')}
              label={translate('PlanningResourceConfiguration.groupByOffices')}
            />
            <ResourceModeRadioListItem
              value="teamMember"
              name="resource-teamMember-radio"
              checked={resourceMode === 'teamMember'}
              onChange={handleResourceModeChange}
              onClick={onResourceModeButtonClick('teamMember')}
              label={translate('PlanningResourceConfiguration.groupByTeamMembers')}
            />
          </List>
        </div>
        <Divider />
        <div className={classes.resourcesContainer}>
          {resourceMode === 'vehicle' && (
            <VehiclesSelectableList
              selectedResourceIds={selectedResourceIds}
              onResourcesSelected={onResourcesSelected}
              vehicleHashTable={vehicleHashTable}
              vehicles={vehicles}
              loading={vehiclesLoading}
            />
          )}
          {resourceMode === 'teamMember' && (
            <TeamMembersSelectableList
              selectedResourceIds={selectedResourceIds}
              onResourcesSelected={onResourcesSelected}
              teamMembersHashTable={teamMembersHashTable}
              teamMembers={teamMembers}
              loading={teamMembersLoading}
            />
          )}
          {resourceMode === 'office' && (
            <OfficesSelectableList
              selectedResourceIds={selectedResourceIds}
              onResourcesSelected={onResourcesSelected}
              officeHashTable={officeHashTable}
              offices={offices}
              loading={officesLoading}
            />
          )}
        </div>
      </Drawer>
    </>
  )
}

export default PlanningResourceConfiguration
