import React, { FC } from 'react'
import { useTranslate } from 'ra-core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import CardActions from '@material-ui/core/CardActions'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { AiOutlineUser } from 'react-icons/ai'
import CardField from './CardField'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { ColorField } from 'react-admin-color-input'
import { ReferenceField, TextField } from 'react-admin'

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    title: {},
    icon: {
      marginRight: theme.spacing(1),
    },
  }
})

type Props = {
  record?: any
  permissionsMap: Record<string, boolean>
  [x: string]: any
}

const TeamMemberProfilePreviewCard: FC<Props> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  const emptyValueLabel = translate('custom.noData')
  const { record, permissionsMap } = props
  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center" mb={3}>
          <AiOutlineUser size="1.2rem" className={classes.icon} />
          <Typography variant="h4" className={classes.title}>
            Profilo
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CardField label={translate('resources.Customer.fields.firstName')}>
              {record.firstName || emptyValueLabel}
            </CardField>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardField label={translate('resources.Customer.fields.lastName')}>
              {record.lastName || emptyValueLabel}
            </CardField>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardField label={translate('resources.Customer.fields.birthday')}>
              {record.birthday ? moment(record.birthday).format('L') : emptyValueLabel}
            </CardField>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardField label={translate('resources.TeamMember.fields.calendarColor')}>
              <ColorField source="calendarColor" record={record} />
            </CardField>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardField label={translate('resources.Customer.fields.fiscalCode')}>
              {record.fiscalCode || emptyValueLabel}
            </CardField>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardField label={translate('resources.TeamMember.fields.officeId')}>
              <ReferenceField {...props} source="officeId" reference="Office">
                <TextField variant="body1" source="name" />
              </ReferenceField>
            </CardField>
          </Grid>
          {/* <Grid item md={12}>
            <CardField label={translate('resources.Customer.fields.note')}>{record.note || emptyValueLabel}</CardField>
          </Grid> */}
        </Grid>
      </CardContent>
      <Divider />
      {permissionsMap.updateTeamMember && (
        <CardActions>
          <Button component={Link} to={`/TeamMember/${record.id}/edit`} color="primary" fullWidth variant="text">
            Modifica Profilo
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

export default TeamMemberProfilePreviewCard
