import italianMessages from 'ra-language-italian'

export default {
  ...italianMessages,
  ra: {
    ...italianMessages.ra,
    page: {
      ...italianMessages.ra.page,
      empty: 'Nessun elemento presente',
      invite: 'Clicca sul pulsante per creare un nuovo elemento',
    },
    boolean: {
      ...italianMessages.ra.boolean,
      null: 'Tutti',
    },
    action: {
      ...italianMessages.ra.action,
      unselect: 'Deseleziona',
      sort: 'Ordina',
    },
    message: {
      ...italianMessages.ra.message,
      delete_content: 'Sei sicuro di voler eliminare questo elemento?',
    },
  },
  SendInviteButton: {
    label: 'Invia Invito',
  },
  CalendarPlanning: {
    title: 'Pianificazione Eventi',
    viewMode: {
      twoWeeks: '2 Settimane',
      week: 'Settimana',
      day: 'Giorno',
    },
    resourceMode: {
      vehicle: 'Per Veicolo',
      teamMember: 'Per Istruttore',
    },
  },
  CalendarToolbar: {
    today: 'Oggi',
    previousDay: 'Precedente',
    nextDay: 'Successivo',
    closeCalendarSidebar: 'Chiudi barra laterale',
    openCalendarSidebar: 'Apri barra laterale',
  },
  PlanningResourceConfiguration: {
    filterResources: 'Filtra Veicoli/Istruttori',
    changeCalendarResourceViewMode: 'Visualizza calendario per',
    groupByTeamMembers: 'Per dipendenti',
    groupByVehicles: 'Per veicoli',
    groupByOffices: 'Per Sedi',
    selectVisibleVehicles: 'Seleziona Veicoli',
    selectVisibleTeamMembers: 'Seleziona Dipendenti',
    selectVisibleOffices: 'Seleziona Sedi',
  },
  PlanningCustomersList: {
    searchCustomers: 'Cerca clienti',
  },
  PlanningEventTemplatesList: {
    searchEventTemplates: 'Cerca Eventi Rapidi',
  },
  dayOfWeek: {
    MONDAY: 'Lunedì',
    TUESDAY: 'Martedì',
    WEDNESDAY: 'Mercoledì',
    THURSDAY: 'Giovedì',
    FRIDAY: 'Venerdì',
    SATURDAY: 'Sabato',
    SUNDAY: 'Domenica',
  },
  contactTypes: {
    sms: 'Cellulare',
    email: 'E-mail',
    telegram: 'Telegram',
  },
  CustomerForm: {
    mainInfo: 'Informazioni Principali',
    otherInfo: 'Altre Informazioni',
  },
  uploadPictureButton: {
    label: 'Carica Immagine',
  },
  uploadDocumentButton: {
    label: 'Carica Documento',
  },
  QuickCreateCustomerCoursePlanningButton: {
    label: 'Aggiungi Studente al corso',
  },
  QuickEditCourseScheduleButton: {
    label: 'Gestisci Pianificazione Corso',
  },
  QuickCreateCustomerExamPlanningButton: {
    label: "Aggiungi Studente all' esame",
  },
  QuickCreateCustomerContactButton: {
    label: 'Aggiungi contatto',
  },
  QuickCreateCustomerDrivingLicenseButton: {
    label: 'Aggiungi patente',
  },
  QuickCreateCourseLessonButton: {
    label: 'Registra nuova lezione',
  },
  CustomerContactsCard: {
    notificationEnabled: 'Notifiche Abilitate',
    notificationDisabled: 'Notifiche non attive',
  },
  custom: {
    noData: 'N.D.',
  },
  menu: {
    categories: {
      organizations: 'Organizzazioni',
      planning: 'Pianificazioni',
      vehicles: 'Veicoli',
      users: 'Users',
      localization: 'Localizzazione',
      catalog: 'Catalogo',
      order: 'Preventivi e Ordini',
      customers: 'Clienti',
      coursesAndExams: 'Corsi ed Esami',
      account: 'Account e Ruoli',
    },
  },
  resources: {
    Organization: {
      name: 'Scuola Guida |||| Scuole Guida',
      fields: {
        name: 'Nome',
        vat: 'Partita IVA',
        email: 'E-mail',
        phone: 'Telefono',
      },
    },
    Office: {
      name: 'Sede |||| Sedi',
      fields: {
        name: 'Nome',
        email: 'E-mail',
        phone: 'Telefono',
        organizationId: 'Scuola Guida',
        countryId: 'Nazione',
        stateId: 'Provincia',
        city: 'Città',
        street: 'Via',
        postCode: 'CAP',
      },
    },
    TeamMember: {
      name: 'Dipendente |||| Dipendenti',
      fields: {
        firstName: 'Nome',
        lastName: 'Cognome',
        birthday: 'Data di nascita',
        calendarColor: 'Colore nel calendario',
        fiscalCode: 'Codice fiscale',
        vehicles: 'Veicoli Associati',
        telegramId: 'Telegram',
        phone: 'Telefono',
        officeId: 'Ufficio di riferimento',
        profilePictureId: 'Immagine del profilo',
      },
    },
    TeamMemberWorkPlanning: {
      name: 'Pianificazione Dipendente |||| Pianificazione Dipendenti',
      fields: {
        teamMemberId: 'Dipendente',
        startDate: 'Inizio Pianificazione',
        endDate: 'Fine Pianificazione',
      },
    },
    Customer: {
      name: 'Cliente |||| Clienti',
      fields: {
        firstName: 'Nome',
        lastName: 'Cognome',
        fullName: 'Nome',
        fiscalCode: 'Codice Fiscale',
        profilePictureId: 'Immagine profilo',
        birthday: 'Data di Nascita',
        note: 'Note',
        email: 'E-mail',
        phone: 'Numero di telefono',
        countryId: 'Nazione di Nascita',
        mainReferenceTeamMemberId: 'Istruttore di riferimento',
        subscriptionOfficeId: 'Ufficio di riferimento',
        address: 'Indirizzo di residenza',
      },
    },
    CustomerAddress: {
      name: 'Contatto |||| Contatti',
      fields: {
        contactType: 'Tipologia/Mezzo di comunicazione',
        contact: 'Contatto',
        notificationEnabled: 'Notifiche Abilitate',
        description: 'Descrizione',
      },
    },
    Address: {
      name: 'Indirizzo |||| Indirizzi',
      fields: {
        firstName: 'Nome',
        lastName: 'Cognome',
        street: 'Indirizzo',
        postCode: 'C.A.P.',
        city: 'Città',
        phone: 'Telefono',
        customer: 'Cliente',
        country: 'Nazione',
      },
      sections: {
        associatedCustomer: 'Cliente associato',
        addressInfo: 'Informazioni indirizzo',
      },
    },
    Product: {
      name: 'Prodotto |||| Prodotti',
      fields: {
        name: 'Nome del Prodotto',
        sku: 'SKU',
        productQuantityType: 'Tipo di Prodotto',
        netPrice: 'Prezzo Netto',
        retailPrice: 'Prezzo di Vendita',
        taxId: 'Tassazione',
        description: 'Descrizione',
        active: 'Pubblicato',
        catalogPictureId: 'Immagine',
        mainCategoryId: 'Categoria',
        categoryIds: 'Categorie',
        features: 'Feature',
        tax: 'Tassa',
        drivingSchoolAttribute: {
          hasLearnerPermits: 'Contiene Foglio Rosa',
          isDrivingTraining: 'È una lezione di guida',
          isDefaultDrivingTraining: 'È la guida di default (per calcolare il prezzo delle guide non contabilizzate)',
          driveTrainingMinutesDuration: 'Durata pacchetto guida (minuti)',
          courseIds: 'Corsi Associati',
          examIds: 'Esami Associati',
          isTheoryExam: 'È un esame di teoria',
          isDrivingExam: 'È un esame di guida',
          isMedicalExam: 'È una visita medica',
        },
        quantityType: {
          simple: 'Semplice',
          withCombinations: 'Con combinazioni',
        },
        discountType: {
          field: 'Tipologia sconto',
          absolute: 'Assoluto',
          percentage: 'Percentuale',
        },
        discountActive: 'Sconto attivo',
        discountValue: 'Valore sconto',
        discountPrice: 'Prezzo scontato',
      },
    },
    ProductCategory: {
      name: 'Categoria |||| Categorie',
      fields: {
        name: 'Nome',
        parentId: 'Categoria principale',
      },
    },
    ProductAttribute: {
      name: 'Attributo |||| Attributi',
    },
    ProductFeature: {
      name: 'Funzionalità |||| Funzionalità',
    },
    Vehicle: {
      name: 'Veicolo |||| Veicoli',
      fields: {
        vehicleModelId: 'Modello',
        licensePlate: 'Targa',
        registrationYear: 'Anno di registrazione',
        vehicleFuelId: 'Alimentazione',
        vehicleCategoryId: 'Tipologia Veicolo',
        numberOfSeats: 'N. posti',
        color: 'Colore',
        yearOfManufacture: 'Anno di produzione',
        yearOfRestyling: 'Anno di "Restyling"',
        description: 'Descrizione',
      },
    },
    VehicleFuel: {
      name: 'Alimentazione Veicolo |||| Alimentazione Veicoli',
      fields: {
        vehicleManufacturerId: 'Produttore',
        name: 'Nome',
        modelYear: 'Anno del modello',
        vehicleFuelId: 'Alimentazione',
        vehicleCategoryId: 'Tipologia Veicolo',
        color: 'Colore',
        yearOfManufacture: 'Anno di produzione',
        yearOfRestyling: 'Anno di "Restyling"',
        description: 'Descrizione',
      },
    },
    VehicleManufacturer: {
      name: 'Produttore Veicoli |||| Produttori Veicoli',
    },
    VehicleType: {
      name: 'Tipologia Veicolo |||| Tipologie Veicoli',
    },
    VehicleModel: {
      name: 'Modello Veicolo |||| Modelli Veicoli',
    },
    OpeningHour: {
      name: 'Opening Hours',
    },
    EventTemplate: {
      name: 'Evento Rapido |||| Eventi Rapidi',
      fields: {
        title: 'Titolo',
        duration: 'Durata',
        color: 'Colore',
        description: 'Descrizione',
        name: 'Nome',
        active: 'Attivo?',
      },
    },
    EventStatus: {
      name: 'Stato |||| Stati',
      fields: {
        id: 'id',
        name: 'Nome',
        color: 'Colore',
        order: 'Ordine',
      },
    },
    PlannedEvent: {
      name: 'Evento Pianificato |||| Eventi Pianificati',
      invites: {
        success: 'Inviti inviati con successo',
        failure: 'Non tutti gli inviti sono stati inviati',
        error: "Errore nell'invio degli inviti",
      },
      fields: {
        customerIds: 'Studenti',
        teamMemberIds: 'Istruttori',
        customerEventStatus: 'Stato',
        title: 'Titolo',
        startDate: 'Data di inizio',
        endDate: 'Data di fine',
        vehicleId: 'Veicolo',
        description: 'Descrizione',
        color: 'Colore nel calendario',
      },
    },
    Country: {
      name: 'Nazione |||| Nazioni',
      fields: {
        name: 'Nome',
        phonePrefix: 'Prefisso telefonico',
        taxIncluded: 'Tasse incluse',
        active: 'Attivo?',
      },
    },
    State: {
      name: 'Provincia |||| Province',
      fields: {
        name: 'Nome',
        countryId: 'Nazione',
        active: 'Attivo?',
      },
    },
    Currency: {
      name: 'Valuta |||| Valute',
      fields: {
        name: 'Nome',
        sign: 'Simbolo',
        format: 'Formato',
        conversionRate: 'Tasso di conversione',
        showDecimals: 'Mostra decimali?',
        showSpace: 'Mostra spazio?',
        active: 'Attivo?',
      },
    },
    Tax: {
      name: 'Tassazione |||| Tassazioni',
      fields: {
        name: 'Nome',
        active: 'Attivo?',
        rate: 'Percentuale',
      },
    },
    Quote: {
      name: 'Preventivo |||| Preventivi',
      fields: {
        printTemplateId: 'Template di stampa',
        product: 'Prodotto',
        quantity: 'Quantità',
        price: 'Prezzo netto',
        tax: 'Tassa (%)',
        createdAt: 'Creato il',
        customer: 'Studente',
        approved: 'Approvato',
        totalAmount: 'Totale',
        reference: 'ID Preventivo',
      },
    },
    QuoteTemplate: {
      name: 'Modello Preventivo |||| Modelli Preventivo',
      fields: {
        name: 'Nome',
        printTemplateId: 'Template di stampa',
        product: 'Prodotto',
        quantity: 'Quantità',
        price: 'Prezzo netto',
        tax: 'Tassa (%)',
        createdAt: 'Creato il',
        customer: 'Studente',
        approved: 'Approvato',
        totalAmount: 'Totale',
      },
      sections: {
        base: 'Informazioni di base',
      },
    },
    Order: {
      name: 'Ordine |||| Ordini',
      fields: {
        createdAt: 'Creato il',
        expiringDate: 'Data scadenza',
        closed: 'Chiuso?',
        amountToPay: 'Da pagare',
        amountPayed: 'Pagato',
        totalAmount: 'Totale',
        customer: 'Cliente',
        product: 'Prodotto',
        quantity: 'Quantità',
        price: 'Prezzo netto',
        tax: 'Tassa (%)',
        notes: 'Note',
        orderDate: 'Iscrizione protocollo',
        reference: 'ID Ordine',
      },
      sections: {
        customer: 'Cliente',
        list: 'Lista prodotti',
        summary: 'Resoconto',
        info: 'Informazioni aggiuntive',
      },
    },
    Document: {
      name: 'Documento |||| Documenti',
      fields: {
        id: 'id',
        description: 'Descrizione',
        url: 'Url di download',
      },
      sections: {
        general: 'Generali',
      },
    },
    Course: {
      name: 'Corso |||| Corsi',
      fields: {
        name: 'Nome',
      },
    },
    Exam: {
      name: 'Esame |||| Esami',
      fields: {
        name: 'Nome',
      },
    },
    ExamPlanning: {
      name: 'Pianificazione Esame |||| Pianificazioni Esame',
      fields: {
        teamMemberIds: 'Istruttori',
        date: 'Data',
        startDate: 'Data Inizio Esame',
        endDate: 'Data Fine Esame',
        totalPartecipants: 'Numero di Partecipanti',
      },
    },
    DrivingLicense: {
      name: 'Patente |||| Patenti',
      fields: {
        licenceNumber: 'Numero Patente',
        licenceCodes: 'Codice Patente',
        releaseDate: 'Data di rilascio',
        expiringDate: 'Data di scadenza',
        // eslint-disable-next-line quotes
        releasedFrom: "Rilasciato dall'ente",
        drivingLicenceCategoryId: 'Tipologie patenti',
      },
    },
    CoursePlanning: {
      name: 'Pianificazione Corso |||| Pianificazioni Corso',
      fields: {
        startDate: 'Data Inizio Corso',
        endDate: 'Data Fine Corso',
        totalPartecipants: 'Numero di Partecipanti',
        profilePictureId: 'Foto',
        teamMemberId: 'Referente',
      },
    },
    CourseSchedule: {
      name: 'Schedulazione Corso |||| Schedulazioni Corso',
    },
    SmsTemplate: {
      name: 'Template SMS |||| Template SMS',
      fields: {
        name: 'Nome',
        content: 'Contenuto',
      },
    },
    EmailTemplate: {
      name: 'Template Email |||| Template Email',
      fields: {
        name: 'Nome',
        content: 'Contenuto',
      },
    },
    TelegramTemplate: {
      name: 'Template Telegram |||| Template Telegram',
    },
    CustomerContact: {
      name: 'Contatti',
      fields: {
        description: 'Descrizione',
        contactType: 'Tipo di contatto',
        notificationEnabled: 'Notifiche Abilitate',
        contact: 'Contatto',
      },
    },
    Role: {
      name: 'Ruoli',
      fields: {
        name: 'Nome',
      },
    },
    User: {
      name: 'Account',
      fields: {
        active: 'Attivo',
        firstName: 'Nome',
        lastName: 'Cognome',
        fullName: 'Nome',
        rolesIds: 'Ruoli',
        email: 'E-mail',
      },
    },
    WorkReport: {
      fields: {
        teamMemberIds: 'Dipendenti',
      },
    },
    AccountingCard: {
      name: 'Scheda contabile |||| Schede contabili',
      fields: {
        openingDate: 'Istruzione protocollo',
        expiringDate: 'Scadenza',
        isClosed: 'Chiuso?',
        remainingAmount: 'Da pagare',
        paidAmount: 'Pagato',
        totalAmount: 'Debito',
        customerId: 'Studente',
        closingDate: 'Data chiusura',
      },
    },
  },
  customFields: {
    galleryInput: {
      title: 'Galleria Immagini',
      mobileTitle: 'Mobile Catalog Pictures',
      galleryTitle: 'Product Details Gallery',
      modal: {
        error: 'Error uploading picture',
        success: 'Picture uploaded correctly',
        title: {
          addToGallery: 'Add new to Gallery',
          setCatalog: 'Aggiungi immagine principale',
          setSecondaryCatalog: 'Set Secondary Catalog Picture',
        },
      },
    },
    galleryGrid: {
      setFeaturedImage: 'Set Featured Picture',
      catalogImage: 'Primary',
      catalogSecondaryImage: 'Secondary',
      featured: 'Featured',
      featuredImage: 'Featured',
      addToGallery: 'Add to gallery',
      setCatalogImage: 'Aggiungi immagine',
      setCatalogSecondaryImage: 'Set Secondary Image',
    },
    priceSection: {
      title: 'Prezzo',
    },
    discountSection: {
      title: 'Sconto',
    },
    orderProductTableField: {
      subtotal: 'Subtotal',
      shippingCost: 'Shipping',
      tax: 'Tax',
      total: 'Total',
    },
    orderCustomerField: {
      // eslint-disable-next-line quotes
      noRaffleParticipations: "User hasn't participated to any raffle yet",
      nbPartecipations: '%{total} raffle partecipation |||| %{total} raffle partecipations',
      noPurchases: 'No purchases',
      nbPurchases: '%{total} purchase |||| %{total} purchases',
      customerAgreeNewsletter: 'Accept newsletter e-mails',
    },
    countryState: {
      country: 'Nazione',
      state: 'Provincia',
    },
  },
  products: {
    edit: {
      tabs: {
        basicSettings: 'Impostazioni Generali',
        options: 'Opzioni',
        pricing: 'Gestione Prezzo',
        shipping: 'Spedizione',
        combinations: 'Combinazioni',
        drivingSchoolAttributes: 'Impostazioni Scuola Guida',
      },
    },
  },
  weekdays: {
    MONDAY: 'Lunedì',
    TUESDAY: 'Martedì',
    WEDNESDAY: 'Mercoledì',
    THURSDAY: 'Giovedì',
    FRIDAY: 'Venerdì',
    SATURDAY: 'Sabato',
    SUNDAY: 'Domenica',
  },
}
