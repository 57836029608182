import React, { FC } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import CardActions from '@material-ui/core/CardActions'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { ReferenceManyField, SimpleList } from 'react-admin'
import { TiDocument } from 'react-icons/ti'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import QuickCreateCustomerContactButton from './QuickCreateCustomerContactButton'
import TelegramIcon from '@material-ui/icons/Telegram'
import ContactMoreMenu from './ContactMoreMenu'
import { useTranslate } from 'ra-core'
import Avatar from '@material-ui/core/Avatar'
import CheckIcon from '@material-ui/icons/Check'

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    title: {},
    icon: {
      marginRight: theme.spacing(1),
    },
    notificationEnabledAvatar: {
      width: 12,
      height: 12,
      backgroundColor: theme.palette.success.main,
      marginRight: theme.spacing(0.6),
    },
    notificationEnabledIcon: {
      width: 12,
      height: 12,
    },
  }
})

type Props = {
  record?: any
  permissionsMap: Record<string, boolean>
  [x: string]: any
}

const CustomerContactsCard: FC<Props> = (props) => {
  const classes = useStyles(props)
  const translate = useTranslate()
  const { record, permissionsMap } = props

  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center" mb={3}>
          <TiDocument size="1.2rem" className={classes.icon} />
          <Typography variant="h4" className={classes.title}>
            Contatti
          </Typography>
        </Box>
        <ReferenceManyField {...props} record={record} reference="CustomerContact" target="customerId" link={false}>
          <SimpleList
            linkType={false}
            // className={classes.list}
            leftIcon={(record: any): any => {
              switch (record.contactType) {
                case 'sms':
                  return <PhoneIcon />
                case 'email':
                  return <EmailIcon />
                case 'telegram':
                  return <TelegramIcon />
                default:
                  return null
              }
            }}
            rightIcon={
              !permissionsMap.updateCustomerContact
                ? undefined
                : (record: any): any => <ContactMoreMenu record={record} />
            }
            primaryText={(record: any): any => `${record.contact}`}
            // secondaryText={(record: any): any => `${record.contactType}`}
            secondaryText={(record: any): any =>
              record.notificationEnabled ? (
                <Box display="flex" alignItems="center">
                  <Avatar className={classes.notificationEnabledAvatar}>
                    <CheckIcon className={classes.notificationEnabledIcon} />
                  </Avatar>{' '}
                  {translate('CustomerContactsCard.notificationEnabled')}
                </Box>
              ) : (
                translate('CustomerContactsCard.notificationDisabled')
              )
            }
          />
        </ReferenceManyField>
      </CardContent>
      <Divider />
      {permissionsMap.createCustomerContact && (
        <CardActions>
          <QuickCreateCustomerContactButton
            customerId={record.id}
            color="primary"
            fullWidth
            variant="text"
            // onChange={onDocumentUploaded}
          />
          {/* <Button color="primary" fullWidth variant="text">
          Upload picture
        </Button> */}
        </CardActions>
      )}
    </Card>
  )
}

export default CustomerContactsCard
