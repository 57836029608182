import { makeStyles } from '@material-ui/core/styles'
import React, { FC } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import Filter from '../../components/Filter'
import UserPreviewField from '../../components/UserPreviewField'

const useFilterStyles = makeStyles((theme) => ({
  suggestionsContainer: {
    zIndex: 1300,
  },
}))

export const PlanningFilters = (props: any): any => {
  const classes = useFilterStyles()
  return (
    <Filter {...props}>
      <ReferenceInput reference="TeamMember" source="teamMemberId" alwaysOn>
        <AutocompleteInput
          options={{
            suggestionsContainerProps: {
              className: classes.suggestionsContainer,
            },
          }}
          variant="outlined"
          fullWidth
          optionText="fullName"
        />
      </ReferenceInput>
      <DateInput alwaysOn variant="outlined" source="startDate" />
      <DateInput alwaysOn variant="outline" source="endDate" />
    </Filter>
  )
}

const TeamMemberWorkPlanningList: FC = (props) => {
  return (
    <List {...props} filters={<PlanningFilters />} sort={{ field: 'startDate', order: 'DESC' }}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <ReferenceField link={false} source="teamMemberId" reference="TeamMember">
          <UserPreviewField />
        </ReferenceField>
        <DateField source="startDate" />
        <DateField source="endDate" />
      </Datagrid>
    </List>
  )
}

export default TeamMemberWorkPlanningList
