import React, { FC, useEffect } from 'react'
import userManager from '../userManager'

type Props = {
  //
}

const AuthCallback: FC<Props> = () => {
  useEffect(() => {
    const silentRenewCallback = async (): Promise<void> => {
      try {
        const result = await userManager.signinSilent()
        console.log('loginCallback() siamo qua', result)
        if (result && result.access_token) {
          // redirect('/')
          window.location.replace(process.env.REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI || 'https://crm.drivesystem.it')
        }
      } catch (e) {
        console.error('errore in redirect', e)
      }
    }

    silentRenewCallback()
  }, [])

  return <div>Login in corso...</div>
}

export default AuthCallback
