import React, { FC } from 'react'
import { TextInput } from 'react-admin'
import FormSection from '../../components/forms/FormSection'
import RichTextInput from 'ra-input-rich-text'
import { useTranslate } from 'ra-core'

type Props = {
  //
}

const EmailTemplateForm: FC<Props> = (props) => {
  const translate = useTranslate()
  return (
    <FormSection title={translate('resources.EmailTemplate.name', { smart_count: 1 })} {...props}>
      <TextInput source="name" />
      <TextInput fullWidth multiline source="content" />
      <RichTextInput variant="outlined" source="html" />
    </FormSection>
  )
}

export default EmailTemplateForm
