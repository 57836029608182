import React, { FC, useMemo, useCallback, useRef, useState, useEffect } from 'react'
import FullCalendar, {
  EventInput,
  DateSelectArg,
  EventSourceFunc,
  DatesSetArg,
  EventClickArg,
  CustomContentGenerator,
  EventContentArg,
  ViewApi,
  Duration,
} from '@fullcalendar/react'
import adaptivePlugin from '@fullcalendar/adaptive'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import itLocale from '@fullcalendar/core/locales/it'
import { useApolloClient, useQuery } from '@apollo/client'
import { useVersion } from 'ra-core'
import { Loading, fetchStart, fetchEnd, useNotify, useRedirect } from 'react-admin'
import { gql } from 'apollo-boost'
import interactionPlugin, { Draggable, EventResizeDoneArg, EventDropArg } from '@fullcalendar/interaction'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import invert from 'invert-color'
import PlanningCustomersList from './PlanningCustomersList'
import PlanningEventTemplatesList from './PlanningEventTemplatesList'
import { QUERY_GET_EVENT_STATUSES_CONFIGURATION } from '../../queries'
import Tooltip from '@material-ui/core/Tooltip'
import UserPreviewField from '../../components/UserPreviewField'
import moment from 'moment'
import { dateRangeOverlaps } from '../../utils/datesOverlap'
import { useCalendarPlanningStyles } from './CalendarPlanning.styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateMomentUtils from '@date-io/moment'
import MonthlyPreviewPicker from './MonthlyPreviewPicker'
import CalendarToolbar from './CalendarToolbar'
import { useDispatch } from 'react-redux'
import CustomerAvatarField from '../CustomerAvatarField'
import IconButton from '@material-ui/core/IconButton'
import clsx from 'clsx'

type Props = {
  basePath?: string
}

const DEFAULT_HOLIDAY_COLOR = '#066b1f'

const dayOfWeekToFullCalendarWeekDay = (dayOfWeek: string): number | undefined => {
  switch (dayOfWeek) {
    case 'MONDAY':
      return 1
      break
    case 'TUESDAY':
      return 2
      break
    case 'WEDNESDAY':
      return 3
      break
    case 'THURSDAY':
      return 4
      break
    case 'FRIDAY':
      return 5
      break
    case 'SATURDAY':
      return 6
      break
    case 'SUNDAY':
      return 0
      break

    default:
      break
  }
}

const printTeamMemberVehicles = (teamMember: any): string =>
  `${teamMember.vehicles && teamMember.vehicles.length > 0 ? ' - ' : ''}${
    teamMember.vehicles && teamMember.vehicles.length > 0
      ? teamMember.vehicles.map((vehicle: any, index: number) => {
          return `${vehicle.licensePlate} `
        })
      : ''
  }`

const QUERY_GET_TEAM_MEMBER = gql`
  query GetTeamMembers($startDate: DateTime, $endDate: DateTime) {
    teamMembers(pagination: { disabled: true }, sort: { lastName: ASC }) {
      total
      data {
        id
        fullName
        firstName
        lastName
        calendarColor
        holidayCalendarColor
        profilePictureId
        email
        vehicles {
          id
          licensePlate
        }
        workingPlannings(startDate: $startDate, endDate: $endDate) {
          id
          startDate
          endDate
          workingHours {
            id
            startTime
            finishTime
            dayNumber
            teamMemberWorkPlanningId
            isWorkTime
          }
        }
      }
    }
  }
`

const QUERY_GET_VEHICLES = gql`
  query GetVehicles {
    vehicles(pagination: { disabled: true }, sort: { licensePlate: ASC }) {
      total
      data {
        id
        licensePlate
        vehicleCategory {
          id
          name
        }
        vehicleModel {
          id
          name
          vehicleType {
            id
            name
          }
        }
      }
    }
  }
`

const QUERY_GET_OFFICES = gql`
  query GetOffices {
    offices(pagination: { disabled: true }, sort: { name: ASC }) {
      total
      data {
        id
        name
        street
        city
      }
    }
  }
`

const QUERY_GET_COURSE_PLANNINGS = gql`
  query GetCoursePlannings($filters: CoursePlanningFilterInput) {
    coursePlannings(filters: $filters) {
      data {
        id
        courseId
        startDate
        endDate
        teamMemberId
        schedules {
          id
          dayNumber
          startTime
          finishTime
        }
        course {
          id
          name
        }
      }
    }
  }
`

const QUERY_GET_EXAMS_PLANNINGS = gql`
  query GetExamPlannings($filters: ExamPlanningFilterInput) {
    examPlannings(filters: $filters) {
      __typename
      data {
        id
        examId
        startDate
        endDate
        vehicles {
          id
        }
        teamMembers {
          id
        }
        customers {
          id
          fullName
          firstName
          lastName
          profilePicture {
            id
            urlSmall
            urlMedium
            urlLarge
          }
        }
        exam {
          id
          name
        }
      }
    }
  }
`

const QUERY_GET_PLANNED_EVENTS = gql`
  query GetPlannedEvents($filters: PlannedEventFilterInput) {
    plannedEvents(pagination: { disabled: true }, filters: $filters) {
      data {
        id
        event {
          id
          startDate
          endDate
          title
          additionalData
        }
        officeId
        isWorkEvent
        office {
          id
          name
          street
          city
        }
        customerEvents {
          id
          customer {
            id
            fullName
            firstName
            lastName
            email
            phone
            fiscalCode
            profilePictureId
          }
          status {
            id
            name
            color
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        teamMemberEvents {
          id
          teamMember {
            id
            fullName
            firstName
            lastName
            email
            phone
            calendarColor
            holidayCalendarColor
          }
        }
        vehicleId
        vehicle {
          id
          licensePlate
        }
      }
      total
      offset
    }
  }
`

const MUTATION_CREATE_PLANNED_EVENT = gql`
  mutation CreatePlannedEvent($data: PlannedEventCreateDataInput!) {
    createPlannedEvent(data: $data) {
      id
      event {
        id
        startDate
        endDate
        title
        additionalData
      }
      officeId
      office {
        id
        name
        street
        city
      }
      customerEvents {
        id
        customer {
          id
          fullName
          firstName
          lastName
          email
          phone
          fiscalCode
          profilePictureId
        }
        status {
          id
          name
          color
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      teamMemberEvents {
        id
        teamMember {
          id
          fullName
          firstName
          lastName
          email
          phone
          calendarColor
        }
      }
      vehicleId
      vehicle {
        id
        licensePlate
      }
    }
  }
`

const MUTATION_UPDATE_PLANNED_EVENT = gql`
  mutation UpdatePlannedEvent($data: PlannedEventUpdateDataInput!, $id: ID!) {
    updatePlannedEvent(data: $data, id: $id) {
      id
      event {
        id
        startDate
        endDate
        title
        additionalData
      }
      officeId
      office {
        id
        name
        street
        city
      }
      customerEvents {
        id
        customer {
          id
          fullName
          firstName
          lastName
          email
          phone
          fiscalCode
          profilePictureId
        }
        status {
          id
          name
          color
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      teamMemberEvents {
        id
        teamMember {
          id
          fullName
          firstName
          lastName
          email
          phone
          calendarColor
        }
      }
      vehicleId
      vehicle {
        id
        licensePlate
      }
    }
  }
`

const isEventEditable = (eventStatusConfiguration: any, item: any): boolean => {
  return eventStatusConfiguration.getConfiguration && eventStatusConfiguration.getConfiguration.value
    ? item.customerEvents.length > 0 && item.customerEvents[0].status
      ? item.customerEvents[0].status.id === eventStatusConfiguration.getConfiguration.value.created[0]
      : true
    : false
}

const EventTooltip = withStyles((theme) => ({
  tooltip: {
    // backgroundColor: '#f5f5f9',
    // color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    minWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    // border: '1px solid #dadde9',
  },
}))(Tooltip)

const today = moment().startOf('day').toDate()

const CalendarPlanning: FC<Props> = (props) => {
  const redirect = useRedirect()
  const client = useApolloClient()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const notify = useNotify()
  const fullCalendarRef = useRef<FullCalendar>()
  const calendarContainerRef = useRef<any>()
  const [calTitle, setCalTitle] = useState<string>('')
  const [teamMembers, setTeamMembers] = useState<any[]>([])
  const [teamMembersHashTable, setTeamMembersHashTable] = useState<Record<string, any>>({})
  const [teamMembersLoading, setTeamMembersLoading] = useState<boolean>(true)
  const [vehicles, setVehicles] = useState<any[]>([])
  const [vehiclesHashTable, setVehiclesHashTable] = useState<Record<string, any>>({})
  const [vehiclesLoading, setVehiclesLoading] = useState<boolean>(true)
  const [offices, setOffices] = useState<any[]>([])
  const [officesHashTable, setOfficesHashTable] = useState<Record<string, any>>({})
  const [officesLoading, setOfficesLoading] = useState<boolean>(true)
  const history = useHistory()
  const viewVersion = useVersion()
  const draggableCustomersRef = useRef<Draggable>()
  const draggableEventTemplatesRef = useRef<Draggable>()
  const [fastEventsOpen, setFastEventsOpen] = useState<boolean>(
    localStorage.getItem('fastEventsOpen') === 'false' ? false : true
  )
  const [eventsListOpen, setEventsListOpen] = useState<boolean>(
    localStorage.getItem('eventsListOpen') === 'true' ? true : false
  )
  const [currentDates, setCurrentDates] = useState<DatesSetArg | undefined>(undefined)
  const [data, setData] = useState<any>(undefined)
  const classes = useCalendarPlanningStyles({ ...props, eventsListOpen, fastEventsOpen })
  const { loading: configurationLoading, data: eventStatusConfiguration } = useQuery(
    QUERY_GET_EVENT_STATUSES_CONFIGURATION
  )

  const parsedQueryString = useMemo(() => {
    return queryString.parse(search)
  }, [search])

  const selectedResourceMode = useMemo<'vehicle' | 'teamMember' | 'office'>((): 'vehicle' | 'teamMember' | 'office' => {
    if (parsedQueryString.resourceMode) {
      return typeof parsedQueryString.resourceMode === 'string'
        ? (parsedQueryString.resourceMode as 'vehicle' | 'teamMember' | 'office')
        : (parsedQueryString.resourceMode[0] as 'vehicle' | 'teamMember' | 'office')
    }

    return 'vehicle'
  }, [parsedQueryString])

  const selectedResourceIds = useMemo<string[]>((): string[] => {
    if (parsedQueryString.sr) {
      return typeof parsedQueryString.sr === 'string' ? [parsedQueryString.sr] : parsedQueryString.sr
    }
    return []
  }, [parsedQueryString])

  const selectedResources = useMemo(() => {
    if (selectedResourceMode === 'vehicle') {
      if (selectedResourceIds && vehiclesHashTable) {
        return selectedResourceIds
          .filter((id) => !!vehiclesHashTable[id])
          .map((id) => ({ ...vehiclesHashTable[id], title: vehiclesHashTable[id].licensePlate }))
      }
    } else if (selectedResourceMode === 'teamMember') {
      if (selectedResourceIds && teamMembersHashTable) {
        return selectedResourceIds
          .filter((id) => !!teamMembersHashTable[id])
          .map((id) => ({
            ...teamMembersHashTable[id],
            title: teamMembersHashTable[id].fullName,
            // businessHours: teamMembersHashTable[id].workingHours
            //   ? teamMembersHashTable[id].workingHours.map((item: any) => {
            //       return {
            //         daysOfWeek: [dayOfWeekToFullCalendarWeekDay(item.dayNumber)],
            //         startTime: item.startTime,
            //         endTime: item.finishTime,
            //       }
            //     })
            //   : [],
          }))
      }
    } else if (selectedResourceMode === 'office') {
      if (selectedResourceIds && officesHashTable) {
        return selectedResourceIds
          .filter((id) => !!officesHashTable[id])
          .map((id) => ({
            ...officesHashTable[id],
            title: officesHashTable[id].name,
          }))
      }
    }
  }, [selectedResourceIds, vehiclesHashTable, teamMembersHashTable, officesHashTable, selectedResourceMode])

  const initialCalendarView = useMemo<string>(() => {
    if (parsedQueryString.initialView) {
      return typeof parsedQueryString.initialView === 'string'
        ? parsedQueryString.initialView
        : parsedQueryString.initialView[0]
    }
    return 'resourceTimeGridTwoWeeksDay'
  }, [parsedQueryString])

  const selectedDateString = useMemo<string>(() => {
    if (parsedQueryString.date) {
      return typeof parsedQueryString.date === 'string' ? parsedQueryString.date : parsedQueryString.date[0]
    }
    return new Date().toISOString()
  }, [parsedQueryString])

  const selectedDate = useMemo<Date>(() => {
    return new Date(selectedDateString)
  }, [selectedDateString])

  const {
    loading: loadingEvents,
    data: dataEvents,
    error: errorEvents,
    refetch: refetchEvents,
    startPolling,
    stopPolling,
  } = useQuery(QUERY_GET_PLANNED_EVENTS, {
    fetchPolicy: 'cache-and-network',
    skip: !currentDates || !currentDates.startStr || !currentDates.endStr,
    variables: {
      filters: {
        dateRange: {
          startDate: currentDates ? currentDates.startStr : undefined,
          endDate: currentDates ? currentDates.endStr : undefined,
        },
        teamMemberIds: selectedResourceMode === 'teamMember' ? selectedResourceIds : undefined,
        vehicleIds: selectedResourceMode === 'vehicle' ? selectedResourceIds : undefined,
        officeIds: selectedResourceMode === 'office' ? selectedResourceIds : undefined,
      },
    },
  })

  const { loading: loadingExams, data: dataExams, error: errorExams, refetch: refetchExams } = useQuery(
    QUERY_GET_EXAMS_PLANNINGS,
    {
      fetchPolicy: 'cache-and-network',
      skip: !currentDates || !currentDates.startStr || !currentDates.endStr,
      variables: {
        filters: {
          dateRange: {
            startDate: currentDates ? currentDates.startStr : undefined,
            endDate: currentDates ? currentDates.endStr : undefined,
          },
          teamMemberIds: selectedResourceMode === 'teamMember' ? selectedResourceIds : undefined,
          vehiclesIds: selectedResourceMode === 'vehicle' ? selectedResourceIds : undefined,
          // officeIds: selectedResourceMode === 'office' ? selectedResourceIds : undefined,
        },
      },
    }
  )

  const exams = useMemo<any[]>(
    () =>
      dataExams && dataExams.examPlannings && dataExams.examPlannings.data
        ? dataExams.examPlannings.data.map((planning: any) => {
            return {
              id: 'exam-planning-' + planning.id,
              title: planning.exam.name,
              start: planning.startDate,
              end: planning.endDate,
              resourceIds:
                selectedResourceMode === 'teamMember' && planning.teamMembers
                  ? planning.teamMembers.map((m: any) => m.id)
                  : selectedResourceMode === 'vehicle' && planning.vehicles
                  ? planning.vehicles.map((m: any) => m.id)
                  : // .map(({ id }) => id)
                    [],
              extendedProps: { ...planning },
              backgroundColor: 'gray',
              borderColor: 'gray',
            }
          })
        : [],
    [dataExams]
  )

  const events = useMemo(() => {
    return [
      ...(dataEvents && dataEvents.plannedEvents && dataEvents.plannedEvents.data
        ? dataEvents.plannedEvents.data
            .filter((item: any) => !!item.event)
            .map((item: any) => {
              const isEditable = isEventEditable(eventStatusConfiguration, item)
              return {
                id: item.id,
                title: item.event.title,
                start: item.event.startDate,
                end: item.event.endDate,
                resourceIds:
                  selectedResourceMode === 'vehicle'
                    ? item.vehicleId
                      ? [item.vehicleId]
                      : []
                    : selectedResourceMode === 'teamMember'
                    ? item.teamMemberEvents.map((r: any) => r.teamMember.id)
                    : selectedResourceMode === 'office'
                    ? item.officeId
                      ? [item.officeId]
                      : []
                    : [],
                backgroundColor:
                  item.event.additionalData && item.event.additionalData.backgroundColor
                    ? item.event.additionalData.backgroundColor
                    : item.teamMemberEvents.length > 0
                    ? item.isWorkEvent
                      ? item.teamMemberEvents[0].teamMember.calendarColor
                      : item.teamMemberEvents[0].teamMember.holidayCalendarColor
                      ? item.teamMemberEvents[0].teamMember.holidayCalendarColor
                      : DEFAULT_HOLIDAY_COLOR
                    : undefined,
                borderColor:
                  item.event.additionalData && item.event.additionalData.backgroundColor
                    ? item.event.additionalData.backgroundColor
                    : item.teamMemberEvents.length > 0
                    ? item.isWorkEvent
                      ? item.teamMemberEvents[0].teamMember.calendarColor
                      : item.teamMemberEvents[0].teamMember.holidayCalendarColor
                      ? item.teamMemberEvents[0].teamMember.holidayCalendarColor
                      : DEFAULT_HOLIDAY_COLOR
                    : undefined,
                editable: isEditable,
                overlap: isEditable,
                resourceEditable: isEditable,
                extendedProps: {
                  ...item,
                },
              }
            })
        : []),
      ...(selectedResourceMode === 'vehicle' && selectedResources && selectedResources.length > 0
        ? selectedResources.reduce((acc: any, item: any) => {
            const members = teamMembers.filter((tm) => tm.vehicles.find((v: any) => v.id === item.id))
            if (members && members.length > 0) {
              return [
                ...acc,
                ...members.reduce((acc, member) => {
                  if (member.workingPlannings?.workingHours && member.workingPlannings.workingHours.length > 0) {
                    console.log(member.workingPlanning.workingHours, 'carico le ore')
                    return []
                    return [
                      ...acc,
                      ...member.workingHours.map((wh: any) => {
                        return {
                          backgroundColor: member.calendarColor,
                          display: 'background',
                          daysOfWeek: [dayOfWeekToFullCalendarWeekDay(wh.dayNumber)],
                          startTime: wh.startTime,
                          endTime: wh.finishTime,
                          resourceIds: [item.id],
                          extendedProps: {
                            teamMemberId: member.id,
                          },
                        }
                      }),
                    ]
                  }

                  return acc
                }, []),
              ]
            }
            return acc
          }, [])
        : selectedResourceMode === 'teamMember' && selectedResources && selectedResources.length > 0
        ? selectedResources.reduce((acc: any, item: any) => {
            const members = selectedResources.filter((tm: any) => tm.id === item.id)
            if (members && members.length > 0) {
              return [
                ...acc,
                ...members.reduce((acc, member) => {
                  if (member.workingPlannings?.length > 0) {
                    console.log(member.workingPlannings, 'carico le ore')
                    return member.workingPlannings.reduce((acc: any[], wp: any) => {
                      return [
                        ...acc,
                        ...wp.workingHours.map((wh: any) => {
                          return {
                            backgroundColor: wh.isWorkTime
                              ? member.calendarColor
                              : member.holidayCalendarColor
                              ? member.holidayCalendarColor
                              : DEFAULT_HOLIDAY_COLOR,
                            display: 'background',
                            daysOfWeek: [dayOfWeekToFullCalendarWeekDay(wh.dayNumber)],
                            startRecur: wp.startDate,
                            endRecur: wp.endDate,
                            startTime: wh.startTime,
                            endTime: wh.finishTime,
                            resourceIds: [item.id],
                            extendedProps: {
                              teamMemberId: member.id,
                              isWorkTime: wh.isWorkTime,
                            },
                          }
                        }),
                      ]
                    }, [])
                    return [
                      ...acc,
                      ...member.workingHours.map((wh: any) => {
                        return {
                          backgroundColor: member.calendarColor,
                          display: 'background',
                          daysOfWeek: [dayOfWeekToFullCalendarWeekDay(wh.dayNumber)],
                          startTime: wh.startTime,
                          endTime: wh.finishTime,
                          resourceIds: [item.id],
                          extendedProps: {
                            teamMemberId: member.id,
                          },
                        }
                      }),
                    ]
                  }

                  return acc
                }, []),
              ]
            }

            return acc
          }, [])
        : []),
      ...exams,
    ]
  }, [dataEvents, selectedResourceMode, selectedResources, exams])

  const handleToggleEventsList = useCallback(() => {
    setEventsListOpen(!eventsListOpen)
    localStorage.setItem('eventsListOpen', JSON.stringify(!eventsListOpen))
  }, [eventsListOpen])

  const handleToggleFastEvents = useCallback(() => {
    setFastEventsOpen(!fastEventsOpen)
    localStorage.setItem('fastEventsOpen', JSON.stringify(!fastEventsOpen))
  }, [fastEventsOpen])

  const handleResourceModeChange = useCallback(
    (resourceMode: 'vehicle' | 'teamMember' | 'office') => {
      // setResourceMode(value)
      history.push({
        pathname: props.basePath,
        search: queryString.stringify({
          ...parsedQueryString,
          sr: undefined,
          resourceMode,
        }),
      })
    },
    [parsedQueryString]
  )

  const onEvents = useCallback(
    async (info: DatesSetArg): Promise<void> => {
      try {
        dispatch(fetchStart())
        const result = await client.query({
          query: QUERY_GET_PLANNED_EVENTS,
          // fetchPolicy: 'cache-and-network',
          variables: {
            filters: {
              dateRange: {
                startDate: info.startStr,
                endDate: info.endStr,
              },
              teamMemberIds: selectedResourceMode === 'teamMember' ? selectedResourceIds : undefined,
              vehicleIds: selectedResourceMode === 'vehicle' ? selectedResourceIds : undefined,
            },
          },
        })

        const coursePlanningResult = await client.query({
          query: QUERY_GET_COURSE_PLANNINGS,
          variables: {
            filters: {
              // dateRange: {
              //   startDate: new Date(info.startStr),
              //   endDate: new Date(info.endStr),
              // },
            },
          },
        })

        const examPlanningResult = await client.query({
          query: QUERY_GET_EXAMS_PLANNINGS,
          variables: {
            filters: {
              dateRange: {
                startDate: currentDates ? currentDates.startStr : undefined,
                endDate: currentDates ? currentDates.endStr : undefined,
              },
              teamMemberIds: selectedResourceMode === 'teamMember' ? selectedResourceIds : undefined,
              vehicleIds: selectedResourceMode === 'vehicle' ? selectedResourceIds : undefined,
              // officeIds: selectedResourceMode === 'office' ? selectedResourceIds : undefined,
            },
          },
        })

        const calendarCourses =
          coursePlanningResult.data &&
          coursePlanningResult.data.coursePlannings &&
          coursePlanningResult.data.coursePlannings.data
            ? coursePlanningResult.data.coursePlannings.data
                .filter((item: any) => {
                  return dateRangeOverlaps(
                    new Date(item.startDate).getTime(),
                    new Date(item.endDate).getTime(),
                    info.start.getTime(),
                    info.end.getTime()
                  )
                })

                .reduce((acc: any[], planning: any) => {
                  if (planning.schedules) {
                    return [
                      ...acc,
                      ...planning.schedules.map((schedule: any) => {
                        return {
                          id: 'course-planning-' + planning.id + '-schedule-' + schedule.id,
                          daysOfWeek: [dayOfWeekToFullCalendarWeekDay(schedule.dayNumber)],
                          startTime: schedule.startTime,
                          endTime: schedule.finishTime,
                          startRecur: planning.startDate,
                          endRecur: planning.endDate,
                          title: planning.course.name,
                          backgroundColor: '#333',
                          borderColor: '#333',
                          editable: false,
                          overlap: false,
                          resourceEditable: false,
                          resourceIds:
                            selectedResourceMode === 'teamMember' && planning.teamMemberId
                              ? [planning.teamMemberId]
                              : selectedResourceMode === 'vehicle' && selectedResources && selectedResources.length > 0
                              ? selectedResources
                                  .filter((item: any) => {
                                    const vehicleTeamMembers = teamMembers.filter((tm) =>
                                      tm.vehicles.find((v: any) => v.id === item.id)
                                    )
                                    return vehicleTeamMembers.find((item) => item.id === planning.teamMemberId)
                                  })
                                  .map(({ id }: any) => id)
                              : [],
                          extendedProps: { ...schedule, planning },
                        }
                      }),
                    ]
                  }
                  return acc
                }, [])
            : []

        const exams =
          examPlanningResult.data && examPlanningResult.data.examPlannings && examPlanningResult.data.examPlannings.data
            ? examPlanningResult.data.examPlannings.data.map((planning: any) => {
                return {
                  id: 'exam-planning-' + planning.id,
                  title: planning.exam.name,
                  start: planning.startDate,
                  end: planning.endDate,
                  resourceIds:
                    selectedResourceMode === 'teamMember' && planning.teamMembers
                      ? planning.teamMembers.map((m: any) => m.id)
                      : selectedResourceMode === 'vehicle' &&
                        selectedResources &&
                        selectedResources.length > 0 &&
                        planning.teamMembers
                      ? selectedResources
                          .filter((item: any) => {
                            const vehicleTeamMembers = teamMembers.filter((tm) =>
                              tm.vehicles.find((v: any) => v.id === item.id)
                            )
                            const sortedVTIds = vehicleTeamMembers.map(({ id }) => id).sort()
                            const sortedETIds = planning.teamMembers.map(({ id }: any) => id).sort()

                            return sortedETIds.filter((eid: any) => sortedVTIds.indexOf(eid) > -1).length > 0
                          })
                          .map(({ id }) => id)
                      : [],
                  extendedProps: { ...planning },
                  backgroundColor: 'gray',
                  borderColor: 'gray',
                }
              })
            : []

        const finalResult = [
          ...result.data.plannedEvents.data
            .filter((item: any) => !!item.event)
            .map((item: any) => {
              const isEditable = isEventEditable(eventStatusConfiguration, item)
              return {
                id: item.id,
                title: item.event.title,
                start: item.event.startDate,
                end: item.event.endDate,
                resourceIds:
                  selectedResourceMode === 'vehicle'
                    ? item.vehicleId
                      ? [item.vehicleId]
                      : []
                    : item.teamMemberEvents.map((r: any) => r.teamMember.id),
                backgroundColor:
                  item.event.additionalData && item.event.additionalData.backgroundColor
                    ? item.event.additionalData.backgroundColor
                    : item.teamMemberEvents.length > 0
                    ? item.teamMemberEvents[0].teamMember.calendarColor
                    : undefined,
                borderColor:
                  item.event.additionalData && item.event.additionalData.backgroundColor
                    ? item.event.additionalData.backgroundColor
                    : item.teamMemberEvents.length > 0
                    ? item.teamMemberEvents[0].teamMember.calendarColor
                    : undefined,
                editable: isEditable,
                overlap: isEditable,
                resourceEditable: isEditable,
                extendedProps: {
                  ...item,
                },
              }
            }),
          ...(selectedResourceMode === 'vehicle' && selectedResources && selectedResources.length > 0
            ? selectedResources.reduce((acc: any, item: any) => {
                const members = teamMembers.filter((tm) => tm.vehicles.find((v: any) => v.id === item.id))
                if (members && members.length > 0) {
                  return [
                    ...acc,
                    ...members.reduce((acc, member) => {
                      if (member.workingHours && member.workingHours.length > 0) {
                        return [
                          ...acc,
                          ...member.workingHours.map((wh: any) => {
                            return {
                              backgroundColor: member.calendarColor,
                              display: 'background',
                              daysOfWeek: [dayOfWeekToFullCalendarWeekDay(wh.dayNumber)],
                              startTime: wh.startTime,
                              endTime: wh.finishTime,
                              resourceIds: [item.id],
                              extendedProps: {
                                teamMemberId: member.id,
                              },
                            }
                          }),
                        ]
                      }

                      return acc
                    }, []),
                  ]
                }
                return acc
              }, [])
            : selectedResourceMode === 'teamMember' && selectedResources && selectedResources.length > 0
            ? selectedResources.reduce((acc: any, item: any) => {
                const members = selectedResources.filter((tm: any) => tm.id === item.id)
                if (members && members.length > 0) {
                  return [
                    ...acc,
                    ...members.reduce((acc, member) => {
                      if (member.workingHours && member.workingHours.length > 0) {
                        return [
                          ...acc,
                          ...member.workingHours.map((wh: any) => {
                            return {
                              backgroundColor: member.calendarColor,
                              display: 'background',
                              daysOfWeek: [dayOfWeekToFullCalendarWeekDay(wh.dayNumber)],
                              startTime: wh.startTime,
                              endTime: wh.finishTime,
                              resourceIds: [item.id],
                              extendedProps: {
                                teamMemberId: member.id,
                              },
                            }
                          }),
                        ]
                      }

                      return acc
                    }, []),
                  ]
                }

                return acc
              }, [])
            : []),
          ...calendarCourses,
          ...exams,
        ]
        setData(finalResult)
      } catch (e) {
        console.error(e)
        // error({ message: 'Error fetching Planned Event' })
      } finally {
        dispatch(fetchEnd())
      }
    },
    [teamMembers, selectedResources, selectedResourceIds, selectedResourceMode, eventStatusConfiguration]
  )

  const createPlannedEvent = useCallback(
    async (event: {
      startDate: string
      endDate: string
      title?: string
      description?: string
      customerIds?: string[]
      teamMemberIds?: string[]
      vehicleId?: string
      statusId?: string
      officeId?: string
      additionalData?: {
        backgroundColor?: string
      }
    }) => {
      try {
        dispatch(fetchStart())
        const result = await client.mutate({
          mutation: MUTATION_CREATE_PLANNED_EVENT,
          variables: { data: event },
          update: (cache, { data: { createPlannedEvent } }) => {
            cache.modify({
              fields: {
                plannedEvents: (existingPlannedEventsRefs = { data: [], total: 0, offset: 0 }, { readField }): any => {
                  console.log(existingPlannedEventsRefs, '<-- existingPlannedEventsRefs')
                  const newPlannedEventRef = cache.writeFragment({
                    data: createPlannedEvent,
                    fragment: gql`
                      fragment NewPlannedEvent on PlannedEvent {
                        id
                        event {
                          id
                          startDate
                          endDate
                          title
                          additionalData
                        }
                        customerEvents {
                          id
                          customer {
                            id
                            fullName
                            firstName
                            lastName
                            email
                            phone
                            fiscalCode
                            profilePictureId
                          }
                          status {
                            id
                            name
                            color
                            createdAt
                            updatedAt
                          }
                          createdAt
                          updatedAt
                        }
                        teamMemberEvents {
                          id
                          teamMember {
                            id
                            fullName
                            firstName
                            lastName
                            email
                            phone
                            calendarColor
                          }
                        }
                        vehicleId
                        vehicle {
                          id
                          licensePlate
                        }
                      }
                    `,
                  })
                  return {
                    total: existingPlannedEventsRefs.data + 1,
                    offset: existingPlannedEventsRefs.offset,
                    data: [...existingPlannedEventsRefs.data, newPlannedEventRef],
                  }
                },
              },
            })
          },
        })
        // fullCalendarRef.current!.getApi().refetchEvents()
      } catch (e) {
        console.error(e)
        notify('Error creating Planned Event', 'warning')
      } finally {
        dispatch(fetchEnd())
      }
    },
    [client, fullCalendarRef]
  )

  const updatePlannedEvent = useCallback(
    async (
      id: string,
      event: {
        startDate: string
        endDate: string
        title?: string
        description?: string
        customerIds?: string[]
        teamMemberIds?: string[]
        vehicleId?: string
        statusId?: string
        officeId?: string
      }
    ) => {
      try {
        dispatch(fetchStart())
        const result = await client.mutate({
          mutation: MUTATION_UPDATE_PLANNED_EVENT,
          variables: { data: event, id: id },
        })
        fullCalendarRef.current!.getApi().refetchEvents()
      } catch (e) {
        console.error(e)
        notify('Error updating Planned Event', 'warning')
      } finally {
        dispatch(fetchEnd())
      }
    },
    [client, fullCalendarRef]
  )

  const onDateSelected = useCallback(
    (arg: DateSelectArg) => {
      if (!eventStatusConfiguration.getConfiguration || !eventStatusConfiguration.getConfiguration.value) {
        notify('ra.error')
      }

      const foundedBackgroundEvent = arg
        .view!.calendar.getEvents()
        .find(
          (event) =>
            event.display === 'background' &&
            event.start &&
            event.end &&
            event.start.getTime() <= arg.start.getTime() &&
            event.end.getTime() >= arg.end.getTime()
        )

      history.push({
        pathname: '/PlannedEvent/create',
        search: search,
        state: {
          record: {
            title: 'Guida',
            description: '',
            startDate: arg.startStr,
            endDate: arg.endStr,
            officeId: selectedResourceMode === 'office' ? (arg.resource ? arg.resource.id : undefined) : undefined,
            vehicleId:
              selectedResourceMode === 'vehicle'
                ? arg.resource
                  ? arg.resource.id
                  : undefined
                : selectedResourceMode === 'teamMember'
                ? arg.resource &&
                  teamMembersHashTable[arg.resource.id].vehicles &&
                  teamMembersHashTable[arg.resource.id].vehicles.length > 0
                  ? teamMembersHashTable[arg.resource.id].vehicles[0].id
                  : undefined
                : undefined,
            statusId: eventStatusConfiguration.getConfiguration.value.created[0],
            teamMemberIds:
              selectedResourceMode === 'vehicle'
                ? foundedBackgroundEvent &&
                  foundedBackgroundEvent.extendedProps &&
                  foundedBackgroundEvent.extendedProps.teamMemberId
                  ? [foundedBackgroundEvent.extendedProps.teamMemberId]
                  : undefined
                : selectedResourceMode === 'teamMember'
                ? arg.resource
                  ? [arg.resource.id]
                  : undefined
                : undefined,
          },
        },
      })
    },
    [teamMembersHashTable, eventStatusConfiguration, selectedResourceMode, search]
  )

  const onEventResize = useCallback((arg: EventResizeDoneArg) => {
    updatePlannedEvent(arg.event.id, {
      startDate: arg.event.startStr,
      endDate: arg.event.endStr,
    })
  }, [])

  const onEventDrop = useCallback(
    (arg: EventDropArg) => {
      const foundedBackgroundEvents = arg.view!.calendar.getEvents().filter((event: any) => {
        return (
          event.display === 'background' &&
          event.start &&
          event.end &&
          event.start.getTime() <= new Date(arg.event.startStr).getTime() &&
          event.end.getTime() >= new Date(arg.event.endStr).getTime() &&
          arg.event._def &&
          arg.event._def.resourceIds &&
          arg.event._def.resourceIds.length > 0 &&
          event._def &&
          event._def.resourceIds &&
          event._def.resourceIds.length > 0 &&
          event._def.resourceIds.indexOf(arg.event._def.resourceIds[0]) !== -1
        )
      })
      const foundedBackgroundEvent = foundedBackgroundEvents.length > 0 ? foundedBackgroundEvents[0] : undefined
      // const foundedBackgroundEvent = arg
      //   .view!.calendar.getEvents()
      //   .find(
      //     (event: any) =>
      //       event.display === 'background' &&
      //       event.start &&
      //       event.end &&
      //       event.start.getTime() <= new Date(arg.event.startStr).getTime() &&
      //       event.end.getTime() >= new Date(arg.event.endStr).getTime()
      //   )

      // console.log('evento droppato:', arg)
      updatePlannedEvent(arg.event.id, {
        startDate: arg.event.startStr,
        endDate: arg.event.endStr,
        // teamMemberIds:
        //   arg.event.getResources() && arg.event.getResources().length > 0
        //     ? [arg.event.getResources()[0].id]
        //     : undefined,
        teamMemberIds:
          selectedResourceMode === 'vehicle'
            ? foundedBackgroundEvent &&
              foundedBackgroundEvent.extendedProps &&
              foundedBackgroundEvent.extendedProps.teamMemberId
              ? foundedBackgroundEvent.extendedProps.teamMemberId
              : undefined
            : selectedResourceMode === 'teamMember'
            ? arg.event.getResources() && arg.event.getResources().length > 0
              ? arg.event.getResources()[0].id
              : undefined
            : undefined,
        vehicleId:
          selectedResourceMode === 'vehicle'
            ? arg.event.getResources() && arg.event.getResources().length > 0
              ? arg.event.getResources()[0].id
              : undefined
            : undefined,
        officeId:
          selectedResourceMode === 'office'
            ? arg.event.getResources() && arg.event.getResources().length > 0
              ? arg.event.getResources()[0].id
              : undefined
            : undefined,
      })
    },
    [teamMembersHashTable, selectedResourceMode]
  )

  const onFastEventDrop = useCallback(
    (arg: any) => {
      console.log('onFastEventDrop()', arg, arg.view.getCurrentData())

      if (!eventStatusConfiguration.getConfiguration || !eventStatusConfiguration.getConfiguration.value) {
        notify('ra.error')
      }

      const customerDetails =
        arg.draggedEl.attributes.getNamedItem('data-customer') &&
        arg.draggedEl.attributes.getNamedItem('data-customer')?.value
          ? JSON.parse(arg.draggedEl.attributes.getNamedItem('data-customer')!.value)
          : {}

      const eventTemplateDetails =
        arg.draggedEl.attributes.getNamedItem('data-event-template') &&
        arg.draggedEl.attributes.getNamedItem('data-event-template')?.value
          ? JSON.parse(arg.draggedEl.attributes.getNamedItem('data-event-template')!.value)
          : {}

      const foundedBackgroundEvents = arg.view!.calendar.getEvents().filter((event: any) => {
        return (
          event.display === 'background' &&
          event.start &&
          event.end &&
          event.start.getTime() <= arg.event.start.getTime() &&
          event.end.getTime() >= arg.event.end.getTime() &&
          arg.event._def &&
          arg.event._def.resourceIds &&
          arg.event._def.resourceIds.length > 0 &&
          event._def &&
          event._def.resourceIds &&
          event._def.resourceIds.length > 0 &&
          event._def.resourceIds.indexOf(arg.event._def.resourceIds[0]) !== -1
        )
      })
      const foundedBackgroundEvent =
        foundedBackgroundEvents && foundedBackgroundEvents.length > 0 ? foundedBackgroundEvents[0] : undefined

      console.log(foundedBackgroundEvent, 'ciao foundedBackgroundEvent')

      if (customerDetails.id) {
        createPlannedEvent({
          title: customerDetails.fullName ? customerDetails.fullName : 'N.D.',
          description: '',
          startDate: arg.event.startStr,
          endDate: arg.event.endStr,
          statusId: eventStatusConfiguration.getConfiguration.value.created[0],
          vehicleId:
            selectedResourceMode === 'vehicle'
              ? arg.event._def.resourceIds && arg.event._def.resourceIds.length > 0
                ? arg.event._def.resourceIds[0]
                : undefined
              : selectedResourceMode === 'teamMember'
              ? arg.event._def.resourceIds &&
                arg.event._def.resourceIds.length > 0 &&
                teamMembersHashTable[arg.event._def.resourceIds[0]] &&
                teamMembersHashTable[arg.event._def.resourceIds[0]].vehicles &&
                teamMembersHashTable[arg.event._def.resourceIds[0]].vehicles.length > 0
                ? teamMembersHashTable[arg.event._def.resourceIds[0]].vehicles[0].id
                : undefined
              : undefined,
          teamMemberIds:
            selectedResourceMode === 'vehicle'
              ? foundedBackgroundEvent &&
                foundedBackgroundEvent.extendedProps &&
                foundedBackgroundEvent.extendedProps.teamMemberId
                ? [foundedBackgroundEvent.extendedProps.teamMemberId]
                : undefined
              : selectedResourceMode === 'teamMember'
              ? arg.event._def.resourceIds && arg.event._def.resourceIds.length > 0
                ? [arg.event._def.resourceIds[0]]
                : undefined
              : undefined,
          officeId:
            selectedResourceMode === 'office'
              ? arg.event._def.resourceIds.length > 0
                ? arg.event._def.resourceIds[0]
                : undefined
              : undefined,
          customerIds: customerDetails.id ? [customerDetails.id] : undefined,
          // vehicleId:
          //   arg.resource &&
          //   teamMembersHashTable[arg.resource.id].vehicles &&
          //   teamMembersHashTable[arg.resource.id].vehicles.length > 0
          //     ? teamMembersHashTable[arg.resource.id].vehicles[0].id
          //     : undefined,
        }).then((data) => arg.revert())
      }

      if (eventTemplateDetails.id) {
        createPlannedEvent({
          title: eventTemplateDetails.title,
          description: eventTemplateDetails.description,
          startDate: arg.event.startStr,
          endDate: arg.event.endStr,
          statusId: eventStatusConfiguration.getConfiguration.value.created[0],
          vehicleId:
            selectedResourceMode === 'vehicle'
              ? arg.event._def.resourceIds && arg.event._def.resourceIds.length > 0
                ? arg.event._def.resourceIds[0]
                : undefined
              : undefined,
          teamMemberIds:
            selectedResourceMode === 'vehicle'
              ? foundedBackgroundEvent &&
                foundedBackgroundEvent.extendedProps &&
                foundedBackgroundEvent.extendedProps.teamMemberId
                ? foundedBackgroundEvent.extendedProps.teamMemberId
                : undefined
              : selectedResourceMode === 'teamMember'
              ? arg.event._def.resourceIds && arg.event._def.resourceIds.length > 0
                ? arg.event._def.resourceIds[0]
                : undefined
              : undefined,
          officeId:
            selectedResourceMode === 'office'
              ? arg.event._def.resourceIds.length > 0
                ? arg.event._def.resourceIds[0]
                : undefined
              : undefined,
          customerIds: customerDetails.id ? [customerDetails.id] : undefined,
          additionalData: {
            backgroundColor: eventTemplateDetails.color,
          },
          // vehicleId:
          //   arg.resource &&
          //   teamMembersHashTable[arg.resource.id].vehicles &&
          //   teamMembersHashTable[arg.resource.id].vehicles.length > 0
          //     ? teamMembersHashTable[arg.resource.id].vehicles[0].id
          //     : undefined,
        }).then((data) => arg.revert())
      }
    },
    [createPlannedEvent, eventStatusConfiguration, selectedResourceMode, teamMembersHashTable]
  )

  const onEventClick = useCallback(
    (e: EventClickArg) => {
      if (e.event.id && e.event.id.indexOf('course-planning-') === -1 && e.event.id.indexOf('exam-planning-') === -1) {
        history.push({
          pathname: `/PlannedEvent/${e.event.id}`,
          search: search,
        })
      } else if (e.event.extendedProps.__typename === 'ExamPlanning') {
        // console.log('sono io, funziono', e.event.extendedProps.id)
        history.push({
          pathname: `/ExamPlanning/${e.event.extendedProps.id}/show`,
        })
      }
    },
    [props.basePath, search]
  )

  const onCalendarViewChange = useCallback(
    (arg: DatesSetArg): void => {
      // console.log('onCalendarViewChange()', arg)
      // console.log('getEvents()', arg.view.calendar.getEvents())
      setCurrentDates(arg)
      setCalTitle(arg.view.title)
      stopPolling()
      startPolling(2000)
      // onEvents(arg)
    },
    [setCalTitle, onEvents, setCurrentDates]
  )

  const onCalGoToDate = useCallback(
    (date: any) => {
      // fullCalendarRef.current!.getApi().gotoDate(date)
      history.push({
        pathname: props.basePath,
        search: queryString.stringify({
          ...parsedQueryString,
          date: date.toISOString(),
        }),
      })
    },
    [history, parsedQueryString]
  )

  const onCalPrev = useCallback(() => {
    fullCalendarRef.current!.getApi().prev()
    history.push({
      pathname: props.basePath,
      search: queryString.stringify({
        ...parsedQueryString,
        date: fullCalendarRef.current!.getApi().view.currentStart.toISOString(),
      }),
    })
  }, [fullCalendarRef.current, history, parsedQueryString])

  const onCalNext = useCallback(() => {
    fullCalendarRef.current!.getApi().next()
    history.push({
      pathname: props.basePath,
      search: queryString.stringify({
        ...parsedQueryString,
        date: fullCalendarRef.current!.getApi().view.currentStart.toISOString(),
      }),
    })
  }, [fullCalendarRef.current, history, parsedQueryString])

  const onCalToday = useCallback(() => {
    fullCalendarRef.current!.getApi().today()
    history.push({
      pathname: props.basePath,
      search: queryString.stringify({
        ...parsedQueryString,
        date: fullCalendarRef.current!.getApi().view.currentStart.toISOString(),
      }),
    })
  }, [fullCalendarRef.current, history, parsedQueryString])

  const onSwitchCalView = useCallback(
    (view: string) => (): void => {
      history.push({
        pathname: props.basePath,
        search: queryString.stringify({ ...parsedQueryString, initialView: view }),
      })
    },
    [fullCalendarRef.current, parsedQueryString]
  )

  const onResourcesSelected = useCallback(
    (selectedResourceIds: string[]): void => {
      // if (selectedResourceIds.length > 0) {
      // console.log('onResourcesSelected', selectedResourceIds)

      history.push({
        pathname: props.basePath,
        search: queryString.stringify({ ...parsedQueryString, sr: selectedResourceIds }),
      })
      // }
    },
    [vehiclesHashTable, history, props.basePath, parsedQueryString]
  )

  const onFullCalendarRef = useCallback((ref: FullCalendar) => {
    fullCalendarRef.current = ref
    // fullCalendarRef.current.getApi().
  }, [])

  const onCustomersFERef = useCallback(
    (ref) => {
      if (draggableCustomersRef.current) {
        return
      }

      draggableCustomersRef.current = new Draggable(ref, {
        itemSelector: '.draggable-event',
        eventData: (eventEl: HTMLElement): any => {
          const customerDetails =
            eventEl.attributes.getNamedItem('data-customer') && eventEl.attributes.getNamedItem('data-customer')?.value
              ? JSON.parse(eventEl.attributes.getNamedItem('data-customer')!.value)
              : {}

          return {
            title: customerDetails.fullName ? customerDetails.fullName : 'N.D.',
            duration: {
              hours: 1,
            },
          }
        },
      })
    },
    [draggableCustomersRef]
  )

  const onTemplateEventsFERef = useCallback((ref) => {
    if (draggableEventTemplatesRef.current) {
      return
    }

    draggableEventTemplatesRef.current = new Draggable(ref, {
      itemSelector: '.draggable-event',
      eventData: (eventEl: HTMLElement): any => {
        const templateDetails =
          eventEl.attributes.getNamedItem('data-event-template') &&
          eventEl.attributes.getNamedItem('data-event-template')?.value
            ? JSON.parse(eventEl.attributes.getNamedItem('data-event-template')!.value)
            : {}

        return {
          title: templateDetails.title,
          duration: {
            minutes: templateDetails.duration,
          },
        }
      },
    })
  }, [])

  const getEventClassNames = useCallback((arg: EventContentArg) => {
    if (arg.event.display === 'background') {
      return classes.fcBgEvent
    }
    return classes.fcEvent
    // return []
  }, [])

  const eventContent = useCallback(
    (arg: EventContentArg) => {
      if (
        arg.event.display === 'background' &&
        arg.event.extendedProps &&
        arg.event.extendedProps.teamMemberId &&
        teamMembersHashTable[arg.event.extendedProps.teamMemberId]
      ) {
        const teamMember = teamMembersHashTable[arg.event.extendedProps.teamMemberId]
        return (
          <div className={classes.backgroundEventContent}>
            <Typography variant="body2" style={{ color: invert(arg.event.backgroundColor, true), fontWeight: 'bold' }}>
              {arg.event.extendedProps.isWorkTime ? 'Lavoro' : 'Pausa/Ferie'}
            </Typography>
            <Typography variant="body2" style={{ color: invert(arg.event.backgroundColor, true) }}>
              {`${teamMember.fullName}`}
            </Typography>
          </div>
        )
      }

      if (
        arg.event.display !== 'background' &&
        arg.event.display !== 'inverse-background' &&
        arg.event.display !== 'none'
      ) {
        const hasStatus =
          arg.event.extendedProps &&
          arg.event.extendedProps.customerEvents &&
          arg.event.extendedProps.customerEvents.length > 0 &&
          arg.event.extendedProps.customerEvents[0].status
        const badgeColor = hasStatus ? arg.event.extendedProps.customerEvents[0].status.color : undefined

        return (
          <EventTooltip
            title={
              <Box my={1} mx={1}>
                {arg.event.extendedProps &&
                  arg.event.extendedProps.teamMemberEvents &&
                  arg.event.extendedProps.teamMemberEvents.length > 0 &&
                  arg.event.extendedProps.teamMemberEvents[0].teamMember && (
                    <Box display="flex" mb={1}>
                      <UserPreviewField
                        record={teamMembersHashTable[arg.event.extendedProps.teamMemberEvents[0].teamMember.id]}
                      />
                    </Box>
                  )}
                {arg.event.extendedProps &&
                  arg.event.extendedProps.teamMembers &&
                  arg.event.extendedProps.teamMembers.length > 0 &&
                  arg.event.extendedProps.teamMembers.map((item: any) => (
                    <Box key={`team-member-${item.id}`} display="flex" mb={1}>
                      <UserPreviewField record={teamMembersHashTable[item.id]} />
                    </Box>
                  ))}
                <Typography variant="h5" component="h2">
                  {arg.event.title}
                </Typography>
                <Box mt={1}>
                  <Chip
                    size="small"
                    label={`${moment(arg.event.start).format('LT')} - ${moment(arg.event.end).format('LT')}`}
                  />
                </Box>
                {arg.event.extendedProps &&
                  arg.event.extendedProps.customerEvents &&
                  arg.event.extendedProps.customerEvents.length > 0 && (
                    <Box display="flex" mt={2}>
                      {arg.event.extendedProps.customerEvents[0].status ? (
                        <div>
                          <UserPreviewField
                            avatarSize={35}
                            record={arg.event.extendedProps.customerEvents[0].customer}
                            badgeColor={arg.event.extendedProps.customerEvents[0].status.color}
                          />
                          <Box my={1}>
                            <Typography variant="caption">{`${arg.event.extendedProps.customerEvents[0].status.name}${
                              arg.event.extendedProps &&
                              arg.event.extendedProps.customerEvents &&
                              arg.event.extendedProps.customerEvents.length > 0 &&
                              arg.event.extendedProps.customerEvents[0]
                                ? ' il: ' +
                                  new Date(arg.event.extendedProps.customerEvents[0].updatedAt).toLocaleString()
                                : ''
                            }`}</Typography>
                            {/* <br />
                            <Typography variant="caption">{`(modificato il ${new Date(
                              arg.event.extendedProps.customerEvents[0].status.updatedAt
                            ).toLocaleString()})`}</Typography> */}
                          </Box>
                        </div>
                      ) : (
                        <UserPreviewField avatarSize={35} record={arg.event.extendedProps.customerEvents[0].customer} />
                      )}
                    </Box>
                  )}
                {arg.event.extendedProps &&
                  arg.event.extendedProps.customers &&
                  arg.event.extendedProps.customers.length > 0 && (
                    <Box display="flex" flexDirection="column" mt={2}>
                      {arg.event.extendedProps.customers.map((item: any) => (
                        <UserPreviewField
                          key={`customer-${item.id}`}
                          // avatarSize={20}
                          record={item}
                          // badgeColor={arg.event.extendedProps.customerEvents[0].status.color}
                        />
                      ))}
                    </Box>
                  )}
              </Box>
            }
          >
            <div
              className={classes.eventContainer}
              style={badgeColor ? { borderLeft: `5px solid ${badgeColor}` } : undefined}
            >
              <div className={classes.eventTopContainer}>
                {/* {badgeColor && <div className={classes.eventBadge} style={{ backgroundColor: badgeColor }} />} */}
                <Typography className={classes.eventTitle} variant="body2">
                  {arg.event.title}
                </Typography>
                {arg.event.extendedProps &&
                  arg.event.extendedProps.customers &&
                  arg.event.extendedProps.customers.length > 0 &&
                  arg.event.extendedProps.customers.map((item: any) => (
                    <Typography
                      key={`customer-event-content-${item.id}`}
                      className={classes.eventCustomer}
                      variant="body2"
                    >
                      {`${item.lastName}${item.firstName ? ` ${item.firstName[0]}.` : ''}`}
                    </Typography>
                  ))}
                <Typography noWrap variant="caption" className={classes.eventTimeText}>
                  {arg.timeText}
                </Typography>
              </div>
            </div>
          </EventTooltip>
        )
      }
    },
    [teamMembersHashTable]
  )

  const resourceLabelContent: CustomContentGenerator<{
    resource: any
    date?: Date
    view: ViewApi
  }> = useCallback(
    ({ resource, date, view }) => {
      if (selectedResourceMode === 'teamMember') {
        return (
          <div className={classes.resourceTeamMemberLabelContent}>
            <CustomerAvatarField
              className={classes.resourceCustomerAvatar}
              avatarSize={36}
              record={resource.extendedProps}
              source="profilePictureId"
            />
            <Typography variant="body2" className={classes.resourceCustomerLabel}>
              {resource.extendedProps.fullName}
            </Typography>
          </div>
        )
      } else if (selectedResourceMode === 'vehicle') {
        return (
          <div className={classes.resourceVehicleLabelContent}>
            <Typography variant="h4" className={classes.resourceCustomerLabel}>
              {resource.extendedProps.licensePlate}
            </Typography>
            <Typography color="textSecondary" variant="subtitle2" className={classes.resourceCustomerLabel}>
              {resource.extendedProps.vehicleModel.name}
            </Typography>
          </div>
        )
      } else if (selectedResourceMode === 'office') {
        return (
          <div className={classes.resourceVehicleLabelContent}>
            <Typography variant="h4" className={classes.resourceCustomerLabel}>
              {resource.extendedProps.name}
            </Typography>
            <Typography color="textSecondary" variant="subtitle2" className={classes.resourceCustomerLabel}>
              {`${resource.extendedProps.street}, ${resource.extendedProps.city}`}
            </Typography>
          </div>
        )
      }
    },
    [selectedResourceMode]
  )

  const onDayLabelClick = useCallback(
    (date: any) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      history.push({
        pathname: props.basePath,
        search: queryString.stringify({
          ...parsedQueryString,
          initialView: 'resourceTimeGridDay',
          date: date.toISOString(),
        }),
      })
    },
    [onCalGoToDate, parsedQueryString]
  )

  const dayHeaderContent: CustomContentGenerator<{
    date: Date
    view: ViewApi
    text: string
  }> = useCallback(
    ({ text, date, view }) => {
      const momentDate = moment(date)
      const isTodayActive = today.getTime() === date.getTime() ? classes.activeDayLabel : undefined
      return (
        <div className={classes.dayHeaderContent}>
          <Typography
            className={classes.dayOfWeekText}
            color={isTodayActive ? 'primary' : 'textSecondary'}
            variant="body2"
          >
            {momentDate.format('ddd')}
          </Typography>
          <IconButton
            size="small"
            // color={selectedDate.getTime() === date.getTime() ? 'primary' : undefined}
            onClick={onDayLabelClick(date)}
            className={clsx(classes.dayIconButton, { [classes.activeDayLabel]: isTodayActive })}
          >
            <Typography color={!isTodayActive ? 'textPrimary' : undefined} className={classes.dayIconText} variant="h4">
              {momentDate.format('D')}
            </Typography>
          </IconButton>
        </div>
      )
    },
    [onDayLabelClick]
  )

  const slotLabelContent: CustomContentGenerator<{
    level: number
    time: Duration
    date: Date
    view: ViewApi
    text: string
  }> = useCallback(({ level, time, date, view, text }) => {
    return (
      <div className={classes.hourSlotLabelContent}>
        <Typography className={classes.hourSlotLabel} role="body2" color="textSecondary">
          {text}
        </Typography>
      </div>
    )
  }, [])

  useEffect(() => {
    if (fullCalendarRef.current) {
      fullCalendarRef.current!.getApi().changeView(initialCalendarView)
    }
  }, [initialCalendarView, fullCalendarRef])

  useEffect(() => {
    if (fullCalendarRef.current && selectedDateString) {
      const date = new Date(selectedDateString)
      if (fullCalendarRef.current.getApi().view.activeStart.getTime() !== date.getTime()) {
        fullCalendarRef.current.getApi().gotoDate(date)
      }
    }
  }, [selectedDateString, fullCalendarRef])

  useEffect(() => {
    const fetchTeamMembers = async (): Promise<void> => {
      setTeamMembersLoading(true)
      try {
        const result = await client.query({
          query: QUERY_GET_TEAM_MEMBER,
          variables: {
            startDate: currentDates?.startStr,
            endDate: currentDates?.endStr,
          },
        })

        setTeamMembers(result.data.teamMembers.data)
        setTeamMembersHashTable(
          result.data.teamMembers.data.reduce((acc: any, item: any) => {
            return {
              ...acc,
              [item.id]: item,
            }
          }, {})
        )
        // setSelectedResources(
        //   result.data.teamMembers.data.map((item: any) => ({
        //     ...item,
        //     title: `${item.firstName} ${item.lastName}${printTeamMemberVehicles(item)}`,
        //   }))
        // )
      } catch (e) {
        console.error(e)
        notify('Error fetching Team Members', 'warning')
      } finally {
        setTeamMembersLoading(false)
      }
    }

    fetchTeamMembers()
  }, [currentDates?.startStr, currentDates?.endStr])

  useEffect(() => {
    const fetchVehicles = async (): Promise<void> => {
      setVehiclesLoading(true)
      try {
        const result = await client.query({
          query: QUERY_GET_VEHICLES,
        })

        setVehicles(result.data.vehicles.data)
        setVehiclesHashTable(
          result.data.vehicles.data.reduce((acc: any, item: any) => {
            return {
              ...acc,
              [item.id]: item,
            }
          }, {})
        )
        // setSelectedResources(
        //   result.data.teamMembers.data.map((item: any) => ({
        //     ...item,
        //     title: `${item.firstName} ${item.lastName}${printTeamMemberVehicles(item)}`,
        //   }))
        // )
      } catch (e) {
        console.error(e)
        notify('Error fetching Vehicles', 'warning')
      } finally {
        setVehiclesLoading(false)
      }
    }

    fetchVehicles()
  }, [])

  useEffect(() => {
    const fetchOffices = async (): Promise<void> => {
      setOfficesLoading(true)
      try {
        const result = await client.query({
          query: QUERY_GET_OFFICES,
        })

        setOffices(result.data.offices.data)
        setOfficesHashTable(
          result.data.offices.data.reduce((acc: any, item: any) => {
            return {
              ...acc,
              [item.id]: item,
            }
          }, {})
        )
        // setSelectedResources(
        //   result.data.teamMembers.data.map((item: any) => ({
        //     ...item,
        //     title: `${item.firstName} ${item.lastName}${printTeamMemberVehicles(item)}`,
        //   }))
        // )
      } catch (e) {
        console.error(e)
        notify('Error fetching Offices', 'warning')
      } finally {
        setOfficesLoading(false)
      }
    }

    fetchOffices()
  }, [])

  useEffect(() => {
    if (viewVersion > 0 && !!fullCalendarRef.current) {
      console.log('refething events')
      refetchEvents()
      // fullCalendarRef.current.getApi().refetchEvents()
    }
  }, [viewVersion])

  useEffect(() => {
    return (): void => {
      draggableCustomersRef.current!.destroy()
    }
  }, [])

  useEffect(() => {
    const queryString = localStorage.getItem('queryString')
    if (queryString !== null && queryString !== '' && !search) {
      redirect(`/PlannedEvent${queryString}`)
    }
  }, [])

  useEffect(() => {
    return (): void => {
      if (search !== '') {
        localStorage.setItem('queryString', search)
      }
    }
  }, [search])

  // useEffect(() => {
  //   if (currentDates && currentDates.start && currentDates.end) {
  //     console.log('cambio e faccio prefetch :D :D ', currentDates)
  //     const previousMonth = moment(currentDates.start).subtract(1, 'months').startOf('month')
  //     const nextMonth = moment(currentDates.start).add(1, 'months').endOf('month')
  //     client.query({
  //       query: QUERY_GET_PLANNED_EVENTS,
  //       variables: {
  //         filters: {
  //           dateRange: {
  //             startDate: previousMonth.format('YYYY-MM-DD'),
  //             endDate: nextMonth.format('YYYY-MM-DD'),
  //           },
  //           teamMemberIds: selectedResourceMode === 'teamMember' ? selectedResourceIds : undefined,
  //           vehicleIds: selectedResourceMode === 'vehicle' ? selectedResourceIds : undefined,
  //         },
  //       },
  //     })
  //   }
  // }, [currentDates, client, selectedResourceMode])

  useEffect(() => {
    startPolling(2000)
    return (): void => stopPolling()
  }, [])

  if (vehiclesLoading || configurationLoading) {
    return <Loading />
  }

  return (
    <MuiPickersUtilsProvider utils={DateMomentUtils}>
      <CalendarToolbar
        onCalToday={onCalToday}
        onCalPrev={onCalPrev}
        onCalNext={onCalNext}
        onToggleCalendarSidebarOpen={handleToggleFastEvents}
        calendarSidebarOpen={fastEventsOpen}
        dateTitle={calTitle}
        initialCalendarView={initialCalendarView}
        onTwoWeeksClick={onSwitchCalView('resourceTimeGridTwoWeeksDay')}
        onWeekClick={onSwitchCalView('resourceTimeGridWeek')}
        onDayClick={onSwitchCalView('resourceTimeGridDay')}
        calendarRef={fullCalendarRef}
        calendarContainerRef={calendarContainerRef}
        resourceMode={selectedResourceMode}
        onResourceModeChange={handleResourceModeChange}
        selectedResourceIds={selectedResourceIds}
        onResourcesSelected={onResourcesSelected}
        vehicleHashTable={vehiclesHashTable}
        vehicles={vehicles}
        vehiclesLoading={vehiclesLoading}
        teamMembersHashTable={teamMembersHashTable}
        teamMembers={teamMembers}
        teamMembersLoading={teamMembersLoading}
        officeHashTable={officesHashTable}
        offices={offices}
        officesLoading={officesLoading}
      />

      <div className={classes.root}>
        <Box display="flex" flexDirection="row" className={classes.container}>
          <Box pr={2} ml={1} py={1} className={classes.calendarFastEventTemplatesContainer} width={300}>
            <MonthlyPreviewPicker value={selectedDate} onChange={onCalGoToDate} />
            <PlanningCustomersList ref={onCustomersFERef} {...props} />
            <PlanningEventTemplatesList ref={onTemplateEventsFERef} {...props} />
          </Box>
          <Box flex="1" className={classes.calendarSectionContainer}>
            <div className={classes.calendarContainer} ref={calendarContainerRef}>
              {initialCalendarView === 'resourceTimeGridDay' && (
                <div className={classes.oneDayDate}>
                  <Typography align="center" variant="h4">
                    {calTitle}
                  </Typography>
                </div>
              )}
              <FullCalendar
                ref={onFullCalendarRef}
                locale={itLocale}
                allDaySlot={false}
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                headerToolbar={false}
                plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, resourceTimeGridPlugin, adaptivePlugin]}
                initialView={initialCalendarView}
                // events={onEvents}
                // events={data}
                events={events}
                resources={selectedResources}
                selectable={true}
                droppable={true}
                editable={true}
                initialDate={selectedDateString}
                eventResize={onEventResize}
                eventReceive={onFastEventDrop}
                eventDrop={onEventDrop}
                select={onDateSelected}
                eventClick={onEventClick}
                eventContent={eventContent}
                resourceLabelContent={resourceLabelContent}
                dayHeaderContent={dayHeaderContent}
                slotLabelContent={slotLabelContent}
                nowIndicator={true}
                resourceLabelClassNames={classes.fcResource}
                slotLabelClassNames={classes.fcSlotLabel}
                slotLaneClassNames={classes.fcSlotLane}
                dayHeaderClassNames={classes.fcCalendarDayHeader}
                eventClassNames={getEventClassNames}
                height="100%"
                views={{
                  resourceTimeGridTwoWeeksDay: {
                    type: 'resourceTimeGrid',
                    duration: { weeks: 2 },
                    buttonText: '14 day',
                  },
                }}
                datesSet={onCalendarViewChange}
              />
            </div>
          </Box>
        </Box>
      </div>
    </MuiPickersUtilsProvider>
  )
}

export default CalendarPlanning
