import { client } from './apolloClient'
import { QUERY_GET_USER_ME, QUERY_GET_PERMISSION_ROLES } from './queries'
import userManager from './userManager'

const cleanup = (): void => {
  // Remove the ?code&state from the URL
  window.history.replaceState({}, window.document.title, window.location.origin)
}

const getRoleParams = (userMe: any): any => {
  if (userMe && userMe.roles && userMe.roles.length > 0) {
    return userMe.roles[0].params
  }

  return {}
}

export default {
  login: async (): Promise<any> => {
    console.log('facciamo login')
    userManager.signinRedirect()
    return
  },

  logout: async (params: any): Promise<any> => {
    const user = await userManager.getUser()
    console.log('chiamato logout', params, user)
    if (user) {
      userManager.signoutRedirect()
    }
    return Promise.resolve()
  },

  checkError: (error: any): any => {
    console.log('checkError()', error)
    return Promise.resolve()
  },

  checkAuth: async (): Promise<any> => {
    const user = await userManager.getUser()
    console.log(user, 'sono autenticato?')
    if (!user || !user.access_token || user.expired) {
      return Promise.reject()
    }

    return Promise.resolve()
  },

  getIdentity: async (): Promise<any> => {
    try {
      const result = await client.query({
        query: QUERY_GET_USER_ME,
        fetchPolicy: 'no-cache',
      })
      if (result && result.data && result.data.userMe) {
        return Promise.resolve({ ...result.data.userMe, fullName: result.data.lastName })
      } else {
        return Promise.reject(new Error('User not found'))
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  getPermissions: async (): Promise<any> => {
    try {
      const result = await client.query({
        query: QUERY_GET_USER_ME,
        fetchPolicy: 'no-cache',
      })

      const rolesIds =
        result && result.data && result.data.userMe && result.data.userMe.roles
          ? result.data.userMe.roles.map((role: any) => role.id)
          : []

      const data = await client.query({ query: QUERY_GET_PERMISSION_ROLES })

      const formatted = data.data.allPermissionRoles.data.map((permission: any) => ({
        name: permission.permissionName,
        roles: permission.roles ? permission.roles.map((role: any) => role.id) : [],
      }))
      const activePermissions = formatted.filter((permission: any) =>
        rolesIds.some((roleId: string) => permission.roles.includes(roleId))
      )
      const permissionsMap = activePermissions.reduce((acc: any, item: any) => {
        return {
          ...acc,
          [item.name]: true,
        }
      }, {})

      return result && result.data && result.data.userMe
        ? Promise.resolve({
            user: result.data.userMe,
            roles: result.data.userMe.roles || [],
            params: getRoleParams(result.data.userMe),
            permissionsMap,
          })
        : Promise.reject({ params: {}, permissionsMap: {} })
    } catch (e) {
      console.error('getPermissions failed: ', e)
      Promise.reject({ params: {}, permissionsMap: {} })
    }
  },
}
