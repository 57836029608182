import React, { FC } from 'react'
import { ReferenceArrayField, TextField, SimpleList, Pagination, ReferenceField } from 'react-admin'
import { Card, CardContent, Box, Typography, Divider, makeStyles } from '@material-ui/core'
import { BiCar } from 'react-icons/bi'

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    list: {
      minHeight: 400,
    },
    title: {},
    icon: {
      marginRight: theme.spacing(1),
    },
  }
})

type Props = {
  record?: any
  [x: string]: any
}

const TeamMemberVehiclesCard: FC<Props> = ({ record, ...rest }) => {
  const classes = useStyles()
  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <BiCar size="1.2rem" className={classes.icon} />
          <Typography variant="h4" className={classes.title}>
            Veicoli associati
          </Typography>
        </Box>
        <Divider />
        <ReferenceArrayField
          {...rest}
          pagination={<Pagination />}
          record={record}
          reference="Vehicle"
          source="vehiclesIds"
        >
          <SimpleList
            linkType={false}
            className={classes.list}
            primaryText={(record: any): any => (
              <ReferenceField {...rest} link={false} record={record} reference="VehicleModel" source="vehicleModelId">
                <TextField source="name" />
              </ReferenceField>
            )}
            secondaryText={(record: any): any => <TextField source="color" record={record} />}
            tertiaryText={(record: any): any => <TextField source="licensePlate" record={record} />}
          />
        </ReferenceArrayField>
      </CardContent>
    </Card>
  )
}

export default TeamMemberVehiclesCard
