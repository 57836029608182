import React, { FC, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import { AiOutlineUser } from 'react-icons/ai'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { useGetList, useTranslate } from 'ra-core'
import QuickEditCourseScheduleButton from './QuickEditCourseScheduleButton'

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    list: {
      minHeight: 400,
    },
    title: {},
    icon: {
      marginRight: theme.spacing(1),
    },
  }
})

type Props = {
  record?: any
  permissionsMap: Record<string, boolean>
  [x: string]: any
}

const daysMap = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
}

const defaultTimes = {
  MONDAY: [],
  TUESDAY: [],
  WEDNESDAY: [],
  THURSDAY: [],
  FRIDAY: [],
  SATURDAY: [],
  SUNDAY: [],
}

const CoursePlanningScheduleCard: FC<Props> = ({ record, permissionsMap, ...rest }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [initialized, setInitialized] = useState<boolean>(false)
  // const today = useMemo(() => moment(), [])
  const { loading, data, ids } = useGetList(
    'CourseSchedule',
    { page: 1, perPage: 1000 },
    { field: 'dayNumber', order: 'ASC' },
    { coursePlanningId: record.id }
  )
  const [timesMap, setTimesMap] = useState<{ [x: string]: any[] }>(defaultTimes)

  // const emptyValueLabel = useMemo(() => translate('custom.noData'), [translate])

  useEffect(() => {
    if (!loading && data && ids && ids.length > 0 && !initialized) {
      setInitialized(true)
      setTimesMap(
        ids.reduce((acc: any, item: any): any => {
          // console.log('esisto', acc, data[item], data[item].dayNumber, acc[data[item].dayNumber])
          return {
            ...acc,
            [data[item].dayNumber]: [...(acc[data[item].dayNumber] ? acc[data[item].dayNumber] : []), data[item]],
          }
        }, defaultTimes)
      )
    }
  }, [data, ids, loading, initialized])

  // if (error) {
  //   return (
  //     <Card className={classes.root}>
  //       <Typography color="error">{error}</Typography>
  //     </Card>
  //   )
  // }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <AiOutlineUser size="1.2rem" className={classes.icon} />
          {!loading && data && (
            <Typography variant="h4" className={classes.title}>
              Orari Corso
            </Typography>
          )}
        </Box>
        <Divider />
        {/* <pre>{JSON.stringify(timesMap, null, 2)}</pre> */}
        <TableContainer>
          <Table size="small">
            <TableBody>
              {Object.keys(timesMap).map((item, index) => {
                return (
                  <TableRow key={`course-hour-${index}`}>
                    <TableCell>{translate(`dayOfWeek.${item}`)}</TableCell>
                    <TableCell>
                      {timesMap[item].map((range, index) => {
                        return (
                          <Typography key={`course-hour-${item}-${index}`}>
                            {`${moment(range.startTime, 'HH:mm:ss').format('LT')} - ${moment(
                              range.finishTime,
                              'HH:mm:ss'
                            ).format('LT')}`}
                          </Typography>
                        )
                      })}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      {permissionsMap.createCustomerCoursePlanning && (
        <CardActions>
          <QuickEditCourseScheduleButton
            color="primary"
            fullWidth
            variant="text"
            coursePlanningId={record.id}
            record={record}
            // onChange={onDocumentUploaded}
            // disabled={loading}
          />
        </CardActions>
      )}
    </Card>
  )
}

export default CoursePlanningScheduleCard
