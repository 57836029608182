import React, { FC, useCallback } from 'react'
import { ReferenceInput, AutocompleteInput, useTranslate, NullableBooleanInput } from 'react-admin'
import Filter from './../../components/Filter'

const sort = { field: 'lastName', order: 'ASC' }

type Props = {}

const AccountingCardFilters: FC<Props> = (props) => {
  const translate = useTranslate()
  const filterToQuery = useCallback((q: string): Record<string, string> => ({ q }), [])
  const filterToQueryProduct = useCallback((name: string): Record<string, string> => ({ name }), [])

  return (
    <Filter {...props}>
      <ReferenceInput
        alwaysOn
        label={translate('resources.Order.fields.customer')}
        reference="Customer"
        source="customerId"
        {...{ sort, filterToQuery }}
      >
        <AutocompleteInput optionText="fullName" />
      </ReferenceInput>
      <NullableBooleanInput source="isClosed" alwaysOn />
      <ReferenceInput
        alwaysOn
        label={'Prodotto guida di default'}
        reference="Product"
        source="driveTrainingProductId"
        filterToQuery={filterToQueryProduct}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </Filter>
  )
}

export default AccountingCardFilters
