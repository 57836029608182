import React, { FC, useCallback, useEffect, useMemo, Fragment } from 'react'
import { ReferenceInput, SelectInput, AutocompleteInput, useTranslate } from 'react-admin'
import { useForm } from './../../../node_modules/react-final-form'
import { makeStyles } from '@material-ui/core/styles'

const sort = { field: 'name', order: 'ASC' }
const perPage = 300
const useStyles = makeStyles({
  container: {
    display: 'inline-block',
    width: 'auto',
    marginRight: 16,
  },
})
/**
 * Display inputs for Country and State, to show the proper
 * list of states based on countryId; it also handles a state reset when countryId changes
 * @param props.formData current values for every form field
 */
const CountryStateDoubleInput: FC<Record<string, any>> = ({ formData, ...rest }) => {
  const translate = useTranslate()
  const form = useForm()
  const { container } = useStyles()

  const filterToQuery = useCallback(() => (name: string): Record<string, string> => ({ name }), [])

  const filter = useMemo(() => ({ countryId: formData.countryId }), [formData.countryId])

  useEffect(() => form.change('stateId', null), [formData.countryId])

  return (
    <Fragment>
      <ReferenceInput
        label={translate('customFields.countryState.country')}
        reference="Country"
        source="countryId"
        {...{ filterToQuery, sort, perPage }}
        classes={{ container }}
      >
        <AutocompleteInput optionText="name" {...rest} />
      </ReferenceInput>
      {formData.countryId && (
        <ReferenceInput
          label={translate('customFields.countryState.state')}
          reference="State"
          source="stateId"
          {...{ filter, sort, perPage }}
        >
          <SelectInput optionText="name" {...rest} />
        </ReferenceInput>
      )}
    </Fragment>
  )
}

export default CountryStateDoubleInput
