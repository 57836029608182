import React, { FC } from 'react'
import { IconContext } from 'react-icons'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'

type Props = {
  source: string
  record?: Record<string, boolean | null>
  size?: string
  label?: string
}

const ColoredNullableBooleanField: FC<Props> = ({ source, record = {}, size = '1.4em' }) => {
  const value = {
    color: record[source] ? 'green' : 'red',
    style: { verticalAlign: 'middle' },
    size,
  }

  return (
    <span>
      <IconContext.Provider {...{ value }}>
        {record[source] ? <FaCheckCircle /> : <FaTimesCircle />}
      </IconContext.Provider>
    </span>
  )
}

ColoredNullableBooleanField.defaultProps = {}

export default ColoredNullableBooleanField
