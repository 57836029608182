import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/Create'
import TeamMemberForm from './TeamMemberForm'

type Props = {}

const TeamMemberCreate: FC<Props> = (props) => (
  <Create {...props} component="div">
    <SectionedForm variant="outlined" redirect="show">
      <TeamMemberForm {...props} />
    </SectionedForm>
  </Create>
)

export default TeamMemberCreate
