import React, { FC, useState, useEffect, useMemo } from 'react'
import { useInput, useDataProvider } from 'react-admin'
import keyBy from 'lodash/keyBy'
import without from 'lodash/without'
import { InputLabel, Typography, makeStyles } from '@material-ui/core'
import ProductCategoryTree from './ProductCategoryTree'
import { InputProps } from 'ra-core'

const useProductCategoryStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  }
}))

const resolveNodePath = (tree: any[], match: string | { id: string }[]): any[] => {
  const fragments = []
  console.log(match, 'defaultExpanded')
  if (typeof match === 'string') {
    const node = tree.find(node => node.id === match)

    if (node) {
      fragments.push(match)

      if (node.parentId) {
        fragments.push(...resolveNodePath(tree, node.parentId))
      }
    }
  } else {
    const nodes = tree.filter(node => !!match.find(selected => node.id === selected.id))
    console.log(nodes, 'ciaoooo')
    if (nodes.length > 0) {
      fragments.push(...nodes.map(node => node.id))
      nodes.forEach(node => {
        if (node.parentId) {
          fragments.push(...resolveNodePath(tree, node.parentId))
        }
      })
    }
  }

  return fragments
}

const buildCategoryTree = (entries: any[], lookup: any, exclude?: string): any[] =>
  entries
    .filter(entry => entry.id !== exclude)
    .map((entry: any): any => {
      if (!entry.subCategoriesIds || !entry.subCategoriesIds.length) {
        return entry
      }
      const subCategories = entry.subCategoriesIds.map((subEntryId: string): any => lookup[subEntryId])

      return { ...entry, subCategories: buildCategoryTree(subCategories, lookup, exclude) }
    })

type Props = InputProps & {
  exclude?: string | undefined
  multiSelect?: true | undefined
  label?: string
}

const ProductCategoryInput: FC<Props> = props => {
  const {
    input: { name, onChange, value },
    meta: { touched },
    isRequired
  } = useInput(props)

  const classes = useProductCategoryStyles()
  const [nodes, setNodes] = useState([])
  const [ready, setReady] = useState(false)
  const dataProvider = useDataProvider()

  // component did mount
  // load the list of main categories
  useEffect(() => {
    dataProvider
      .getList('ProductCategory', {
        pagination: {
          page: 1,
          perPage: 100
        },
        sort: { field: 'name', order: 'ASC' },
        filter: {}
      })
      .then((response: any): void => {
        if (response) {
          if (response.data && response.data.length > 0) {
            setNodes(nodes.concat(response.data))
          } else {
            setReady(true)
          }
        }
      })
  }, [])

  // recursively load all subcategories
  useEffect(() => {
    const categoriesIds = nodes.map((node: any): string => node.id)

    // find a category that has subcategories which where not loadead before
    const category: any = nodes.find(
      (category: any): any =>
        category.subCategories && category.subCategories.length && !categoriesIds.includes(category.subCategories[0].id)
    )

    if (category) {
      // get subcategories and update the state
      dataProvider
        .getList('ProductCategory', {
          pagination: {
            page: 1,
            perPage: 100
          },
          sort: { field: 'name', order: 'ASC' },
          filter: { parentId: category.id }
        })
        .then((response: any): void => {
          if (response.data) {
            setNodes(nodes.concat(response.data))
          }
        })
    } else if (nodes.length > 0) {
      setReady(true)
    }
  }, [nodes])

  const memoizedTree = useMemo(
    () =>
      buildCategoryTree(
        nodes.filter((node: any): boolean => !node.parentId),
        keyBy(nodes, 'id'),
        props.exclude
      ),
    [ready]
  )

  const memoizedExpanded = useMemo(() => resolveNodePath(nodes, value), [nodes])

  const handleNodeSelect = (event: any, input: string): void => {
    if (props.multiSelect) {
      const origin = value && value.length ? value : []
      onChange(
        origin.find((item: any) => item.id === input)
          ? origin.filter((item: any) => item.id !== input)
          : [...origin, { id: input }]
      )
      console.log(
        input,
        origin.find((item: any) => item.id === input)
          ? origin.filter((item: any) => item.id !== input)
          : [...origin, { id: input }],
        'onChange'
      )
    } else {
      onChange(input === value ? null : input)
    }
  }

  const treeValue = useMemo(
    () => (props.multiSelect && value && value.length ? value.map((item: any) => item.id) : value),
    [value, props.multiSelect]
  )

  return (
    <div className={classes.root}>
      <InputLabel>{props.label}</InputLabel>
      {!ready ? (
        <Typography>Loading...</Typography>
      ) : memoizedTree && memoizedTree.length > 0 ? (
        <ProductCategoryTree
          selected={treeValue}
          defaultExpanded={memoizedExpanded}
          multiSelect={props.multiSelect}
          data={memoizedTree}
          onNodeSelect={handleNodeSelect}
        />
      ) : (
        <Typography>Category not present in database</Typography>
      )}
    </div>
  )
}

export default React.memo(ProductCategoryInput)
