import React, { FC } from 'react'
import { Show } from 'react-admin'
// import Show from '../../components/details/Show'
import RoleDetails from './RoleDetails'

type Props = {}

const RoleShow: FC<Props> = (props) => {
  return (
    <Show component="div" {...props}>
      <RoleDetails />
    </Show>
  )
}

export default RoleShow
