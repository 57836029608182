import gql from 'graphql-tag'

export const MUTATION_SEND_EVENT_INVITE = gql`
  mutation SendEventInvite($data: EventNotificationDataInput!) {
    sendEventInvite(data: $data) {
      success
    }
  }
`

export const QUERY_GET_EVENT_STATUSES_CONFIGURATION = gql`
  query GetEventStatusesConfiguration {
    getConfiguration(key: "CALENDAR_PLANNING_STATUSES") {
      id
      key
      value
    }
  }
`

export const QUERY_GET_PLANNED_EVENT = gql`
  query GetPlannedEventStatus($id: ID!) {
    plannedEvent(id: $id) {
      id
      recurringEventId
      event {
        id
        startDate
        endDate
        additionalData
      }
      customerEvents {
        id
        customer {
          id
          fullName
          firstName
          lastName
          profilePicture {
            id
            urlLarge
            urlMedium
            urlSmall
          }
        }
        status {
          id
          name
          color
        }
      }
    }
  }
`

export const QUERY_GET_USER_ME = gql`
  query GetUserMe {
    userMe {
      id
      firstName
      lastName
      roles {
        id
        name
        params
      }
    }
  }
`

export const QUERY_GET_PERMISSION_ROLES = gql`
  query GetRoleDetails {
    allPermissionRoles: permissionRoles(pagination: { disabled: true }, sort: { permissionName: "ASC" }) {
      total
      data {
        id
        permissionName
        categories {
          id
          name
        }
        roles {
          id
          name
        }
      }
    }

    permissionCategories(pagination: { disabled: true }, sort: { name: "ASC" }) {
      data {
        id
        name
      }
    }
  }
`

export const GET_CUSTOMER_EVENT = gql`
  query GetCustomerEvent($id: ID) {
    plannedEvent(id: $id) {
      id
      eventId
      customerIds
      teamMemberIds
      vehicleId
      dsEventType
      event {
        id
        startDate
        endDate
        title
      }
      customerEvents {
        id
        statusId
        customerId
      }
      teamMemberEvents {
        teamMember {
          fullName
        }
      }
    }
  }
`
