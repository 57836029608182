import React, { FC } from 'react'
import List from '../../components/List'
import { Datagrid, TextField, ReferenceField, ImageField, BooleanField, NumberField, FunctionField } from 'react-admin'
import ProductFilters from './ProductFilters'
import { makeStyles } from '@material-ui/core/styles'

const useImageFieldStyles = makeStyles({
  image: {
    height: 80,
    margin: 0,
  },
})

type Props = {
  [x: string]: any
}

const ProductList: FC<Props> = (props) => {
  const imageFieldClasses = useImageFieldStyles()
  // const { data, loaded } = useQuery({ resource: 'Configuration', type: 'getOne', payload: { id: '1' } })
  // const defaultCurrency = useMemo(() => (loaded && data && data.defaultCurrency ? data.defaultCurrency : 'EUR'), [
  //   data,
  //   loaded
  // ])
  const defaultCurrency = 'EUR'
  // const defaultLocale = useMemo(
  //   () => (loaded && data && data.defaultCountry ? data.defaultCountry.defaultCountry : 'en-US'),
  //   [data, loaded]
  // )
  const defaultLocale = 'it-IT'

  // if (!loaded) {
  //   return <Loading />
  // }

  // return <pre>{JSON.stringify(data, null, 2)}</pre>

  return (
    <List {...props} sort={{ field: 'name', order: 'ASC' }} filters={<ProductFilters />}>
      <Datagrid
        rowClick={
          props.permissions && props.permissions.permissionsMap && props.permissions.permissionsMap.updateProduct
            ? 'edit'
            : undefined
        }
      >
        <TextField source="id" />
        <FunctionField
          label="Immagine"
          render={(record: any): React.ReactNode => {
            if (!record.catalogPictureId) {
              return <div className={imageFieldClasses.image} />
            } else {
              return (
                <ReferenceField
                  basePath="/Product"
                  resource="Product"
                  record={record}
                  source="catalogPictureId"
                  reference="Picture"
                  sortable={false}
                  link={false}
                >
                  <ImageField classes={imageFieldClasses} source="urlSmall" />
                </ReferenceField>
              )
            }
          }}
        />

        {/* <ReferenceField label="Picture" source="catalogPictureId" reference="Picture" sortable={false}>
          <ImageField source="urlSmall" height={50} />
        </ReferenceField> */}
        <TextField source="sku" />
        <TextField source="name" />
        <NumberField
          source="retailPrice"
          locales={defaultLocale}
          options={{ style: 'currency', currency: defaultCurrency }}
          sortable={false}
        />
        <ReferenceField source="mainCategoryId" reference="ProductCategory">
          <TextField source="name" />
        </ReferenceField>
        {/* <ReferenceField label="Brand" source="brandId" reference="Brand" sortable={false}>
          <TextField source="name" />
        </ReferenceField> */}
        <BooleanField source="active" sortable={false} />
        {/* <BooleanField source="featured" /> */}
      </Datagrid>
    </List>
  )
}

export default ProductList
