import React, { FC } from 'react'
import SectionedForm from '../../../components/forms/SectionedForm'
import Create from '../../../components/Create'
import VehicleModelForm from './VehicleModelForm'

type Props = {}

const VehicleModelCreate: FC<Props> = props => {
  return (
    <Create {...props} component="div">
      <SectionedForm variant="outlined" redirect="list">
        <VehicleModelForm />
      </SectionedForm>
    </Create>
  )
}

export default VehicleModelCreate
