import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import itLocale from 'date-fns/locale/it'
import GenericMoreMenu from '../../components/GenericMoreMenu'
import { ReferenceField, TextField } from 'react-admin'

const getTimezonedDate = (x: string): Date => {
  const y = new Date(x)
  const z = new Date(y.getTime() + Math.abs(y.getTimezoneOffset() * 60000))
  return z
}

const AccountingCardNote: FC<any> = ({ record }) => {
  const classes = useStyles()
  return (
    <Card className={classes.container}>
      <div className={classes.cardHeader}>
        <Tooltip placement="top" title={getTimezonedDate(record.createdAt).toLocaleString()}>
          <Typography variant="caption" color="primary">
            {formatDistanceToNow(getTimezonedDate(record.createdAt), { addSuffix: true, locale: itLocale })}
          </Typography>
        </Tooltip>
        <GenericMoreMenu record={record} resource="AccountingCardNote" hideEdit />
      </div>
      <Typography variant="body2">{record.body}</Typography>
      <div className={classes.cardFooter}>
        <Typography variant="caption">aggiunta da: &nbsp;</Typography>
        <ReferenceField basePath="/User" resource="User" record={record} reference="User" source="userId" link={false}>
          <TextField source="fullName" variant="caption" />
        </ReferenceField>
      </div>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardFooter: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}))

export default AccountingCardNote
