import React, { FC, useEffect } from 'react'
import Loading from '../components/Loading'
import userManager from '../userManager'
import { connect } from 'react-redux'
import { userLogin as userLoginAction } from 'react-admin'
import { useRedirect } from 'ra-core'

type Props = {
  userLogin: (data?: any) => void
}

const LoginPage: FC<Props> = ({ userLogin }) => {
  const redirect = useRedirect()

  useEffect(() => {
    const manageLogin = async (): Promise<void> => {
      setTimeout(async () => {
        const user = await userManager.getUser()
        console.log(user, userManager, 'ciao')
        // userLogin()
        if (!user || user.expired || !user.access_token) {
          userLogin()
        } else {
          redirect('/')
        }
      }, 1000)
    }

    manageLogin()
  }, [userLogin])

  return (
    <div>
      <Loading />
      {/* <button type="button" onClick={userLogin}> */}
      {/* Login */}
      {/* </button> */}
    </div>
  )
}

const mapDispatchToProps = {
  userLogin: userLoginAction,
}

export default connect(undefined, mapDispatchToProps)(LoginPage)
