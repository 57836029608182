import React, { FC } from 'react'
import RAList from './../../components/List'
import { TextField, NumberField, Datagrid, BooleanField } from 'react-admin'
import EditButtonWPermission from '../../components/EditButtonWPermission'
import CurrencyFilters from './CurrencyFilters'

const sort = { field: 'name', order: 'ASC' }
const filterDefaultValues = { active: true }

type Props = {}

const CurrencyList: FC<Props> = (props) => (
  <RAList {...{ sort, filterDefaultValues }} {...props} filters={<CurrencyFilters />}>
    <Datagrid>
      <NumberField source="id" />
      <TextField source="name" />
      <TextField source="isoCode" />
      <NumberField source="isoCodeNumber" />
      <TextField source="sign" sortable={false} />
      <TextField source="format" sortable={false} />
      <NumberField source="conversionRate" sortable={false} />
      <BooleanField source="showDecimals" sortable={false} />
      <BooleanField source="showSpace" sortable={false} />
      <BooleanField source="active" sortable={false} />
      <EditButtonWPermission {...props} />
    </Datagrid>
  </RAList>
)

export default CurrencyList
