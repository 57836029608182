import React, { FC, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import PaymentIcon from './PaymentIcon'
import { DateField, Confirm } from 'react-admin'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { numToEUR } from '../../utils/prices'
import DeleteIcon from '@material-ui/icons/Delete'
import UserField from '../../components/CustomFields/UserField'
import { useCallback } from 'react'
import { useDeleteWithConfirmController, useTranslate } from 'ra-core'
import inflection from 'inflection'

type Props = {
  transaction: any
}

export const OrderTransactionItem: FC<Props> = ({ transaction }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const translate = useTranslate()

  const {
    open,
    loading,
    handleDialogOpen: handleDeleteDialogOpen,
    handleDialogClose: handleDeleteModalClose,
    handleDelete,
  } = useDeleteWithConfirmController({
    resource: 'OrderTransaction',
    record: transaction,
    redirect: false,
    basePath: '/Order',
    onClick: undefined,
  })

  const onCloseMenu = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const onOpenMenu = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const onItemDeleteClick = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    handleDeleteDialogOpen(event)
  }, [])

  return (
    <Accordion key={transaction.id}>
      <AccordionSummary
        expandIcon={undefined}
        aria-controls={`panel${transaction.id}-content`}
        id={`panel${transaction.id}-header`}
        classes={{ content: classes.accordionSummary }}
      >
        <DateField variant="body1" record={transaction} source="transactionDate" />
        <Typography variant="body1" className={classes.amount}>
          <PaymentIcon paymentMethod={transaction.paymentMethod} />
          {numToEUR(transaction.amount)}
        </Typography>
        <IconButton onClick={onOpenMenu} size="small" aria-label="Opzioni transazione">
          <MoreVertIcon />
        </IconButton>
        <Menu
          id={`more-menu-transaction-${transaction.id}`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={onCloseMenu}
        >
          <MenuItem onClick={onItemDeleteClick}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            Elimina
          </MenuItem>
        </Menu>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetail }}>
        {transaction.note && (
          <div>
            <Typography variant="subtitle2">{`Note: ${transaction.note}`}</Typography>
          </div>
        )}
        <div className={classes.writtenBy}>
          <Typography variant="caption">
            inserita da: {transaction.accountId ? <UserField record={transaction} /> : 'N.D.'}
          </Typography>
        </div>
      </AccordionDetails>

      <Confirm
        isOpen={open}
        loading={loading}
        title="ra.message.delete_title"
        content="ra.message.delete_content"
        // ConfirmIcon={({ props, context }) => {
        //   return ''
        // }}
        // CancelIcon={({ props, context }) => {
        //   return ''
        // }}
        translateOptions={{
          name: translate('resources.OrderTransaction.forcedCaseName', {
            smart_count: 1,
            _: inflection.humanize(
              translate('resources.OrderTransaction.name', {
                smart_count: 1,
                _: inflection.singularize('OrderTransaction'),
              }),
              true
            ),
          }),
          id: transaction.id,
        }}
        onConfirm={handleDelete}
        onClose={handleDeleteModalClose}
      />
    </Accordion>
  )
}

const useStyles = makeStyles((theme) => ({
  accordionSummary: {
    justifyContent: 'space-between',
  },
  accordionDetail: {
    display: 'flex',
    flexDirection: 'column',
  },
  writtenBy: {
    display: 'block',
    textAlign: 'right',
    marginTop: 10,
  },
  notes: {
    display: 'block',
    marginBottom: 8,
  },
  amount: {
    display: 'flex',
  },
}))
