import React, { FC, useCallback } from 'react'
import { ReferenceInput, AutocompleteInput, TextInput, useTranslate } from 'react-admin'
import Filter from './../../components/Filter'

const sort = { field: 'lastName', order: 'ASC' }

type Props = {}

const OrderFilters: FC<Props> = (props) => {
  const translate = useTranslate()
  const filterToQuery = useCallback((q: string): Record<string, string> => ({ q }), [])

  return (
    <Filter {...props}>
      <TextInput source="reference" alwaysOn />
      <ReferenceInput
        alwaysOn
        label={translate('resources.Order.fields.customer')}
        reference="Customer"
        source="customerId"
        {...{ sort, filterToQuery }}
      >
        <AutocompleteInput optionText="fullName" />
      </ReferenceInput>
    </Filter>
  )
}

export default OrderFilters
