import React, { FC, useEffect, useMemo, useRef } from 'react'
import { QUERY_GET_EVENT_STATUSES_CONFIGURATION } from './../queries'
import { SelectInput, useQueryWithStore } from 'react-admin'
import { useQuery } from '@apollo/client'
import { useForm, useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core'

type Props = {
  plannedEvent?: any
  record?: any
}

const useStyles = makeStyles({
  selectStatus: {
    marginTop: 20,
  },
})

const PlannedEventSelectCustomerStatus: FC<Props> = (props) => {
  const classes = useStyles()
  const form = useForm()
  const formState = useFormState()
  const initialized = useRef<boolean>(false)
  // const [itaStatusesMap, setItaStatusesMap] = useState<Record<string, any>>({})
  const { data: eventStatuses } = useQueryWithStore({
    type: 'getList',
    resource: 'EventStatus',
    payload: {
      filter: {},
      pagination: { perPage: 100, page: 1 },
      sort: { field: 'id', order: 'ASC' },
    },
  })

  const eventStatusesTable = useMemo(() => {
    if (eventStatuses && eventStatuses.length > 0) {
      return eventStatuses.reduce((acc: any, item: any) => {
        return {
          ...acc,
          [item.id]: item,
        }
      }, {})
    }
    return {}
  }, [eventStatuses])

  const { loading: configurationLoading, data: eventStatusConfiguration } = useQuery(
    QUERY_GET_EVENT_STATUSES_CONFIGURATION
  )

  useEffect(() => {
    if (
      props.plannedEvent &&
      props.plannedEvent.plannedEvent &&
      props.plannedEvent.plannedEvent.customerEvents &&
      props.plannedEvent.plannedEvent.customerEvents.length > 0 &&
      !initialized.current &&
      formState.values
    ) {
      setTimeout(() => {
        form.change('customerEventStatus', props.plannedEvent.plannedEvent.customerEvents[0].status.id)
      }, 100)
      initialized.current = true
    }
  }, [props.plannedEvent, formState.values, initialized.current])

  const eventStatusChoices = useMemo(() => {
    if (eventStatusConfiguration && Object.keys(eventStatusesTable).length > 0) {
      const partial = Object.entries(eventStatusConfiguration.getConfiguration.value).reduce<any[]>((acc, item) => {
        return [
          ...acc,
          ...(item[1] as string[]).map((item) => {
            return {
              id: item,
              name: eventStatusesTable[item].name,
            }
          }),
        ]
      }, [])
      return partial.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)
    } else return []
  }, [eventStatusConfiguration, eventStatusesTable])

  if (
    Object.keys(eventStatusesTable).length > 0 &&
    eventStatusConfiguration &&
    props.plannedEvent &&
    props.plannedEvent.plannedEvent &&
    props.plannedEvent.plannedEvent.customerEvents &&
    props.plannedEvent.plannedEvent.customerEvents.length > 0
  ) {
    return (
      <SelectInput
        {...props}
        choices={eventStatusChoices}
        source="customerEventStatus"
        // label="Stato"
        fullWidth
        resource="PlannedEvent"
        className={classes.selectStatus}
      />
    )
  }

  return null
}

export default PlannedEventSelectCustomerStatus
