import { useQuery } from '@apollo/client'
import React, { FC, forwardRef, useCallback, useEffect, useState } from 'react'
import { useDataProvider, useNotify, useTranslate } from 'ra-core'
import CircularProgress from '@material-ui/core/CircularProgress'
import List from '@material-ui/core/List'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useDebounce } from '../../utils/useDebounce'
import { Accordion, AccordionSummary } from './PlanningAccordions'
import Avatar from '@material-ui/core/Avatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'

type Props = {
  [prop: string]: any
  className?: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      // backgroundColor: theme.palette.background.paper,
    },
    attributeHeading: {
      // fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
    },
    searchField: {
      marginTop: theme.spacing(1),
      backgroundColor: theme.palette.grey[100],
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.grey[100],
        },
        '&:hover fieldset': {
          borderColor: theme.palette.grey[400],
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.grey[400],
        },
      },
    },
    iconRoot: {
      minWidth: 24,
    },
    avatar: {
      width: 12,
      height: 12,
    },
  })
)

const PlanningEventTemplatesList: FC<Props> = forwardRef<any, Props>(({ className, ...rest }, ref) => {
  const classes = useStyles()
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const [initialized, setInitialized] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const notify = useNotify()
  const [data, setData] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)
  const [filter, setFilter] = useState<string>('')
  const debouncedFilter = useDebounce(filter, 150)
  const [pagination, setPagination] = useState<{ perPage: number; page: number }>({ perPage: 20, page: 1 })
  const [sort, setSort] = useState<{ field: string; order: 'ASC' | 'DESC' }>({ field: 'title', order: 'ASC' })

  const onFilterChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value)
  }, [])

  const fetchEventTemplates = useCallback(async (): Promise<void> => {
    try {
      setLoading(true)
      const result = await dataProvider.getList('EventTemplate', {
        filter: {
          title: debouncedFilter ? debouncedFilter : undefined,
        },
        pagination,
        sort,
      })
      setData(result.data)
      setTotal(result.total)
    } catch (e) {
      //
      notify('ra.error')
      console.error('error on fetching event templates', e)
    } finally {
      setLoading(false)
      setInitialized(true)
    }
  }, [debouncedFilter, pagination, sort, dataProvider, notify])

  useEffect(() => {
    fetchEventTemplates()
  }, [])

  useEffect(
    () => {
      fetchEventTemplates()
    },
    [debouncedFilter] // Only call effect if debounced search term changes
  )

  if (!initialized) {
    return <CircularProgress />
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary focusRipple={true} disableRipple={false} expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1" className={classes.attributeHeading}>
          {translate('resources.EventTemplate.name', { smart_count: 2 })}
        </Typography>
      </AccordionSummary>
      <Box display="flex" flexDirection="column">
        <TextField
          className={classes.searchField}
          variant="outlined"
          size="small"
          value={filter}
          onChange={onFilterChange}
          placeholder={translate('PlanningEventTemplatesList.searchEventTemplates')}
        />
      </Box>
      <List ref={ref}>
        {data.map((item, index) => {
          return (
            <ListItem
              key={`calendar-list-customer-${item.id}`}
              id={`calendar-list-customer-${item.id}`}
              button
              className="draggable-event"
              data-event-template={JSON.stringify({
                id: item.id,
                title: item.title,
                description: item.description,
                duration: item.duration,
                color: item.color ? item.color : undefined,
              })}
            >
              <ListItemIcon className={classes.iconRoot}>
                <Avatar className={classes.avatar} style={{ backgroundColor: item.color || 'transparent' }}>
                  {' '}
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{
                  variant: 'body2',
                }}
              />
            </ListItem>
          )
        })}
      </List>
    </Accordion>
  )
})

export default PlanningEventTemplatesList
