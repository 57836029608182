import React, { FC, useMemo } from 'react'
import { differenceInMinutes } from 'date-fns'

type Props = {
  record?: Record<string, boolean | null>
  startSource?: string
  endSource?: string
  label?: string
}

const defaultOptions = {
  separator: ' ',
  minute: 'm',
  hour: 'h',
  day: 'd',
}

export const formatMinutesDuration = (duration: number, options: Record<string, string> = defaultOptions): string => {
  if (!duration) {
    return ''
  } else if (duration < 60) {
    return `${duration.toString()}${options.minute}`
  } else {
    const hours = Math.trunc(duration / 60)
    const minutes = duration % 60

    return `${hours}${options.hour}${options.separator}${minutes}${options.minute}`
    // if (hours < 24) {
    //   return `${hours}${options.hour}${options.separator}${minutes}${options.minute}`
    // } else {
    //   const days = Math.trunc(hours / 24)
    //   hours = hours % 24
    //   return `${days}${options.day}${options.separator}${hours}${options.hour}${options.separator}${minutes}${options.minute}`
    // }
  }
}

const EventDurationField: FC<Props> = ({ startSource = 'startDate', endSource = 'endDate', record = {} }) => {
  const minutes = useMemo(
    (): number => differenceInMinutes(new Date(`${record[endSource]}`), new Date(`${record[startSource]}`)),
    [record[startSource], record[endSource]]
  )

  if (!record[startSource] || !record[endSource]) return null
  else {
    return <span>{formatMinutesDuration(minutes)}</span>
  }
}

export default EventDurationField
