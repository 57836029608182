import React, { FC, useCallback } from 'react'
import RAList from './../../components/List'
import {
  TextField,
  NumberField,
  EditButton,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  FunctionField,
} from 'react-admin'
import CustomerAvatarField from '../../components/CustomerAvatarField'
import Datagrid from '../../components/Datagrid'
import CustomerFilters from './CustomerFilters'
import UserPreviewField from '../../components/UserPreviewField'
import { useTranslate } from 'ra-core'

type Props = {}

const CustomerList: FC<Props> = (props) => {
  const translate = useTranslate()
  const renderUserField = useCallback((record: any) => {
    return <UserPreviewField record={record} />
  }, [])

  return (
    <RAList {...props} filters={<CustomerFilters />} sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid rowClick="show">
        <NumberField source="id" />
        <FunctionField render={renderUserField} />
        <ReferenceManyField
          filter={{ contactType: 'email' }}
          // source="email"
          label={translate('resources.Customer.fields.email')}
          reference="CustomerContact"
          target="customerId"
        >
          <SingleFieldList linkType={false}>
            <TextField source="contact" />
          </SingleFieldList>
        </ReferenceManyField>
        <ReferenceManyField
          filter={{ contactType: 'sms' }}
          label={translate('resources.Customer.fields.phone')}
          reference="CustomerContact"
          target="customerId"
        >
          <SingleFieldList linkType={false}>
            <TextField source="contact" />
          </SingleFieldList>
        </ReferenceManyField>
        <TextField source="fiscalCode" />
        <ReferenceField reference="Office" source="subscriptionOfficeId" sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference="TeamMember" source="mainReferenceTeamMemberId" sortable={false}>
          <TextField source="lastName" />
        </ReferenceField>
        {/* <EditButton /> */}
      </Datagrid>
    </RAList>
  )
}

export default CustomerList
