import React, { FC, useCallback } from 'react'
import { ReferenceManyField, TextField, ReferenceField, SimpleList, Pagination } from 'react-admin'
import inflection from 'inflection'
import { useDeleteWithConfirmController, useTranslate, useRefresh } from 'ra-core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import { AiOutlineCar } from 'react-icons/ai'
import { Confirm } from 'ra-ui-materialui'
import QuickAddVehicleToExamPlanning from './QuickAddVehicleToExamPlanning'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    list: {
      minHeight: 400,
    },
    title: {},
    icon: {
      marginRight: theme.spacing(1),
    },
    iconMore: {
      paddingLeft: theme.spacing(1),
      // paddingRight: theme.spacing(1),
      width: 29,
      height: 33,
      borderRadius: theme.shape.borderRadius,
    },
  }
})

type Props = {
  record?: any
  [x: string]: any
}

const DeleteVehicleButton: FC<Props> = ({ record = {}, basePath = '', resource = '' }) => {
  const classes = useStyles()
  const translate = useTranslate()

  const {
    open,
    loading,
    handleDialogOpen: handleDeleteDialogOpen,
    handleDialogClose: handleDeleteModalClose,
    handleDelete,
  } = useDeleteWithConfirmController({
    resource,
    record,
    redirect: false,
    basePath,
    onClick: undefined,
  })

  const onItemDeleteClick = useCallback(
    (event: React.MouseEvent<any, MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()
      handleDeleteDialogOpen(event)
    },
    [record, basePath]
  )

  return (
    <>
      <IconButton className={classes.iconMore} edge="start" onClick={onItemDeleteClick}>
        <DeleteIcon />
      </IconButton>
      <Confirm
        isOpen={open}
        loading={loading}
        title="ra.message.delete_title"
        content="ra.message.delete_content"
        translateOptions={{
          name: translate(`resources.${resource}.forcedCaseName`, {
            smart_count: 1,
            _: inflection.humanize(
              translate(`resources.${resource}.name`, {
                smart_count: 1,
                _: inflection.singularize(`${resource}`),
              }),
              true
            ),
          }),
          id: record.id,
        }}
        onConfirm={handleDelete}
        onClose={handleDeleteModalClose}
      />
    </>
  )
}

const ExamPlanningVehiclesCard: FC<Props> = ({ record, permissionsMap, ...rest }) => {
  const refresh = useRefresh()
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <AiOutlineCar size="1.2rem" className={classes.icon} />
          <Typography variant="h4" className={classes.title}>
            Veicoli
          </Typography>
        </Box>
        <Divider />
        <ReferenceManyField
          {...rest}
          pagination={<Pagination />}
          record={record}
          reference="ExamPlanningVehicle"
          target="examPlanningId"
          link={false}
        >
          <SimpleList
            linkType={false}
            className={classes.list}
            primaryText={(record: any): React.ReactElement => (
              <ReferenceField {...rest} link={false} record={record} reference="Vehicle" source="vehicleId">
                <TextField source="licensePlate" />
              </ReferenceField>
            )}
            secondaryText={(record: any): React.ReactElement => (
              <ReferenceField {...rest} link={false} record={record} reference="Vehicle" source="vehicleId">
                <ReferenceField link={false} reference="VehicleModel" source="vehicleModelId">
                  <TextField source="name" />
                </ReferenceField>
              </ReferenceField>
            )}
            rightIcon={(record: any): React.ReactElement => (
              <DeleteVehicleButton basePath={rest.basePath} record={record} resource="ExamPlanningVehicle" />
            )}
          />
        </ReferenceManyField>
      </CardContent>
      <Divider />
      {permissionsMap.createExamPlanningVehicle && (
        <CardActions>
          <QuickAddVehicleToExamPlanning color="primary" fullWidth variant="text" record={record} onChange={refresh} />
        </CardActions>
      )}
    </Card>
  )
}

export default ExamPlanningVehiclesCard
