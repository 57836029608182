import UserList from './UserList'
import UserCreate from './UserCreate'
import UserEdit from './UserEdit'
// import User from './UserShow'

export default {
  name: 'User',
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  // show: User,
}
