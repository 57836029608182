import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import CardActions from '@material-ui/core/CardActions'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import IconButton from '@material-ui/core/IconButton'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import { makeStyles } from '@material-ui/core/styles'
import { useDataProvider, useNotify, useQueryWithStore, useRefresh } from 'ra-core'
import { ReferenceField, ReferenceManyField, Pagination, SimpleList, TextField } from 'react-admin'
import UploadDocumentButton from './UploadDocumentButton'
import { TiDocument } from 'react-icons/ti'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import MoreVert from '@material-ui/icons/MoreVert'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import moment from 'moment'
import TelegramIcon from '@material-ui/icons/Telegram'
import QuickCreateCustomerDrivingLicenseButton from './QuickCreateCustomerDrivingLicenseButton'

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    title: {},
    icon: {
      marginRight: theme.spacing(1),
    },
  }
})

type Props = {
  record?: any
  [x: string]: any
}

const MoreMenu: FC<Props> = ({ record }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const onMoreClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const onMoreClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <IconButton edge="end" aria-label="delete" onClick={onMoreClick}>
        <MoreVert />
      </IconButton>
      <Menu id={`more-${record.id}`} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onMoreClose}>
        <MenuItem>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Modifica Patente
        </MenuItem>
        <MenuItem onClick={onMoreClose}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Elimina
        </MenuItem>
      </Menu>
    </>
  )
}

const CustomerDrivingLicensesCard: FC<Props> = (props) => {
  const classes = useStyles(props)
  const { record, permissionsMap } = props
  const [submitting, setSubmitting] = useState<boolean>(false)
  const dataProvider = useDataProvider()

  const notify = useNotify()
  const refresh = useRefresh()

  const onDocumentUploaded = useCallback(
    (data: any) => {
      setSubmitting(true)
      dataProvider.create(
        'CustomerContact',
        {
          data: { customerId: record.id, documentId: data.id },
        },
        {
          onSuccess: ({ data }: any) => {
            setSubmitting(false)
            refresh()
          },
          onFailure: (error: any) => {
            setSubmitting(false)
            notify(error.message, 'error')
          },
        }
      )
    },
    [dataProvider, record, notify]
  )

  // useEffect(() => {
  //   const fetchData = async (): Promise<void> => {
  //     try {
  //       setLoading(true)
  //       const result = await dataProvider.getList('CustomerDocument', {
  //         filter: {
  //           customerId: record.id,
  //         },
  //         sort: {
  //           field: 'createdAt',
  //           order: 'DESC',
  //         },
  //         pagination: {
  //           page: 1,
  //           perPage: 20,
  //         },
  //       })
  //     } catch (e) {
  //       notify(e.message, 'error')
  //     } finally {
  //       setLoading(false)
  //     }
  //   }

  //   fetchData()
  // }, [record])

  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center" mb={3}>
          <TiDocument size="1.2rem" className={classes.icon} />
          <Typography variant="h4" className={classes.title}>
            Patenti
          </Typography>
        </Box>
        <ReferenceManyField {...props} record={record} reference="DrivingLicence" target="customerId" link={false}>
          <SimpleList
            linkType={false}
            // className={classes.list}
            rightIcon={(record: any): any => <MoreMenu record={record} />}
            primaryText={(record: any): any => `${record.licenceNumber}`}
            // secondaryText={(record: any): any => `${record.contactType}`}
          />
        </ReferenceManyField>
      </CardContent>
      {permissionsMap.createDrivingLicence && (
        <>
          <Divider />
          <CardActions>
            <QuickCreateCustomerDrivingLicenseButton
              customerId={record.id}
              color="primary"
              fullWidth
              variant="text"
              onChange={onDocumentUploaded}
            />
            {/* <Button color="primary" fullWidth variant="text">
          Upload picture
        </Button> */}
          </CardActions>
        </>
      )}
    </Card>
  )
}

export default CustomerDrivingLicensesCard
