import React, { FC, useMemo } from 'react'
import moment from 'moment'
import 'moment/locale/it'

import { DateField, ReferenceField, FunctionField } from 'react-admin'
import { useQueryWithStore } from 'ra-core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))

type Props = {
  record?: any
}

const OrderMessageItem: FC<Props> = ({ record }) => {
  const classes = useStyles()
  const { data, loading, error } = useQueryWithStore({
    type: 'getOne',
    resource: 'User',
    payload: {
      id: record.senderUserId,
    },
  })

  // const messageDate = useMemo(
  //   () => (record && record.createdAt ? moment(record.createdAt).format('L LT') : undefined),
  //   [record]
  // )
  const messageDate = useMemo(
    () => (record && record.createdAt ? moment(record.createdAt).format('L LT') : undefined),
    [record]
  )

  return (
    <div className={classes.root} key={`order-message-${record.id}`}>
      {!loading && data && <div>{`${data.firstName} ${data.lastName}`}</div>}
      <div>
        <Typography variant="caption">{messageDate}</Typography>
      </div>
      <div>{record.message}</div>
      <div></div>
      {/* {record.isPrivate && <div>private</div>} */}
    </div>
  )
}

export default OrderMessageItem
