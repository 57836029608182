import React, { FC } from 'react'
import { NumberInput, TextInput, NullableBooleanInput } from 'react-admin'
import Filter from '../../components/Filter'

type Props = {}

const TaxFilters: FC<Props> = props => (
  <Filter {...props}>
    <NumberInput source="id" />
    <TextInput source="name" alwaysOn />
    <NullableBooleanInput source="active" alwaysOn />
  </Filter>
)
export default TaxFilters
