import React, { FC, useCallback, MouseEvent } from 'react'
import GridList from '@material-ui/core/GridList'
import InputLabel from '@material-ui/core/InputLabel'
import { FieldTitle, useTranslate } from 'ra-core'

import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import { makeStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import GalleryTileButton from './GalleryTileButton'
import IconButton from '@material-ui/core/IconButton'
import RemoveCircle from '@material-ui/icons/RemoveCircle'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '-2px',
    paddingBottom: '20px'
  },
  gridList: {
    maxWidth: '1000px',
    margin: 0
  },
  label: {
    marginBottom: 10
  },
  tileBar: {
    background: 'linear-gradient(to top, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.6) 70%,rgba(0,0,0,0) 100%)'
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: '100%'
  },
  price: {
    display: 'inline',
    fontSize: '1em'
  },
  link: {
    color: '#fff'
  },
  removeButtonContainer: {
    display: 'inline-block',
    position: 'relative',
    float: 'left',
    '& button': {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      minWidth: theme.spacing(2),
      opacity: 0
    },
    '&:hover button': {
      opacity: 1
    }
  },
  removeIcon: {
    color: theme.palette.error.main
  }
}))

export const getColsForWidth = (width: 'xs' | 'sm' | 'md' | 'lg' | 'xl'): number => {
  if (width === 'xs') return 2
  if (width === 'sm') return 3
  if (width === 'md') return 4
  if (width === 'lg') return 5
  return 6
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const times = (nbChildren: number, fn: Function): Array<any> => Array.from({ length: nbChildren }, (_, key) => fn(key))

type LoadingProps = {
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  nbItems?: number
  label?: string
}

const LoadingGridList: FC<LoadingProps> = ({ width = 'xs', nbItems = 10, label }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <InputLabel shrink className={classes.label}>
        <FieldTitle label={label} source={'galleryId'} resource={'Product'} isRequired={false} />
      </InputLabel>
      <GridList cellHeight={180} cols={getColsForWidth(width)} className={classes.gridList}>
        {' '}
        {times(nbItems, (key: string) => (
          <GridListTile key={key}>
            <div className={classes.placeholder} />
          </GridListTile>
        ))}
      </GridList>
    </div>
  )
}

type Props = {
  ids?: any[]
  data?: any
  basePath?: string
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  loaded?: boolean
  showAddPictureTile?: boolean
  onAddPictureClick?: () => void
  onGalleryPictureClick?: (picture: any, index: number) => void
  label?: string
  onRemoveClick?: ((id: string) => void) | undefined
}

const LoadedGridList: FC<Props> = ({
  ids = [],
  data = {},
  basePath,
  width = 'xs',
  showAddPictureTile = true,
  onAddPictureClick,
  onGalleryPictureClick,
  label,
  loaded,
  onRemoveClick
}) => {
  const classes = useStyles()
  const translate = useTranslate()

  const handlePictureClick = useCallback(
    (picture: any, index: number) => (): void => {
      if (onGalleryPictureClick) {
        onGalleryPictureClick(picture, index)
      }
    },
    []
  )

  const handleRemoveClick = useCallback(
    (id: string) => (): void => {
      if (onRemoveClick) {
        onRemoveClick(id)
      }
    },
    []
  )

  return (
    <div className={classes.root}>
      <InputLabel shrink className={classes.label}>
        <FieldTitle label={label} source={'galleryId'} resource={'Product'} isRequired={false} />
      </InputLabel>
      <GridList cellHeight={180} cols={getColsForWidth(width)} className={classes.gridList}>
        {showAddPictureTile && (
          <GalleryTileButton onClick={onAddPictureClick} label="customFields.galleryGrid.addToGallery" />
        )}

        {ids.map(
          (id: string, index: number): React.ReactNode => (
            <GridListTile
              onClick={handlePictureClick(data[id], index)}
              key={id}
              classes={{ root: classes.removeButtonContainer }}
            >
              <img src={data[id].urlMedium} alt="" />
              <GridListTileBar className={classes.tileBar} title={data[id].description} />
              <IconButton
                onClick={handleRemoveClick(data[id].id)}
                aria-label={translate('ra.action.delete')}
                title={translate('ra.action.delete')}
              >
                <RemoveCircle className={classes.removeIcon} />
              </IconButton>
            </GridListTile>
          )
        )}
      </GridList>
    </div>
  )
}

const ProductGalleryList: FC<Props> = ({ loaded, ...props }) =>
  loaded && props.data ? <LoadedGridList {...props} /> : <LoadingGridList {...props} />

export default withWidth()(ProductGalleryList)
