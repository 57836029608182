import React, { FC, useCallback, useState } from 'react'
import Button, { ButtonProps } from '@material-ui/core/Button'
import { saveAs } from 'file-saver'
import { useDataProvider, useNotify } from 'ra-core'
import XLSX from 'xlsx'
import ExcelJS, { Borders } from 'exceljs'
import { calculateTax } from './../utils/prices'

const EXCEL_EURO_FORMAT = '_("€"* #,##0.00_);_("€"* (#,##0.00);_("€"* "-"??_);_(@_)'

const TRANSLATED_PAYMENT_METHOD = {
  POS: 'POS',
  TRANSFER: 'Bonifico',
  CASH: 'Contanti',
}

const defaultBorder: Partial<Borders> = {
  top: { style: 'thin', color: { argb: '00000000' } },
  left: { style: 'thin', color: { argb: '00000000' } },
  bottom: { style: 'thin', color: { argb: '00000000' } },
  right: { style: 'thin', color: { argb: '00000000' } },
}

type Props = {
  record?: any
  items: any[]
  [x: string]: any
} & ButtonProps

const PrintOrderTransactionsButton: FC<Props> = ({ record, items, ...props }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const generateExcelDocument2 = useCallback(async (): Promise<void> => {
    try {
      setLoading(true)
      let json: any[] = []
      const finalItems: any[] = []
      const { data: fullCustomer } = await dataProvider.getOne('Customer', { id: record.customerId })

      for (let i = 0; i < items.length; i++) {
        const finalItem = {
          Nome: items[i].isExceedingTrainingProduct
            ? `GUIDE NON CONTABILIZZATE(${items[i].exceedingTime} min eccedenti)`
            : `${items[i].orderProductName} ${
                items[i].isDrivingTraining ? `(${items[i].remainingTime} min rimanenti)` : ''
              }`,
          Guida: '',
          Quantità: items[i].quantity,
          'IVA (%)': items[i].taxRateOverride,
          'Prezzo unitario': items[i].isExceedingTrainingProduct
            ? null
            : parseFloat(items[i].productPriceOverride.toFixed(2)),
          'IVA (€)': parseFloat(calculateTax(items[i].productPriceOverride, items[i].taxRateOverride).toFixed(2)),
          Totale: +(
            items[i].quantity *
            (items[i].productPriceOverride + calculateTax(items[i].productPriceOverride, items[i].taxRateOverride))
          ).toFixed(2),
          Istruttore: '',
        }
        finalItems.push(finalItem)

        if (items[i].isDrivingTraining && items[i].eventsForThisItem && items[i].eventsForThisItem.length > 0) {
          items[i].eventsForThisItem.map((fullEvent: any) => {
            const price = items[i].isExceedingTrainingProduct
              ? items[i].retailPrice * (fullEvent.duration / items[i].driveTrainingMinutesDuration)
              : (items[i].finalPrice * fullEvent.duration) / items[i].driveTrainingMinutesDuration
            finalItems.push({
              Nome: items[i].isExceedingTrainingProduct ? 'N.D.' : items[i].orderProductName,
              Guida: `${fullEvent.licensePlate} - ${new Date(fullEvent.startDate).toLocaleDateString()} - ${new Date(
                fullEvent.startDate
              ).toLocaleTimeString()} - ${new Date(fullEvent.endDate).toLocaleTimeString()}`,
              'Prezzo unitario': price,
              Quantità: null,
              Totale: null,
              Istruttore: fullEvent.teamMemberName,
            })
          })
        }
      }

      json = [...finalItems]

      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

      const objectMaxLength: any[] = []
      for (let i = 0; i < json.length; i++) {
        const value: any[] = Object.values(json[i])
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] == 'number' || !value[j]) {
            objectMaxLength[j] = 10
          } else {
            objectMaxLength[j] = objectMaxLength[j] >= value[j].length ? objectMaxLength[j] : value[j].length
          }
        }
        const keys = Object.keys(json[i])
        for (let j = 0; j < keys.length; j++) {
          objectMaxLength[j] = objectMaxLength[j] >= keys[j].length ? objectMaxLength[j] : keys[j].length
        }
      }

      const wscols: any[] = []
      objectMaxLength.forEach((width: any) => wscols.push({ width }))

      // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { sheetStubs: true })

      const summary = [
        { a: '' },
        { a: '', b: '', c: '', d: '', e: '', f: 'Residuo:', g: record.amountToPay },
        { a: '', b: '', c: '', d: '', e: '', f: 'Pagato:', g: record.amountPayed },
        { a: '' },
        { a: '', b: '', c: '', d: '', e: '', f: 'Subtotale:', g: parseFloat(record.productsSubtotal.toFixed(2)) },
        {
          a: '',
          b: '',
          c: '',
          d: '',
          e: '',
          f: 'Totale IVA:',
          g: parseFloat((record.productsTotal - record.productsSubtotal).toFixed(2)),
        },
        { a: '', b: '', c: '', d: '', e: '', f: 'Totale:', g: record.totalAmount },
      ]

      const ws = XLSX.utils.aoa_to_sheet([
        ['', 'SCHEDA CONTABILE DEL CLIENTE:'],
        ['', fullCustomer.fullName],
        ['', `ID ordine: ${record.reference}`],
        [''],
      ])
      XLSX.utils.sheet_add_json(ws, json, { origin: 'A5', sheetStubs: true })
      const summaryStartRow: number = json.length + 6
      XLSX.utils.sheet_add_json(ws, summary, { origin: `A${summaryStartRow}`, sheetStubs: true, skipHeader: true })

      ws['!cols'] = wscols
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      saveAs(data, `scheda_contabile_${fullCustomer.lastName}-${fullCustomer.firstName}.xlsx`)
    } catch (err) {
      console.log('errore print', err)
      notify('ra.error', 'error')
    } finally {
      setLoading(false)
    }
  }, [items, record])

  const generateExcelDocument = useCallback(async (): Promise<void> => {
    try {
      setLoading(true)
      const { data: fullCustomer } = await dataProvider.getOne('Customer', { id: record.customerId })
      const { data: transactionsData } = await dataProvider.getList('OrderTransaction', {
        filter: { orderId: record.id },
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'createdAt', order: 'ASC' },
      })
      const { data: customerContacts } = await dataProvider.getList('CustomerContact', {
        filter: {
          customerId: record.customerId,
        },
        sort: {
          field: 'id',
          order: 'ASC',
        },
        pagination: {
          page: 1,
          perPage: 10,
        },
      })

      const newItems = [
        ...items,
        ...transactionsData.map((item: any) => {
          return {
            ...item,
            date: item.transactionDate,
          }
        }),
      ]
        .reduce((acc: any[], item: any) => {
          const newItems = []
          if (item.eventsForThisItem && item.eventsForThisItem.length > 0) {
            newItems.push(
              ...item.eventsForThisItem.map((cItem: any) => {
                return {
                  ...cItem,
                  ...item,
                  date: cItem.startDate,
                }
              })
            )
          } else if (item.isExam) {
            newItems.push({
              date: item.examDate,
              ...item,
            })
          } else {
            newItems.push({
              date: record.orderDate ? record.orderDate : record.createdAt,
              ...item,
            })
          }

          return [...acc, ...newItems]
        }, [])
        .sort((a, b) => {
          console.log(new Date(a.date), new Date(b.date), 'nuovi item con date --- date')
          return new Date(a.date).getTime() - new Date(b.date).getTime()
        })

      // console.log('nuovi item con date', newItems)

      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'DriveSystem'
      workbook.created = new Date()
      workbook.modified = new Date()

      const worksheet = workbook.addWorksheet('Scheda Contabile')

      worksheet.getRow(2).height = 15
      worksheet.getColumn('C').width = 45
      worksheet.getCell('C2').value = 'SCHEDA CONTABILE DEL CLIENTE'
      worksheet.getCell('C2').style = {
        alignment: { horizontal: 'center' },
        border: defaultBorder,
      }

      const customerCell = worksheet.getCell('C3')
      customerCell.value = fullCustomer.fullName
      customerCell.style = {
        font: {
          bold: true,
          size: 12,
        },
        alignment: { horizontal: 'center' },
        border: defaultBorder,
      }

      worksheet.mergeCells('D2:E2')
      worksheet.mergeCells('D3:E3')
      worksheet.getCell('D2').value = 'Istruttore:'
      worksheet.getCell('D2').style = { font: { bold: true }, border: defaultBorder }

      if (fullCustomer.mainReferenceTeamMemberId) {
        const { data: referenceTeamMember } = await dataProvider.getOne('TeamMember', {
          id: fullCustomer.mainReferenceTeamMemberId,
        })
        worksheet.getCell('D3').value = referenceTeamMember.fullName
        worksheet.getCell('D3').style = {
          font: { bold: true, size: 12 },
          alignment: { horizontal: 'left' },
          border: defaultBorder,
        }
      }

      worksheet.getCell('E2').style = { border: defaultBorder }
      worksheet.getCell('E3').style = { border: defaultBorder }
      worksheet.getCell('F2').style = { border: defaultBorder }
      worksheet.getCell('F3').style = { border: defaultBorder }

      worksheet.mergeCells('F2:G2')
      worksheet.getCell('F2').value = 'TEL.'
      worksheet.getCell('F2').style = { font: { bold: true }, border: defaultBorder }

      if (customerContacts && customerContacts.length > 0) {
        const foundedPhone = customerContacts.find((item) => item.contactType === 'sms')
        if (foundedPhone) {
          worksheet.mergeCells('F3:G3')
          // worksheet.getColumn('G').width = 18
          worksheet.getCell('F3').value = foundedPhone.contact
          worksheet.getCell('F3').style = {
            font: { bold: true, size: 12 },
            border: defaultBorder,
            alignment: { horizontal: 'left' },
          }
        }
      }

      worksheet.getColumn('B').width = 12
      worksheet.getColumn('D').width = 14
      worksheet.getColumn('E').width = 14
      worksheet.getColumn('F').width = 14
      worksheet.getColumn('G').width = 14
      worksheet.getColumn('H').width = 14

      const itemsHeaderRow = worksheet.getRow(5)
      itemsHeaderRow.getCell('B').value = 'DATA'
      itemsHeaderRow.getCell('B').style = {
        alignment: {
          horizontal: 'center',
        },
        border: defaultBorder,
      }
      itemsHeaderRow.getCell('C').style = {
        border: defaultBorder,
      }
      itemsHeaderRow.getCell('D').value = 'IMPONIBILE'
      itemsHeaderRow.getCell('D').style = {
        alignment: {
          horizontal: 'center',
        },
        border: defaultBorder,
      }
      itemsHeaderRow.getCell('E').value = 'IVA'
      itemsHeaderRow.getCell('E').style = {
        alignment: {
          horizontal: 'center',
        },
        border: defaultBorder,
      }
      itemsHeaderRow.getCell('F').value = 'TOTALE'
      itemsHeaderRow.getCell('F').style = {
        alignment: {
          horizontal: 'center',
        },
        border: defaultBorder,
      }
      itemsHeaderRow.getCell('G').value = 'PAGATO'
      itemsHeaderRow.getCell('G').style = {
        alignment: {
          horizontal: 'center',
        },
        border: defaultBorder,
      }

      itemsHeaderRow.getCell('H').value = 'ISTRUTTORE'

      for (let i = 0; i < newItems.length; i++) {
        const item = newItems[i]
        const newRow = worksheet.getRow(6 + i)
        newRow.getCell('B').value = new Date(item.date).toLocaleDateString()
        newRow.getCell('B').style = {
          border: defaultBorder,
        }

        newRow.getCell('C').value = item.isDrivingTraining
          ? `${new Date(item.startDate).toLocaleTimeString()} - ${new Date(item.endDate).toLocaleTimeString()} (${
              item.licensePlate
            })`
          : item.transactionDate && item.paymentMethod
          ? `PAGATO SALDO (${TRANSLATED_PAYMENT_METHOD[item.paymentMethod as 'TRANSFER' | 'POS' | 'CASH'] as string})`
          : item.isExam
          ? `${new Date(item.date).toLocaleTimeString()} - ${item.examName}`
          : item.orderProductName
          ? item.orderProductName
          : 'N.D.'
        newRow.getCell('C').style = {
          border: defaultBorder,
        }
        if (item.transactionDate && item.paymentMethod) {
          newRow.getCell('C').style = {
            font: {
              bold: true,
            },
          }
          newRow.getCell('G').style = {
            font: {
              bold: true,
            },
          }
        }

        if ((item.isDrivingTraining && item.isExceedingTrainingProduct) || item.isFakeExam) {
          newRow.getCell('C').style = {
            font: {
              color: {
                argb: 'FF0000',
              },
            },
          }
          newRow.getCell('D').style = {
            font: {
              color: {
                argb: 'FF0000',
              },
            },
          }
          newRow.getCell('E').style = {
            font: {
              color: {
                argb: 'FF0000',
              },
            },
          }
          newRow.getCell('F').style = {
            font: {
              color: {
                argb: 'FF0000',
              },
            },
          }
        }

        newRow.getCell('D').value = item.isDrivingTraining
          ? item.netPrice * (item.duration / item.driveTrainingMinutesDuration)
          : item.productPriceOverride
          ? item.productPriceOverride
          : null
        newRow.getCell('D').style = {
          border: defaultBorder,
          numFmt: EXCEL_EURO_FORMAT,
        }

        newRow.getCell('E').value = item.isDrivingTraining
          ? item.isExceedingTrainingProduct
            ? calculateTax(item.retailPrice * (item.duration / item.driveTrainingMinutesDuration), item.taxRate)
            : calculateTax((item.finalPrice * item.duration) / item.driveTrainingMinutesDuration, item.taxRate)
          : item.productPriceOverride
          ? calculateTax(item.productPriceOverride, item.taxRateOverride || 0)
          : null
        newRow.getCell('E').style = {
          border: defaultBorder,
          numFmt: EXCEL_EURO_FORMAT,
        }

        if (!item.transactionDate && !item.paymentMethod) {
          newRow.getCell('F').value =
            item.productPriceOverride + calculateTax(item.productPriceOverride, item.taxRateOverride)
        }
        newRow.getCell('F').style = {
          border: defaultBorder,
          numFmt: EXCEL_EURO_FORMAT,
        }

        if (item.transactionDate && item.paymentMethod) {
          newRow.getCell('G').value = item.amount
        }
        newRow.getCell('G').style = {
          border: defaultBorder,
          numFmt: EXCEL_EURO_FORMAT,
        }

        if (item.teamMemberName) {
          newRow.getCell('H').value = item.teamMemberName
        }
      }

      // Registerd Total Amounts
      const registeredTotalAmountsRow = worksheet.getRow(6 + newItems.length)
      registeredTotalAmountsRow.getCell('C').value = 'TOTALI CONTABILIZZATI'
      registeredTotalAmountsRow.getCell('C').style = {
        alignment: {
          horizontal: 'right',
        },
        border: defaultBorder,
      }

      registeredTotalAmountsRow.getCell('D').value = record.productsSubtotal
      registeredTotalAmountsRow.getCell('D').style = {
        alignment: {
          horizontal: 'right',
        },
        border: defaultBorder,
        numFmt: EXCEL_EURO_FORMAT,
      }

      registeredTotalAmountsRow.getCell('E').value = record.productsTotal - record.productsSubtotal
      registeredTotalAmountsRow.getCell('E').style = {
        alignment: {
          horizontal: 'right',
        },
        border: defaultBorder,
        numFmt: EXCEL_EURO_FORMAT,
      }

      registeredTotalAmountsRow.getCell('F').value = record.totalAmount
      registeredTotalAmountsRow.getCell('F').style = {
        alignment: {
          horizontal: 'right',
        },
        border: defaultBorder,
        numFmt: EXCEL_EURO_FORMAT,
      }

      registeredTotalAmountsRow.getCell('G').value = record.amountPayed
      registeredTotalAmountsRow.getCell('G').style = {
        alignment: {
          horizontal: 'right',
        },
        border: defaultBorder,
        numFmt: EXCEL_EURO_FORMAT,
      }

      const amountToPayRow = worksheet.getRow(6 + newItems.length + 2)
      amountToPayRow.getCell('D').value = 'SALDO'
      amountToPayRow.getCell('D').style = {
        alignment: {
          horizontal: 'left',
        },
        font: {
          bold: true,
        },
        border: defaultBorder,
        numFmt: EXCEL_EURO_FORMAT,
      }

      amountToPayRow.getCell('E').style = {
        border: defaultBorder,
      }

      amountToPayRow.getCell('F').style = {
        border: defaultBorder,
      }

      amountToPayRow.getCell('G').value = record.amountToPay
      amountToPayRow.getCell('G').style = {
        alignment: {
          horizontal: 'left',
        },
        font: {
          bold: true,
        },
        border: defaultBorder,
        numFmt: EXCEL_EURO_FORMAT,
      }

      const buffer = await workbook.xlsx.writeBuffer()
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `scheda_contabile_${fullCustomer.lastName}-${fullCustomer.firstName}.xlsx`
      )

      return

      let json: any[] = []
      const finalItems: any[] = []
      // const { data: fullCustomer } = await dataProvider.getOne('Customer', { id: record.customerId })

      for (let i = 0; i < items.length; i++) {
        const finalItem = {
          Nome: items[i].isExceedingTrainingProduct
            ? `GUIDE NON CONTABILIZZATE(${items[i].exceedingTime} min eccedenti)`
            : `${items[i].orderProductName} ${
                items[i].isDrivingTraining ? `(${items[i].remainingTime} min rimanenti)` : ''
              }`,
          Guida: '',
          Quantità: items[i].quantity,
          'IVA (%)': items[i].taxRateOverride,
          'Prezzo unitario': items[i].isExceedingTrainingProduct
            ? null
            : parseFloat(items[i].productPriceOverride.toFixed(2)),
          'IVA (€)': parseFloat(calculateTax(items[i].productPriceOverride, items[i].taxRateOverride).toFixed(2)),
          Totale: +(
            items[i].quantity *
            (items[i].productPriceOverride + calculateTax(items[i].productPriceOverride, items[i].taxRateOverride))
          ).toFixed(2),
          Istruttore: '',
        }
        finalItems.push(finalItem)

        if (items[i].isDrivingTraining && items[i].eventsForThisItem && items[i].eventsForThisItem.length > 0) {
          items[i].eventsForThisItem.map((fullEvent: any) => {
            const price = items[i].isExceedingTrainingProduct
              ? items[i].retailPrice * (fullEvent.duration / items[i].driveTrainingMinutesDuration)
              : (items[i].finalPrice * fullEvent.duration) / items[i].driveTrainingMinutesDuration
            finalItems.push({
              Nome: items[i].isExceedingTrainingProduct ? 'N.D.' : items[i].orderProductName,
              Guida: `${fullEvent.licensePlate} - ${new Date(fullEvent.startDate).toLocaleDateString()} - ${new Date(
                fullEvent.startDate
              ).toLocaleTimeString()} - ${new Date(fullEvent.endDate).toLocaleTimeString()}`,
              'Prezzo unitario': price,
              Quantità: null,
              Totale: null,
              Istruttore: fullEvent.teamMemberName,
            })
          })
        }
      }

      json = [...finalItems]

      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

      const objectMaxLength: any[] = []
      for (let i = 0; i < json.length; i++) {
        const value: any[] = Object.values(json[i])
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] == 'number' || !value[j]) {
            objectMaxLength[j] = 10
          } else {
            objectMaxLength[j] = objectMaxLength[j] >= value[j].length ? objectMaxLength[j] : value[j].length
          }
        }
        const keys = Object.keys(json[i])
        for (let j = 0; j < keys.length; j++) {
          objectMaxLength[j] = objectMaxLength[j] >= keys[j].length ? objectMaxLength[j] : keys[j].length
        }
      }

      const wscols: any[] = []
      objectMaxLength.forEach((width: any) => wscols.push({ width }))

      // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { sheetStubs: true })

      const summary = [
        { a: '' },
        { a: '', b: '', c: '', d: '', e: '', f: 'Residuo:', g: record.amountToPay },
        { a: '', b: '', c: '', d: '', e: '', f: 'Pagato:', g: record.amountPayed },
        { a: '' },
        { a: '', b: '', c: '', d: '', e: '', f: 'Subtotale:', g: parseFloat(record.productsSubtotal.toFixed(2)) },
        {
          a: '',
          b: '',
          c: '',
          d: '',
          e: '',
          f: 'Totale IVA:',
          g: parseFloat((record.productsTotal - record.productsSubtotal).toFixed(2)),
        },
        { a: '', b: '', c: '', d: '', e: '', f: 'Totale:', g: record.totalAmount },
      ]

      const ws = XLSX.utils.aoa_to_sheet([
        ['', 'SCHEDA CONTABILE DEL CLIENTE:'],
        ['', fullCustomer.fullName],
        ['', `ID ordine: ${record.reference}`],
        [''],
      ])
      XLSX.utils.sheet_add_json(ws, json, { origin: 'A5', sheetStubs: true })
      const summaryStartRow: number = json.length + 6
      XLSX.utils.sheet_add_json(ws, summary, { origin: `A${summaryStartRow}`, sheetStubs: true, skipHeader: true })

      ws['!cols'] = wscols
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      saveAs(data, `scheda_contabile_${fullCustomer.lastName}-${fullCustomer.firstName}.xlsx`)
    } catch (err) {
      console.log('errore print', err)
      notify('ra.error', 'error')
    } finally {
      setLoading(false)
    }
  }, [items, record])

  return (
    <>
      <Button {...props} onClick={generateExcelDocument} disabled={loading || !record}>
        {loading ? 'Elaborazione...' : 'Stampa'}
      </Button>
    </>
  )
}

export default PrintOrderTransactionsButton
