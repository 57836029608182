import React, { FC } from 'react'
import RAList from '../../List'
import { TextField, NumberField, Datagrid } from 'react-admin'
import EditButtonWPermission from '../../../components/EditButtonWPermission'

type Props = {}

const NameOnlyList: FC<Props> = (props) => {
  return (
    <RAList {...props}>
      <Datagrid>
        <NumberField source="id" />
        <TextField source="name" />
        <EditButtonWPermission {...props} />
      </Datagrid>
    </RAList>
  )
}

export default NameOnlyList
