import { useApolloClient } from '@apollo/client'
import React, { FC, useEffect, useState } from 'react'
import { Loading, BooleanInput, ReferenceArrayInput, AutocompleteArrayInput, NumberInput } from 'react-admin'
import { useFormState, useForm } from 'react-final-form'
import gql from 'graphql-tag'
import { useNotify } from 'ra-core'

const QUERY_GET_PRODUCT_DRIVING_SCHOOL_ATTRIBUTES = gql`
  query GetProductDrivingSchoolAttributes(
    $filters: ProductDrivingSchoolAttributeFilterInput
    $sort: ProductDrivingSchoolAttributeSortInput
    $pagination: PaginationInputType
  ) {
    productDrivingSchoolAttributes(filters: $filters, sort: $sort, pagination: $pagination) {
      data {
        id
        productId
        hasLearnerPermits
        isDrivingTraining
        isDefaultDrivingTraining
        driveTrainingMinutesDuration
        isTheoryExam
        isDrivingExam
        isMedicalExam
        courses {
          id
          name
        }
        exams {
          id
          name
        }
      }
    }
  }
`

type Props = {
  record: any
}

const ProductDrivingSchoolAttributesForm: FC<Props> = (props) => {
  const { values } = useFormState()
  const form = useForm()
  const notify = useNotify()
  const [loading, setLoading] = useState<boolean>(false)
  const client = useApolloClient()

  useEffect(() => {
    const fetchAttributes = async (): Promise<void> => {
      try {
        setLoading(true)
        const { data } = await client.query({
          query: QUERY_GET_PRODUCT_DRIVING_SCHOOL_ATTRIBUTES,
          variables: {
            filters: {
              productId: props.record.id,
            },
            sort: {
              id: 'DESC',
            },
            pagination: {
              limit: 1,
              offset: 0,
            },
          },
          fetchPolicy: 'network-only',
        })
        if (
          data &&
          data.productDrivingSchoolAttributes &&
          data.productDrivingSchoolAttributes.data &&
          data.productDrivingSchoolAttributes.data.length > 0
        ) {
          // console.log(data.productDrivingSchoolAttributes.data[0], 'eccofece')
          const result = data.productDrivingSchoolAttributes.data[0]
          form.change('drivingSchoolAttribute', {
            hasLearnerPermits: result.hasLearnerPermits,
            isDrivingTraining: result.isDrivingTraining,
            isDefaultDrivingTraining: result.isDefaultDrivingTraining,
            driveTrainingMinutesDuration: result.driveTrainingMinutesDuration,
            isTheoryExam: result.isTheoryExam,
            isDrivingExam: result.isDrivingExam,
            isMedicalExam: result.isMedicalExam,
            courseIds: result.courses.map(({ id }: any) => id),
            examIds: result.exams.map(({ id }: any) => id),
          })
        }
        setLoading(false)
      } catch (e) {
        notify('ra.error', 'error')
      }
    }

    if (props.record && props.record.id) {
      fetchAttributes()
    }
  }, [props.record])

  if (loading) {
    return <Loading />
  }

  return (
    <div>
      <BooleanInput resource="Product" source="drivingSchoolAttribute.hasLearnerPermits" />
      <BooleanInput resource="Product" source="drivingSchoolAttribute.isDrivingTraining" />
      <BooleanInput resource="Product" source="drivingSchoolAttribute.isDefaultDrivingTraining" />
      <BooleanInput resource="Product" source="drivingSchoolAttribute.isTheoryExam" />
      <BooleanInput resource="Product" source="drivingSchoolAttribute.isDrivingExam" />
      <BooleanInput resource="Product" source="drivingSchoolAttribute.isMedicalExam" />
      <NumberInput
        resource="Product"
        source="drivingSchoolAttribute.driveTrainingMinutesDuration"
        fullWidth
        disabled={values && values.drivingSchoolAttribute && !values.drivingSchoolAttribute.isDrivingTraining}
      />
      <ReferenceArrayInput resource="Product" fullWidth reference="Course" source="drivingSchoolAttribute.courseIds">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput resource="Product" fullWidth reference="Exam" source="drivingSchoolAttribute.examIds">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      {/* <pre>{JSON.stringify(record, null, 2)}</pre>
      <pre>{JSON.stringify(data.productDrivingSchoolAttributes.data, null, 2)}</pre> */}
    </div>
  )
}

export default ProductDrivingSchoolAttributesForm
