import React, { FC } from 'react'
import { TextInput, NumberInput, SelectInput } from 'react-admin'
import { useTranslate } from 'ra-core'
import FormSection from '../../components/forms/FormSection'
import InputColor from './../../components/InputColor'

type Props = {
  //
}

const EventStatusForm: FC<Props> = (props) => {
  const translate = useTranslate()
  return (
    <FormSection {...props} title={translate('resources.EventStatus.name', { smart_count: 1 })}>
      <TextInput source="name" />
      <NumberInput source="order" />
      <InputColor source="color" />
      {/* <SelectInput
        source="configurationType"
        label="Tipo di stato"
        choices={[
          { id: 'created', name: 'Creato' },
          { id: 'waitingConfirmation', name: 'In Attesa di conferma' },
          { id: 'confirmed', name: 'Confermato' },
          { id: 'rejected', name: 'Rifiutato' },
        ]}
      /> */}
    </FormSection>
  )
}

export default EventStatusForm
