import { useQuery } from '@apollo/client'
import React, { FC } from 'react'
import gql from 'graphql-tag'
// import Tooltip from '@material-ui/core/Tooltip'
import { Tooltip, FormControl, Select, InputLabel, MenuItem } from '@material-ui/core'
import UserPreviewField from './UserPreviewField'
import { QUERY_GET_PLANNED_EVENT, QUERY_GET_EVENT_STATUSES_CONFIGURATION } from '../queries'

type Props = {
  //
  record?: any
}

const PlannedEventStatusField: FC<Props> = ({ record }) => {
  const { loading, data } = useQuery(QUERY_GET_PLANNED_EVENT, {
    variables: {
      id: record.id,
    },
    skip: !record,
  })
  const { loading: configurationLoading, data: eventStatusConfiguration } = useQuery(
    QUERY_GET_EVENT_STATUSES_CONFIGURATION
  )

  if (loading || !data || !data.plannedEvent || !data.plannedEvent.customerEvents) {
    return null
  }

  // if (eventStatusConfiguration) console.log('AJOOOO', eventStatusConfiguration)

  return (
    <div>
      {data.plannedEvent.customerEvents.map((item: any, index: number): any => {
        if (item.status) {
          // console.log('SONO QUIII', item)
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Tooltip title={`${item.status.name}`}>
                <div>
                  <UserPreviewField avatarSize={35} record={item.customer} badgeColor={item.status.color} />
                </div>
              </Tooltip>
              {/* {eventStatusConfiguration && (
                <FormControl variant="outlined">
                  <InputLabel id="eventStatusLabel">Stato</InputLabel>
                  <Select labelId="eventStatusLabel">
                    {Object.entries(eventStatusConfiguration.getConfiguration.value).map((item: any, index: number) => {
                      return <MenuItem value={item[1]}>{item[0]}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              )} */}
            </div>
          )
        }
      })}
    </div>
  )
}

export default PlannedEventStatusField
