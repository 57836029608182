import React, { FC } from 'react'
import { NumberInput, TextInput, NullableBooleanInput } from 'react-admin'
import Filter from './../../components/Filter'

type Props = {}

const CurrencyFilters: FC<Props> = props => (
  <Filter {...props}>
    <NumberInput source="id" />
    <TextInput source="name" alwaysOn />
    <TextInput source="isoCode" alwaysOn />
    <NumberInput source="isoCodeNumber" />
    <TextInput source="sign" />
    <NullableBooleanInput source="active" alwaysOn />
  </Filter>
)

export default CurrencyFilters
