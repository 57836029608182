import React, { FC, useCallback, useMemo, useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { PriceField } from '../../components/CustomFields'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import SimpleTransactionRow, { AccountingCardTransaction } from './SimpleTransactionRow'
import SimpleTransactionHeader from './SimpleTransactionHeader'
import { calculateGroupTotal } from '../../utils/accountingCard'
import PlannedEventRow from './PlannedEventRow'
// import ExamPlanningRow from './ExamPlanningRow'

export type TransactionGroup = {
  groupId: string
  prodId: string
  array: AccountingCardTransaction[]
  plannedEventIds?: string[]
  examPlanningId?: string
  remainingDuration?: number
  productName: string
}

type Props = {
  record: TransactionGroup
  transIdToEdit: string | null
  setTransIdToEdit: React.Dispatch<React.SetStateAction<string | null>>
  closeCreateForm: () => void
  onAddPayment: (record: Record<string, any>) => void
  isAccountClosed: boolean
  filterType?: 'INCOME' | 'OUTCOME' | null | string | string[]
  accountingCardRecord: Record<string, any>
}

const GroupedTransactionRow: FC<Props> = ({
  record,
  closeCreateForm,
  transIdToEdit,
  setTransIdToEdit,
  onAddPayment,
  isAccountClosed,
  filterType,
  accountingCardRecord,
}: Props) => {
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(true)

  const total = useMemo(() => ({ amount: calculateGroupTotal(record.array) }), [record.array])
  const onArrowClick = useCallback(() => setOpen(!open), [open])

  return (
    <>
      <TableRow className={classes.row}>
        <TableCell>
          <IconButton size="small" onClick={onArrowClick}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell colSpan={6}>
          {
            <Typography variant="h5">{`${record.productName} ${
              record.remainingDuration || record.remainingDuration === 0
                ? record.remainingDuration < 0
                  ? `(${-record.remainingDuration} minuti in eccedenza)`
                  : `(${record.remainingDuration} minuti rimanenti)`
                : ''
            }`}</Typography>
          }
        </TableCell>
        {filterType === 'INCOME' || filterType === 'OUTCOME' || isAccountClosed ? (
          <TableCell>
            {isAccountClosed && (
              <Typography variant="h5" className={classes.paidStatus}>
                PAGATO
              </Typography>
            )}
          </TableCell>
        ) : (
          <TableCell>
            {total.amount === 0 && (
              <Typography variant="h5" className={classes.paidStatus}>
                PAGATO
              </Typography>
            )}
            {total.amount > 0 && (
              <Typography variant="h5">
                Da pagare: <PriceField record={total} source="amount" color="red" variant="h5"></PriceField>
              </Typography>
            )}
            {total.amount < 0 && (
              <Typography variant="h5">
                In eccedenza: <PriceField record={total} source="amount" variant="h5"></PriceField>
              </Typography>
            )}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell colSpan={8} padding={open ? undefined : 'none'}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {record.plannedEventIds && (
              <Table size="small" className={classes.driveTrainingTable}>
                <TableBody>
                  {record.plannedEventIds.map((id) => (
                    <PlannedEventRow key={`plannedEvent-${id}`} id={id} />
                  ))}
                </TableBody>
              </Table>
            )}
            {/* {record.examPlanningId && <ExamPlanningRow id={record.examPlanningId} />} */}
            <Table size="small" padding="none">
              <SimpleTransactionHeader />
              <TableBody>
                {record.array.map((elem: any) => {
                  return (
                    <SimpleTransactionRow
                      key={elem.id}
                      record={elem}
                      closeCreateForm={closeCreateForm}
                      transIdToEdit={transIdToEdit}
                      setTransIdToEdit={setTransIdToEdit}
                      onAddPayment={onAddPayment}
                      isAccountClosed={isAccountClosed}
                      accountingCardRecord={accountingCardRecord}
                    />
                  )
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  paidStatus: {
    color: 'green',
  },
  row: {
    backgroundColor: 'rgba(0,0,0,.05)',
  },
  driveTrainingTable: {
    backgroundColor: 'rgba(0,0,0,.05)',
    marginBottom: theme.spacing(1),
  },
}))

export default GroupedTransactionRow
