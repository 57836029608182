import React, { FC, useCallback, useState } from 'react'
import { DateField, useShowController, TitleForRecord } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { CalendarToday as CalendarTodayIcon } from '@material-ui/icons'

import OrderTitle from '../../components/OrderTitle'
import OrderMessagesField from '../../components/OrderMessagesField'
import CustomerSection from './CustomerSection'
import OrderProductsTable from './OrderProductsTable'
import OrderTransactionSection from './OrderTransactionSection'
import { PriceField } from './../../components/CustomFields'
import SummaryCard from './../../components/SummaryCard'
import ExamSummaryCard from './ExamSummaryCard'
import { useGetList, useGetMany, usePermissions, useDataProvider } from 'ra-core'
import { useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { QUERY_GET_EVENT_STATUSES_CONFIGURATION } from '../../queries'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2, 0),
  },
  content: {
    padding: theme.spacing(0, 3),
  },
  fieldLabel: {
    dispay: 'inline',
    marginRight: 8,
  },
}))

export const OrderShowComponent: FC<any> = (props: any) => {
  const { defaultTitle, loading, loaded, ...data } = useShowController(props)
  const dataProvider = useDataProvider()
  const apolloClient = useApolloClient()
  const [confirmedStatusIds, setConfirmedStatusIds] = useState()
  const [statusesData, setStatusesData] = useState<any[] | undefined>()
  // const { error: statusError, data: statusData } = useGetList(
  //   'EventStatus',
  //   { perPage: 1, page: 1 },
  //   { field: 'id', order: 'ASC' },
  //   { name: CONFIRMED_STATUS }
  // )

  const { loaded: permissionsLoaded, permissions } = usePermissions()
  const classes = useStyles()

  // const renderMessagesSummary = useCallback(() => {
  //   return <Typography variant="h6">{'0'}</Typography>
  // }, [])

  // const renderProductsSummary = useCallback(() => {
  //   if (!data.record) {
  //     return null
  //   }

  //   return <Typography variant="h6">{data.record.orderItems.length}</Typography>
  // }, [data])

  const [totalMessages, setTotalMessages] = useState<number | undefined>(undefined)
  const fetchMessages = useCallback(async (): Promise<void> => {
    try {
      const result = await dataProvider.getList('OrderMessage', {
        filter: {
          orderId: data?.record?.id,
        },
        pagination: {
          page: 1,
          perPage: 1,
        },
        sort: {
          field: 'id',
          order: 'ASC',
        },
      })
      if (result && result.data) {
        setTotalMessages(result.total)
      }
    } catch (e) {
      //
    }
  }, [data])

  const onUpdateMessages = useCallback(() => {
    // fetchMessages()
  }, [fetchMessages])

  const renderDateSummary = useCallback(() => {
    return (
      <div>
        <div>
          <Typography variant="caption" className={classes.fieldLabel}>
            Iscrizione protocollo:
          </Typography>
          <DateField variant="h6" record={data.record} source="orderDate" />
        </div>
        {data?.record?.expiringDate && (
          <div>
            <Typography variant="caption" className={classes.fieldLabel}>
              Scadenza:
            </Typography>
            <DateField variant="h6" record={data.record} source="expiringDate" />
          </div>
        )}
      </div>
    )
  }, [data])

  const renderTotalSummary = useCallback(() => {
    if (!data.record) return null
    return (
      <div>
        <div>
          <Typography variant="caption" className={classes.fieldLabel}>
            Pagato:
          </Typography>
          <PriceField source="amountPayed" record={data.record} color="green" />
        </div>
        <div>
          <Typography variant="caption" className={classes.fieldLabel}>
            Da pagare:
          </Typography>
          <PriceField source="amountToPay" record={data.record} color="red" />
        </div>
        <div>
          <Typography variant="caption" className={classes.fieldLabel}>
            Totale:
          </Typography>
          <PriceField source="totalAmount" record={data.record} />
        </div>
      </div>
    )
  }, [data])

  useEffect(() => {
    const fetchInitialData = async (): Promise<void> => {
      try {
        const configurationResult = await apolloClient.query({ query: QUERY_GET_EVENT_STATUSES_CONFIGURATION })
        if (
          configurationResult?.data?.getConfiguration?.value &&
          configurationResult.data.getConfiguration.value.confirmed &&
          configurationResult.data.getConfiguration.value.confirmed.length > 0
        ) {
          setConfirmedStatusIds(configurationResult.data.getConfiguration.value.confirmed)
          const statusesResult = await dataProvider.getMany('EventStatus', {
            ids: configurationResult.data.getConfiguration.value.confirmed,
          })

          if (statusesResult?.data) {
            setStatusesData(statusesResult.data)
          }
          console.log(statusesResult, dataProvider, 'statusesResult')

          console.log(configurationResult.data.getConfiguration.value.confirmed, 'configurationResult')
        }
      } catch (error) {
        //
      }
    }

    fetchInitialData()
  }, [])

  return (
    <Container maxWidth={false}>
      {data && data.record && <TitleForRecord {...data} title={<OrderTitle />} />}
      <Grid container spacing={3} className={classes.root}>
        <Grid item container xs={12} md={12}>
          <Grid item md={4} xs={12}>
            <SummaryCard icon={CalendarTodayIcon} title="Date" render={renderDateSummary} />
          </Grid>
          <Grid item md={4} xs={12}>
            <SummaryCard title="Totali" render={renderTotalSummary} />
          </Grid>
          <Grid item md={4} xs={12}>
            {/* <SummaryCard icon={EmailIcon} title="Messages" render={renderMessagesSummary} /> */}
            {data && data.record && <ExamSummaryCard customerId={data.record.customerId} />}
          </Grid>
          {/* <Grid item md={3} xs={12}>
            <SummaryCard icon={FormatListBulletedIcon} title="Products" render={renderProductsSummary} />
          </Grid> */}
        </Grid>
      </Grid>
      <Grid container item xs={12} justify="space-between" alignItems="flex-start">
        <Grid item spacing={2} container xs={9} md={9}>
          {data && data.record && permissionsLoaded && permissions && permissions.permissionsMap.customer && (
            <CustomerSection id={data.record.customerId} />
          )}
          {data && data.record && statusesData && statusesData.length > 0 && (
            // <pre>{JSON.stringify({ ciao: 'prova' }, null, 2)}</pre>
            <OrderProductsTable record={data.record} confirmedStatuses={statusesData} />
          )}
        </Grid>
        <Grid item spacing={2} container xs={3} md={3} justify="flex-end" direction="column">
          {data && data.record && permissionsLoaded && permissions && (
            <OrderMessagesField {...data} {...{ onUpdateMessages }} permissionsMap={permissions.permissionsMap} />
          )}
          {data && data.record && permissionsLoaded && permissions && permissions.permissionsMap.orderTransactions && (
            <OrderTransactionSection orderId={data.record.id} permissionsMap={permissions.permissionsMap} />
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default OrderShowComponent
