import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { useRedirect, useDataProvider, useNotify } from 'ra-core'
import gql from 'graphql-tag'
import { differenceInMinutes, addYears } from 'date-fns'

import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'

import OrderProductRow from './OrderProductRow'
import { calculateTax, numToEUR } from '../../utils/prices'
import PrintOrderTransactionsButton from '../../components/PrintOrderTransactionsButton'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  editOrderButton: {
    marginLeft: theme.spacing(1),
  },
  '@keyframes blinker': {
    from: { opacity: 1 },
    to: { opacity: 0.5 },
  },
  editOrderButtonRed: {
    marginLeft: theme.spacing(1),
    borderColor: 'red',
    color: 'red',
    animationName: '$blinker',
    animationDuration: '1.5s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
}))

function event_dates_sort_asc(e1: any, e2: any): any {
  if (e1.startDate > e2.startDate) return 1
  if (e1.startDate < e2.startDate) return -1
  return 0
}

function event_durations_sort_desc(e1: any, e2: any): any {
  if (e1.duration > e2.duration) return -1
  if (e1.duration < e2.duration) return 1
  return 0
}

function nontraining_sort(e1: any, e2: any): any {
  return e1.isDrivingTraining === e2.isDrivingTraining ? 0 : !e1.isDrivingTraining ? -1 : 1
}

function driving_duration_sort(e1: any, e2: any): any {
  return e1.driveTrainingMinutesDuration === e2.driveTrainingMinutesDuration
    ? 0
    : !e1.driveTrainingMinutesDuration
    ? -1
    : e1.driveTrainingMinutesDuration > e2.driveTrainingMinutesDuration
    ? -1
    : 1
}

export const GET_CUSTOMER_CONFIRMED_EVENTS = gql`
  query GetCustomerConfirmedEvents($filters: PlannedEventFilterInput) {
    plannedEvents(filters: $filters, sort: { id: ASC }, pagination: { disabled: true }) {
      data {
        id
        eventId
        customerIds
        teamMemberIds
        vehicleId
        dsEventType
        event {
          id
          startDate
          endDate
          title
        }
        customerEvents {
          id
          statusId
          customerId
        }
      }
    }
  }
`

const OrderProductsTable: FC<any> = ({ record, confirmedStatuses }: any) => {
  const [totalTaxes, setTotalTaxes] = useState<number>(0)
  const [totalNet, setTotalNet] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [items, setItems] = useState<any[]>([])
  const [hasDrivingTrainings, setHasDrivingTrainings] = useState<boolean>(false)

  const classes = useStyles()
  const redirect = useRedirect()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const client = useApolloClient()

  const goToEditOrder = useCallback(() => {
    redirect(`/Order/${record.id}`)
  }, [record, redirect])

  const getEverything = useCallback(async () => {
    const allDrivingTrainings: any[] = []
    const drivingTrainingProductsMap: Record<string, any> = {}
    const allVehiclesMap: Record<string, any> = {}
    const allTeammembersMap: Record<string, any> = {}
    let hasTrainings = false
    let allOrderItems: any[] = []
    let totNet = 0
    let totTax = 0

    try {
      // 1. get all confirmed driving trainings for current user
      const { data: resp } = await client.query({
        query: GET_CUSTOMER_CONFIRMED_EVENTS,
        variables: {
          filters: {
            customerIds: [record.customerId],
            dateRange: {
              startDate: record.orderDate,
              endDate: record.expiringDate ? record.expiringDate : addYears(new Date(record.orderDate), 1),
            },
          },
          // pagination: { disabled: true },
        },
      })

      if (resp && resp.plannedEvents && resp.plannedEvents.data && resp.plannedEvents.data.length > 0) {
        resp.plannedEvents.data.forEach((eventPlanned: any) => {
          if (
            eventPlanned.vehicleId &&
            eventPlanned.customerEvents.length > 0 &&
            eventPlanned.teamMemberIds &&
            eventPlanned.teamMemberIds.length > 0
          ) {
            // taking only confirmed driving trainings
            const confirmed: any[] = eventPlanned.customerEvents.filter((xxx: any) => {
              if (xxx.customerId === record.customerId) {
                console.log(
                  'cerco',
                  confirmedStatuses.find((item: any) => item.id === xxx.statusId)
                )
                return !!confirmedStatuses.find((item: any) => item.id === xxx.statusId)
              }
              return false
              // return xxx.statusId === confirmedStatusId && xxx.customerId === record.customerId
            })

            const foundedStatus = confirmedStatuses.find((item: any) => {
              if (eventPlanned?.customerEvents?.length > 0) {
                return eventPlanned.customerEvents[0].statusId === item.id
              }
              // console.log('fatto per dentro', item.id, eventPlanned)
              return false
            })

            if (confirmed.length > 0) {
              const fullEvent = {
                id: eventPlanned.id,
                startDate: eventPlanned.event.startDate,
                endDate: eventPlanned.event.endDate,
                vehicleId: eventPlanned.vehicleId,
                status: foundedStatus,
                teamMemberId: eventPlanned.teamMemberIds[0],
                // status: confirmedStatuses.find((item: any) => item.id === item.customerEvents[0].statusId),
                duration: differenceInMinutes(
                  new Date(eventPlanned.event.endDate),
                  new Date(eventPlanned.event.startDate)
                ),
              }
              allDrivingTrainings.push(fullEvent)
            }
          }
        })
        allDrivingTrainings.sort(event_dates_sort_asc)
        allDrivingTrainings.sort(event_durations_sort_desc)
        if (allDrivingTrainings.length > 0) hasTrainings = true
      }

      // 2. get all items
      // const { data: orderItems } = await dataProvider.getMany('OrderItem', { ids: record.orderItemsIds })
      // if (record.orderItems.length === 0) {
      //   throw new Error('No items found :/')
      // }
      const orderItems: any[] = [
        ...(record.orderItems
          ? record.orderItems.map((item: any) => {
              return { ...item }
            })
          : []),
      ]
      allOrderItems = [...orderItems]

      // 3. set all totals
      orderItems.forEach((item: any) => {
        const net = item.productPriceOverride * item.quantity
        totTax += calculateTax(net, item.taxRateOverride)
        totNet += net
      })

      for (let i = 0; i < orderItems.length; i++) {
        // need to move this before, for every orderItem
        const { data: orderProduct } = await dataProvider.getOne('Product', { id: orderItems[i].productId })
        orderItems[i].orderProductName = orderProduct.name
        // orderItems[i].productId = orderProduct.originalId
      }

      // WE CAN FINISH HERE IF THERE ARE NO DRIVING TRAINING
      if (allDrivingTrainings.length > 0) {
        // 4. GET ALL PRODUCTS WITH ISDRIVINGTRAINIGS = TRUE
        const { data: drivingTrainingProducts } = await dataProvider.getList('ProductDrivingSchoolAttribute', {
          filter: { isDrivingTraining: true },
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'driveTrainingMinutesDuration', order: 'DESC' },
        })
        drivingTrainingProducts.forEach((elem: any) => (drivingTrainingProductsMap[elem.productId] = elem))

        // 5. get orderProducts for each orderItem to associate drivingschoolattributes and other shit
        for (let i = 0; i < orderItems.length; i++) {
          // need to move this before, for every orderItem
          // const { data: orderProduct } = await dataProvider.getOne('OrderProduct', { id: orderItems[i].orderProductId })
          // orderItems[i].orderProductName = orderProduct.name
          // orderItems[i].productId = orderProduct.originalId

          // if (Object.keys(drivingTrainingProductsMap).includes(orderProduct.originalId)) {
          //   orderItems[i].isDrivingTraining = true
          //   orderItems[i].driveTrainingMinutesDuration =
          //     drivingTrainingProductsMap[orderProduct.originalId].driveTrainingMinutesDuration
          // } else orderItems[i].isDrivingTraining = false
          if (Object.keys(drivingTrainingProductsMap).includes(orderItems[i].productId)) {
            orderItems[i].isDrivingTraining = true
            orderItems[i].driveTrainingMinutesDuration =
              drivingTrainingProductsMap[orderItems[i].productId].driveTrainingMinutesDuration
          } else orderItems[i].isDrivingTraining = false
        }
        // console.log('XXX parte 1 ', orderItems)
        orderItems.sort(driving_duration_sort)
        orderItems.sort(nontraining_sort)

        // 6. get all vehicles and teammembers
        const { data: vehicles } = await dataProvider.getList('Vehicle', {
          filter: {},
          pagination: { perPage: 100, page: 1 },
          sort: { field: 'id', order: 'ASC' },
        })
        vehicles.map((vehicle: any) => (allVehiclesMap[vehicle.id] = vehicle))
        const { data: teamMembers } = await dataProvider.getList('TeamMember', {
          filter: {},
          pagination: { perPage: 100, page: 1 },
          sort: { field: 'id', order: 'ASC' },
        })
        teamMembers.map((teamMember: any) => (allTeammembersMap[teamMember.id] = teamMember))

        // 7. associate all driving trainings with related products
        orderItems.forEach((item: any) => {
          if (item.isDrivingTraining) {
            const eventsForThisItem: any[] = []
            let remainingTime = item.driveTrainingMinutesDuration * item.quantity
            // console.log('XXX associating events with products:: remaining time: ', remainingTime)
            const tempDrivingTrainings = [...allDrivingTrainings]

            tempDrivingTrainings.map((driveTraining: any) => {
              if (remainingTime > 0 && remainingTime - driveTraining.duration >= 0) {
                const formattedTraining = {
                  licensePlate: allVehiclesMap[driveTraining.vehicleId].licensePlate,
                  teamMemberName: allTeammembersMap[driveTraining.teamMemberId].fullName,
                  duration: driveTraining.duration,
                  startDate: driveTraining.startDate,
                  endDate: driveTraining.endDate,
                  status: driveTraining.status,
                }
                // console.log('XXX examining ', driveTraining)
                // console.log('XXX adding formattedTraining::: ', formattedTraining)
                eventsForThisItem.push(formattedTraining)
                // console.log('XXX just playing with ', remainingTime, driveTraining.duration)
                remainingTime = remainingTime - driveTraining.duration
                // allDrivingTrainings = allDrivingTrainings.map((grazzini: any) => grazzini.id !== driveTraining.id)
                // console.log('XXX indexof', allDrivingTrainings.indexOf(driveTraining))
                const verdini = allDrivingTrainings.indexOf(driveTraining)
                // allDrivingTrainings.shift()
                allDrivingTrainings.splice(verdini, 1)
              }
            })
            item.remainingTime = remainingTime
            item.eventsForThisItem = eventsForThisItem
          }
        })
        // console.log('XXX allDrivingTrainings ', allDrivingTrainings)
        // 8. take care of orphaned driving trainings
        if (allDrivingTrainings.length > 0) {
          const { data: defaultProductAttributes } = await dataProvider.getList('ProductDrivingSchoolAttribute', {
            filter: { isDefaultDrivingTraining: true },
            pagination: { perPage: 1, page: 1 },
            sort: { field: 'id', order: 'DESC' },
          })

          const { data: defaultProduct } = await dataProvider.getOne('Product', {
            id: defaultProductAttributes[0].productId,
          })
          const item = { ...defaultProduct, ...defaultProductAttributes[0] }
          const tempDrivingTrainings = [...allDrivingTrainings]
          const eventsForThisItem: any[] = []
          let exceedingTime = 0

          tempDrivingTrainings.map((driveTraining: any) => {
            // console.log('XXX tempDrivingTrainings ', driveTraining.duration, driveTraining)
            const formattedTraining = {
              ...defaultProduct,
              licensePlate: allVehiclesMap[driveTraining.vehicleId].licensePlate,
              teamMemberName: allTeammembersMap[driveTraining.teamMemberId].fullName,
              duration: driveTraining.duration,
              startDate: driveTraining.startDate,
              endDate: driveTraining.endDate,
              status: driveTraining.status,
            }
            eventsForThisItem.push(formattedTraining)
            exceedingTime += driveTraining.duration
            // console.log('XXX inside map exceedingTime ', exceedingTime)
            // allDrivingTrainings = allDrivingTrainings.map((grazzini: any) => grazzini.id !== driveTraining.id)
            const grazzini = allDrivingTrainings.indexOf(driveTraining)
            allDrivingTrainings.splice(grazzini, 1)
            // allDrivingTrainings.shift()
          })

          item.exceedingTime = exceedingTime
          item.eventsForThisItem = eventsForThisItem
          item.isExceedingTrainingProduct = true
          delete item.quantity
          // console.log('XXX outside pushing item: ', exceedingTime, item)
          allOrderItems.push(item)
        }
      }
      // console.log('XXX allOrderItems ', allOrderItems)
      // const drivingExam = {}
      // const theoryExam = {}
      // const examPlanningCustomer = await dataProvider.getList('ExamPlanningCustomer', {
      //   filter: { customerId: record.customerId },
      //   pagination: { page: 1, perPage: 100 },
      //   sort: { field: 'id', order: 'DESC' },
      // })
      // if (examPlanningCustomer && examPlanningCustomer.data) {
      //   examPlanningCustomer.data.forEach(async (planning: any) => {
      //     const examPlanning = await dataProvider.getOne('ExamPlanning', { id: planning.examPlanningId })
      //     console.log('XXX examPlanning ', examPlanning)
      //   })
      // }

      // console.log('XXX examplannings: ', examPlanningCustomer)
      // check if the customer has some exam to do
      const examPlanningCustomer = await dataProvider.getList('ExamPlanningCustomer', {
        filter: { customerId: record.customerId },
        sort: { field: 'id', order: 'DESC' },
        pagination: { page: 1, perPage: 100 },
      })

      if (examPlanningCustomer && examPlanningCustomer.data && examPlanningCustomer.data.length > 0) {
        // need to check if theory or driving exam or both
        let theoryExamPlanningId = 0 // inside examPlanningCustomer
        let theoryExamDate
        let theoryExamDateString = ''
        let drivingExamPlanningId = 0
        let drivingExamDate
        let drivingExamDateString = ''
        for (let index = 0; index < examPlanningCustomer.data.length; index++) {
          const examPlanning = await dataProvider.getOne('ExamPlanning', {
            id: examPlanningCustomer.data[index].examPlanningId,
          })
          // console.log('XXX examPlanning ', examPlanning)
          if (examPlanning && examPlanning.data) {
            const exam = await dataProvider.getOne('Exam', { id: examPlanning.data.examId })
            // console.log('XXX exam', exam)
            if (exam && exam.data && exam.data.productDrivingSchoolAttributeId) {
              const attribute = await dataProvider.getOne('ProductDrivingSchoolAttribute', {
                id: exam.data.productDrivingSchoolAttributeId,
              })
              // console.log('XXX attribute', attribute)
              if (attribute.data.isTheoryExam) {
                theoryExamPlanningId = examPlanningCustomer.data[index].examPlanningId
                theoryExamDate = new Date(examPlanning.data.startDate)
                theoryExamDateString = `${theoryExamDate.toLocaleDateString()} - ${theoryExamDate.toLocaleTimeString()}`
              }
              if (attribute.data.isDrivingExam) {
                drivingExamPlanningId = examPlanningCustomer.data[index].examPlanningId
                drivingExamDate = new Date(examPlanning.data.startDate)
                drivingExamDateString = `${drivingExamDate.toLocaleDateString()} - ${drivingExamDate.toLocaleTimeString()}`
              }
            }
          }
        }
        // console.log('dio impastato: ', theoryExamPlanningId, drivingExamPlanningId)
        // we need to associate exams to orderproducts or add orphaned exams
        // theory exam
        if (theoryExamPlanningId > 0) {
          const theoryResult = await dataProvider.getList('ProductDrivingSchoolAttribute', {
            filter: { isTheoryExam: true },
            sort: { field: 'id', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
          })
          // console.log('XXX theory: ', theoryResult)
          if (theoryResult && theoryResult.data && theoryResult.data.length > 0) {
            const theoryExamsProductsIds: any[] = []
            theoryResult.data.forEach((exam: any) => theoryExamsProductsIds.push(exam.productId))

            let foundIdx = -1
            for (let i = 0; i < allOrderItems.length; i++) {
              // console.log('===== ESEGUI PER ', allOrderItems[i])
              for (let j = 0; j < theoryExamsProductsIds.length; j++) {
                // console.log('===== inside second for')
                if (theoryExamsProductsIds[j] === allOrderItems[i].productId && !allOrderItems[i].forceName) {
                  console.log('helloooooo', allOrderItems[i].orderProductName)
                  foundIdx = i
                  allOrderItems[i].orderProductName = `${allOrderItems[i].orderProductName} (${theoryExamDateString})` // TODO: CHANGE ME
                  allOrderItems[i].isExam = true
                  allOrderItems[i].examName = allOrderItems[i].orderProductName
                  allOrderItems[i].examDate = theoryExamDate
                  allOrderItems[i].forceName = true
                }
              }
            }

            // console.log('FOUNDDDD?? ', foundIdx)
            if (foundIdx === -1) {
              // NEED TO ADD FAKE EXAM PRODUCT
              const fakeExamTheoryItem = {
                id: 'fakeTheory',
                isFakeExam: true,
                isExam: true,
                examName: 'Esame di teoria',
                examDate: theoryExamDate,
                orderProductName: `Esame di teoria non contabilizzato - ${theoryExamDateString}`,
                quantity: 1,
              }
              allOrderItems.push(fakeExamTheoryItem)
            }
          }
        }

        if (drivingExamPlanningId > 0) {
          const drvResult = await dataProvider.getList('ProductDrivingSchoolAttribute', {
            filter: { isDrivingExam: true },
            sort: { field: 'id', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
          })
          // console.log('XXX theory: ', drvResult)
          if (drvResult && drvResult.data && drvResult.data.length > 0) {
            const theoryExamsProductsIds: any[] = []
            drvResult.data.forEach((exam: any) => theoryExamsProductsIds.push(exam.productId))

            let foundIdx = -1
            for (let i = 0; i < allOrderItems.length; i++) {
              // console.log('===== ESEGUI PER ', allOrderItems[i])
              for (let j = 0; j < theoryExamsProductsIds.length; j++) {
                // console.log('===== inside second for')
                if (theoryExamsProductsIds[j] === allOrderItems[i].productId && !allOrderItems[i].forceName) {
                  // console.log('helloooooo', allOrderItems[i].orderProductName)
                  foundIdx = i
                  allOrderItems[i].orderProductName = `${allOrderItems[i].orderProductName} (${drivingExamDateString})` // TODO: CHANGE ME
                  allOrderItems[i].isExam = true
                  allOrderItems[i].examName = allOrderItems[i].orderProductName
                  allOrderItems[i].examDate = drivingExamDate
                  allOrderItems[i].forceName = true
                }
              }
            }
            // console.log('FOUNDDDD?? ', foundIdx)
            if (foundIdx === -1) {
              // NEED TO ADD FAKE EXAM PRODUCT
              const fakeExamTheoryItem = {
                id: 'fakeDrivingExam',
                isFakeExam: true,
                isExam: true,
                examName: 'Esame di guida',
                examDate: drivingExamDate,
                orderProductName: `Esame di guida non contabilizzato - ${drivingExamDateString}`,
                quantity: 1,
              }
              allOrderItems.push(fakeExamTheoryItem)
            }
          }
        }
      }

      // console.log('XXX ecamlplanningcustomer', examPlanningCustomer)
    } catch (err) {
      console.error('Error creating table: ', err)
      notify(err, 'error')
    } finally {
      setTotalNet(totNet)
      setTotalTaxes(totTax)
      setItems([...allOrderItems])
      setHasDrivingTrainings(hasTrainings)
      setLoading(false)
    }
  }, [record, confirmedStatuses])

  const hasExceedingRow = useMemo((): boolean => {
    if (!hasDrivingTrainings) return false
    else return items.some((item: any) => item.isExceedingTrainingProduct)
  }, [hasDrivingTrainings, items])

  useEffect(() => {
    getEverything()
  }, [])

  return loading ? (
    <LinearProgress />
  ) : (
    <TableContainer className={classes.root} component={Paper}>
      <Box my={1} px={2} justifyContent="space-between" alignItems="center" display="flex">
        <Typography variant="h6">SCHEDA CONTABILE</Typography>
        <Box>
          {!loading && <PrintOrderTransactionsButton record={record} items={items} color="primary" />}
          <Button
            variant="outlined"
            className={hasExceedingRow ? classes.editOrderButtonRed : classes.editOrderButton}
            color="primary"
            onClick={goToEditOrder}
          >
            Modifica Ordine
          </Button>
        </Box>
      </Box>
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            {hasDrivingTrainings ? <TableCell /> : null}
            <TableCell>Nome</TableCell>
            <TableCell align="right">Quantità</TableCell>
            <TableCell align="right">IVA</TableCell>
            <TableCell align="right">Prezzo Unitario</TableCell>
            <TableCell align="right">Prezzo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items &&
            items.map((item: any) => <OrderProductRow key={item.id} {...item} noCollapsible={!hasDrivingTrainings} />)}
          <TableRow>
            <TableCell rowSpan={3} colSpan={hasDrivingTrainings ? 4 : 3} />
            <TableCell>Totale netto</TableCell>
            <TableCell align="right">{numToEUR(totalNet)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Totale tasse</TableCell>
            <TableCell align="right">{numToEUR(totalTaxes)}</TableCell>
          </TableRow>
          {record.totalAmount && (
            <TableRow>
              <TableCell>Totale</TableCell>
              <TableCell align="right">{numToEUR(record.totalAmount)}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default OrderProductsTable
