import React, { FC, Children, cloneElement, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import MuiAppBar, { AppBarProps } from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Hidden from '@material-ui/core/Hidden'
import { toggleSidebar } from 'ra-core'
import { UserMenu as DefaultUserMenu, HideOnScroll, LoadingIndicator } from 'react-admin'
// import { useSubscription } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import clsx from 'clsx'

// const TRANSACTIONS_REQUEST_SUBSCRIPTION = gql`
//   subscription TransactionRequest {
//     incomingPayment {
//       id
//       rejectMessage
//     }
//   }
// `

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    avatar: {
      width: 60,
      height: 60,
    },

    // toolbar: {
    //   paddingRight: 24,
    // },
    menuButton: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '@media print': {
      root: {
        display: 'none',
      },
    },
  }),
  { name: 'RaAppBar' }
)

type Props = AppBarProps & {
  children?: React.ReactNode
  logout?: React.ReactElement
  open?: boolean
  userMenu?: React.ReactElement
}

const AppBar: FC<Props> = (props) => {
  const { children, classes: classesOverride, className, logout, open, userMenu = <DefaultUserMenu />, ...rest } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  // const { loading, data } = useSubscription(TRANSACTIONS_REQUEST_SUBSCRIPTION)

  const handleToggleSidebar = useCallback(() => {
    dispatch(toggleSidebar())
  }, [])

  return (
    <>
      <MuiAppBar elevation={0} className={clsx(classes.root, className)} color="primary" {...rest}>
        <Toolbar>
          {/* <Hidden lgUp> */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleToggleSidebar}
            className={classes.menuButton}
          >
            <MenuIcon
              classes={{
                root: open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed,
              }}
            />
          </IconButton>
          {/* </Hidden> */}
          {Children.count(children) === 0 ? (
            <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
          ) : (
            children
          )}
          {/* <LoadingIndicator /> */}
          {cloneElement(userMenu, { logout })}
        </Toolbar>
      </MuiAppBar>
      <div id="react-admin-sub-app-bar" />
    </>
  )
}

export default AppBar
