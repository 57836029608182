import React, { FC, useCallback } from 'react'
import { NumberInput, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin'
import Filter from './../../../components/Filter'

type Props = {}
const sort = { field: 'name', order: 'ASC' }

// TODO: modelYear filter not working (DB ISSUE)
// TODO: vehicleFuelId not working (DB ISSUE)

const VehicleModelFilters: FC<Props> = (props) => {
  const filterToQuery = useCallback((name: string): Record<string, string> => ({ name }), [])
  return (
    <Filter {...props}>
      <NumberInput source="id" />
      <TextInput source="name" alwaysOn />
      {/* <NumberInput source="modelYear" alwaysOn /> */}
      <ReferenceInput
        source="vehicleManufacturerId"
        reference="VehicleManufacturer"
        alwaysOn
        filterToQuery={filterToQuery}
        sort={sort}
      >
        <AutocompleteInput optionText="name" emptyText="ra.boolean.null" />
      </ReferenceInput>
      <ReferenceInput source="vehicleTypeId" reference="VehicleType" alwaysOn filterToQuery={filterToQuery} sort={sort}>
        <AutocompleteInput optionText="name" emptyText="ra.boolean.null" />
      </ReferenceInput>
      {/* <ReferenceInput source="vehicleFuelId" reference="VehicleFuel" alwaysOn {...{ filterToQuery }}>
        <AutocompleteInput optionText="name" emptyText="ra.boolean.null" />
      </ReferenceInput> */}
    </Filter>
  )
}

export default VehicleModelFilters
