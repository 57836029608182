import React, { FC } from 'react'
import { TextField } from 'react-admin'
import SectionedForm from '../../../components/forms/SectionedForm'
import FormSection from '../../../components/forms/FormSection'
import Edit from '../../../components/Edit'
import VehicleModelForm from './VehicleModelForm'

type Props = {}

const VehicleModelEdit: FC<Props> = props => {
  return (
    <Edit {...props} component="div">
      <SectionedForm variant="outlined" redirect="list">
        <FormSection title="ID">
          <TextField source="id" addLabel={false} />
        </FormSection>
        <VehicleModelForm />
      </SectionedForm>
    </Edit>
  )
}

export default VehicleModelEdit
