import React, { FC, useCallback } from 'react'
import { NumberInput, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin'
import Filter from './../../components/Filter'

type Props = {}

const VehicleFilters: FC<Props> = (props) => {
  const filterToQuery = useCallback((name: string): Record<string, string> => ({ name }), [])
  return (
    <Filter {...props}>
      <NumberInput source="id" />
      <TextInput source="licensePlate" alwaysOn />
      <NumberInput source="numberOfSeats" alwaysOn />
      <ReferenceInput source="vehicleModelId" reference="VehicleModel" alwaysOn {...{ filterToQuery }}>
        <AutocompleteInput optionText="name" emptyText="ra.boolean.null" />
      </ReferenceInput>
      <ReferenceInput source="vehicleFuelId" reference="VehicleFuel" alwaysOn {...{ filterToQuery }}>
        <AutocompleteInput optionText="name" emptyText="ra.boolean.null" />
      </ReferenceInput>
    </Filter>
  )
}

export default VehicleFilters
