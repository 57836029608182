import VehicleList from './VehicleList'
import VehicleCreate from './VehicleCreate'
import VehicleEdit from './VehicleEdit'

export default {
  name: 'Vehicle',
  list: VehicleList,
  create: VehicleCreate,
  edit: VehicleEdit
}
