import React, { FC } from 'react'
import AvatarField from './AvatarField'
import ReferencePictureAvatarField from './ReferencePictureAvatarField'

type Props = {
  record?: any
  source: string
  avatarSize?: number
  imageSize?: 'Small' | 'Medium' | 'Large'
  className?: string
  badgeColor?: string
  [x: string]: any
}

const renderLetters = (record: any): string => {
  if (!record) {
    return ''
  }

  if (record.firstName && !record.lastName) {
    return record.firstName.substring(0, 2).toUpperCase()
  }
  if (!record.firstName && record.lastName) {
    return record.lastName.substring(0, 2).toUpperCase()
  }

  if (record.firstName && record.lastName) {
    return `${record.firstName.toUpperCase()[0]}${record.lastName.toUpperCase()[0]}`
  }

  return ''
}

const CustomerAvatarField: FC<Props> = (props) => {
  if (!props.record) {
    return <AvatarField className={props.className} record={{}} badgeColor={props.badgeColor} />
  }

  if (props.record && props.record.profilePictureId) {
    return <ReferencePictureAvatarField {...props} />
  }

  return (
    <AvatarField
      record={props.record}
      source={props.source}
      renderLetters={renderLetters}
      size={props.avatarSize}
      className={props.className}
      badgeColor={props.badgeColor}
    />
  )
}

export default CustomerAvatarField
