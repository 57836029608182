import React, { FC, useEffect } from 'react'
import { useMutation } from 'react-admin'
import { CircularProgress, IconButton, makeStyles } from '@material-ui/core'
import { Add, Error } from '@material-ui/icons'

const HourCreator: FC<Record<string, any>> = ({ officeId, dayNumber, confirmAction, disabled }) => {
  const classes = useStyles()
  const [onClick, { loading, loaded, error, data }] = useMutation({
    type: 'create',
    resource: 'OpeningHour',
    payload: {
      data: {
        officeId,
        dayNumber,
        openingTime: '09:00',
        closingTime: '18:00',
        open: true,
      },
    },
  })

  useEffect(() => {
    if (loaded && data) {
      data.openingTime = data.openingTime.slice(0, -3)
      data.closingTime = data.closingTime.slice(0, -3)
      confirmAction(data)
    }
  }, [loaded])

  return loading ? (
    <CircularProgress />
  ) : (
    <IconButton {...{ onClick, disabled }} className={classes.button}>
      {error ? <Error /> : <Add />}
    </IconButton>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(2),
    alignSelf: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 6,
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}))

export default HourCreator
