import React, { FC, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import IOSSwitch from './../IOSSwitch'
import { makeStyles } from '@material-ui/core/styles'
import { useFormState, useForm } from 'react-final-form'

type Props = {
  [x: string]: any
}

const FormSwitchInput: FC<Props> = (props) => {
  const classes = useStyles()
  const { values } = useFormState()
  const form = useForm()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    form.change(props.source, checked)
  }

  useEffect(
    () =>
      form.registerField(
        props.source,
        (fieldState: any) => {
          const { blur, change, focus, ...rest } = fieldState
        },
        { active: false, dirty: true, touched: true, valid: false, value: true }
      ),
    []
  )

  // useEffect(() => {
  //   setTimeout(() => form.change(props.source, props.startingValue), 500)
  // }, [])

  // return formState && formState.values && formState.values[props.source] !== undefined ? (
  return (
    <div className={classes.switchWrapper}>
      <IOSSwitch {...props} onChange={handleChange} checked={values[props.source]} />
      <Typography variant="body2" className={classes.switchHelperText}>
        {props.helperText}
      </Typography>
    </div>
  )
  // ) : null
}

const useStyles = makeStyles((theme) => ({
  switchWrapper: {
    display: 'flex',
    width: 'auto',
    alignItems: 'center',
  },
  switchHelperText: {
    marginLeft: theme.spacing(8),
  },
}))

export default FormSwitchInput
