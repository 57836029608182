import React, { FC, useCallback, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import CardActions from '@material-ui/core/CardActions'
import Box from '@material-ui/core/Box'
import { LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CustomerAvatarField from './CustomerAvatarField'
import UploadPictureButton from './UploadPictureButton'
import { useNotify, useRefresh, useDataProvider } from 'ra-core'
import useOrganization from './../hooks/useOrganization'

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    avatar: {
      marginBottom: theme.spacing(5),
    },
    title: {
      marginTop: theme.spacing(2),
    },
  }
})

type Props = {
  resource: 'Customer' | 'TeamMember'
  record?: any
  [x: string]: any
}

const UserPicturePreviewCard: FC<Props> = (props) => {
  const classes = useStyles(props)
  const { record, permissionsMap } = props
  const [loading, setLoading] = useState<boolean>(false)
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const { loading: loadingOrganization, error, organizationId } = useOrganization()

  const onPictureUploaded = useCallback(
    (data: any) => {
      // console.log(data, record, { profilePictureId: data.id }, 'sono io')
      setLoading(true)
      dataProvider.update(
        props.resource,
        {
          id: record.id,
          data: { profilePictureId: data.id, organizationId },
          previousData: { ...record },
        },
        {
          onSuccess: ({ data }: any) => {
            setLoading(false)
            refresh()
          },
          onFailure: (error: any) => {
            setLoading(false)
            notify(error.message, 'error')
          },
        }
      )
    },
    [dataProvider, record, notify, organizationId, loadingOrganization]
  )

  return (
    <Card>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <CustomerAvatarField avatarSize={100} record={record} source="profilePictureId" />
          <Typography className={classes.title} color="textPrimary" variant="h3">
            {record.fullName}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      {permissionsMap.createPicture && (
        <CardActions>
          {loadingOrganization ? (
            <LinearProgress />
          ) : (
            <UploadPictureButton
              color="primary"
              fullWidth
              variant="text"
              onChange={onPictureUploaded}
              disabled={loading}
            />
          )}
        </CardActions>
      )}
    </Card>
  )
}

export default UserPicturePreviewCard
