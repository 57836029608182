import React, { FC } from 'react'
import {
  AccountBalance as AccountBalanceIcon,
  MonetizationOn as MonetizationOnIcon,
  CreditCard as CreditCardIcon,
} from '@material-ui/icons'
import { makeStyles, Tooltip } from '@material-ui/core'

type Props = {
  paymentMethod?: 'CASH' | 'TRANSFER' | 'POS'
}

const useStyles = makeStyles({
  icon: {
    display: 'inline',
    marginRight: 8,
  },
})

const PaymentIcon: FC<Props> = ({ paymentMethod }: Props) => {
  const classes = useStyles()
  switch (paymentMethod) {
    case 'POS':
      return (
        <Tooltip title="POS" placement="top">
          <CreditCardIcon className={classes.icon} />
        </Tooltip>
      )
    case 'CASH':
      return (
        <Tooltip title="CASH" placement="top">
          <MonetizationOnIcon className={classes.icon} />
        </Tooltip>
      )
    case 'TRANSFER':
      return (
        <Tooltip title="TRANSFER" placement="top">
          <AccountBalanceIcon className={classes.icon} />
        </Tooltip>
      )

    default:
      return null
  }
}

export default PaymentIcon
