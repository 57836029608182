import React, { FC, useState, useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import PriceInput from './PriceInput'
import { SelectInput, ReferenceInput } from 'react-admin'
import { useTranslate, useDataProvider } from 'ra-core'
import { useForm, useFormState } from 'react-final-form'

type Props = {}

const applyTaxes = (price: any, taxes: any): number => {
  const p = parseFloat(price)
  const t = parseFloat(taxes)

  return p + (p / 100) * t
}

const removeTaxes = (price: any, taxes: any): number => {
  const p = parseFloat(price)
  const t = parseFloat(taxes)

  return (100 * p) / (100 + t)
}

const ProductPriceInput: FC<Props> = (props) => {
  const translate = useTranslate()
  const form = useForm()
  const { values: formData } = useFormState()
  const dataProvider = useDataProvider()
  const [loading, setLoading] = useState(false)
  const [taxRate, setTaxRate] = useState(0)

  useEffect(() => {
    const fetchTaxRate = async (): Promise<void> => {
      try {
        setLoading(true)
        const result = await dataProvider.getOne('Tax', { id: formData.taxId })
        if (result && result.data && result.data.rate) {
          setTaxRate(result.data.rate)
          if (formData.netPrice) {
            form.change('retailPrice', applyTaxes(formData.netPrice, result.data.rate))
          }
        } else {
          setTaxRate(0)
        }
      } catch (e) {
        console.error(e)
        setTaxRate(0)
      } finally {
        setLoading(false)
      }
    }

    if (formData.taxId) {
      fetchTaxRate()
    }
  }, [formData.taxId])

  return (
    <Box pt="1rem">
      <Typography variant="h5">{translate('customFields.priceSection.title')}</Typography>
      <Box display="flex" pb="1.5rem">
        <Box flex={1}>
          <PriceInput
            onChange={
              !loading
                ? (value): void => {
                    const newRetailPrice = applyTaxes(value, taxRate)
                    if (parseFloat(formData.retailPrice) !== newRetailPrice) {
                      form.change('retailPrice', newRetailPrice)
                    }
                  }
                : undefined
            }
            disabled={loading}
            resource="Product"
            source="netPrice"
            decimalScale={5}
            variant="outlined"
          />
        </Box>
        <Box flex={1}>
          <PriceInput
            onChange={
              !loading
                ? (value): void => {
                    const newNetPrice = removeTaxes(value, taxRate)
                    if (parseFloat(formData.netPrice) !== newNetPrice) {
                      form.change('netPrice', newNetPrice)
                    }
                  }
                : undefined
            }
            disabled={loading}
            resource="Product"
            source="retailPrice"
            variant="outlined"
          />
        </Box>
      </Box>
      <ReferenceInput label="resources.Product.fields.tax" reference="Tax" source="taxId">
        <SelectInput variant="outlined" fullWidth optionText="name" optionValue="id" />
      </ReferenceInput>
    </Box>
  )
}

export default ProductPriceInput
