import React, { FC, useCallback, useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { useNotify, useRedirect, useTranslate, useDataProvider } from 'ra-core'
import {
  Dialog,
  DialogTitle,
  Button,
  CircularProgress,
  Paper,
  Typography,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core'
import { useApolloClient } from '@apollo/client'
import { MUTATION_SEND_EVENT_INVITE, QUERY_GET_EVENT_STATUSES_CONFIGURATION } from '../queries'
import { useHistory, useLocation } from 'react-router-dom'

const QUERY_GET_TEMPLATE_CONFIGURATION = gql`
  query GetInviteTemplatesConfiguration {
    getConfiguration(key: "INVITE_TEMPLATES") {
      id
      key
      value
    }
  }
`

const QUERY_GET_TELEGRAM_INVITE_SMS_TEMPLATE_ID = gql`
  query GetSMSInviteTemplateForTG {
    getConfiguration(key: "TELEGRAM_INVITE_SMS_TEMPLATE_ID") {
      id
      key
      value
    }
  }
`

const MUTATION_SEND_TELEGRAM_INVITE_SMS = gql`
  mutation SendTelegramInviteSMS($data: SmsInputData!) {
    sendSms(data: $data)
  }
`

type Props = {
  record?: any
  [x: string]: any
}

const SendEventInviteButton: FC<Props> = (props) => {
  const { loading, data: templatesConfiguration } = useQuery(QUERY_GET_TEMPLATE_CONFIGURATION)
  const { loading: configurationLoading, data: eventStatusConfiguration } = useQuery(
    QUERY_GET_EVENT_STATUSES_CONFIGURATION
  )

  const [contactsLoaded, setContactsLoaded] = useState<boolean>(false)
  const [contactsLoading, setContactsLoading] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false)
  const [contactStatus, setContactStatus] = useState<number>(-1)
  const [smsNumber, setSmsNumber] = useState<string>('')
  const [smsSending, setSmsSending] = useState<boolean>(false)
  const { search } = useLocation()
  const translate = useTranslate()
  const client = useApolloClient()
  const notify = useNotify()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()

  // console.log('SENDEVENT props', props)

  const handleInviteClick = useCallback(async () => {
    // console.log(props, templatesConfiguration, eventStatusConfiguration, 'ciaociao')
    // return
    try {
      setContactsLoading(true)
      // setSubmitting(true)
      // props.record.customerIds è un array
      const { data: customerContacts } = await dataProvider.getList('CustomerContact', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
        filter: { customerId: props.record.customerIds[0] },
      })

      // 4 different cases
      // 0 = no sms/telegram contacts at all
      // 1 = only sms
      // 2 = telegram with disabled notifications
      // 3 = telegram with enabled notifications
      let status = 0
      if (customerContacts.length > 0) {
        const tgIndex = customerContacts.findIndex((contact: any) => contact.contactType === 'telegram')
        if (tgIndex > -1) {
          if (customerContacts[tgIndex].notificationEnabled) status = 3
          else status = 2
        } else {
          const smsIndex = customerContacts.findIndex((contact: any) => contact.contactType === 'sms')
          if (smsIndex > -1) {
            status = 1
            setSmsNumber(customerContacts[smsIndex].contact)
          }
        }
      }

      console.log('customerContacts: ', customerContacts)
      console.log('STATUUS?? ', status)

      setContactStatus(status)
      setContactsLoading(false)
      setContactsLoaded(true)
      // if (status < 3) {
      //   // deve aprirsi sempre la dialog
      //   setIsDialogOpened(true)
      // } else {
      //   sendEventInvite()
      //   // const { data: result } = await client.mutate({
      //   //   mutation: MUTATION_SEND_EVENT_INVITE,
      //   //   variables: {
      //   //     data: {
      //   //       plannedEventId: props.record.id,
      //   //       telegramTemplateId: templatesConfiguration.getConfiguration.value.telegram,
      //   //       smsTemplateId: templatesConfiguration.getConfiguration.value.sms,
      //   //       emailTemplateId: templatesConfiguration.getConfiguration.value.email,
      //   //       waitingStatusId: eventStatusConfiguration.getConfiguration.value.waitingConfirmation,
      //   //       confirmedStatusId: eventStatusConfiguration.getConfiguration.value.confirmed,
      //   //       rejectedStatusId: eventStatusConfiguration.getConfiguration.value.rejected,
      //   //     },
      //   //   },
      //   // })
      //   // console.log('DOPO INVIO, ECCOMI')
      //   // if (result && result.sendEventInvite && result.sendEventInvite.length > 0) {
      //   //   const allSuccess = result.sendEventInvite.every((invite: any) => invite.success)
      //   //   if (allSuccess) notify('resources.PlannedEvent.invites.success')
      //   //   else notify('resources.PlannedEvent.invites.failure')
      //   // } else throw new Error()
      //   // redirect(`/PlannedEvent${search}`)
      // }
    } catch (e) {
      console.log('ERROR: ', e)
      notify('resources.PlannedEvent.invites.error')
    } finally {
      setSubmitting(false)
    }
  }, [templatesConfiguration, eventStatusConfiguration, props.record])

  const sendEventInvite = async (): Promise<void> => {
    try {
      const { data: result } = await client.mutate({
        mutation: MUTATION_SEND_EVENT_INVITE,
        variables: {
          data: {
            plannedEventId: props.record.id,
            telegramTemplateId: templatesConfiguration.getConfiguration.value.telegram,
            smsTemplateId: templatesConfiguration.getConfiguration.value.sms,
            emailTemplateId: templatesConfiguration.getConfiguration.value.email,
            waitingStatusId: eventStatusConfiguration.getConfiguration.value.waitingConfirmation[0],
            confirmedStatusId: eventStatusConfiguration.getConfiguration.value.confirmed[0],
            rejectedStatusId: eventStatusConfiguration.getConfiguration.value.rejected[0],
          },
        },
      })
      console.log('DOPO INVIO, ECCOMI')
      if (result && result.sendEventInvite && result.sendEventInvite.length > 0) {
        const allSuccess = result.sendEventInvite.every((invite: any) => invite.success)
        if (allSuccess) notify('resources.PlannedEvent.invites.success')
        else notify('resources.PlannedEvent.invites.failure')
      } else throw new Error()
      redirect(`/PlannedEvent${search}`)
    } catch (error) {
      console.log('ERROR: ', error)
      notify('resources.PlannedEvent.invites.error')
    }
  }

  const onDialogClose = useCallback(() => setIsDialogOpened(false), [])

  const goToCustomer = useCallback(() => redirect(`/Customer/${props.record.customerIds[0]}/show`), [])

  const renderDialogContent = () => {
    switch (contactStatus) {
      case 0:
        return (
          <Card>
            <CardContent>
              <Typography variant="body1">
                Il cliente non ha alcun contatto associato, aggiungi numero di telefono e contatto telegram!
              </Typography>
            </CardContent>
            <CardActions>
              <Button fullWidth onClick={goToCustomer} color="primary">
                VAI AL DETTAGLIO CLIENTE
              </Button>
            </CardActions>
          </Card>
        )
      case 1:
        return (
          <Card>
            <CardContent>
              <Typography variant="body1">
                Il cliente non ha un contatto telegram collegato. Vuoi inviargli un sms per invitarlo ad utilizzare il
                bot telegram?
              </Typography>
            </CardContent>
            <CardActions>
              {smsSending ? (
                <Button disabled>INVO IN CORSO..</Button>
              ) : (
                <>
                  <Button color="primary" onClick={sendSMSInvite}>
                    SI
                  </Button>
                  <Button color="primary" onClick={onDialogClose}>
                    NO
                  </Button>
                </>
              )}
            </CardActions>
          </Card>
        )
      case 2:
        return (
          <Card>
            <CardContent>
              <Typography variant="body1">
                Le notifiche non sono abilitate per il contatto Telegram del cliente. Modifica le impostazioni dalla sua
                pagina di dettaglio!
              </Typography>
            </CardContent>
            <CardActions>
              <Button fullWidth onClick={goToCustomer} color="primary">
                VAI AL DETTAGLIO CLIENTE
              </Button>
            </CardActions>
          </Card>
        )
      default:
        return null
    }
  }

  const sendSMSInvite = async (): Promise<any> => {
    try {
      setSmsSending(true)
      const { data: template } = await client.query({
        query: QUERY_GET_TELEGRAM_INVITE_SMS_TEMPLATE_ID,
      })
      // console.log('TEMPLAAATE? ', template)
      if (template && template.getConfiguration && template.getConfiguration.value) {
        const { data: sendSms } = await client.mutate({
          mutation: MUTATION_SEND_TELEGRAM_INVITE_SMS,
          variables: {
            data: {
              templateId: template.getConfiguration.value,
              to: smsNumber,
            },
          },
        })
        // console.log('sms result? ', sendSms)
        if (sendSms) {
          notify('SMS di invito andato in coda con successo')
        } else throw new Error('Errore nell\'invio dell\'SMS')
      } else throw new Error('Template SMS non trovato')
    } catch (err) {
      console.log('ERROR: ', err)
      notify(err.message)
    } finally {
      setSmsSending(false)
      setIsDialogOpened(false)
    }
  }

  const disableButton = useCallback(() => {
    if (
      props &&
      props.plannedEvent &&
      props.plannedEvent.plannedEvent &&
      props.plannedEvent.plannedEvent.customerEvents &&
      props.plannedEvent.plannedEvent.customerEvents.length > 0
    )
      return (
        props.plannedEvent.plannedEvent.customerEvents[0].status.id !==
        eventStatusConfiguration.getConfiguration.value.created[0]
      )
    else return true
  }, [props, eventStatusConfiguration])

  useEffect(() => {
    if (!contactsLoading && contactsLoaded) {
      if (contactStatus === 3) {
        // console.log('abc: MANDA STO INVITO OH')
        sendEventInvite()
      }
    } else {
      if (contactStatus !== -1) {
        // console.log('abc: dialog', contactStatus)
        setIsDialogOpened(true)
      }
    }
  }, [contactStatus, contactsLoading, contactsLoaded])

  return (
    <>
      <Dialog open={isDialogOpened} onClose={onDialogClose}>
        <DialogTitle>ATTENZIONE</DialogTitle>
        <Paper>{renderDialogContent()}</Paper>
      </Dialog>
      <Button
        onClick={handleInviteClick}
        variant="contained"
        color="secondary"
        disabled={loading || submitting || disableButton()}
      >
        {loading || submitting ? <CircularProgress size={24} /> : <>{translate('SendInviteButton.label')}</>}
      </Button>
    </>
  )
  // return <pre>{JSON.stringify(templatesConfiguration, null, 2)}</pre>
}

export default SendEventInviteButton
