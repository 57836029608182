import React, { FC } from 'react'
import RAList from './../../components/List'
import { TextField, NumberField, Datagrid, ReferenceField } from 'react-admin'
import EditButtonWPermission from '../../components/EditButtonWPermission'
import { ColorField } from 'react-admin-color-input'
import CustomerAvatarField from '../../components/CustomerAvatarField'
import CustomerFilters from '../Customer/CustomerFilters'

type Props = {}

const TeamMemberList: FC<Props> = (props) => (
  <RAList {...props} filters={<CustomerFilters />}>
    <Datagrid rowClick="show">
      <NumberField source="id" />
      <CustomerAvatarField source="profilePictureId" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="phone" />
      <ReferenceField link={false} source="officeId" reference="Office">
        <TextField source="name" />
      </ReferenceField>
      <ColorField source="calendarColor" />
      <EditButtonWPermission {...props} />
    </Datagrid>
  </RAList>
)

export default TeamMemberList
