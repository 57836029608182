const cartesian = (...inputs: any[]): any[] => {
  const r: any[] = []
  const arg = inputs
  const max = arg.length - 1

  const helper = (arr: any[], i: number): any => {
    for (let j = 0, l = arg[i].length; j < l; j++) {
      const a = arr.slice(0) // clone arr
      a.push(arg[i][j])
      if (i === max) r.push(a)
      else helper(a, i + 1)
    }
  }

  helper([], 0)

  return r
}

export default cartesian
