import React, { FC, useCallback } from 'react'
import { NumberInput, ReferenceInput, AutocompleteInput } from 'react-admin'
import { useForm } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslate } from 'ra-core'

import ProductNetPrice from './ProductNetPrice'
import TaxRateOverride from './TaxRateOverride'
import ProductImageName from '../Order/ProductImageName'

const filterToQuery = (name: string): Record<string, string> => ({ name })

const useStyles = makeStyles({
  root: {
    maxWidth: 128,
    marginLeft: 16,
  },
})

const CreateQuoteItem: FC<any> = ({ formData, scopedFormData, getSource, ...rest }) => {
  const dataForm = useForm()
  const { root } = useStyles()
  const translate = useTranslate()

  const changePrice = useCallback(
    (field: string, value: number): void => dataForm.change(`${field}.productPriceOverride`, value),
    []
  )

  const changeTax = useCallback(
    (field: string, value: number): void => dataForm.change(`${field}.taxRateOverride`, value),
    []
  )

  return (
    <div style={{ display: 'flex' }}>
      <ReferenceInput
        {...rest}
        label={translate('resources.Order.fields.product')}
        filterToQuery={filterToQuery}
        reference="Product"
        source={getSource('productId')}
        helperText={false}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      {scopedFormData && scopedFormData.productId && (
        <NumberInput
          {...rest}
          source={getSource('quantity')}
          initialValue={1}
          classes={{ root }}
          label={translate('resources.Order.fields.quantity')}
          helperText={false}
        />
      )}
      {scopedFormData && scopedFormData.productId && (
        <ProductNetPrice
          {...rest}
          {...{ formData, scopedFormData, getSource, changePrice }}
          label={translate('resources.Order.fields.price')}
          helperText={false}
        />
      )}
      {scopedFormData && scopedFormData.productId && (
        <TaxRateOverride
          {...rest}
          {...{ formData, scopedFormData, getSource, changeTax }}
          label={translate('resources.Order.fields.tax')}
          helperText={false}
        />
      )}
      {scopedFormData && scopedFormData.productId && (
        <ProductImageName orderProductId={scopedFormData.productId} isInIterator hideName />
      )}
    </div>
  )
}

export default CreateQuoteItem
