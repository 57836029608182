import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import { ReferenceField, TextField } from 'react-admin'

const CustomerSection: FC<any> = ({ record }) => {
  const classes = useStyles()
  return (
    <Card className={classes.container}>
      <div className={classes.cardHeader}>
        <Typography variant="h4">{'Cliente'}</Typography>
      </div>
      <Typography variant="body2">Nome:</Typography>
      <ReferenceField
        basePath="/Customer"
        resource="Customer"
        record={record}
        reference="Customer"
        source="customerId"
        link="show"
      >
        <TextField source="fullName" variant="h6" />
      </ReferenceField>
      <div className={classes.field}>
        <Typography variant="body2">Codice Fiscale:</Typography>
        <ReferenceField
          basePath="/Customer"
          resource="Customer"
          record={record}
          reference="Customer"
          source="customerId"
          link={false}
        >
          <TextField source="fiscalCode" variant="body2" emptyText="N.D." />
        </ReferenceField>
      </div>
      <div className={classes.field}>
        <Typography variant="body2">Email:</Typography>
        <ReferenceField
          basePath="/Customer"
          resource="Customer"
          record={record}
          reference="Customer"
          source="customerId"
          link={false}
        >
          <TextField source="email" variant="body2" emptyText="N.D." />
        </ReferenceField>
      </div>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardFooter: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  field: {
    marginTop: theme.spacing(1),
  },
}))

export default CustomerSection
