import React, { FC } from 'react'
// import RAList from '../../components/list/List'
import { TextField, NumberField, Filter, CreateButton } from 'react-admin'

import Datagrid from '../../components/Datagrid'
// import CreateButton from '../../components/button/CreateButton'
import { FaStore } from 'react-icons/fa'

import GenericMoreMenu from '../../components/GenericMoreMenu'
import FilterTextInput from '../../components/FilterTextInput'
import BooleanStatusField from '../../components/BooleanStatusField'
import List from '../../components/List'

type Props = {}

const UserFilters: FC<any> = (props) => {
  return (
    <Filter {...props}>
      <FilterTextInput label="Cerca" source="q" alwaysOn />
    </Filter>
  )
}

const UserList: FC<Props> = (props) => {
  return (
    <List
      {...props}
      title="Account"
      sort={{ field: 'lastName', order: 'ASC' }}
      // titleIcon={<FaStore />}
      filters={<UserFilters variant="outlined" />}
    >
      <Datagrid rowClick="show">
        {/* <TextField source="id" sortable={false} /> */}
        <TextField source="fullName" />
        <TextField source="email" />
        <TextField source="phone" />
        <BooleanStatusField
          // label="Attivo"
          source="active"
          falseValue="Disabilitato"
          trueValue="Abilitato"
          trueColor="success"
          falseColor="grey"
        />
        <BooleanStatusField
          label="Eliminazione Bloccata"
          source="isLocked"
          falseValue="Aperto"
          trueValue="Bloccato"
          trueColor="warning"
          falseColor="success"
        />
        <GenericMoreMenu />
      </Datagrid>
    </List>
  )
}

export default UserList
