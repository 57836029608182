import React, { FC } from 'react'
import { ReferenceManyField, TextField, SimpleList, Pagination } from 'react-admin'
import { Card, CardContent, Box, Typography, Divider, makeStyles } from '@material-ui/core'
import CustomerAvatarField from './CustomerAvatarField'
import { AiOutlineUser } from 'react-icons/ai'

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    list: {
      minHeight: 400,
    },
    title: {},
    icon: {
      marginRight: theme.spacing(1),
    },
  }
})

type Props = {
  record?: any
  [x: string]: any
}

const TeamMemberCustomersCard: FC<Props> = ({ record, ...rest }) => {
  const classes = useStyles()
  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <AiOutlineUser size="1.2rem" className={classes.icon} />
          <Typography variant="h4" className={classes.title}>
            Studenti associati
          </Typography>
        </Box>
        <Divider />
        <ReferenceManyField
          {...rest}
          pagination={<Pagination />}
          record={record}
          reference="Customer"
          target="mainReferenceTeamMemberId"
          link={false}
        >
          <SimpleList
            linkType="show"
            className={classes.list}
            primaryText={(record: any): any => <TextField source="fullName" record={record} />}
            leftAvatar={(record: any): any => <CustomerAvatarField source="profilePictureId" record={record} />}
          />
        </ReferenceManyField>
      </CardContent>
    </Card>
  )
}

export default TeamMemberCustomersCard
