import React, { FC, useState, useCallback, useEffect } from 'react'
import { useDataProvider, useVersion } from 'ra-core'
import CreateNoteForm from './CreateNoteForm'
import Button from '@material-ui/core/Button'
import AccountingCardNote from './AccountingCardNote'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { makeStyles } from '@material-ui/core/styles'

type Props = {
  accountingCardId: number
}

const perPage = 5

const AccountingCardNotesSection: FC<Props> = ({ accountingCardId }: Props) => {
  const classes = useStyles()
  const [totalNotes, setTotalNotes] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)
  const [results, setResults] = useState<any[]>([])
  const dataProvider = useDataProvider()
  const version = useVersion()

  const onMoreClick = (): void => setPage((prevPage) => prevPage + 1)

  const getNotes = useCallback(async (): Promise<void> => {
    try {
      setLoading(true)
      const { data, total } = await dataProvider.getList('AccountingCardNote', {
        pagination: { page, perPage },
        filter: { accountingCardId },
        sort: { field: 'createdAt', order: 'DESC' },
      })
      setResults((prevResults) =>
        [...prevResults, ...data].filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)
      )
      setTotalNotes(total)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [page, accountingCardId, dataProvider])

  useEffect(() => {
    getNotes()
  }, [page, version])

  useEffect(() => setResults([]), [version])

  return loading ? null : (
    <div>
      <CreateNoteForm accountingCardId={accountingCardId} total={totalNotes} />
      {results.map((elem) => (
        <AccountingCardNote record={elem} />
      ))}
      {!loading && totalNotes > page * perPage && (
        <div className={classes.div}>
          <Button
            color="primary"
            onClick={onMoreClick}
            variant="contained"
            disabled={loading}
            size="small"
            startIcon={<ArrowDropDownIcon />}
            className={classes.button}
          >
            Carica altre note
          </Button>
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  div: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default AccountingCardNotesSection
