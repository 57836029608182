import React, { FC, useCallback, useEffect, useState } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { CircularProgress } from '@material-ui/core'
import { useDataProvider, useMutation } from 'react-admin'
import HourUpdater from './HourUpdater'
import HourDeleter from './HourDeleter'
import HourCreator from './HourCreator'
import { useFormState } from 'react-final-form'
import { useTranslate } from 'ra-core'

const defaultDays = {
  MONDAY: false,
  TUESDAY: false,
  WEDNESDAY: false,
  THURSDAY: false,
  FRIDAY: false,
  SATURDAY: false,
  SUNDAY: false,
}

const defaultTimes = {
  MONDAY: [],
  TUESDAY: [],
  WEDNESDAY: [],
  THURSDAY: [],
  FRIDAY: [],
  SATURDAY: [],
  SUNDAY: [],
}

const processTimes = (data: any): Record<string, any> => {
  const openedDays: Record<string, any> = { ...defaultDays }
  const openedTimes: Record<string, any> = { ...defaultTimes }
  data.map((row: any) => {
    // if (row.open) {
    openedDays[row.dayNumber] = true
    openedTimes[row.dayNumber] = [
      ...openedTimes[row.dayNumber],
      {
        id: row.id,
        startTime: row.startTime.slice(0, -3),
        finishTime: row.finishTime.slice(0, -3),
        isOpeningInvalid: false,
        isClosingInvalid: false,
      },
    ]
    // }
  })
  return { openedDays, openedTimes }
}

const styles = {
  dayGrid: { paddingTop: 36 },
  timeGrid: { paddingTop: 24 },
}
const InputLabelProps = { shrink: true }

type Props = {
  [x: string]: any
}

const CourseScheduleHoursForm: FC<Props> = ({ reference, sources, ...rest }) => {
  const [isReady, setIsReady] = useState<boolean>(false)
  const [days, setDays] = useState<Record<string, any>>({ ...defaultDays })
  const [times, setTimes] = useState<Record<string, any>>({ ...defaultTimes })
  const [dayToDelete, setDayToDelete] = useState<string>('')
  const [dayToCreate, setDayToCreate] = useState<string>('')
  const { values: formData } = useFormState()

  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const [deleteMutation, { loading: deleteLoading, loaded: deleteLoaded, error: deleteError }] = useMutation()
  const [
    createMutation,
    { loading: createLoading, loaded: createLoaded, error: createError, data: createData },
  ] = useMutation()

  useEffect(() => {
    async function getHours(): Promise<void> {
      try {
        const { data } = await dataProvider.getList('CourseSchedule', {
          filter: { coursePlanningId: formData.id },
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'dayNumber', order: 'ASC' },
        })
        const { openedDays, openedTimes } = processTimes(data)
        setDays(openedDays)
        setTimes(openedTimes)
        setIsReady(true)
      } catch (err) {
        console.log('GETHOURS ERR: ', err)
      }
    }
    getHours()
  }, [])

  useEffect(() => {
    if (deleteLoaded && !deleteLoading && !deleteError) {
      // console.log('inside useffect deleteloaded')
      setDays({ ...days, [dayToDelete]: false })
      setTimes({ ...times, [dayToDelete]: [] })
      setDayToDelete('')
    }
  }, [deleteLoaded, deleteLoading, deleteError])

  useEffect(() => {
    if (createLoaded && !createLoading && !createError) {
      // console.log('inside useffect createloaded')
      const timeToPush = {
        id: createData.id,
        startTime: createData.startTime.slice(0, -3),
        finishTime: createData.finishTime.slice(0, -3),
        isOpeningInvalid: false,
        isClosingInvalid: false,
        saveOpening: false,
        saveClosing: false,
      }
      setDays({ ...days, [dayToCreate]: true })
      setTimes({ ...times, [dayToCreate]: [timeToPush] })
      setDayToCreate('')
    }
  }, [createLoaded, createLoading, createError])

  const deleteMany = useCallback(
    (ids: any[]): void =>
      deleteMutation({
        type: 'deleteMany',
        resource: 'CourseSchedule',
        payload: { ids },
      }),
    [deleteMutation]
  )

  const createDay = useCallback(
    (dayNumber: string, coursePlanningId: string): void =>
      createMutation({
        type: 'create',
        resource: 'CourseSchedule',
        payload: {
          data: {
            coursePlanningId,
            teamMemberId: formData.teamMemberId,
            dayNumber,
            startTime: '09:00',
            finishTime: '18:00',
          },
        },
      }),
    [createMutation, formData]
  )

  const handleDayChange = (coursePlanningId: string) => (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      // console.log('HANDLE SINGLE CREATION HERE')
      setDayToCreate(event.target.name)
      createDay(event.target.name, coursePlanningId)
    } else {
      const idsToDelete = times[event.target.name].map((time: any) => time.id)
      // console.log('idsToDelete: ', idsToDelete)
      setDayToDelete(event.target.name)
      deleteMany(idsToDelete)
    }
  }

  const handleAddClick = (time: Record<string, any>): void => {
    // console.log('inside handleAdd: ', time)
    const day = time.dayNumber
    const dayToChange = times[day]
    dayToChange.push({ ...time })
    setTimes({ ...times, [day]: dayToChange })
  }

  const handleRemoveClick = (day: string, index: number) => (): void => {
    const dayToChange = times[day]
    dayToChange.splice(index, 1)
    setTimes({ ...times, [day]: dayToChange })
    if (dayToChange.length === 0) setDays({ ...days, [day]: false })
  }

  const handleTimeChange = (day: string, index: number) => (event: React.ChangeEvent<HTMLInputElement>): void => {
    const dayToChange = times[day]
    dayToChange[index][event.target.name] = event.target.value
    setTimes({ ...times, [day]: dayToChange })
  }

  const validatestartTime = (day: string, index: number) => (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    let isInvalid = false
    const timeToChange = times[day]
    if (event.target.value === '') isInvalid = true
    if (times[day][index].finishTime !== '' && event.target.value >= times[day][index].finishTime) isInvalid = true
    timeToChange[index].isOpeningInvalid = isInvalid
    timeToChange[index].saveOpening = !isInvalid
    if (!isInvalid && timeToChange[index].isClosingInvalid && event.target.value < timeToChange[index].finishTime) {
      timeToChange[index].isClosingInvalid = false
      timeToChange[index].saveClosing = true
    }
    setTimes({ ...times, [day]: timeToChange })
  }

  const validateClosingTime = (day: string, index: number) => (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    let isInvalid = false
    const timeToChange = times[day]
    if (event.target.value === '') isInvalid = true
    if (times[day][index].startTime !== '' && event.target.value <= times[day][index].startTime) isInvalid = true
    timeToChange[index].isClosingInvalid = isInvalid
    timeToChange[index].saveClosing = !isInvalid
    if (!isInvalid && timeToChange[index].isOpeningInvalid && timeToChange[index].startTime < event.target.value) {
      timeToChange[index].isOpeningInvalid = false
      timeToChange[index].saveOpening = true
    }
    setTimes({ ...times, [day]: timeToChange })
  }

  const resetSave = (day: string, id: string, field: string): void => {
    const timeToChange = times[day]
    const index = timeToChange.findIndex((elem: any) => elem.id === id)
    if (index > -1) timeToChange[index][field] = false
    setTimes({ ...times, [day]: timeToChange })
  }

  return isReady ? (
    <>
      {Object.keys(days).map((weekDay: string) => (
        <Grid container spacing={4} key={`${weekDay}`} style={styles.dayGrid}>
          <Grid item xs={3} alignItems="flex-start">
            <FormControlLabel
              control={
                <Switch
                  checked={days[weekDay]}
                  onChange={handleDayChange(formData.id)}
                  name={`${weekDay}`}
                  color="primary"
                  disabled={createLoading || deleteLoading}
                />
              }
              label={translate(`dayOfWeek.${weekDay}`)}
            />
          </Grid>
          {dayToDelete === weekDay && deleteLoading ? (
            <CircularProgress />
          ) : (
            <Grid container item spacing={3} xs={6}>
              {days[weekDay] &&
                times[weekDay].map(
                  (
                    { id, startTime, finishTime, saveOpening, saveClosing, isOpeningInvalid, isClosingInvalid }: any,
                    index: number
                  ) => (
                    <Grid container spacing={3} key={`${weekDay}-${index}`} style={styles.timeGrid}>
                      <Grid item xs={3}>
                        <TextField
                          key={`${weekDay}-${index}-ot`}
                          variant="outlined"
                          type="time"
                          label="Inizio"
                          name="startTime"
                          value={startTime}
                          disabled={saveOpening}
                          onChange={handleTimeChange(`${weekDay}`, index)}
                          onBlur={validatestartTime(`${weekDay}`, index)}
                          error={times[weekDay][index].isOpeningInvalid}
                          {...{ InputLabelProps }}
                        />
                        {saveOpening && !isOpeningInvalid && (
                          <HourUpdater {...{ id, startTime, saveOpening, resetSave }} dayNumber={weekDay} />
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          key={`${weekDay}-${index}-ct`}
                          variant="outlined"
                          type="time"
                          label="Fine"
                          name="finishTime"
                          value={finishTime}
                          disabled={saveClosing}
                          onChange={handleTimeChange(`${weekDay}`, index)}
                          onBlur={validateClosingTime(`${weekDay}`, index)}
                          error={times[weekDay][index].isClosingInvalid}
                          {...{ InputLabelProps }}
                        />
                        {saveClosing && !isClosingInvalid && (
                          <HourUpdater {...{ id, finishTime, saveClosing, resetSave }} dayNumber={weekDay} />
                        )}
                      </Grid>
                      <HourDeleter confirmAction={handleRemoveClick(`${weekDay}`, index)} {...{ id }} />
                      {index === times[weekDay].length - 1 && (
                        <HourCreator
                          confirmAction={handleAddClick}
                          teamMemberId={formData.id}
                          dayNumber={weekDay}
                          disabled={
                            times[weekDay][index].startTime === '' ||
                            times[weekDay][index].finishTime === '' ||
                            times[weekDay][index].isOpeningInvalid ||
                            times[weekDay][index].isClosingInvalid
                          }
                        />
                      )}
                    </Grid>
                  )
                )}
            </Grid>
          )}
        </Grid>
      ))}
    </>
  ) : (
    <></>
  )
}

export default CourseScheduleHoursForm
