import React, { FC } from 'react'
import RAList from '../../components/List'
import { TextField, NumberField, Datagrid, ReferenceField, BooleanField } from 'react-admin'
import EditButtonWPermission from '../../components/EditButtonWPermission'
import CourseFilters from './CourseFilters'

const sort = { field: 'name', order: 'ASC' }
const filterDefaultValues = {}

type Props = {
  //
}

const CourseList: FC<Props> = (props) => (
  <RAList {...{ sort, filterDefaultValues }} {...props} filters={<CourseFilters />}>
    <Datagrid rowClick="show">
      <NumberField source="id" />
      <TextField source="name" />
      <EditButtonWPermission {...props} />
    </Datagrid>
  </RAList>
)

export default CourseList
